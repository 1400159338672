import { AgentCreateDto } from 'utils/types/BrokerModels';

const adminPanelInitialState: AgentCreateDto = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    smsPackage: 0
};

export const initialState = {
    initialState: () => {
        return adminPanelInitialState;
    }
};

