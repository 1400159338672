import React from 'react';
import dayjs from 'dayjs';
import { getNameFromModule } from 'utils/getNameFromModule';
import { SaleOfferResponseContent } from 'utils/state-managment/sale/saleOffer';
import { MetaValueDto } from 'utils/types/SaleModels';

import { InfoElementType } from 'components/common/Card/common/V2CardBodyDefault/InfoElements';
import { IconEnum } from 'components/common/Icon';
import { V2DetailsTabsProps } from 'components/common/Tabs/tabs/V2DetailsTab';

export type Offer = Omit<SaleOfferResponseContent, 'detailedContent'> & {
    detailedContent?: {
        description: string;
        metaValue?: MetaValueDto[];
    }
};

const generateInfoElements = (offer: Offer): InfoElementType[] => {
    return [
        {
            icon: IconEnum.BUILDING,
            isVisible: !!offer.module,
            children: <b>{getNameFromModule(offer.module)}</b>
        },
        {
            icon: IconEnum.WORK,
            isVisible: !!offer.typeOfMarket,
            children: <>Rynek <b>{offer.typeOfMarket}</b></>
        },
        {
            icon: IconEnum.SQUARE,
            isVisible: !!offer.area,
            children: <><b>{offer.area}</b> m<sup>2</sup></>
        },
        {
            icon: IconEnum.BED,
            isVisible: !!offer.numberOfRooms,
            children: <><b>{offer.numberOfRooms}</b> pokoje</>
        },
        {
            icon: IconEnum.FLOOR_LEVEL,
            isVisible: !!offer.floor,
            children: <><b>{offer.floor}</b> piętro</>
        },
        {
            icon: IconEnum.TWO_TYPE,
            isVisible: !!offer.buildingType,
            children: <><b>{offer.buildingType}</b></>
        },
        {
            icon: IconEnum.CALENDAR,
            isVisible: !!offer.builtYear,
            children: <><b>{offer.builtYear}</b></>
        },
        {
            icon: IconEnum.PAINT_ROLLER,
            isVisible: !!offer.buildingCondition,
            children: <><b>{offer.buildingCondition}</b></>
        }
    ];
};

const generateOfferDetailsTabContent = (offer: Offer) => {
    const price = offer.price || offer.priceBeforeModification;

    const isHouseType = offer.module === 'SALE_HOUSE';

    const detailsElements = [
        ...offer.lastUpdated && offer.lastUpdated !== offer.dateAdded
            ? [
                {
                    icon: IconEnum.SQUARE_TIMER,
                    text: 'Ostatnia modyfikacja:',
                    value: dayjs(offer.lastUpdated).format('DD.MM.YYYY, HH:mm')
                }
            ]
            : [],
        {
            icon: IconEnum.CALENDAR,
            text: 'Data dodania:',
            value: dayjs(offer.dateAdded).format('DD.MM.YYYY, HH:mm')
        },
        ...price
            ? [
                {
                    icon: IconEnum.PRICE,
                    text: 'Aktualna cena:',
                    value: `${price} złotych`
                }
            ]
            : [],
        ...offer.pricePerSquareMeter
            ? [
                {
                    icon: IconEnum.PRICE_PART,
                    text: 'Aktualna cena za m<sup>2</sup>:',
                    value: `${offer.pricePerSquareMeter} zł/m<sup>2</sup>`
                }
            ]
            : [],
        ...offer.area
            ? [
                {
                    icon: IconEnum.SQUARE,
                    text: 'Metraż:',
                    value: `${offer.area} m<sup>2</sup>`
                }
            ]
            : [],
        ...offer.numberOfRooms
            ? [
                {
                    icon: IconEnum.BED,
                    text: 'Liczba pokoi:',
                    value: `${offer.numberOfRooms} pokoje`
                }
            ]
            : [],
        ...offer.floor
            ? [
                {
                    icon: IconEnum.FLOOR_LEVEL,
                    text: 'Piętro:',
                    value: `${offer.floor} piętro`
                }
            ]
            : [],
        ...'floors' in offer && isHouseType
            ? [
                {
                    icon: IconEnum.FLOOR_LEVEL,
                    text: 'Liczba pięter:',
                    value: `${offer.floors} piętro`
                }
            ]
            : [],
        ...offer.module
            ? [
                {
                    icon: IconEnum.LEAVE,
                    text: 'Typ nieruchomości:',
                    value: getNameFromModule(offer.module)
                }
            ]
            : [],
        ...offer.buildingType
            ? [
                {
                    icon: IconEnum.TWO_TYPE,
                    text: 'Rodzaj budynku:',
                    value: offer.buildingType
                }
            ]
            : [],
        ...offer.builtYear
            ? [
                {
                    icon: IconEnum.CALENDAR,
                    text: 'Rok budowy:',
                    value: offer.builtYear.toString()
                }
            ]
            : [],
        ...offer.typeOfMarket
            ? [
                {
                    icon: IconEnum.WORK,
                    text: 'Rodzaj rynku:',
                    value: offer.typeOfMarket
                }
            ]
            : [],
        ...offer.offerFrom
            ? [
                {
                    icon: IconEnum.PEOPLE,
                    text: 'Oferta od:',
                    value: offer.offerFrom
                }
            ]
            : [],
        ...offer.portal
            ? [
                {
                    icon: IconEnum.LINK,
                    text: 'Portal:',
                    value: offer.portal
                }
            ]
            : [],
        ...offer.buildingCondition
            ? [
                {
                    icon: IconEnum.PAINT_ROLLER,
                    text: 'Stan nieruchomości:',
                    value: offer.buildingCondition
                }
            ]
            : []
    ];

    const offerDetailsTabContent: V2DetailsTabsProps = {
        title: offer.title,
        address: offer.location,
        detailsElements,
        description: offer.detailedContent?.description,
        meta: offer.detailedContent?.metaValue
    };

    return offerDetailsTabContent;
};

export const saleOfferListFunctions = {
    generateInfoElements,
    generateOfferDetailsTabContent
};
