import styled from 'styled-components';

export const BoxContainer = styled.div`
  padding: 30px;
  background-color: var(--color-white);
  border-radius: var(--box-border-radius);
  position: relative;
  margin-bottom: 15px;
  grid-column-end: span 2;
  
  &.users-media {
    padding: 10px 0;
  }

  &.swipeable {
    @media (max-width: 1100px) {
      padding: 30px 0;
    }
  }

  & > h1 {
    padding: 10px 15px;
  }
`;

