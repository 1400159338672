import { PropertyType } from 'utils/enums/propertyType';
import { Module as RentModuleType } from 'utils/types/RentModels';
import { SaleModuleType } from 'utils/types/Sale';

export enum NormalizedPropertyType {
    FLAT = 'flat',
    HOUSE = 'house',
    PLOT = 'plot',
    OTHER = 'other',
}

type AllPropertyTypes = PropertyType | RentModuleType | SaleModuleType | NormalizedPropertyType;

export type PropertyTypes = never;

export const getNormalizedPropertyType = (type: AllPropertyTypes) => {
    switch (type) {
    case PropertyType.HOUSE:
    case RentModuleType.RENT_HOUSE:
    case SaleModuleType.SALE_HOUSE:
        return NormalizedPropertyType.HOUSE;
    case PropertyType.PLOT:
    case RentModuleType.RENT_PLOT:
    case SaleModuleType.SALE_PLOT:
        return NormalizedPropertyType.PLOT;
    case PropertyType.OTHER:
    case RentModuleType.RENT_OTHER:
    case SaleModuleType.SALE_OTHER:
        return NormalizedPropertyType.OTHER;
    case PropertyType.FLAT:
    case RentModuleType.RENT_FLAT:
    case SaleModuleType.SALE_FLAT:
    default:
        return NormalizedPropertyType.FLAT;
    }
};
