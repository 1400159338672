
import { axiosApiInstance } from 'Store';
import {
    CitiesType,
    LocationOptionsType, SearchLocationResult,
    SearchLocationResultType,
    Voivodeship,
    VoivodeshipCities
} from 'utils/types/Locations';

import { activeNotification } from 'components/functions/activeNotification';

const getVoivodeships = async (): Promise<LocationOptionsType[]> => {
    return axiosApiInstance
        .get<Voivodeship[]>('/location/voivodeships')
        .then((resp) => {
            if (resp.data) {
                return resp.data.map((el) => ({
                    ...el,
                    label: el.name,
                    fullName: el.name,
                    regionNumber: null,
                    cities: []
                }));
            }

            return [];
        })
        .catch(() => {
            return [];
        });
};

const getVoivodeshipCities = async (id: number): Promise<CitiesType[]> => {
    return axiosApiInstance
        .get<VoivodeshipCities[]>(`/location/voivodeship-cities?voivodeship=${id}`)
        .then((resp) => {
            if (resp.data) {
                return resp.data.map((el) => ({
                    ...el.city,
                    label: el.city.name,
                    districts: el.districts?.map((district) => ({
                        ...district,
                        label: district.name
                    }))
                }));
            }

            return [];
        })
        .catch(() => {
            return [];
        });
};

const getLocationsFromSearch = async (search: string): Promise<SearchLocationResultType[]> => {
    return axiosApiInstance
        .get<SearchLocationResult[]>(`/location/match?location=${search}`)
        .then((resp) => {
            if (resp.data) {
                return resp.data.map((el) => ({
                    ...el,
                    label: el.name,
                    fullName: el.name
                }));
            }

            return [];
        })
        .catch(() => {
            return [];
        });
};

const submitRecommendedPage = (message: string) => {
    axiosApiInstance
        .post('sendEmail', { message })
        .then(() => {
            activeNotification('Strona została przekazana', 'Dziękujemy! ', 'success');
        })
        .catch(() => {
            activeNotification(
                'Coś poszło nie tak!',
                'Spróbuj ponownie',
                'danger'
            );
        });
};

const getPageInCityWhereScannerWorks = (city: string): Promise<string[]> => {
    return axiosApiInstance
        .get(`cooperative/showScrappedServices?city=${city}`)
        .then((res) => {
            return res.data;
        })
        .catch(() => {
            activeNotification(
                'Coś poszło nie tak!',
                'Spróbuj ponownie',
                'danger'
            );
        });
};

export const filtersApiRequests = {
    submitRecommendedPage,
    getPageInCityWhereScannerWorks,
    getVoivodeships,
    getVoivodeshipCities,
    getLocationsFromSearch
};
