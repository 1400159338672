import React, { createContext, useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { BrokerOfferMainModule } from 'pages/BrokerOffersPage';
import { UserActions } from 'store/User/Actions';
import { useUser } from 'store/User/Context';
import { brokerApiRequests } from 'utils/api-requests/broker';
import { filtersApiRequests } from 'utils/api-requests/filters';
import { getModuleByBrokerType } from 'utils/formatters/getModuleByBrokerType';
import { getSubModuleByBrokerType } from 'utils/formatters/getSubModuleByBrokerType';
import { brokerOffersInitialState, BrokerStateType } from 'utils/state-managment/broker/brokerOffer';
import { initialState } from 'utils/state-managment/broker/manageAdvert';
import { BrokerCreateAdvertStateType } from 'utils/types/BrokerAdvert';
import { ObjectAndSingleValueChangeType } from 'utils/types/InputTypes';
import { AdvertisementPhotoParamsModuleEnum } from 'utils/types/UserModels';
import { AdvertValidatorType, isFieldValid } from 'utils/validators/advertAddingValidator';

interface BrokerAdvertType {
    handleChange: (name: string, value: ObjectAndSingleValueChangeType) => void
    brokerAdvertFieldsState: BrokerCreateAdvertStateType
    validateField: (name: AdvertValidatorType, value: ObjectAndSingleValueChangeType) => boolean
    clearFields: () => void
    validateAllFields: () => boolean
    handleFillAdvertFields: (data: BrokerCreateAdvertStateType) => void,
    loadBrokerOffers: (brokerOfferModule: BrokerOfferMainModule, page?: number, size?: number, submodule?: string) => void,
    brokerOffersState: BrokerStateType,
    loadBrokerOfferDetailsAsync: (id: number, submodel: string) => void,
    deleteBrokerOffer: (id: number, subtype: string) => void,
    changeActivationStatus: (id: number, submodule: string, activationStatus: boolean) => void,
    generateAiTitle: () => void,
    generateAiDescription: () => void
}

const values: BrokerAdvertType = {
    handleChange: () => {},
    brokerAdvertFieldsState: {} as BrokerCreateAdvertStateType,
    validateField: () => false,
    clearFields: () => {},
    validateAllFields: () => false,
    handleFillAdvertFields: () => {},
    loadBrokerOffers: () => {},
    brokerOffersState: {} as BrokerStateType,
    loadBrokerOfferDetailsAsync: () => {},
    deleteBrokerOffer: () => {},
    changeActivationStatus: () => {},
    generateAiTitle: () => {},
    generateAiDescription: () => {}
};

const BrokerAdvertContext = createContext(values);

export const BrokerAdvertProvider = ({ children }: { children: React.ReactNode }) => {
    const [brokerAdvertFieldsState, setBrokerAdvertFieldsState] = useState(initialState.initialState());
    const [brokerOffersState, setBrokerOffersState] = useState(brokerOffersInitialState);

    const { dispatch: dispatchUser } = useUser();
    const baseRequiredFields = [
        'cityName',
        'title',
        'description'
    ];

    const setLoadingPage = () => {
        setBrokerOffersState((prev) => ({
            ...prev,
            isLoading: true
        }));
    };

    const handleChange = useCallback((fieldName: string, value: ObjectAndSingleValueChangeType) => {
        const isPriceOrArea = ['price', 'area'].includes(fieldName);
        setBrokerAdvertFieldsState((filters: BrokerCreateAdvertStateType) => ({
            ...filters,
            [fieldName]: value
        }));

        if (isPriceOrArea) {
            let price = brokerAdvertFieldsState.price;
            let area = brokerAdvertFieldsState.area;

            if (fieldName === 'price') {
                price = value as number;
            }

            if (fieldName === 'area') {
                area = value as number;
            }

            if (price && area) {
                const pricePerSquareMeter = (Number(price) / Number(area)).toFixed(2);
                handleChange('pricePerSquareMeter', pricePerSquareMeter);
            }
        }
    }, []);

    const handleFillAdvertFields = useCallback((fieldsData: BrokerCreateAdvertStateType) => {
        getVoivodeships();
        setBrokerAdvertFieldsState((prev) => ({ ...initialState.initialState(), ...fieldsData, locations: prev.locations, offerType: prev.offerType }));
    }, []);

    const validateAllFields = () => {
        return baseRequiredFields.every((item) => isFieldValid(AdvertValidatorType.NO_EMPTY_TEXT, brokerAdvertFieldsState[item as keyof BrokerCreateAdvertStateType] as string));
    };

    const clearFields = () => {
        setBrokerAdvertFieldsState((prev) => ({ ...initialState.initialState(), offerType: prev.offerType, locations: prev.locations }));
    };

    const getVoivodeships = useCallback(async () => {
        const locations = await filtersApiRequests.getVoivodeships();
        setBrokerAdvertFieldsState((filters: BrokerCreateAdvertStateType) => ({
            ...filters,
            locations
        }));
    }, []);

    useEffect(() => {
        UserActions.clearOffers(dispatchUser);
    }, [dispatchUser]);

    useEffect(() => {
        if (brokerAdvertFieldsState.locations) {
            getVoivodeships();
        }
    }, []);

    const handleChangeDateList = (fieldName: string, value: number) => {
        setBrokerAdvertFieldsState((filters: BrokerCreateAdvertStateType) => ({
            ...filters,
            offerAdded: {
                from: value === -1
                    ? null
                    : dayjs().subtract(value, 'day').toISOString()
                        .split('T')[0],
                to: null
            },
            [fieldName]: value
        }));
    };

    const loadBrokerOffers = async (brokerOfferModule: BrokerOfferMainModule, page?: number, size?:number, submodule?: string) => {
        setBrokerOffersState((prev) => ({
            ...prev,
            isLoading: true
        }));

        const response = brokerOfferModule === BrokerOfferMainModule.SALE
            ? await brokerApiRequests.getSaleBrokerOffers(page ?? 1, size ?? 25, submodule)
            : await brokerApiRequests.getRentBrokerOffers(page ?? 1, size ?? 25, submodule);

        if (response) {
            setBrokerOffersState((prev) => ({
                ...prev,
                brokerOfferList: {
                    content: response.content,
                    empty: response.empty,
                    pageable: response.pageable,
                    totalPages: response.totalPages,
                    numberOfElements: response.numberOfElements,
                    totalElements: response.totalElements,
                    size: response.size,
                    last: response.last
                },
                isLoading: false
            }));
        }

        return { empty: true, content: [], afterSearch: true };
    };

    const loadBrokerOfferDetailsAsync = async (id: number, submodule: string) => {
        setLoadingPage();
        // @ts-expect-error fix type after Api changes
        const response = await brokerApiRequests.getBrokerOfferDetailsForEdit(getModuleByBrokerType(submodule), getSubModuleByBrokerType(submodule as unknown as AdvertisementPhotoParamsModuleEnum), id);

        setBrokerOffersState((prevState) => ({
            ...prevState,
            brokerOfferList: {
                ...prevState.brokerOfferList,
                content: prevState.brokerOfferList.content.map((el) => {
                    if (el.advertisementId === response.advertisementId) {
                        return {
                            ...el,
                            detailedContent: response
                        };
                    }

                    return el;
                })
            },

            isLoading: false
        }));
    };

    const deleteBrokerOffer = async (id: number, submodule: string) => {
        setLoadingPage();
        await brokerApiRequests.deleteBrokerOffer(id, submodule);

        setBrokerOffersState((prevState) => ({
            ...prevState,
            brokerOfferList: {
                ...prevState.brokerOfferList,
                content: prevState.brokerOfferList.content.filter((el) => el.advertisementId !== id)
            },
            isLoading: false
        }));
    };

    const changeActivationStatus = async (id: number, submodule: string, activationStatus: boolean) => {
        setLoadingPage();
        await brokerApiRequests.changeActivationStatus(id, submodule, activationStatus);

        setBrokerOffersState((prevState) => ({
            ...prevState,
            brokerOfferList: {
                ...prevState.brokerOfferList,
                content: prevState.brokerOfferList.content.map((offer) => offer.advertisementId === id
                    ? { ...offer, activationStatus: !activationStatus }
                    : offer)
            },
            isLoading: false
        }));
    };

    const generateAiTitle = async () => {
        setLoadingPage();
        const newTitle = await brokerApiRequests.generateAiTitle(brokerAdvertFieldsState.encryptedId);

        setBrokerAdvertFieldsState((prevState) => ({
            ...prevState,
            title: newTitle
        }));

        setBrokerOffersState((prevState) => ({
            ...prevState,
            isLoading: false
        }));
    };

    const generateAiDescription = async () => {
        setLoadingPage();
        const newDescription = await brokerApiRequests.generateAiDescription(brokerAdvertFieldsState.encryptedId);

        setBrokerAdvertFieldsState((prevState) => ({
            ...prevState,
            description: newDescription
        }));

        setBrokerOffersState((prevState) => ({
            ...prevState,
            isLoading: false
        }));
    };

    const userFiltersContextValues = {
        brokerOffersState,
        brokerAdvertFieldsState,
        handleChange,
        clearFields,
        validateField: isFieldValid,
        validateAllFields,
        handleFillAdvertFields,
        handleChangeDateList,
        loadBrokerOffers,
        loadBrokerOfferDetailsAsync,
        deleteBrokerOffer,
        changeActivationStatus,
        generateAiTitle,
        generateAiDescription

    };

    return (
        <BrokerAdvertContext.Provider value={userFiltersContextValues}>
            {children}
        </BrokerAdvertContext.Provider>
    );
};

export default BrokerAdvertContext;
