import { SavedFilter } from './Filter';
import { BaseStateType } from './FiltersStateType';
import { MultipleFilterType } from './InputTypes';
import { CitiesType, LocationOptionsType, SavedLocationsType, SearchLocationResultType } from './Locations';
import {
    FlatBuildingTypeEnum,
    FlatsFilterDto, HouseBuildingTypeEnum,
    HousesFilterDto, Module, OfferFromEnum,
    OtherBuildingTypeEnum,
    OtherFilterDto,
    PlotBuildingTypeEnum,
    PlotsFilterDto, SortType, TypeOfMarketEnum
} from './SaleModels';

// TODO: Remove and use from SaleModels
export enum SaleModuleType {
    SALE_FLAT = 'SALE_FLAT',
    SALE_HOUSE = 'SALE_HOUSE',
    SALE_PLOT = 'SALE_PLOT',
    SALE_OTHER = 'SALE_OTHER',
}

export interface SaleFiltersStateType extends Omit<BaseStateType, 'buildingType'> {
    id?: number,
    city: CitiesType[],
    tossedUp: boolean,
    notificationsDelay: string,
    groupDuplicate: boolean,
    includeZeroArea: boolean,
    savedFilter: [] | SavedFilter<Module>[] // override module type
    module: Module,
    modification: boolean,
    offerAdded: MultipleFilterType,
    wantedPhoneNumbers: string[],
    unwantedPhoneNumbers: string[],
    offerFrom: OfferFromEnum[],
    locations: LocationOptionsType[],
    searchLocationResult: SearchLocationResultType[],
    savedLocations: SavedLocationsType,
    floors: MultipleFilterType,
    sortType: SortType,
    buildingType: PlotBuildingTypeEnum[] & OtherBuildingTypeEnum[] & HouseBuildingTypeEnum[] & FlatBuildingTypeEnum[],
    typeOfMarket: TypeOfMarketEnum[],
    lowerOriginalPrice: boolean,
    unlockedAdvertisements: boolean,
}

type SaleFilterEditDto = PlotsFilterDto & OtherFilterDto & HousesFilterDto & FlatsFilterDto;

export type SaleFilterReturnData = SaleFilterEditDto & {
    savedFilter?: [] | SavedFilter<Module>[],
    locations: LocationOptionsType[],
    searchLocationResult: SearchLocationResultType[],
    savedLocations: SavedLocationsType,
}
