import React, { ChangeEvent, FC, ReactNode, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';

import Icon, { IconEnum } from 'components/common/Icon';

const Container = styled.span<{ isEditMode: boolean } >`
    position: relative;
    display: flex;
    gap: 10px;
    line-height: 20px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: var(--color-primary);
    padding: 15px 10px 15px 15px;
    height: 50px;
`;

const Input = styled.input`
    font-weight: var(--font-weight);
    font-size: var(--font-size-body-2);
    line-height: 20px;
    width: 100%;
    flex: 1;
    background: transparent;
    border: none;
    color: var(--color-secondary);
    outline: none;
    text-align: left;
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    padding: 0 10px;
    gap: 10px;
    width: 100%;
    color: var(--color-primary);
    font-weight: var(--font-weight);
    font-size: var(--font-size-body-2);
    line-height: 0;

    @media (max-width: 1475px) {
        line-height: 1;
    }
`;

const Label = styled.span`
    font-weight: var(--font-weight);
    font-size: var(--font-size-body-2);
    line-height: 20px;
    background: transparent;
    border: none;
    color: var(--color-primary);
`;

const RightPartBoxWrapper = styled.div`
    display: flex;
    width: 35%;
    align-items: center;
    justify-content: space-between;
    background: var(--color-alt-second);
    color: var(--color-primary);
    padding: 15px 8px 15px 19px;
    border-radius: var(--border-radius);
    height: 50px;

    &.is-edit-mode {
         border: 1px solid var(--color-primary);
    }
`;

const ValueBox = styled.div`
    line-height: 30px;
`;

const InputIcon = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    background-color: ${(props) => props.darkMode ? 'var(--color-white)' : 'inherit'};

    img {
        color: ${(props) => props.darkMode ? 'white' : 'inherit'};
    }

`;

interface ActionsType {
    actionText: string;
    actionClick: () => void;
    isEditable: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
    onOutsideClick?: () => void,
}

export interface TextBoxStructureType {
    title: string;
    titleIcon?: IconEnum;
    label: string;
    value: number | string;
    labelIcon?: IconEnum;
    actions?: ActionsType;
    editedValue?: string | number;
}

interface TextBoxProperties {
    children?: ReactNode,
    title?: string,
    icon?: IconEnum,
    actionText?: string,
    onActionClick?: () => void,
    isEditMode?: boolean,
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
    label: string,
    value: string | number,
    labelIcon?: IconEnum,
    editedValue?: string | number,
    separateBoxes?: boolean,
    onOutsideClick?: () => void,
}

const TextBox: FC<TextBoxProperties> = ({ children, title, icon, onActionClick, isEditMode, label, editedValue, value, labelIcon, onChange, onOutsideClick }) => {
    const { user } = useUser();
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node) && isEditMode && onOutsideClick) {
                onOutsideClick();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isEditMode, onActionClick]);

    return (
        <>
            {title && <Title>
                {icon && <Icon icon={icon} />}
                {title}
            </Title>}

            <Container ref={containerRef} isEditMode={isEditMode}>
                <Label>{labelIcon && <Icon icon={labelIcon}/>}{label}</Label>
                <RightPartBoxWrapper className={clsx({ 'is-edit-mode': isEditMode })}>
                    {isEditMode
                        ? <Input value={editedValue} alignRight={true} onChange={onChange}/>
                        : <ValueBox>{value}</ValueBox>
                    }
                    {onActionClick && <InputIcon onClick={onActionClick} darkMode={user.isDarkMode} >
                        <Icon icon={isEditMode ? IconEnum.SEND : IconEnum.EDIT}/>
                    </InputIcon>}
                    {children && children}
                </RightPartBoxWrapper>
            </Container>
        </>
    );
};

export default TextBox;
