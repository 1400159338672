import { FavoritePageFiltersType } from 'pages/FavoritesPage';
import qs from 'qs';
import { axiosApiInstance } from 'Store';
import { BailiffOffersResponse } from 'utils/state-managment/bailiff/bailiffOffer';
import { RentOfferResponseContent, RentOffersResponse } from 'utils/state-managment/rent/rentOffer';
import { SaleOffersResponse } from 'utils/state-managment/sale/saleOffer';
import { OfferData } from 'utils/types/OfferData';

import { activeNotification } from 'components/functions/activeNotification';
import { compareDateDescending } from 'components/Sort';

const getUsersFavoriteOffers = (type: string, page?: number, size?: number, filters?: FavoritePageFiltersType): Promise<RentOffersResponse[] | OfferData[] | SaleOffersResponse[] | BailiffOffersResponse[]> => {
    return axiosApiInstance({
        method: 'get',
        url: `/favorite/find?module=${type}`,
        params: {
            ...filters,
            page,
            size
        },
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        }
    })
        .then((resp) => {
            if (resp.data.content && resp.data.content.length > 0) {
                const content = resp.data.content.map((x: RentOfferResponseContent) => {
                    if (type === 'BAILIFF_NOTICE') {
                        return {
                            ...x,
                            bailiffNoticeId: x.advertisementId
                        };
                    } else if (type === 'TENDER') {
                        return {
                            ...x,
                            tenderId: x.advertisementId
                        };
                    } else if (type === 'AUCTION') {
                        return {
                            ...x,
                            auctionId: x.advertisementId
                        };
                    } else if (type === 'COOPERATIVE') return {
                        ...x,
                        cooperativeId: x.advertisementId
                    };

                    return x;
                });

                return {
                    ...resp.data,
                    pageable: {
                        ...resp.data.pageable,
                        pageNumber: resp.data.pageable.pageNumber + 1
                    },
                    content: content.sort(compareDateDescending)
                };
            }

            return [{ noItems: true }];
        })

        .catch(() => {
            activeNotification(
                'Nie znaleziono wyników',
                'Obecnie w naszej bazie nie posiadamy ogłoszeń, które spełniają twoje kryteria. Spróbuj zmienić kryteria i wyszukaj jeszcze raz.',
                'warning'
            );

            return [{ noItems: true }];
        })
        .catch(() => {
            activeNotification(
                'Nie znaleziono wyników',
                'Obecnie w naszej bazie nie posiadamy ogłoszeń, które spełniają twoje kryteria. Spróbuj zmienić kryteria i wyszukaj jeszcze raz.',
                'warning'
            );

            return [];
        });
};

export const offersApiRequests = {
    getUsersFavoriteOffers
};
