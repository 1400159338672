import React, { FC, MouseEvent, useCallback, useState } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { formatDate } from 'utils/formatters/formatDate';
import { stringToBoolean } from 'utils/formatters/stringToBoolean';
import { useWindowWidth } from 'utils/useWindowWidth';

import NoteBox, { Note } from 'components/common/NoteBox';
import Slider, { SliderImages } from 'components/common/Slider/Slider';
import { TabType } from 'components/common/Tabs/V2Tabs';

import InfoBadge from './common/InfoBadge';
import V2CardBodyDefault, { V2CardBodyProperties } from './common/V2CardBodyDefault/V2CardBodyDefault';

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    border-radius: var(--box-border-radius);
    background-color: var(--color-white);
    padding: 15px;
    gap: 22px;
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
        transition: box-shadow 0.3s ease-in-out;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.07);
    }

    & > div:nth-child(1) {
        width: 100%;
        height: 150px;
    }

    & > div:nth-child(2) {
        width: 100%;
        height: auto;
    }

    &.grey {
        opacity: 0.5;
    }
    
    @media (min-width: 991px) {
        grid-template-columns: 240px 1fr;

        &.bigImage {
            grid-template-columns: 300px 1fr;
        }

        & > div:nth-child(1) {
            width: 240px;
        }

        & > div:nth-child(2) {
            width: 100%;
            height: auto;
        }
    }
`;

export const InfoBoxContainer = styled.div`
    position: absolute;
    top: 0;
    width: fit-content;
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
`;

const CardImageContainer = styled.div`
    background: var(--color-alt-second);
    border-radius: var(--box-border-radius);
    overflow: hidden;
    position: relative;
    min-height: 240px;

    &.big {
        min-height: 250px;
        min-width: 300px;

        @media (max-width: 991px) {
            min-height: 240px;
            min-width: 240px;
        }
    }
`;

const ImgStyled = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out;

    &:hover {
        transition: transform 0.3s ease-in-out;
        transform: scale(1.2);
    }
`;

export type CardData = {
    date?: string;
    modificationDate?: string;
    additionalInfoBadge?: string;
    images?: SliderImages[];
    thumbnailPath?: string;
    note?: Note;
} & V2CardBodyProperties;

export interface V2BaseCardProperties {
    onClick?: (event: React.MouseEvent) => void;
    openOfferTab: (tab: TabType) => void;
    cardData: CardData;
    bigImage?: boolean;
    isHalfTransparent?: boolean;
}

const V2BaseCard: FC<V2BaseCardProperties> = ({
    onClick,
    openOfferTab,
    cardData,
    bigImage,
    isHalfTransparent
}) => {
    const { date, modificationDate, additionalInfoBadge, images } = cardData;
    const [offerDate, setOfferDate] = useState(modificationDate || date);
    const [isSuccess, setIsSuccess] = useState(!modificationDate);
    const width = useWindowWidth();

    const handleOpenOfferTab = (tab: TabType) => {
        openOfferTab(tab);
    };

    const handleBadgeChange = (e: MouseEvent) => {
        if (modificationDate && modificationDate !== date && width <= 1200) {
            e.stopPropagation();
            setOfferDate((prevDate) => prevDate === modificationDate ? date : modificationDate);
            setIsSuccess((prev) => !prev);
        }
    };

    const onMouseLeave = useCallback(() => {
        if (modificationDate && width > 1100) {
            setTimeout(() => {
                setOfferDate(modificationDate);
                setIsSuccess(false);
            }, 150);
        }
    }, [width, modificationDate]);

    const onMouseEnter = useCallback(() => {
        if (modificationDate && modificationDate !== date && width > 1100) {
            setTimeout(() => {
                setOfferDate(date);
                setIsSuccess(true);
            }, 150);
        }
    }, [modificationDate, width]);

    return (
        <Container onClick={onClick} className={clsx({ bigImage: bigImage, grey: isHalfTransparent })}>
            <CardImageContainer className={clsx({ big: bigImage })}>
                {images && images.length > 1
                    ? <Slider images={images} title={cardData.title} isHovered/>
                    : <ImgStyled src={cardData.thumbnailPath && !cardData.thumbnailPath.includes('fileName=null') ? cardData.thumbnailPath : '/defaultImg.png'}/>}
                <InfoBoxContainer>
                    {offerDate
                        ? <InfoBadge onClick={width <= 1200 ? handleBadgeChange : undefined}
                            isWhite
                            onMouseLeave={onMouseLeave}
                            onMouseEnter={onMouseEnter}
                            isSuccess={isSuccess} isWarning={!isSuccess} showInfoIcon={stringToBoolean(modificationDate)}>
                            {formatDate(offerDate)}
                        </InfoBadge>
                        : null}
                    {additionalInfoBadge && <InfoBadge isWhite>
                        {additionalInfoBadge}
                    </InfoBadge>}
                </InfoBoxContainer>
            </CardImageContainer>
            <V2CardBodyDefault {...{ ...cardData, openOfferTab }} />
            {cardData.note
                ? <NoteBox newestNote={cardData.note} openOfferTab={() => handleOpenOfferTab(TabType.NOTES)}/>
                : null}
        </Container>
    );
};

export default V2BaseCard;
