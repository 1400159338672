import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { cooperativeOffersApiRequests } from 'utils/api-requests/cooperative';
import CooperativeContext from 'utils/context/CooperativeContext';
import { createUniqueFilterName } from 'utils/createUniqueFilterName';
import { scheduleOptions } from 'utils/options';
import { getCooperativeValueFromState } from 'utils/state-managment/cooperative/cooperativeFilter';
import { CooperativeFiltersStateType } from 'utils/types/Cooperative';
import { InputType, LocationChangeType } from 'utils/types/InputTypes';
import { useWindowWidth } from 'utils/useWindowWidth';

import Button from 'components/atom/Button';
import AccordionCard from 'components/common/AccordionCard';
import StickyBottom from 'components/common/FiltersRenderer/StickyBottom';
import V2FiltersRenderer from 'components/common/FiltersRenderer/V2FiltersRenderer';
import { IconEnum } from 'components/common/Icon';
import InputContainer from 'components/common/InputContainer';
import TextInput from 'components/common/Inputs/TextInput';
import { activeNotification } from 'components/functions/activeNotification';
import { getVoivodeshipCities } from 'components/functions/locations';

type FiltersCooperativeProperties = object;

const FiltersCooperative: FC<FiltersCooperativeProperties> = () => {
    const {
        cooperativeFiltersState,
        cooperativeOfferState,
        handleChange,
        handleDeleteFilter,
        handleSetFiltersValues,
        handleChangeDateList,
        getSavedFilters,
        clearFilters,
        loadCooperativeOffers
    } = useContext(CooperativeContext);

    const [isFilterButtonVisible, setIsFilterButtonVisible] = useState(false);
    const [portalName, setPortalName] = useState('');
    const width = useWindowWidth();

    const getCities = async (voivodeship: string) => {
        const newLocations = await getVoivodeshipCities(voivodeship, cooperativeFiltersState.locations);
        handleChange('locations', newLocations || []);
    };

    const handleRemoveSavedLocation = (location: string) => {
        const savedLocations = cooperativeFiltersState.savedLocations;
        delete savedLocations[location];

        handleChange('savedLocations', savedLocations);
        handleChange('city', Object.values(savedLocations as LocationChangeType));
    };

    const locationCompleted =
       cooperativeFiltersState.city.length > 0 &&
       !(cooperativeFiltersState.city.length === 0);

    const handleSubmit = async () => {
        if (!locationCompleted) {
            activeNotification('Popraw wpisane dane', 'Musisz wybrać poprawną miejscowość', 'warning');

            return;
        } else {
            loadCooperativeOffers();
            document.body.scrollTo(0, 0);
        }
    };

    const submitRecommendedPage = (url: string) => {
        if (!portalName) {
            activeNotification('Nazwa Portala', 'Pole nie może być puste', 'warning');

            return;
        }
        cooperativeOffersApiRequests.addPortalUrl(url);
        setPortalName('');
    };

    const handleSaveOrUpdateFilter = async () => {
        if (!locationCompleted) {
            activeNotification('Popraw wpisane dane', 'Musisz wybrać poprawną miejscowość', 'warning');

            return;
        }

        const existFilter = cooperativeFiltersState.savedFilter?.find(
            (filter) => filter.filterId === cooperativeFiltersState.filterId
        );

        const existName = cooperativeFiltersState.savedFilter?.find(
            (filter) => filter.name === cooperativeFiltersState.name
        );

        let id;

        if (existName && !cooperativeFiltersState.filterId) {
            activeNotification('Nazwa filtra', 'Istnieje już filtr o takiej nazwie', 'warning');

            return;
        }

        if (existFilter) {
            await cooperativeOffersApiRequests.updateFilter({
                ...getCooperativeValueFromState(cooperativeFiltersState)
            });
        } else {
            const resp = await cooperativeOffersApiRequests.addFilter({
                ...getCooperativeValueFromState(cooperativeFiltersState)
            });

            id = resp?.data;
        }

        getSavedFilters(id);
    };

    const handleCreateFromExistFilter = async () => {
        if (cooperativeFiltersState.city.length <= 0) {
            activeNotification('Popraw wpisane dane', 'Musisz wybrać poprawną miejscowość', 'warning');

            return;
        }

        const newName = createUniqueFilterName(cooperativeFiltersState.name!, cooperativeFiltersState.savedFilter);

        const newFilterData = {
            ...getCooperativeValueFromState(cooperativeFiltersState),
            name: newName
        };

        const resp = await cooperativeOffersApiRequests.addFilter(newFilterData);

        const id = resp?.data;

        getSavedFilters(id);
    };

    useEffect(() => {
        getSavedFilters();
    }, []);

    const handleChangeNotificationAlert = async (valueName: string, id: number) => {
        const fieldName = valueName === 'pushAlert' ? 'alertPush' : valueName;
        const updatedState = {
            ...cooperativeFiltersState,
            [fieldName]: !cooperativeFiltersState[fieldName as keyof CooperativeFiltersStateType]
        };

        await cooperativeOffersApiRequests.updateFilter(getCooperativeValueFromState(updatedState));
        getSavedFilters(id);
    };

    const cooperativeFiltersStructure = [
        {
            accordionTitle: 'Podstawowe',
            hideTitle: true,
            filters: [
                {
                    title: 'Zapisane filtry',
                    type: InputType.FILTER_AUTOCOMPLETE,
                    icon: IconEnum.BOOKMARK,
                    placeholder: 'Wybierz filtr...',
                    options: {
                        [InputType.FILTER_AUTOCOMPLETE]: {
                            contextFunctions: {
                                handleDeleteFilter,
                                handleSetFiltersValues
                            },
                            modulesProps: {
                                filterId: cooperativeFiltersState.filterId ?? null,
                                options: cooperativeFiltersState.savedFilter.map((x) => ({
                                    ...x,
                                    pushAlert: x.alertPush,
                                    id: x.filterId
                                }
                                ))
                            }
                        }
                    }
                },
                {
                    title: 'Lokalizacja',
                    type: InputType.LOCATION,
                    helperText: 'Wybierz z listy interesującą Ciebie lokalizację. Możesz zaznaczyć dowolną ilość.',
                    icon: IconEnum.MAP_PIN,
                    placeholder: 'Szukaj lokalizacji...',
                    options: {
                        [InputType.LOCATION]: {
                            modulesProps: {
                                locations: cooperativeFiltersState.locations,
                                searchLocationResult: cooperativeFiltersState.searchLocationResult,
                                savedLocations: cooperativeFiltersState.savedLocations,
                                savedLocationsFieldName: 'savedLocations',
                                cityFieldName: 'city'
                            },
                            contextFunctions: {
                                getCities,
                                handleRemoveSavedLocation,
                                handleChange
                            }
                        }
                    }
                },
                {
                    title: 'Data',
                    type: InputType.FLEXIBLE_DATE,
                    helperText: 'Wybierz, z jakiego okresu chcesz wyświetlić oferty. Wybierz opcję "Przedział dat" aby precyzyjnie wskazać datę. Ten parametr nie wpływa na natychmiastowe wysyłanie alertów.',
                    icon: IconEnum.CALENDAR,
                    placeholder: 'Data dodania oferty...',
                    containerActionText: 'Przedział dat',
                    containerActionClick: () => handleChange('dateRangeFilter', !cooperativeFiltersState.dateRangeFilter),
                    options: {
                        [InputType.FLEXIBLE_DATE]: {
                            modulesProps: {
                                date: cooperativeFiltersState.offerAdded,
                                dateRangeFilter: cooperativeFiltersState.dateRangeFilter,
                                daysAmount: cooperativeFiltersState.daysAmount,
                                fieldName: 'offerAdded'
                            },
                            contextFunctions: {
                                handleChange,
                                handleChangeDateList
                            }
                        }
                    }
                }
            ]
        },
        {
            accordionTitle: 'Parametry',
            hideTitle: true,
            filters: [
                {
                    title: 'Słowa pożądane',
                    type: InputType.KEYWORDS_TEXT,
                    helperText: 'Podaj słowa kluczowe, które mają zawierać się w tytule lub opisie ogłoszenia, rozdzielając je przecinkiem. Więcej w zakładce Pomoc',
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Wpisz słowo...',
                    options: {
                        [InputType.KEYWORDS_TEXT]: {
                            modulesProps: {
                                value: cooperativeFiltersState.wantedKeywords.filter(Boolean) as string[],
                                fieldName: 'wantedKeywords',
                                icon: IconEnum.SEARCH,
                                actionIcon: IconEnum.PLUS,
                                errorMessage: 'Słowo musi mieć minimum 3 znaki',
                                minLength: 3
                            },
                            contextFunctions: {
                                inputValidation: (val: string | number | null | undefined) => (val as string).length > 0 && (val as string).length < 3,
                                handleChange
                            }
                        }
                    }
                },
                {
                    title: 'Słowa niepożądane',
                    type: InputType.KEYWORDS_TEXT,
                    helperText: 'Podaj słowa kluczowe, które nie mają zawierać się w tytule lub opisie ogłoszenia, rozdzielając je przecinkiem. Więcej w zakładce Pomoc',
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Wpisz słowo...',
                    options: {
                        [InputType.KEYWORDS_TEXT]: {
                            modulesProps: {
                                value: cooperativeFiltersState.unwantedKeywords.filter(Boolean) as string[],
                                fieldName: 'unwantedKeywords',
                                icon: IconEnum.SEARCH,
                                actionIcon: IconEnum.PLUS,
                                errorMessage: 'Słowo musi mieć minimum 3 znaki',
                                minLength: 3
                            },
                            contextFunctions: {
                                inputValidation: (val: string | number | null | undefined) => (val as string).length > 0 && (val as string).length < 3,
                                handleChange
                            }
                        }
                    }
                }
            ]
        },
        {
            accordionTitle: 'Dodatkowe',
            hideTitle: true,
            filters: [
                {
                    title: 'Harmonogram',
                    type: InputType.AUTOCOMPLETE,
                    helperText: 'Co jaki okres chcesz otrzymywać powiadomienia? Jeżeli wybierzesz "Natychmiast" to każda oferta zostanie wysłana w osobnym mailu. w pozostałych przypadkach otrzymasz jednego maila np. co 5 min z listą ogłoszeń, które pojawiły się w tym czasie.',
                    icon: IconEnum.TIMER,
                    placeholder: 'Wybierz godziny...',
                    options: {
                        [InputType.AUTOCOMPLETE]: {
                            contextFunctions: {
                                handleChange
                            },
                            modulesProps: {
                                value: cooperativeFiltersState.notificationsDelay,
                                fieldName: 'notificationsDelay',
                                options: scheduleOptions
                            }
                        }
                    }
                }
            ]
        },
        {
            accordionTitle: 'Podsumowanie',
            hideTitle: true,
            isWideSpace: true,
            lastElement: true,
            filters: [
                {
                    title: 'Zapisz nowy filtr',
                    type: InputType.TEXT,
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Wpisz nazwę...',
                    containerActionText: cooperativeFiltersState.filterId ? 'Duplikuj' : undefined,
                    containerActionClick: handleCreateFromExistFilter,
                    options: {
                        [InputType.TEXT]: {
                            modulesProps: {
                                value: cooperativeFiltersState.name || '',
                                fieldName: 'name',
                                inputIcon: IconEnum.BOOKMARK_WHITE,
                                actionIcon: IconEnum.ARROW_RIGHT,
                                important: true,
                                isSaveFilterButton: true
                            },
                            contextFunctions: {
                                handleChange,
                                inputActionHandler: handleSaveOrUpdateFilter
                            }
                        }
                    }
                },
                {
                    title: 'Aktywacja powiadomień',
                    type: InputType.NOTIFICATIONS,
                    helperText: 'Przed aktywacją powiadomienia zapisz filtr',
                    icon: IconEnum.ALERT,
                    placeholder: '',
                    options: {
                        [InputType.NOTIFICATIONS]: {
                            contextFunctions: {
                                handleChange: handleChangeNotificationAlert
                            },
                            modulesProps: {
                                alertEmail: cooperativeFiltersState.alertEmail,
                                alertSms: cooperativeFiltersState.alertSms,
                                pushAlert: cooperativeFiltersState.alertPush,
                                filterId: cooperativeFiltersState.filterId ?? null
                            }
                        }
                    }
                },
                {
                    title: 'Zastosuj filtry',
                    type: InputType.SAVE_BUTTON,
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Przeglądaj oferty...',
                    options: {
                        [InputType.SAVE_BUTTON]: {
                            contextFunctions: {
                                onChangeVisibleButtonFilter: setIsFilterButtonVisible,
                                onActionClick: clearFilters,
                                onClick: handleSubmit
                            },
                            modulesProps: {
                                actionText: 'Resetuj'
                            }
                        }
                    }
                }
            ]
        }
    ];

    return (
        <>
            <V2FiltersRenderer elementsToRender={cooperativeFiltersStructure} type={'cooperative'} bottomActions={
                <StickyBottom
                    isVisible={isFilterButtonVisible}
                    active={cooperativeOfferState.cooperativeOfferList?.content?.length > 0 || cooperativeOfferState.cooperativeOfferList.empty}
                    className={'sticky-filter-button'}>
                    <InputContainer title={'Zastosuj filtry'} icon={IconEnum.DICTIONARY}
                        actionText={'Resetuj'}
                        onActionClick={clearFilters}>
                        <Button onClick={handleSubmit}>
                            {'Przeglądaj oferty...'}
                        </Button>
                    </InputContainer>
                </StickyBottom>
            }/>
            <AccordionCard disabledCollapse oneItem>
                <InputContainer
                    title={width >= 490 && cooperativeOfferState.cooperativeOfferList?.content.length === 0 ? 'Zaproponuj stronę, którą powinniśmy w przyszłości monitorować' : 'Jaką stronę monitorować?'}
                    icon={IconEnum.QUESTION}>
                    <TextInput
                        type={'text'}
                        placeholder={'Podaj adres url...'}
                        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setPortalName(e.target.value)}
                        value={portalName}
                        actionIcon={IconEnum.PLUS}
                        onAction={() => submitRecommendedPage(portalName)}
                        icon={IconEnum.SEARCH}
                    />
                </InputContainer>
            </AccordionCard>
        </>
    );
};

export default FiltersCooperative;
