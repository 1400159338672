import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SliderImages } from '@investoro/core';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import BrokerAdvertContext from 'utils/context/BrokerAdvertContext';
import { getModuleByBrokerType } from 'utils/formatters/getModuleByBrokerType';
import { getSubModuleByBrokerType } from 'utils/formatters/getSubModuleByBrokerType';
import { generateSingleOfferLink, ModuleType } from 'utils/generateSingleOfferLink';
import { BrokerOfferResponseContent } from 'utils/state-managment/broker/brokerOffer';
import { AdvertisementPhotoParamsModuleEnum } from 'utils/types/BrokerOfferModels';
import { BrokerOfferModule } from 'utils/types/BrokerOfferModule';

import Indicator from 'components/atom/Indicator';
import AccordionCard from 'components/common/AccordionCard';
import { NotInteractiveElement } from 'components/common/Card/common/V2InteractiveIconsWrapper/NotInteractiveIcons';
import { InteractiveElements } from 'components/common/Card/common/V2InteractiveIconsWrapper/V2InteractiveIconsWrapper';
import { CardData } from 'components/common/Card/V2BaseCard';
import FiltersContentWrapper from 'components/common/FiltersContentWrapper';
import Icon, { IconEnum } from 'components/common/Icon';
import Module from 'components/common/Module';
import TrashWithModal from 'components/common/TrashWithModal';
import { activeNotification } from 'components/functions/activeNotification';
import { convertToThumbnailPath, createImagesForSlider } from 'components/functions/imagesFunctions';
import { brokerOfferListFunctions } from 'components/functions/offerListFunctions/brokerOfferListFunctions/brokerOfferListFunctions';
import V2OfferList, { RenderList } from 'components/offerList/V2OfferList';
import { CustomNoOffersMessage } from 'components/users/myOffers/CustomNoOffersMessage';

const Wrapper = styled.div`
    .active .accordion-card-content {
        row-gap: 10px;
    }
`;

const FiltersContainer = styled.div`
    padding-left: 20px;

    @media (max-width: 1100px) {
        padding-right: 20px;
    }
`;

const ActionWrapper = styled.div`
    background-color: var(--color-alt-second);
    border-radius: 20px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    gap: 8px;

    &:hover {
        transition: background-color ease-in .2s;
        background-color: var(--color-alt);
        cursor: pointer;

        p {
            color: white;
        }

        img {
            filter: ${(props) => props.darkMode ? 'none' : 'brightness(0) invert(1)'};
        }
    }
`;

const Options = styled.div`
    position: absolute;
    right: 0;
    ${({ isInModal }) => isInModal ? 'top: 55px;' : 'top: -130px;'}
    background: var(--color-white);
    border-radius: var(--box-border-radius);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: auto;
    z-index: 2;
`;

export enum BrokerOfferMainModule {
    SALE = 'sale',
    RENT = 'rent',
}

const BrokerOffersPage = () => {
    const { user } = useUser();
    const navigate = useNavigate();
    const [activeModule, setActiveModule] = useState<BrokerOfferMainModule>(BrokerOfferMainModule.SALE);
    const { loadBrokerOffers, brokerOffersState, loadBrokerOfferDetailsAsync, deleteBrokerOffer, changeActivationStatus } = useContext(BrokerAdvertContext);
    const [renderList, setRenderList] = useState<RenderList[]>([]);
    const [brokerOfferActiveButtons, setBrokerOfferActiveButtons] = useState<number | null>(null);
    const [detailsMoreButtonActiveId, setDetailsMoreButtonActiveId] = useState<number | null>(null);

    useEffect(() => {
        loadBrokerOffers(activeModule, 1, 25);
    }, [activeModule]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (brokerOfferActiveButtons !== null || detailsMoreButtonActiveId !== null) {
                const optionsElement = document.querySelector('.options-container');

                if (optionsElement && !optionsElement.contains(event.target as Node)) {
                    setBrokerOfferActiveButtons(null);
                    setDetailsMoreButtonActiveId(null);
                }
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => document.removeEventListener('click', handleClickOutside);
    }, [brokerOfferActiveButtons, detailsMoreButtonActiveId]);

    const handleChangePage = useCallback((currentPage: number, rows = 25) => {
        loadBrokerOffers(activeModule, currentPage, rows);
    }, []);

    const toggleContainerDetails = (detailsOpen: boolean, offer: BrokerOfferResponseContent) => {
        if (!detailsOpen) {
            loadBrokerOfferDetailsAsync(offer.advertisementId, offer.module);
        }
    };

    const handleEditOffer = (event: MouseEvent, id: number, module: AdvertisementPhotoParamsModuleEnum) => {
        event.stopPropagation();
        navigate(`/${getModuleByBrokerType(module)}/${getSubModuleByBrokerType(module as unknown as AdvertisementPhotoParamsModuleEnum)}/${id}/edit`);
    };

    const handleOpenBrokerCardOffer = (event: MouseEvent, module: AdvertisementPhotoParamsModuleEnum, encryptedId: string, activationStatus: boolean) => {
        event.stopPropagation();

        if (!activationStatus) {
            activeNotification('Ogłoszenie niekatywne', 'Aktywuj ogłoszenie aby zobaczyć podgląd', 'warning');
        } else {
            const offerLink = `${process.env.REACT_APP_BROKER_URL}${getModuleByBrokerType(module)}/${getSubModuleByBrokerType(module)}/${encryptedId}`;
            window.open(offerLink, '_blank');
        }
    };

    const handleDownloadPDF = (event: MouseEvent, module: AdvertisementPhotoParamsModuleEnum, encryptedId: string, activationStatus: boolean) => {
        event.stopPropagation();

        if (!activationStatus) {
            activeNotification('Ogłoszenie niekatywne', 'Aktywuj ogłoszenie aby wygenerować PDF', 'warning');
        } else {
            const offerLink = `${process.env.REACT_APP_BROKER_URL}${getModuleByBrokerType(module)}/${getSubModuleByBrokerType(module)}/${encryptedId}`;
            window.open(offerLink + '/print', '_blank');
        }
    };

    const handleCopyLink = async (event: MouseEvent, module: AdvertisementPhotoParamsModuleEnum, encryptedId: string) => {
        event.stopPropagation();
        const offerLink = `${process.env.REACT_APP_BROKER_URL}${getModuleByBrokerType(module)}/${getSubModuleByBrokerType(module)}/${encryptedId}`;
        await navigator.clipboard.writeText(offerLink);
        activeNotification('Skopiowano', 'Link został pomyślnie skopiowany', 'success');
        setTimeout(() => {
            setBrokerOfferActiveButtons(null);
        }, 2000);
    };

    const handleOpenCardOffer = (event: MouseEvent, module: ModuleType, offer: BrokerOfferResponseContent, encryptedId?: string) => {
        event.stopPropagation();
        if (!encryptedId) return;
        const offerLink = generateSingleOfferLink(encryptedId, module);
        window.open(offerLink, '_blank');
    };

    const handleCreateCopy = (event: MouseEvent, id: number, module: AdvertisementPhotoParamsModuleEnum) => {
        event.stopPropagation();
        navigate(`/broker/${getModuleByBrokerType(module)}/${getSubModuleByBrokerType(module)}/${id}/copy`);
    };

    const handleChangeVisibilityOffer = async (event: React.MouseEvent, id: number, module: BrokerOfferModule, activationStatus: boolean) => {
        event.stopPropagation();
        await changeActivationStatus(id, module, !activationStatus);
        loadBrokerOffers(activeModule, brokerOffersState.brokerOfferList.pageable.pageNumber, brokerOffersState.brokerOfferList.pageable.pageSize);
        setBrokerOfferActiveButtons(null);
    };

    const handleDeleteOffer = (id: number, module: string) => {
        deleteBrokerOffer(id, module);
        setBrokerOfferActiveButtons(null);
    };

    const generateSquareIcon = (id: number, isDetail: boolean, offer: BrokerOfferResponseContent) => {
        const isMoreButtonActive = isDetail ? detailsMoreButtonActiveId === id : brokerOfferActiveButtons === id;

        return {
            icon: 'squares' as IconEnum,
            tooltipText: 'Więcej',
            onClick: () => {
                if (isDetail) {
                    isMoreButtonActive
                        ? setDetailsMoreButtonActiveId(null)
                        : setDetailsMoreButtonActiveId(id);
                } else {
                    isMoreButtonActive
                        ? setBrokerOfferActiveButtons(null)
                        : setBrokerOfferActiveButtons(id);
                }
            },
            additionalJSXSibling: isMoreButtonActive
                ? <Options className="options-container" isInModal={isDetail}>
                    <ActionWrapper onClick={(event: MouseEvent) => handleEditOffer(event, offer.advertisementId, offer.module as unknown as AdvertisementPhotoParamsModuleEnum)}>
                        <Icon icon={IconEnum.EDIT}/>
                        <p>Edytuj</p>
                    </ActionWrapper>
                    <ActionWrapper onClick={(event: MouseEvent) => handleCreateCopy(event, offer.advertisementId, offer.module as unknown as AdvertisementPhotoParamsModuleEnum)}>
                        <Icon icon={IconEnum.EDIT}/>
                        <p>Duplikuj</p>
                    </ActionWrapper>
                    {offer.originalEncryptedId
                        ? <ActionWrapper onClick={(event: MouseEvent) => handleOpenCardOffer(event, getModuleByBrokerType(offer.module), offer, offer.originalEncryptedId)}>
                            <Icon icon={IconEnum.DICTIONARY}/>
                            <p>Podgląd oryginału</p>
                        </ActionWrapper>
                        : null}
                    <ActionWrapper onClick={(event: MouseEvent) => handleOpenBrokerCardOffer(event, offer.module as unknown as AdvertisementPhotoParamsModuleEnum, offer.encryptedId, offer.activationStatus)}>
                        <Icon icon={IconEnum.LINK}/>
                        <p>Podgląd ogłoszenia</p>
                    </ActionWrapper>
                    <ActionWrapper onClick={(event: MouseEvent) => handleDownloadPDF(event, offer.module as unknown as AdvertisementPhotoParamsModuleEnum, offer.encryptedId, offer.activationStatus)}>
                        <Icon icon={IconEnum.SAVE}/>
                        <p>Wygeneruj PDF</p>
                    </ActionWrapper>
                    <ActionWrapper onClick={(event: MouseEvent) => handleCopyLink(event, offer.module as unknown as AdvertisementPhotoParamsModuleEnum, offer.encryptedId)}>
                        <Icon icon={IconEnum.LINK}/>
                        <p>Skopiuj link</p>
                    </ActionWrapper>
                    <ActionWrapper
                        onClick={(event: React.MouseEvent) => handleChangeVisibilityOffer(event, offer.advertisementId, offer.module as unknown as BrokerOfferModule, offer.activationStatus)}>
                        <Icon icon={offer.activationStatus ? IconEnum.EYE_HIDDEN : IconEnum.EYE } />
                        <p>{offer.activationStatus ? 'Dezaktywuj' : 'Aktywuj'}</p>
                    </ActionWrapper>
                    <ActionWrapper darkMode={user.isDarkMode}>
                        <TrashWithModal iconText={'Usuń'}
                            title={'Czy na pewno chcesz usunąć ogłoszenie?'}
                            onChange={() => handleDeleteOffer(offer.advertisementId, offer.module)}
                            filter={offer.title!}/>
                    </ActionWrapper>
                </Options>
                : undefined
        };
    };

    const generateInteractiveElements = (offer: BrokerOfferResponseContent): InteractiveElements[] => {
        return [
            generateSquareIcon(offer.advertisementId, false, offer),
            ...offer.photos && offer.photos.length > 0
                ? [
                    {
                        icon: IconEnum.PHOTOS,
                        isModalOpen: true,
                        isLink: true,
                        text: <p>Zobacz zdjęcia</p>,
                        isPhotoBtn: true,
                        visibleDuringModal: true,
                        sliderElements: {
                            photos: createImagesForSlider(offer.photos, offer.module, true) as SliderImages[],
                            title: offer.title
                        }
                    }
                ]
                : []

        ];
    };

    const generateNotInteractiveElements = (offer: BrokerOfferResponseContent): NotInteractiveElement[] => {
        return [
            {
                icon: IconEnum.EYE,
                text: offer.infoDto && offer.infoDto.numberOfViews ? offer.infoDto.numberOfViews.toString() : '0'
            },
            {
                icon: IconEnum.PHONE,
                text: offer.infoDto && offer.infoDto.numberOfPhoneClick ? offer.infoDto.numberOfPhoneClick.toString() : '0'
            }
        ];
    };

    const generateCardData = (offer: BrokerOfferResponseContent): CardData => {
        const subMainValues: React.ReactNode[] = [];

        if (offer.pricePerSquareMeter) {
            subMainValues.push(
                <><b>{offer.pricePerSquareMeter.toString()}</b> zł / m<sup>2</sup></>
            );
        }

        return {
            preTitle: offer.city.fullName,
            activationStatusTextLabel: offer.activationStatus ? 'Ogłoszenie aktywne' : 'Ogłoszenie nieaktywne',
            activationStatus: offer.activationStatus,
            date: offer.dateAdded,
            title: offer.title,
            infoElements: brokerOfferListFunctions.generateInfoElements(offer),
            notInteractiveElements: generateNotInteractiveElements(offer),
            interactiveElements: generateInteractiveElements(offer),
            thumbnailPath: offer.photos && offer.photos.length > 0 ? convertToThumbnailPath(offer.photos[0], offer.module, true) : './defaultImg.png',
            images: createImagesForSlider(offer.photos || [], offer.module, true),
            mainValue: offer.price ? `${offer.price} zł` : '- zł',
            subMainValues
        };
    };

    useEffect(() => {
        const offersList = brokerOffersState.brokerOfferList.content
            ? brokerOffersState.brokerOfferList?.content.map((offer: BrokerOfferResponseContent): RenderList => {
                const detailsTabContent = brokerOfferListFunctions.generateOfferDetailsTabContent(offer);

                return {
                    id: offer.advertisementId,
                    onSingleOfferClick: (_: React.MouseEvent, detailsOpen: boolean) => toggleContainerDetails(detailsOpen, offer),
                    baseCardContent: {
                        bigImage: true,
                        cardData: generateCardData(offer),
                        openOfferTab: () => {
                        }
                    },
                    detailsCardData: {
                        notInteractiveElements: generateNotInteractiveElements(offer),
                        interactiveElements: [
                            ...offer.photos && offer.photos.length > 0
                                ? [
                                    {
                                        icon: IconEnum.PHOTOS,
                                        isModalOpen: true,
                                        isLink: true,
                                        text: <p>Zobacz zdjęcia</p>,
                                        isPhotoBtn: true,
                                        visibleDuringModal: true,
                                        sliderElements: {
                                            photos: createImagesForSlider(offer.photos, offer.module, true) as SliderImages[],
                                            title: offer.title
                                        }
                                    }
                                ]
                                : [],
                            generateSquareIcon(offer.advertisementId, true, offer)
                        ],
                        thumbnailPath: offer.photos && offer.photos.length > 0 ? convertToThumbnailPath(offer.photos[0], offer.module, true) : './defaultImg.png',
                        date: offer.dateAdded!,
                        onToggleContainerDetails: () => {
                        },
                        tabsContent: {
                            toggleContainerDetails: () => {
                            },
                            notInteractiveElements: generateNotInteractiveElements(offer),
                            detailsTabContent,
                            closeDetailsTabText: 'Wróć do listy ogłoszeń'
                        }
                    }
                };
            })
            : [];

        setRenderList(offersList);
    }, [
        brokerOffersState.brokerOfferList?.content,
        brokerOfferActiveButtons,
        detailsMoreButtonActiveId
    ]);

    return (
        <Wrapper id={'offer-list'}>
            <FiltersContentWrapper className={'active'}>
                <FiltersContainer>
                    <AccordionCard type={'favorites'} title={'Moje pozyski'}>
                        <Module
                            isActive={activeModule === BrokerOfferMainModule.SALE}
                            icon={IconEnum.BAG_WHITE}
                            title={'Sprzedam'}
                            onClick={() => setActiveModule(BrokerOfferMainModule.SALE)}/>
                        <Module
                            isActive={activeModule === BrokerOfferMainModule.RENT}
                            icon={IconEnum.CALENDAR_WHITE}
                            title={'Wynajmę'}
                            onClick={() => setActiveModule(BrokerOfferMainModule.RENT)}/>
                    </AccordionCard>
                </FiltersContainer>
                <V2OfferList
                    showNoItemsText={brokerOffersState.brokerOfferList.empty}
                    showList={brokerOffersState.brokerOfferList && brokerOffersState.brokerOfferList.content && brokerOffersState.brokerOfferList.content?.length > 0}
                    renderList={renderList}
                    totalPages={brokerOffersState.brokerOfferList?.totalPages}
                    pageNumber={brokerOffersState.brokerOfferList?.pageable?.pageNumber || 1}
                    handleChangePage={handleChangePage}
                    lastPage={user.myOfferList?.last}
                    customNoOffersMessage={<CustomNoOffersMessage darkMode={user.isDarkMode}/>}
                />

            </FiltersContentWrapper>
            {user.isLoading && <Indicator/>}
        </Wrapper>
    );
};

export default BrokerOffersPage;
