import { FiltersStateType } from 'utils/types/FiltersStateType';
import { ModulesType } from 'utils/types/ModulesType';
import {
    DuplicateFlatDto,
    DuplicateHouseDto,
    DuplicateOtherDto,
    DuplicatePlotDto,
    NoteDto,
    SaleFlatDetailDto,
    SaleFlatDto, SaleHashDto,
    SaleHouseDetailDto,
    SaleHouseDto, SaleOpinionResult,
    SaleOtherDetailDto,
    SaleOtherDto,
    SalePlotDetailDto,
    SalePlotDto,
    SortType
} from 'utils/types/SaleModels';

export interface SaleStateType {
    isLoading: boolean,
    saleOfferList: SaleOffersResponse,
    sortType: SortType;
    filtersState: FiltersStateType;
    pageType: ModulesType;
    filteredCity: string;
    notes?: NoteDto[],
    scoreAndOpinions?: SaleOpinionResult,
}

export type SaleOfferDetailsResponse = SaleFlatDetailDto & SalePlotDetailDto & SaleHouseDetailDto & SaleOtherDetailDto;

export type SaleOfferHashResponse = SaleHashDto;

type DuplicateDto = DuplicateFlatDto & DuplicatePlotDto & DuplicateHouseDto & DuplicateOtherDto;

type SaleOffersDto = SaleHouseDto & SaleFlatDto & SalePlotDto & SaleOtherDto;

export type SaleOfferDuplicatesResponse = DuplicateDto

export interface SaleOfferResponseContent extends SaleOffersDto {
    detailedContent?: SaleOfferDetailsResponse;
    duplicates?: SaleOfferDuplicatesResponse[];
}

export interface SaleOffersResponse {
    empty: boolean,
    content: SaleOfferResponseContent[],
    pageable: {
        pageNumber: number,
        pageSize: number,
    },
    numberOfElements: number,
    totalPages: number,
    totalElements: number,
    last: boolean,
    size: number,
    afterSearch: boolean,
}

export const saleInitialState: SaleStateType = {
    isLoading: false,
    saleOfferList: {
        empty: false,
        content: [],
        pageable: {
            pageNumber: 1,
            pageSize: 0
        },
        numberOfElements: 0,
        size: 25,
        totalPages: 0,
        totalElements: 0,
        last: false,
        afterSearch: false
    },
    sortType: SortType.DATE_ASC,
    filtersState: {} as FiltersStateType,
    pageType: 'advertisement',
    filteredCity: ''
};
