import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextInput } from '@investoro/core';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import AdminPanelContext from 'utils/context/AdminPanelContex';
import { OrganizationRole } from 'utils/types/BrokerModels';

import Button from 'components/atom/Button';
import Indicator from 'components/atom/Indicator';
import Icon, { IconEnum } from 'components/common/Icon';
import InputContainer from 'components/common/InputContainer';
import Modal from 'components/common/Modal/Modal';
import { activeNotification } from 'components/functions/activeNotification';
import V2HeaderSection from 'components/V2HeaderSection';

const Wrapper = styled.div`
    .active .accordion-card-content {
        row-gap: 10px;
    }
`;

const Container = styled.div`
    padding: 35px 20px 0;
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (max-width: 1100px) {
        padding: 20px 20px 0;
    }
`;

const InfoWrapperContainer = styled.div`
    padding: 10px 10px 25px 15px;
    border-radius: var(--box-border-radius);
    background: var(--color-white);
    display: flex;
    flex-direction: column;
    width: 60%;
    gap: 10px;
    margin-bottom: 25px;

    @media (max-width: 1100px) {
        width: 100%;
    }
`;

const InfoWrapper = styled.span`
    position: relative;
    display: flex;
    gap: 10px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background: var(--color-alt-second);
    color: var(--color-primary);
    padding: 15px 19px;
    border-radius: var(--border-radius);
    height: 50px;
`;

const Label = styled.span`
    font-weight: var(--font-weight);
    font-size: var(--font-size-body-2);
    line-height: 20px;
    background: transparent;
    border: none;
    color: var(--color-primary);
`;

const ValueBox = styled.div`
    line-height: 30px;
    text-align: right;
`;

const TableWraper = styled.div`
    padding: 10px 10px 25px 15px;
    border-radius: var(--box-border-radius);
    background: var(--color-white);
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 25px;

`;

const TableScrollContainer = styled.div`
    @media (max-width: 768px) {
        width: 100%;
        overflow-x: auto;
    }
`;

const Title = styled.div`
    margin: 10px 0 0 15px;
    font-weight: var(--font-weight);
    font-size: 20px;
    line-height: 30px;
    color: var(--color-primary);
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const TableHeader = styled.th`
    padding: 10px;
    text-align: left;
    font-weight: bold;
`;

const TableRow = styled.tr<{ isGray: boolean }>`
    background-color: ${(props) => props.isGray ? 'var(--color-alt-second)' : 'var(--color-white)'};
    td:first-child {
        border-top-left-radius: var(--image-border-radius);
        border-bottom-left-radius: var(--image-border-radius);
    }

    td:last-child {
        border-top-right-radius: var(--image-border-radius);
        border-bottom-right-radius: var(--image-border-radius);
    }
`;

const TableCell = styled.td<{ isActive: boolean }>`
    padding: 15px;
    span {
        color: ${(props) => props.isActive ? 'var(--color-success)' : 'var(--color-error)'};
    }
`;

const ActionButtonWrapper = styled.div`
    display: flex;
    gap: 10px;
`;

interface ActionButtonProps {
    darkMode?: boolean;
}

const ActionButton = styled.div<ActionButtonProps>`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;    

    ${(props) => props.darkMode && `
        img {
            filter: brightness(0) invert(1);
        }
    `}

    &:hover {
        background: var(--color-alt);
        cursor: pointer;

        img {
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%);
        }
    }
`;

const AddAgentButton = styled.div`
    cursor: pointer;
    color: var(--color-primary);
    font-weight: var(--font-weight);
    margin: 10px 0 0 15px;
    display: flex;
    align-items: center;
    font-size: 15px;
    height: 50px;
    border: 2px solid var(--color-primary);
    border-radius: 10px;
    padding: 12px;
    width: fit-content;
  
    @media (max-width: 768px) {
        height: auto;
        align-self: flex-start;
    }
`;

const ChangeSmsModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    padding: 40px;

`;

const ModalContainer = styled.div`
    display: flex;
    gap: 50px;
    justify-content: center;
    width: 90%;

    > span {
        width: 100%;
    }
    
    &:last-child {
        margin-top: 20px;

        button {
            min-width: 220px;
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 15px;
    }
`;

const AdminPanelPage = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    const { adminPanelState, changeAgentActiveStatus, changeSmsLimitToAdmin } = useContext(AdminPanelContext);
    const [showChangeSmsPackageForm, setShowChangeSmsPackageForm] = useState(false);

    const [adminSmsPackage, setAdminSmsPackage] = useState(0);
    const [triggerValidation, setTriggerValidation] = useState(false);

    const handleAddAgent = () => {
        if (adminPanelState.totalAccountNumber === adminPanelState.existedAccountNumber) {
            activeNotification('Liczba agentów', 'Wykorzystałeś maksymalną liczbę agentów', 'warning');

            return;
        }
        navigate('/admin-panel/add');
    };

    const validateSmsLimit = (smsLimit: number) => {
        if (smsLimit < 0) {
            return 'Liczba SMS nie może być ujemna';
        } else if (smsLimit > adminPanelState.totalSmsAvailableToNewAccounts) {
            return `Przekroczono dostępną pulę SMS (max: ${adminPanelState.totalSmsAvailableToNewAccounts})`;
        }

        return '';
    };

    const handleChanegSmsPackageSubmit = () => {
        setTriggerValidation(true);
        const validationError = validateSmsLimit(adminSmsPackage);

        if (!validationError) {
            changeSmsLimitToAdmin(adminSmsPackage);
            setShowChangeSmsPackageForm(false);
        }

        return;
    };

    useEffect(() => {
        if (adminPanelState?.agents && adminPanelState.agents.length > 0) {
            const adminAgent = adminPanelState.agents.find((agent) => agent.organizationRole === OrganizationRole.ORGANIZATION_ADMIN);

            if (adminAgent) {
                setAdminSmsPackage(adminAgent.smsPackage || 0);
            }
        }
    }, [adminPanelState.agents]);

    const agencyInfo = [
        {
            label: 'Liczba agentów:',
            value: adminPanelState.numberOfAgents
        },
        {
            label: 'Pakiet aktywny do:',
            value: adminPanelState.packageDate ? new Date(adminPanelState.packageDate).toLocaleDateString() : ''
        },
        {
            label: 'Liczba SMS:',
            value: adminPanelState.smsPackage
        }
    ];

    return (
        <Wrapper>
            <V2HeaderSection title={'Panel administratora'}/>
            <Container>
                <InfoWrapperContainer>
                    <Title>{adminPanelState.agencyName}</Title>
                    {agencyInfo.map((el) => <InfoWrapper key={el.label}>
                        <Label>{el.label}</Label>
                        <ValueBox>{el.value}</ValueBox>
                    </InfoWrapper>)}
                </InfoWrapperContainer>

                <AddAgentButton onClick={handleAddAgent}>+ Dodaj agenta</AddAgentButton>
                <TableWraper>
                    <Title>Lista agentów</Title>
                    {adminPanelState.isLoading
                        ? Indicator
                        : <TableScrollContainer>
                            <Table>
                                <thead>
                                    <tr>
                                        <TableHeader/>
                                        <TableHeader>Imię</TableHeader>
                                        <TableHeader>Nazwisko</TableHeader>
                                        <TableHeader>Email</TableHeader>
                                        <TableHeader>Liczba SMS</TableHeader>
                                        <TableHeader>Ostatnie logowanie</TableHeader>
                                        <TableHeader>Status</TableHeader>
                                    </tr>
                                </thead>
                                <tbody>
                                    {adminPanelState.agents && adminPanelState.agents.length > 0 && adminPanelState.agents.map((agent, index) => <TableRow key={agent.agentId} isGray={index % 2 === 0}>
                                        <TableCell data-label="Akcje">
                                            {agent.organizationRole === OrganizationRole.ORGANIZATION_AGENT
                                                ? <ActionButtonWrapper>
                                                    <ActionButton darkMode={user.isDarkMode} onClick={() => navigate(`/edit-agent/${agent.agentId}`)}>
                                                        <Icon icon={IconEnum.EDIT}/>
                                                    </ActionButton>
                                                    <ActionButton darkMode={user.isDarkMode} onClick={() => {
                                                        changeAgentActiveStatus(agent.agentId, !agent.activationStatus);
                                                    } }>
                                                        <Icon icon={IconEnum.BLOCKED}/>
                                                    </ActionButton>
                                                    {/* @TODO: odkomentować po wprowadzeniu do API */}
                                                    {/* <ActionButton darkMode={user.isDarkMode} onClick={() => deleteAgent(agent.agentId)}>
                                                        <TrashWithModal
                                                            iconText={''}
                                                            title={'Czy na pewno chcesz usunąć pracownika?'}
                                                            onChange={() => deleteAgent(agent.agentId)}
                                                            filter={`${agent.firstName} ${agent.lastName}`}/>
                                                    </ActionButton> */}
                                                </ActionButtonWrapper>
                                                : <ActionButtonWrapper>
                                                    <ActionButton darkMode={user.isDarkMode} onClick={() => setShowChangeSmsPackageForm(true)}>
                                                        <Icon icon={IconEnum.EDIT}/>
                                                    </ActionButton>
                                                </ActionButtonWrapper>
                                            }
                                        </TableCell>
                                        <TableCell>{agent.firstName}</TableCell>
                                        <TableCell>{agent.lastName}</TableCell>
                                        <TableCell>{agent.email}</TableCell>
                                        <TableCell>{agent.smsPackage}</TableCell>
                                        <TableCell>{agent.lastLogin ? new Date(agent.lastLogin).toLocaleString() : '-'}</TableCell>
                                        <TableCell isActive={agent.activationStatus}>
                                            {agent.organizationRole === OrganizationRole.ORGANIZATION_ADMIN
                                                ? <span>Administrator</span>
                                                : agent.activationStatus ? <span>Aktywny</span> : <span>Nieaktywny</span>
                                            }
                                        </TableCell>

                                    </TableRow>)}
                                </tbody>
                            </Table>
                        </TableScrollContainer> }
                    {adminPanelState.isLoading && <Indicator/>}
                </TableWraper>
            </Container>

            <Modal
                isOpen={showChangeSmsPackageForm}
                onClose={() => setShowChangeSmsPackageForm(false)}
                centeredContent
                backgroundColor={user.isDarkMode ? 'var(--color-alt-hover)' : '#fff'}
                strictContentHeight
            >
                <ChangeSmsModalContainer>
                    <h1>Limit SMS dla administratora</h1>
                    <ModalContainer>
                        <InputContainer title="">
                            <TextInput
                                value={adminSmsPackage}
                                onChange={(e) => setAdminSmsPackage(parseInt(e.target.value) || 0)}
                                placeholder="Wprowadź liczbę SMS..."
                                required
                                errorMessage={validateSmsLimit(adminSmsPackage)}
                                inputValidation={() => !!validateSmsLimit(adminSmsPackage)}
                                triggerValidation={triggerValidation}
                            />
                        </InputContainer>
                    </ModalContainer>

                    <ModalContainer>
                        <Button onClick={handleChanegSmsPackageSubmit}>
                            Zapisz zmiany
                        </Button>
                    </ModalContainer>
                </ChangeSmsModalContainer>
            </Modal>

        </Wrapper>
    );
};

export default AdminPanelPage;
