
import { axiosApiInstance } from 'Store';
import { AgencyDashboardDto, AgentCreateDto, AgentEditDetailDto, AgentEditDto, AgentEditPasswordDto } from 'utils/types/BrokerModels';

import { activeNotification } from 'components/functions/activeNotification';

const getAgencyDashboard = async (): Promise<AgencyDashboardDto> => {
    return axiosApiInstance
        .get('/broker/manage/employees-list')
        .then((resp) => resp.data)
        .catch(() => {
            activeNotification(
                'Problem z pobraniem danych',
                'Nie udało się pobrać danych panelu. Spróbuj ponownie później.',
                'warning'
            );

            return null;
        });
};

const changeSmsLimitToAdmin = async (smsPackage: number): Promise<void> => {
    return axiosApiInstance
        .put(`/broker/manage/edit/admin/sms-package?smsPackage=${smsPackage}`)
        .then(() => {
            activeNotification('Udało się!', 'Licza smsów dla administratora została zmieniona', 'success');
        })
        .catch(() => {
            activeNotification(
                'Problem z edycją liczby sms',
                'Nie udało się zmienić liczby sms. Spróbuj ponownie później.',
                'danger'
            );
        });
};

const createAgent = async (data: AgentCreateDto): Promise<void> => {
    return axiosApiInstance
        .post('/broker/manage/create/employee', data)
        .then(() => {
            activeNotification('Udało się!', 'Agent został dodany', 'success');
        })
        .catch(() => {
            activeNotification(
                'Problem z dodaniem agenta',
                'Nie udało się dodać nowego agenta. Spróbuj ponownie później.',
                'danger'
            );
        });
};

const editAgent = async (data: AgentEditDto): Promise<void> => {
    return axiosApiInstance
        .put('/broker/manage/edit/employee', data)
        .then(() => {
            activeNotification('Udało się!', 'Dane agenta zostały zaktualizowane', 'success');
        })
        .catch(() => {
            activeNotification(
                'Problem z edycją',
                'Nie udało się zaktualizować danych agenta. Spróbuj ponownie później.',
                'danger'
            );
        });
};

const changeAgentPassword = async (data: AgentEditPasswordDto): Promise<void> => {
    return axiosApiInstance
        .put('/broker/manage/edit/employee-password', data)
        .then(() => {
            activeNotification('Udało się!', 'Hasło zostało zmienione', 'success');
        })
        .catch(() => {
            activeNotification(
                'Problem ze zmianą hasła',
                'Nie udało się zmienić hasła. Spróbuj ponownie później.',
                'danger'
            );
        });
};

const getAgentDetails = async (agentId: string): Promise<AgentEditDetailDto> => {
    return axiosApiInstance
        .get(`/broker/manage/edit-detail/employee?agentId=${agentId}`)
        .then((resp) => resp.data)
        .catch(() => {
            activeNotification(
                'Problem z pobraniem danych',
                'Nie udało się pobrać szczegółów agenta. Spróbuj ponownie później.',
                'danger'
            );

            return null;
        });
};

const changeAgentActiveStatus = async (agentId: string, activationStatus: boolean): Promise<void> => {
    return axiosApiInstance
        .put(`/broker/manage/edit/account-status?activationStatus=${activationStatus}&agentId=${agentId}`)
        .then(() => {
            activeNotification('Udało się!', 'Status agenta został zmieniony', 'success');
        })
        .catch(() => {
            activeNotification(
                'Problem z usunięciem',
                'Nie udało się zmienić statusu agenta. Spróbuj ponownie później.',
                'danger'
            );
        });
};

const deleteAgent = async (agentId: string): Promise<void> => {
    return axiosApiInstance
        .delete('/broker/manage/delete/account', { data: agentId })
        .then(() => {
            activeNotification('Udało się!', 'Agent został usunięty', 'success');
        })
        .catch(() => {
            activeNotification(
                'Problem z usunięciem',
                'Nie udało się usunąć agenta. Spróbuj ponownie później.',
                'danger'
            );
        });
};

export const adminPanelApiRequests = {
    getAgencyDashboard,
    changeSmsLimitToAdmin,
    createAgent,
    editAgent,
    changeAgentPassword,
    getAgentDetails,
    deleteAgent,
    changeAgentActiveStatus
};
