import React, { createContext, useCallback, useEffect, useState } from 'react';
import { adminPanelApiRequests } from 'utils/api-requests/adminPanel';
import { adminPanelInitialState, AdminPanelStateType } from 'utils/state-managment/adminPanel/adminPanel';
import { initialState } from 'utils/state-managment/adminPanel/manageEmployee';
import { AgentCreateDto, AgentEditDto, AgentEditPasswordDto, OrganizationRole } from 'utils/types/BrokerModels';
import { ObjectAndSingleValueChangeType } from 'utils/types/InputTypes';
import { AdvertValidatorType, isFieldValid } from 'utils/validators/advertAddingValidator';

interface AdminPanelType {
    handleChange: (name: string, value: ObjectAndSingleValueChangeType) => void;
    handleEditChange: (name: string, value: ObjectAndSingleValueChangeType) => void;
    createAgentFieldsState: AgentCreateDto;
    editAgentFieldsState: AgentEditDto;
    validateField: (name: AdvertValidatorType, value: ObjectAndSingleValueChangeType) => boolean
    clearFields: () => void
    handleFillAdvertFields: (data: AgentEditDto) => void,
    adminPanelState: AdminPanelStateType;
    loadAgentDetails: (id: string) => void;
    deleteAgent: (id: string) => void;
    editAgent: (data: AgentEditDto) => void;
    createAgent: (data: AgentCreateDto) => void;
    changeAgentPassword: (data: AgentEditPasswordDto) => void;
    changeAgentActiveStatus: (id: string, status: boolean) => void;
    loadAgencyDashboard: () => void;
    validateCreateAgentFields: (data: AgentCreateDto) => ValidationErrors;
    validatePasswordFields: (password: string, confirmPassword: string) => PasswordValidationErrors;
    validateAgentFields: (data: AgentEditDto) => AgentValidationErrors;
    passwordFieldsState: { password: string, confirmPassword: string };
    handlePasswordChange: (field: string, value: string) => void;
    changeSmsLimitToAdmin: (smsLimit: number) => void;
}

const initialPasswordErrors: PasswordValidationErrors = {
    password: '',
    confirmPassword: ''
};

const initialBaseErrors: BaseValidationErrors = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    smsPackage: ''
};

const values: AdminPanelType = {
    handleChange: () => {},
    handleEditChange: () => {},
    createAgentFieldsState: {} as AgentCreateDto,
    editAgentFieldsState: {} as AgentEditDto,
    validateField: () => false,
    clearFields: () => {},
    validatePasswordFields: () => initialPasswordErrors,
    validateCreateAgentFields: () => ({ ...initialBaseErrors, ...initialPasswordErrors }),
    validateAgentFields: () => initialBaseErrors,
    handleFillAdvertFields: () => {},
    adminPanelState: adminPanelInitialState,
    loadAgentDetails: () => {},
    deleteAgent: () => {},
    editAgent: () => {},
    createAgent: () => {},
    changeAgentPassword: () => {},
    changeAgentActiveStatus: () => {},
    loadAgencyDashboard: () => {},
    passwordFieldsState: { password: '', confirmPassword: '' },
    handlePasswordChange: () => {},
    changeSmsLimitToAdmin: () => {}
};

interface BaseValidationErrors {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    smsPackage: string;
}

interface PasswordFields {
    password: string;
    confirmPassword: string;
}

type AgentValidationErrors = BaseValidationErrors;
type PasswordValidationErrors = PasswordFields;
type ValidationErrors = BaseValidationErrors & PasswordFields;
const AdminPanelContext = createContext(values);

export const AdminPanelProvider = ({ children }: { children: React.ReactNode }) => {
    const [createAgentFieldsState, setCreateAgentFieldsState] = useState<AgentCreateDto>({} as AgentCreateDto);
    const [editAgentFieldsState, setEditAgentFieldsState] = useState<AgentEditDto>({} as AgentEditDto);
    const [adminPanelState, setAdminPanelState] = useState<AdminPanelStateType>(adminPanelInitialState);
    const [passwordFieldsState, setPasswordFieldsState] = useState({
        password: '',
        confirmPassword: ''
    });

    const handlePasswordChange = useCallback((fieldName: string, value: string) => {
        setPasswordFieldsState((prev) => ({
            ...prev,
            [fieldName]: value
        }));
    }, []);

    useEffect(() => {
        loadAgencyDashboard();
    }, []);

    const setLoadingPage = () => {
        setAdminPanelState((prev) => ({
            ...prev,
            isLoading: true
        }));
    };

    const validatePasswordFields = (password: string, confirmPassword: string) => {
        const errors = {
            password: '',
            confirmPassword: ''
        };

        if (!password) {
            errors.password = 'Hasło jest wymagane';
        } else if (password.length < 8) {
            errors.password = 'Hasło musi mieć minimum 8 znaków';
        } else if (!(/[A-Z]/).test(password)) {
            errors.password = 'Hasło musi zawierać co najmniej jedną dużą literę';
        } else if (!(/\d/).test(password)) {
            errors.password = 'Hasło musi zawierać co najmniej jedną cyfrę';
        }

        if (password !== confirmPassword) {
            errors.confirmPassword = 'Hasła nie są identyczne';
        }

        return errors;
    };

    const validateAgentFields = (data: AgentEditDto) => {
        const errors = {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            smsPackage: ''
        };

        if (!data.firstName) errors.firstName = 'Pole jest wymagane';
        if (!data.lastName) errors.lastName = 'Pole jest wymagane';
        if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(data.email)) errors.email = 'Niepoprawny format email';

        if (!data.phoneNumber) {
            errors.phoneNumber = 'Numer telefonu jest wymagany';
        } else if (!(/^\d{9}$/).test(data.phoneNumber.replace(/\s+/g, ''))) {
            errors.phoneNumber = 'Numer telefonu musi składać się z dokładnie 9 cyfr';
        }

        if (data.smsPackage !== undefined && data.smsPackage !== null) {
            if (data.smsPackage < 0) {
                errors.smsPackage = 'Liczba SMS nie może być ujemna';
            } else if (data.smsPackage > adminPanelState.totalSmsAvailableToNewAccounts) {
                errors.smsPackage = `Przekroczono dostępną pulę SMS (max: ${adminPanelState.totalSmsAvailableToNewAccounts})`;
            }
        }

        return errors;
    };

    const validateCreateAgentFields = (data: AgentCreateDto): ValidationErrors => {
        return {
            // @ts-expect-error AgentEditData ne posiada inputów password i confirmPassword
            ...validateAgentFields(data),
            ...validatePasswordFields(data.password, data.confirmPassword)
        };
    };

    const handleChange = useCallback((fieldName: string, value: ObjectAndSingleValueChangeType) => {
        setCreateAgentFieldsState((prev) => ({
            ...prev,
            [fieldName]: value
        }));
    }, []);

    const handleEditChange = useCallback((fieldName: string, value: ObjectAndSingleValueChangeType) => {
        setEditAgentFieldsState((prev) => ({
            ...prev,
            [fieldName]: value
        }));
    }, []);

    const loadAgencyDashboard = async () => {
        setLoadingPage();
        const response = await adminPanelApiRequests.getAgencyDashboard();
        setAdminPanelState((prev) => ({
            ...prev,
            ...response,
            isLoading: false
        }));
    };

    const createAgent = async (data: AgentCreateDto) => {
        setLoadingPage();
        await adminPanelApiRequests.createAgent(data);
        await loadAgencyDashboard();
        setAdminPanelState((prev) => ({ ...prev, isLoading: false }));
    };

    const changeAgentActiveStatus = async (agentId: string, activationStatus: boolean) => {
        await adminPanelApiRequests.changeAgentActiveStatus(agentId, activationStatus);
        setAdminPanelState((prev) => ({
            ...prev,
            isLoading: false,
            agents: prev.agents?.map((agent) => {
                if (agent.agentId === agentId) {
                    return {
                        ...agent,
                        activationStatus: activationStatus,
                        smsPackage: !activationStatus ? 0 : agent.smsPackage
                    };
                }

                return agent;
            })
        }));
    };

    const editAgent = async (data: AgentEditDto) => {
        setLoadingPage();
        await adminPanelApiRequests.editAgent(data);
        await loadAgencyDashboard();
        setAdminPanelState((prev) => ({ ...prev, isLoading: false }));
    };

    const deleteAgent = async (agentId: string) => {
        setLoadingPage();

        await adminPanelApiRequests.deleteAgent(agentId);
        setAdminPanelState((prev) => ({ ...prev, isLoading: false }));
    };

    const loadAgentDetails = async (agentId: string) => {
        setLoadingPage();
        const response = await adminPanelApiRequests.getAgentDetails(agentId);
        setEditAgentFieldsState((prev) => ({ ...prev, ...response }));
        setAdminPanelState((prev) => ({ ...prev, isLoading: false }));
    };

    const changeAgentPassword = async (data: AgentEditPasswordDto) => {
        setLoadingPage();
        await adminPanelApiRequests.changeAgentPassword(data);
        setAdminPanelState((prev) => ({ ...prev, isLoading: false }));
    };

    const handleFillAdvertFields = useCallback((fieldsData: AgentEditDto) => {
        setEditAgentFieldsState({ ...initialState.initialState(), ...fieldsData });
    }, []);

    const clearFields = () => {
        setCreateAgentFieldsState(initialState.initialState());
    };

    const changeSmsLimitToAdmin = async (smsLimit: number) => {
        setLoadingPage();
        await adminPanelApiRequests.changeSmsLimitToAdmin(smsLimit);
        setAdminPanelState((prev) => ({
            ...prev,
            agents: prev.agents?.map((agent) => {
                if (agent.organizationRole === OrganizationRole.ORGANIZATION_ADMIN) {
                    return {
                        ...agent,
                        smsPackage: smsLimit
                    };
                }

                return agent;
            }),
            isLoading: false
        }));
    };

    const adminPanelContextValues: AdminPanelType = {
        handleChange,
        handleEditChange,
        clearFields,
        adminPanelState,
        createAgentFieldsState,
        editAgentFieldsState,
        validateField: isFieldValid,
        handleFillAdvertFields,
        loadAgencyDashboard,
        deleteAgent,
        loadAgentDetails,
        editAgent,
        changeAgentActiveStatus,
        createAgent,
        changeAgentPassword,
        validateCreateAgentFields,
        validateAgentFields,
        validatePasswordFields,
        handlePasswordChange,
        passwordFieldsState,
        changeSmsLimitToAdmin
    };

    return (
        <AdminPanelContext.Provider value={adminPanelContextValues}>
            {children}
        </AdminPanelContext.Provider>
    );
};

export default AdminPanelContext;
