const getTitleByType = (type: string) => {
    switch (type) {
    case 'add_offer':
        return 'Dodaj ogłoszenie';
    case 'my_offer':
        return 'Moje ogłoszenia';
    case 'profile':
        return 'Ogłoszenia użytkownika';
    case 'users':
    default:
        return 'Ogłoszenia użytkowników';
    }
};

export default getTitleByType;
