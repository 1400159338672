/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * Dojazd do budynku
 * @example "["Utwardzony"]"
 */
export enum Access {
  Nieutwardzony = "Nieutwardzony",
  Utwardzony = "Utwardzony",
  Asfaltowy = "Asfaltowy",
}

export interface LocationDto {
  /**
   * @format int32
   * @example 14
   */
  voivodeshipNumber?: number;
  /**
   * @format int32
   * @example 19
   */
  countyNumber?: number;
  /**
   * @format int32
   * @example 15
   */
  communityNumber?: number;
  /**
   * @format int32
   * @example 4
   */
  kindNumber?: number;
  /**
   * @format int32
   * @example 1
   */
  subRegionNumber?: number;
  /**
   * @format int32
   * @example 969379
   */
  regionNumber?: number;
  /**
   * @format int32
   * @example 12548
   */
  streetNumber?: number;
  /**
   * Pełna nazwa
   * @example "Rybaki"
   */
  fullName?: string;
}

/** @example "["Ogrodzenie"]" */
export enum PlotAdditionalInformation {
  Ogrodzenie = "Ogrodzenie",
  Ogrodzenie1 = "Ogrodzenie",
}

/**
 * Typ budynku
 * @example "Budowlana"
 */
export enum PlotBuildingType {
  Budowlana = "Budowlana",
  Rolna = "Rolna",
  Rekreacyjna = "Rekreacyjna",
  PodInwestycje = "Pod inwestycję",
  Lesna = "Leśna",
  RolnoBudowlana = "Rolno-budowlana",
  Siedliskowa = "Siedliskowa",
  Inne = "Inne",
}

/**
 * Media
 * @example "["Prąd"]"
 */
export enum PlotMedia {
  Prad = "Prąd",
  Telefon = "Telefon",
  Oczyszczalnia = "Oczyszczalnia",
  Woda = "Woda",
  Kanalizacja = "Kanalizacja",
  Gaz = "Gaz",
  Szambo = "Szambo",
}

/**
 * Okolica
 * @example "["Jezioro"]"
 */
export enum PlotNeighborhood {
  Las = "Las",
  Gory = "Góry",
  Jezioro = "Jezioro",
  Morze = "Morze",
  OtwartyTeren = "Otwarty teren",
}

export interface SalePlotEditDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  /**
   * Wymiary działki
   * Użytkownik podje je samodzielnie
   * @example "10x20"
   */
  dimensions?: string;
  buildingType?: PlotBuildingType;
  /**
   * Media
   * @example ["Prąd"]
   */
  media?: PlotMedia[];
  /**
   * Dojazd
   * @example ["Nieutwardzony"]
   */
  access?: Access[];
  /**
   * Okolica
   * @example ["Jezioro"]
   */
  neighborhood?: PlotNeighborhood[];
  /** @example ["Ogrodzenie"] */
  additionalInformation?: SalePlotEditDtoAdditionalInformationEnum;
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
}

/**
 * Piętro
 * @example "4"
 */
export enum FloorLevel {
  Sutenera = "Sutenera",
  Parter = "Parter",
  Value1 = "1",
  Value2 = "2",
  Value3 = "3",
  Value4 = "4",
  Value5 = "5",
  Value6 = "6",
  Value7 = "7",
  Value8 = "8",
  Value9 = "9",
  Value10 = "10",
  Value101 = ">10",
  Poddasze = "Poddasze",
}

/**
 * Dodatkowe informacje
 * @example "["Ogrzewanie"]"
 */
export enum OtherAdditionalInformation {
  Witryna = "Witryna",
  Parking = "Parking",
  DojazdAsfaltowy = "Dojazd asfaltowy",
  Winda = "Winda",
  Klimatyzacja = "Klimatyzacja",
  Ogrzewanie = "Ogrzewanie",
  Umeblowanie = "Umeblowanie",
  Ogrodzenie = "Ogrodzenie",
  ValueSwiatlo = "Światło",
}

/**
 * Przeznaczenie budynku
 * @example "["Handlowe"]"
 */
export enum OtherBuildingPurpose {
  Magazynowe = "Magazynowe",
  Produkcyjne = "Produkcyjne",
  Biurowe = "Biurowe",
  Handlowe = "Handlowe",
  Uslugowy = "Usługowy",
  Biurowy = "Biurowy",
  Handlowy = "Handlowy",
  Gastronomiczny = "Gastronomiczny",
  Przemyslowy = "Przemysłowy",
  Hotelowy = "Hotelowy",
}

/**
 * Pomieszczenia użytkowe
 * @example "["Rampa"]"
 */
export enum OtherBuildingRooms {
  PomieszczeniaBiurowe = "Pomieszczenia biurowe",
  ZapleczeSocjalne = "Zaplecze socjalne",
  Rampa = "Rampa",
}

/**
 * Typ budynku
 * @example "Blok"
 */
export enum OtherBuildingType {
  Garaze = "Garaże",
  Hale = "Hale",
  Pozostale = "Pozostałe",
}

/**
 * Rodzaj konstrukcji budynku
 * @example "Murowany"
 */
export enum OtherConstruction {
  Murowany = "Murowany",
  Drewniany = "Drewniany",
  Blaszak = "Blaszak",
  Stalowa = "Stalowa",
  Murowana = "Murowana",
  Wiata = "Wiata",
  Namiotowa = "Namiotowa",
  Drewniana = "Drewniana",
  Szklana = "Szklana",
}

/** @example "Niepylna" */
export enum OtherFlooring {
  Brak = "Brak",
  Pylna = "Pylna",
  Niepylna = "Niepylna",
}

/**
 * Lokalizacja nieruchomości
 * @example "W budynku"
 */
export enum OtherLocation {
  WBudynku = "W budynku",
  Samodzielny = "Samodzielny",
  PrzyDomu = "Przy domu",
  Udzial = "Udział",
  WCentrumHandlowym = "W centrum handlowym",
  WBiurowcu = "W biurowcu",
  WBloku = "W bloku",
  WKamienicy = "W kamienicy",
  WDomuPrywatnym = "W domu prywatnym",
  WBudynkuZabytkowym = "W budynku zabytkowym",
  OddzielnyObiekt = "Oddzielny obiekt",
}

/**
 * Media
 * @example "["Woda"]"
 */
export enum OtherMedia {
  Woda = "Woda",
  Prad = "Prąd",
  Sila = "Siła",
  Kanalizacja = "Kanalizacja",
  Telefon = "Telefon",
  Gaz = "Gaz",
  Internet = "Internet",
  Szambo = "Szambo",
  Oczyszczalnia = "Oczyszczalnia",
  TelewizjaKablowa = "Telewizja kablowa",
}

/**
 * Asfaltowy
 * @example "Utwardzony"
 */
export enum OtherParking {
  Brak = "Brak",
  Utwardzony = "Utwardzony",
  Betonowy = "Betonowy",
  Asfaltowy = "Asfaltowy",
  Nieutwardzony = "Nieutwardzony",
  KostkaBrukowa = "Kostka brukowa",
}

/**
 * Stan wykończenia domu
 * @example "Do zamieszkania"
 */
export enum PropertyCondition {
  DoRemontu = "Do remontu",
  StanSurowyOtwarty = "Stan surowy otwarty",
  StanSurowyZamkniety = "Stan surowy zamknięty",
  DoZamieszkania = "Do zamieszkania",
  GotowyA = "Gotowy/a",
  DoWykonczenia = "Do wykończenia",
}

/**
 * Forma własności mieszkania
 * @example "Udział"
 */
export enum PropertyForm {
  SpoldzielczeWlPrawoDoLokalu = "Spółdzielcze wł. Prawo do lokalu",
  UzytkowanieWieczysteDzierzawa = "Użytkowanie wieczyste / dzierżawa",
  PelnaWlasnosc = "Pełna własność",
  Udzial = "Udział",
}

export interface SaleOtherEditDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  floor?: FloorLevel;
  propertyForm: PropertyForm;
  buildingType?: OtherBuildingType;
  construction?: OtherConstruction;
  location?: OtherLocation;
  /**
   * Wysokość nieruchomości
   * @example "30"
   */
  height?: string;
  parking?: OtherParking;
  flooring?: OtherFlooring;
  /**
   * Pomieszczenia użytkowe
   * @example ["Rampa"]
   */
  buildingRooms?: OtherBuildingRooms[];
  propertyCondition?: PropertyCondition;
  /**
   * Przeznaczenie budynku
   * @example ["Handlowe"]
   */
  buildingPurpose?: OtherBuildingPurpose[];
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Media
   * @example ["Woda"]
   */
  media?: OtherMedia[];
  /**
   * Dojazd
   * @example ["Asfaltowy"]
   */
  access?: Access[];
  /**
   * Dodatkowe informacje
   * @example ["Ogrzewanie"]
   */
  additionalInformation?: OtherAdditionalInformation[];
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
}

/**
 * Zabezpieczenia budynku
 * @example "["Teren zamknięty"]"
 */
export enum Security {
  RoletyAntywlamaniowe = "Rolety antywłamaniowe",
  DrzwiOknaAntywlamaniowe = "Drzwi / okna antywłamaniowe",
  DomofonWideofon = "Domofon / wideofon",
  MonitoringOchrona = "Monitoring / ochrona",
  SystemAlarmowy = "System alarmowy",
  TerenZamkniety = "Teren zamknięty",
}

/**
 * Typ rynku nieruchomości
 * @example "Pierwotny"
 */
export enum TypeOfMarket {
  Pierwotny = "Pierwotny",
  Wtorny = "Wtórny",
}

/**
 * Dodatkowe informacje
 * @example "["Klimatyzacja"]"
 */
export enum HouseAdditionalInformation {
  Strych = "Strych",
  Piwnica = "Piwnica",
  Garaz = "Garaż",
  Basen = "Basen",
  Klimatyzacja = "Klimatyzacja",
}

/**
 * Poddasze
 * @example "Użytkowe"
 */
export enum HouseAttic {
  Brak = "Brak",
  Uzytkowe = "Użytkowe",
  Nieuzytkowe = "Nieużytkowe",
}

/**
 * Materiał wykonania budynku
 * @example "Pustak"
 */
export enum HouseBuildingMaterial {
  Cegla = "Cegła",
  Drewno = "Drewno",
  Pustak = "Pustak",
  Keramzyt = "Keramzyt",
  WielkaPlyta = "Wielka Płyta",
  Beton = "Beton",
  Strzecha = "Strzecha",
  Silikat = "Silikat",
  BetonKomorkowy = "Beton Komórkowy",
  Inne = "Inne",
}

/**
 * Typ budynku
 * @example "Blok"
 */
export enum HouseBuildingType {
  Wolnostojacy = "Wolnostojący",
  Blizniak = "Bliźniak",
  Szeregowiec = "Szeregowiec",
  Kamienica = "Kamienica",
  DworekPalac = "Dworek/pałac",
  Gospodarstwo = "Gospodarstwo",
}

/**
 * Ogrodzenie budynku
 * @example "["Murowane"]"
 */
export enum HouseFence {
  Murowane = "Murowane",
  Drewniane = "Drewniane",
  Siatka = "Siatka",
  Betonowe = "Betonowe",
  Metalowe = "Metalowe",
  ValueZywoplot = "Żywopłot",
  Inne = "Inne",
}

/**
 * Liczba pieter
 * @example "0"
 */
export enum HouseFloorLevel {
  Value0 = "0",
  Value1 = "1",
  Value2 = "2",
  Value3 = "3",
  Value4 = "4",
}

/**
 * Ogrzewanie budynku
 * @example "["Węglowe"]"
 */
export enum HouseHeating {
  Olejowe = "Olejowe",
  Kominkowe = "Kominkowe",
  Weglowe = "Węglowe",
  KolektorSloneczny = "Kolektor słoneczny",
  Elektryczne = "Elektryczne",
  PieceKaflowe = "Piece kaflowe",
  Biomasa = "Biomasa",
  Geotermika = "Geotermika",
  Miejskie = "Miejskie",
  Gazowe = "Gazowe",
  PompaCiepla = "Pompa ciepła",
}

/**
 * Położenie
 * @example "Miasto"
 */
export enum HouseLocation {
  Miasto = "Miasto",
  PodMiastem = "Pod Miastem",
  Wies = "Wieś",
}

/**
 * Media
 * @example "["Woda"]"
 */
export enum HouseMedia {
  Woda = "Woda",
  Telefon = "Telefon",
  Szambo = "Szambo",
  Prad = "Prąd",
  Gaz = "Gaz",
  Oczyszczalnia = "Oczyszczalnia",
  Kanalizacja = "Kanalizacja",
  Internet = "Internet",
  TelewizjaKablowa = "Telewizja kablowa",
}

/**
 * Otoczenie budynku
 * @example "["Morze"]"
 */
export enum HouseNeighborhood {
  Las = "Las",
  Jezioro = "Jezioro",
  Gory = "Góry",
  Morze = "Morze",
}

/**
 * Dach
 * @example "Skośny"
 */
export enum HouseRoof {
  Brak = "Brak",
  Plaski = "Płaski",
  Skosny = "Skośny",
}

/**
 * Pokrycie dachu
 * @example "Blacha"
 */
export enum HouseRoofMaterial {
  Blacha = "Blacha",
  Dachowka = "Dachówka",
  Eternit = "Eternit",
  Gont = "Gont",
  ValueLupek = "Łupek",
  Papa = "Papa",
  Strzecha = "Strzecha",
  Inne = "Inne",
}

export interface SaleHouseEditDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w domu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType?: HouseBuildingType;
  floors?: HouseFloorLevel;
  propertyForm: PropertyForm;
  /**
   * Dom dostępne od
   * Czas w formacie Timestamp
   * @format date-time
   */
  availableFrom?: string;
  attic?: HouseAttic;
  roof?: HouseRoof;
  roofMaterial?: HouseRoofMaterial;
  buildingMaterial?: HouseBuildingMaterial;
  window?: Window;
  propertyCondition?: PropertyCondition;
  location?: HouseLocation;
  /**
   * Dom rekreacyjny
   * @example false
   */
  recreationalHouse: boolean;
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Ogrodzenie budynku
   * @example ["Murowane"]
   */
  fence?: HouseFence[];
  /**
   * Ogrzewanie budynku
   * @example ["Węglowe"]
   */
  heating?: HouseHeating[];
  /**
   * Media
   * @example ["Woda"]
   */
  media?: HouseMedia[];
  /**
   * Dojazd do budynku
   * @example ["Utwardzony"]
   */
  access?: Access[];
  /**
   * Otoczenie budynku
   * @example ["Morze"]
   */
  neighborhood?: HouseNeighborhood[];
  /**
   * Dodatkowe informacje
   * @example ["Klimatyzacja"]
   */
  additionalInformation?: HouseAdditionalInformation[];
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
}

/**
 * Rodzaj okien w budynku
 * @example "Aluminiowe"
 */
export enum Window {
  Plastikowe = "Plastikowe",
  Drewniane = "Drewniane",
  Aluminiowe = "Aluminiowe",
}

/**
 * Dodatkowe informacje
 * @example "["Balkon"]"
 */
export enum FlatAdditionalInformation {
  Balkon = "Balkon",
  Piwnica = "Piwnica",
  Winda = "Winda",
  Klimatyzacja = "Klimatyzacja",
  PomUzytkowe = "Pom. użytkowe",
  Ogrodek = "Ogródek",
  Dwupoziomowe = "Dwupoziomowe",
  GarazMiejsceParkingowe = "Garaż / miejsce parkingowe",
  Taras = "Taras",
  OddzielnaKuchnia = "Oddzielna kuchnia",
}

/**
 * Materiał budynku
 * @example "Beton"
 */
export enum FlatBuildingMaterial {
  Cegla = "Cegła",
  Drewno = "Drewno",
  Pustak = "Pustak",
  Keramzyt = "Keramzyt",
  WielkaPlyta = "Wielka płyta",
  Beton = "Beton",
  Silikat = "Silikat",
  BetonKomorkowy = "Beton komórkowy",
  ValueZelbet = "Żelbet",
  Inne = "Inne",
}

/**
 * Typ budynku
 * @example "Blok"
 */
export enum FlatBuildingType {
  Blok = "Blok",
  Kamienica = "Kamienica",
  DomWolnostojacy = "Dom wolnostojący",
  Plomba = "Plomba",
  Szeregowiec = "Szeregowiec",
  Apartamentowiec = "Apartamentowiec",
  Pozostale = "Pozostałe",
}

/**
 * Wyposażenie mieszkania
 * @example "["Lodówka"]"
 */
export enum FlatEquipment {
  Lodowka = "Lodówka",
  Telewizor = "Telewizor",
  Telewizor1 = "Telewizor",
  Pralka = "Pralka",
  Kuchenka = "Kuchenka",
  Zmywarka = "Zmywarka",
  Piekarnik = "Piekarnik",
}

/**
 * Rodzaj ogrzewania budynku
 * @example "Miejskie"
 */
export enum FlatHeating {
  Miejskie = "Miejskie",
  Gazowe = "Gazowe",
  PieceKaflowe = "Piece kaflowe",
  Elektryczne = "Elektryczne",
  Kotlownia = "Kotłownia",
  Inne = "Inne",
}

/**
 * Media
 * @example "["Telefon"]"
 */
export enum FlatMedia {
  Internet = "Internet",
  TelewizjaKablowa = "Telewizja kablowa",
  Telefon = "Telefon",
}

/**
 * Stan wykończenia mieszkania
 * @example "Do zamieszkania"
 */
export enum FlatPropertyCondition {
  DoZamieszkania = "Do zamieszkania",
  DoWykonczenia = "Do wykończenia",
  GotowyA = "Gotowy/a",
}

export interface SaleFlatEditDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w mieszkaniu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType: FlatBuildingType;
  floor?: FloorLevel;
  /**
   * Liczba pięter w nieruchomości
   * @example "10"
   */
  numberOfFloors?: string;
  /**
   * Mieszkanie dostępne od
   * Czas w formacie Timestamp
   * @format date-time
   */
  availableFrom?: string;
  propertyForm: PropertyForm;
  buildingMaterial?: FlatBuildingMaterial;
  window?: Window;
  heating?: FlatHeating;
  propertyCondition?: FlatPropertyCondition;
  /**
   * Media
   * @example ["Telefon"]
   */
  media?: FlatMedia[];
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Wyposażenie mieszkania
   * @example ["Lodówka"]
   */
  equipment?: FlatEquipment[];
  /**
   * Dodatkowe informacje
   * @example ["Balkon"]
   */
  additionalInformation?: FlatAdditionalInformation[];
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
}

export interface RentPlotEditDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  /**
   * Wymiary działki
   * Użytkownik podje je samodzielnie
   * @example "10x20"
   */
  dimensions?: string;
  buildingType?: PlotBuildingType;
  /**
   * Media
   * @example ["Prąd"]
   */
  media?: PlotMedia[];
  /**
   * Dojazd
   * @example ["Nieutwardzony"]
   */
  access?: Access[];
  /**
   * Okolica
   * @example ["Jezioro"]
   */
  neighborhood?: PlotNeighborhood[];
  /** @example ["Ogrodzenie"] */
  additionalInformation?: RentPlotEditDtoAdditionalInformationEnum;
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @min 0
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @min 0
   * @example 200
   */
  depositPrice?: number;
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
}

export interface RentOtherEditDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  floor?: FloorLevel;
  propertyForm: PropertyForm;
  buildingType?: OtherBuildingType;
  construction?: OtherConstruction;
  location?: OtherLocation;
  /**
   * Wysokość nieruchomości
   * @example "30"
   */
  height?: string;
  parking?: OtherParking;
  flooring?: OtherFlooring;
  /**
   * Pomieszczenia użytkowe
   * @example ["Rampa"]
   */
  buildingRooms?: OtherBuildingRooms[];
  propertyCondition?: PropertyCondition;
  /**
   * Przeznaczenie budynku
   * @example ["Handlowe"]
   */
  buildingPurpose?: OtherBuildingPurpose[];
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Media
   * @example ["Woda"]
   */
  media?: OtherMedia[];
  /**
   * Dojazd
   * @example ["Asfaltowy"]
   */
  access?: Access[];
  /**
   * Dodatkowe informacje
   * @example ["Ogrzewanie"]
   */
  additionalInformation?: OtherAdditionalInformation[];
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @min 0
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @min 0
   * @example 200
   */
  depositPrice?: number;
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
}

export interface RentHouseEditDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w domu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType?: HouseBuildingType;
  floors?: HouseFloorLevel;
  propertyForm: PropertyForm;
  /**
   * Dom dostępne od
   * Czas w formacie Timestamp
   * @format date-time
   */
  availableFrom?: string;
  attic?: HouseAttic;
  roof?: HouseRoof;
  roofMaterial?: HouseRoofMaterial;
  buildingMaterial?: HouseBuildingMaterial;
  window?: Window;
  propertyCondition?: PropertyCondition;
  location?: HouseLocation;
  /**
   * Dom rekreacyjny
   * @example false
   */
  recreationalHouse: boolean;
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Ogrodzenie budynku
   * @example ["Murowane"]
   */
  fence?: HouseFence[];
  /**
   * Ogrzewanie budynku
   * @example ["Węglowe"]
   */
  heating?: HouseHeating[];
  /**
   * Media
   * @example ["Woda"]
   */
  media?: HouseMedia[];
  /**
   * Dojazd do budynku
   * @example ["Utwardzony"]
   */
  access?: Access[];
  /**
   * Otoczenie budynku
   * @example ["Morze"]
   */
  neighborhood?: HouseNeighborhood[];
  /**
   * Dodatkowe informacje
   * @example ["Klimatyzacja"]
   */
  additionalInformation?: HouseAdditionalInformation[];
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @min 0
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @min 0
   * @example 200
   */
  depositPrice?: number;
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
}

export interface RentFlatEditDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w mieszkaniu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType: FlatBuildingType;
  floor?: FloorLevel;
  /**
   * Liczba pięter w nieruchomości
   * @example "10"
   */
  numberOfFloors?: string;
  /**
   * Mieszkanie dostępne od
   * Czas w formacie Timestamp
   * @format date-time
   */
  availableFrom?: string;
  propertyForm: PropertyForm;
  buildingMaterial?: FlatBuildingMaterial;
  window?: Window;
  heating?: FlatHeating;
  propertyCondition?: FlatPropertyCondition;
  /**
   * Media
   * @example ["Telefon"]
   */
  media?: FlatMedia[];
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Wyposażenie mieszkania
   * @example ["Lodówka"]
   */
  equipment?: FlatEquipment[];
  /**
   * Dodatkowe informacje
   * @example ["Balkon"]
   */
  additionalInformation?: FlatAdditionalInformation[];
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @min 0
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @min 0
   * @example 200
   */
  depositPrice?: number;
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
}

export interface MessageCreateDto {
  /**
   * Imię i nazwisko
   * @example "Jan Kowalski"
   */
  contactName: string;
  /**
   * Email klienta
   * @example "test@gmail.com"
   */
  email: string;
  /**
   * Numer telefonu klienta
   * @example "123456789"
   */
  phoneNumber: string;
  /**
   * Wiadomość od klienta
   * @example "Wiadomość testowa"
   */
  messageText: string;
  /**
   * EncryptedId
   * @example "devf4v5tv34545arefqwq===dada"
   */
  encryptedId: string;
}

export interface SalePlotCreateDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  /**
   * Wymiary działki
   * Użytkownik podje je samodzielnie
   * @example "10x20"
   */
  dimensions?: string;
  buildingType?: PlotBuildingType;
  /**
   * Media
   * @example ["Prąd"]
   */
  media?: PlotMedia[];
  /**
   * Dojazd
   * @example ["Nieutwardzony"]
   */
  access?: Access[];
  /**
   * Okolica
   * @example ["Jezioro"]
   */
  neighborhood?: PlotNeighborhood[];
  /** @example ["Ogrodzenie"] */
  additionalInformation?: SalePlotCreateDtoAdditionalInformationEnum;
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * EncryptedId oryginalnego ogłoszenia
   * @example "7HuTRhlV"
   */
  originalEncryptedId?: string;
}

export interface SaleOtherCreateDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  floor?: FloorLevel;
  propertyForm: PropertyForm;
  buildingType?: OtherBuildingType;
  construction?: OtherConstruction;
  location?: OtherLocation;
  /**
   * Wysokość nieruchomości
   * @example "30"
   */
  height?: string;
  parking?: OtherParking;
  flooring?: OtherFlooring;
  /**
   * Pomieszczenia użytkowe
   * @example ["Rampa"]
   */
  buildingRooms?: OtherBuildingRooms[];
  propertyCondition?: PropertyCondition;
  /**
   * Przeznaczenie budynku
   * @example ["Handlowe"]
   */
  buildingPurpose?: OtherBuildingPurpose[];
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Media
   * @example ["Woda"]
   */
  media?: OtherMedia[];
  /**
   * Dojazd
   * @example ["Asfaltowy"]
   */
  access?: Access[];
  /**
   * Dodatkowe informacje
   * @example ["Ogrzewanie"]
   */
  additionalInformation?: OtherAdditionalInformation[];
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * EncryptedId oryginalnego ogłoszenia
   * @example "7HuTRhlV"
   */
  originalEncryptedId?: string;
}

export interface SaleHouseCreateDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w domu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType?: HouseBuildingType;
  floors?: HouseFloorLevel;
  propertyForm: PropertyForm;
  /**
   * Dom dostępne od
   * Czas w formacie Timestamp
   * @format date-time
   */
  availableFrom?: string;
  attic?: HouseAttic;
  roof?: HouseRoof;
  roofMaterial?: HouseRoofMaterial;
  buildingMaterial?: HouseBuildingMaterial;
  window?: Window;
  propertyCondition?: PropertyCondition;
  location?: HouseLocation;
  /**
   * Dom rekreacyjny
   * @example false
   */
  recreationalHouse: boolean;
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Ogrodzenie budynku
   * @example ["Murowane"]
   */
  fence?: HouseFence[];
  /**
   * Ogrzewanie budynku
   * @example ["Węglowe"]
   */
  heating?: HouseHeating[];
  /**
   * Media
   * @example ["Woda"]
   */
  media?: HouseMedia[];
  /**
   * Dojazd do budynku
   * @example ["Utwardzony"]
   */
  access?: Access[];
  /**
   * Otoczenie budynku
   * @example ["Morze"]
   */
  neighborhood?: HouseNeighborhood[];
  /**
   * Dodatkowe informacje
   * @example ["Klimatyzacja"]
   */
  additionalInformation?: HouseAdditionalInformation[];
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * EncryptedId oryginalnego ogłoszenia
   * @example "7HuTRhlV"
   */
  originalEncryptedId?: string;
}

export interface SaleFlatCreateDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w mieszkaniu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType: FlatBuildingType;
  floor?: FloorLevel;
  /**
   * Liczba pięter w nieruchomości
   * @example "10"
   */
  numberOfFloors?: string;
  /**
   * Mieszkanie dostępne od
   * Czas w formacie Timestamp
   * @format date-time
   */
  availableFrom?: string;
  propertyForm: PropertyForm;
  buildingMaterial?: FlatBuildingMaterial;
  window?: Window;
  heating?: FlatHeating;
  propertyCondition?: FlatPropertyCondition;
  /**
   * Media
   * @example ["Telefon"]
   */
  media?: FlatMedia[];
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Wyposażenie mieszkania
   * @example ["Lodówka"]
   */
  equipment?: FlatEquipment[];
  /**
   * Dodatkowe informacje
   * @example ["Balkon"]
   */
  additionalInformation?: FlatAdditionalInformation[];
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * EncryptedId oryginalnego ogłoszenia
   * @example "7HuTRhlV"
   */
  originalEncryptedId?: string;
}

export interface RentPlotCreateDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  /**
   * Wymiary działki
   * Użytkownik podje je samodzielnie
   * @example "10x20"
   */
  dimensions?: string;
  buildingType?: PlotBuildingType;
  /**
   * Media
   * @example ["Prąd"]
   */
  media?: PlotMedia[];
  /**
   * Dojazd
   * @example ["Nieutwardzony"]
   */
  access?: Access[];
  /**
   * Okolica
   * @example ["Jezioro"]
   */
  neighborhood?: PlotNeighborhood[];
  /** @example ["Ogrodzenie"] */
  additionalInformation?: RentPlotCreateDtoAdditionalInformationEnum;
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @min 0
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @min 0
   * @example 200
   */
  depositPrice?: number;
  /**
   * EncryptedId oryginalnego ogłoszenia
   * @example "7HuTRhlV"
   */
  originalEncryptedId?: string;
}

export interface RentOtherCreateDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  floor?: FloorLevel;
  propertyForm: PropertyForm;
  buildingType?: OtherBuildingType;
  construction?: OtherConstruction;
  location?: OtherLocation;
  /**
   * Wysokość nieruchomości
   * @example "30"
   */
  height?: string;
  parking?: OtherParking;
  flooring?: OtherFlooring;
  /**
   * Pomieszczenia użytkowe
   * @example ["Rampa"]
   */
  buildingRooms?: OtherBuildingRooms[];
  propertyCondition?: PropertyCondition;
  /**
   * Przeznaczenie budynku
   * @example ["Handlowe"]
   */
  buildingPurpose?: OtherBuildingPurpose[];
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Media
   * @example ["Woda"]
   */
  media?: OtherMedia[];
  /**
   * Dojazd
   * @example ["Asfaltowy"]
   */
  access?: Access[];
  /**
   * Dodatkowe informacje
   * @example ["Ogrzewanie"]
   */
  additionalInformation?: OtherAdditionalInformation[];
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @min 0
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @min 0
   * @example 200
   */
  depositPrice?: number;
  /**
   * EncryptedId oryginalnego ogłoszenia
   * @example "7HuTRhlV"
   */
  originalEncryptedId?: string;
}

export interface RentHouseCreateDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w domu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType?: HouseBuildingType;
  floors?: HouseFloorLevel;
  propertyForm: PropertyForm;
  /**
   * Dom dostępne od
   * Czas w formacie Timestamp
   * @format date-time
   */
  availableFrom?: string;
  attic?: HouseAttic;
  roof?: HouseRoof;
  roofMaterial?: HouseRoofMaterial;
  buildingMaterial?: HouseBuildingMaterial;
  window?: Window;
  propertyCondition?: PropertyCondition;
  location?: HouseLocation;
  /**
   * Dom rekreacyjny
   * @example false
   */
  recreationalHouse: boolean;
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Ogrodzenie budynku
   * @example ["Murowane"]
   */
  fence?: HouseFence[];
  /**
   * Ogrzewanie budynku
   * @example ["Węglowe"]
   */
  heating?: HouseHeating[];
  /**
   * Media
   * @example ["Woda"]
   */
  media?: HouseMedia[];
  /**
   * Dojazd do budynku
   * @example ["Utwardzony"]
   */
  access?: Access[];
  /**
   * Otoczenie budynku
   * @example ["Morze"]
   */
  neighborhood?: HouseNeighborhood[];
  /**
   * Dodatkowe informacje
   * @example ["Klimatyzacja"]
   */
  additionalInformation?: HouseAdditionalInformation[];
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @min 0
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @min 0
   * @example 200
   */
  depositPrice?: number;
  /**
   * EncryptedId oryginalnego ogłoszenia
   * @example "7HuTRhlV"
   */
  originalEncryptedId?: string;
}

export interface RentFlatCreateDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w mieszkaniu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType: FlatBuildingType;
  floor?: FloorLevel;
  /**
   * Liczba pięter w nieruchomości
   * @example "10"
   */
  numberOfFloors?: string;
  /**
   * Mieszkanie dostępne od
   * Czas w formacie Timestamp
   * @format date-time
   */
  availableFrom?: string;
  propertyForm: PropertyForm;
  buildingMaterial?: FlatBuildingMaterial;
  window?: Window;
  heating?: FlatHeating;
  propertyCondition?: FlatPropertyCondition;
  /**
   * Media
   * @example ["Telefon"]
   */
  media?: FlatMedia[];
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Wyposażenie mieszkania
   * @example ["Lodówka"]
   */
  equipment?: FlatEquipment[];
  /**
   * Dodatkowe informacje
   * @example ["Balkon"]
   */
  additionalInformation?: FlatAdditionalInformation[];
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @min 0
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @min 0
   * @example 200
   */
  depositPrice?: number;
  /**
   * EncryptedId oryginalnego ogłoszenia
   * @example "7HuTRhlV"
   */
  originalEncryptedId?: string;
}

export interface AiGenerateDto {
  /**
   * EncryptedId ogłoszenia
   * @example "6_Rnv-oHPJ3UoCZRm0ynWcGfLs-nLFZKEdkJwBEg75Y="
   */
  encryptedId: string;
}

export interface SalePlotOriginalDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  /**
   * Wymiary działki
   * Użytkownik podje je samodzielnie
   * @example "10x20"
   */
  dimensions?: string;
  buildingType?: PlotBuildingType;
  /**
   * Media
   * @example ["Prąd"]
   */
  media?: PlotMedia[];
  /**
   * Dojazd
   * @example ["Nieutwardzony"]
   */
  access?: Access[];
  /**
   * Okolica
   * @example ["Jezioro"]
   */
  neighborhood?: PlotNeighborhood[];
  /** @example ["Ogrodzenie"] */
  additionalInformation?: SalePlotOriginalDetailDtoAdditionalInformationEnum;
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /** Moduł ogłoszenia */
  module: SalePlotOriginalDetailDtoModuleEnum;
}

export interface SaleOtherOriginalDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  floor?: FloorLevel;
  propertyForm: PropertyForm;
  buildingType?: OtherBuildingType;
  construction?: OtherConstruction;
  location?: OtherLocation;
  /**
   * Wysokość nieruchomości
   * @example "30"
   */
  height?: string;
  parking?: OtherParking;
  flooring?: OtherFlooring;
  /**
   * Pomieszczenia użytkowe
   * @example ["Rampa"]
   */
  buildingRooms?: OtherBuildingRooms[];
  propertyCondition?: PropertyCondition;
  /**
   * Przeznaczenie budynku
   * @example ["Handlowe"]
   */
  buildingPurpose?: OtherBuildingPurpose[];
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Media
   * @example ["Woda"]
   */
  media?: OtherMedia[];
  /**
   * Dojazd
   * @example ["Asfaltowy"]
   */
  access?: Access[];
  /**
   * Dodatkowe informacje
   * @example ["Ogrzewanie"]
   */
  additionalInformation?: OtherAdditionalInformation[];
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /** Moduł ogłoszenia */
  module: SaleOtherOriginalDetailDtoModuleEnum;
}

export interface SaleHouseOriginalDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w domu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType?: HouseBuildingType;
  floors?: HouseFloorLevel;
  propertyForm: PropertyForm;
  /**
   * Dom dostępne od
   * Czas w formacie Timestamp
   * @format date-time
   */
  availableFrom?: string;
  attic?: HouseAttic;
  roof?: HouseRoof;
  roofMaterial?: HouseRoofMaterial;
  buildingMaterial?: HouseBuildingMaterial;
  window?: Window;
  propertyCondition?: PropertyCondition;
  location?: HouseLocation;
  /**
   * Dom rekreacyjny
   * @example false
   */
  recreationalHouse: boolean;
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Ogrodzenie budynku
   * @example ["Murowane"]
   */
  fence?: HouseFence[];
  /**
   * Ogrzewanie budynku
   * @example ["Węglowe"]
   */
  heating?: HouseHeating[];
  /**
   * Media
   * @example ["Woda"]
   */
  media?: HouseMedia[];
  /**
   * Dojazd do budynku
   * @example ["Utwardzony"]
   */
  access?: Access[];
  /**
   * Otoczenie budynku
   * @example ["Morze"]
   */
  neighborhood?: HouseNeighborhood[];
  /**
   * Dodatkowe informacje
   * @example ["Klimatyzacja"]
   */
  additionalInformation?: HouseAdditionalInformation[];
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /** Moduł ogłoszenia */
  module: SaleHouseOriginalDetailDtoModuleEnum;
}

export interface SaleFlatOriginalDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w mieszkaniu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType: FlatBuildingType;
  floor?: FloorLevel;
  /**
   * Liczba pięter w nieruchomości
   * @example "10"
   */
  numberOfFloors?: string;
  /**
   * Mieszkanie dostępne od
   * Czas w formacie Timestamp
   * @format date-time
   */
  availableFrom?: string;
  propertyForm: PropertyForm;
  buildingMaterial?: FlatBuildingMaterial;
  window?: Window;
  heating?: FlatHeating;
  propertyCondition?: FlatPropertyCondition;
  /**
   * Media
   * @example ["Telefon"]
   */
  media?: FlatMedia[];
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Wyposażenie mieszkania
   * @example ["Lodówka"]
   */
  equipment?: FlatEquipment[];
  /**
   * Dodatkowe informacje
   * @example ["Balkon"]
   */
  additionalInformation?: FlatAdditionalInformation[];
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /** Moduł ogłoszenia */
  module: SaleFlatOriginalDetailDtoModuleEnum;
}

/** Profil użytkownika */
export interface ProfileDto {
  /**
   * Nazwa firmy lub imię i nazwisko
   * @example "Testowy Michał"
   */
  name: string;
  /**
   * Email
   * @example "test@test.pl"
   */
  email: string;
  /** Opis profilu */
  description?: string;
  /**
   * Numer telefonu
   * @example "123456789"
   */
  phoneNumber: string;
  /**
   * Zdjęcie użytkownika
   * @example "35trfgr5435.webp"
   */
  userPhoto?: string;
}

export interface SalePlotDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @example 1000000
   */
  pricePerSquareMeter: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  /**
   * Wymiary działki
   * Użytkownik podje je samodzielnie
   * @example "10x20"
   */
  dimensions?: string;
  buildingType?: PlotBuildingType;
  /**
   * Media
   * @example ["Prąd"]
   */
  media?: PlotMedia[];
  /**
   * Dojazd
   * @example ["Nieutwardzony"]
   */
  access?: Access[];
  /**
   * Okolica
   * @example ["Jezioro"]
   */
  neighborhood?: PlotNeighborhood[];
  /** @example ["Ogrodzenie"] */
  additionalInformation?: SalePlotDetailDtoAdditionalInformationEnum;
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @example 1000000
   */
  price: number;
  /** Moduł ogłoszenia */
  module: SalePlotDetailDtoModuleEnum;
  profileDto: ProfileDto;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
}

export interface SaleOtherDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @example 1000000
   */
  pricePerSquareMeter: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  floor?: FloorLevel;
  propertyForm: PropertyForm;
  buildingType?: OtherBuildingType;
  construction?: OtherConstruction;
  location?: OtherLocation;
  /**
   * Wysokość nieruchomości
   * @example "30"
   */
  height?: string;
  parking?: OtherParking;
  flooring?: OtherFlooring;
  /**
   * Pomieszczenia użytkowe
   * @example ["Rampa"]
   */
  buildingRooms?: OtherBuildingRooms[];
  propertyCondition?: PropertyCondition;
  /**
   * Przeznaczenie budynku
   * @example ["Handlowe"]
   */
  buildingPurpose?: OtherBuildingPurpose[];
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Media
   * @example ["Woda"]
   */
  media?: OtherMedia[];
  /**
   * Dojazd
   * @example ["Asfaltowy"]
   */
  access?: Access[];
  /**
   * Dodatkowe informacje
   * @example ["Ogrzewanie"]
   */
  additionalInformation?: OtherAdditionalInformation[];
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @example 1000000
   */
  price: number;
  /** Moduł ogłoszenia */
  module: SaleOtherDetailDtoModuleEnum;
  profileDto: ProfileDto;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
}

export interface SaleHouseDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @example 1000000
   */
  pricePerSquareMeter: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w domu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType?: HouseBuildingType;
  floors?: HouseFloorLevel;
  propertyForm: PropertyForm;
  /**
   * Dom dostępne od
   * Czas w formacie Timestamp
   * @format date-time
   */
  availableFrom?: string;
  attic?: HouseAttic;
  roof?: HouseRoof;
  roofMaterial?: HouseRoofMaterial;
  buildingMaterial?: HouseBuildingMaterial;
  window?: Window;
  propertyCondition?: PropertyCondition;
  location?: HouseLocation;
  /**
   * Dom rekreacyjny
   * @example false
   */
  recreationalHouse: boolean;
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Ogrodzenie budynku
   * @example ["Murowane"]
   */
  fence?: HouseFence[];
  /**
   * Ogrzewanie budynku
   * @example ["Węglowe"]
   */
  heating?: HouseHeating[];
  /**
   * Media
   * @example ["Woda"]
   */
  media?: HouseMedia[];
  /**
   * Dojazd do budynku
   * @example ["Utwardzony"]
   */
  access?: Access[];
  /**
   * Otoczenie budynku
   * @example ["Morze"]
   */
  neighborhood?: HouseNeighborhood[];
  /**
   * Dodatkowe informacje
   * @example ["Klimatyzacja"]
   */
  additionalInformation?: HouseAdditionalInformation[];
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @example 1000000
   */
  price: number;
  /** Moduł ogłoszenia */
  module: SaleHouseDetailDtoModuleEnum;
  profileDto: ProfileDto;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
}

export interface SaleFlatDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @example 1000000
   */
  pricePerSquareMeter: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w mieszkaniu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType: FlatBuildingType;
  floor?: FloorLevel;
  /**
   * Liczba pięter w nieruchomości
   * @example "10"
   */
  numberOfFloors?: string;
  /**
   * Mieszkanie dostępne od
   * Czas w formacie Timestamp
   * @format date-time
   */
  availableFrom?: string;
  propertyForm: PropertyForm;
  buildingMaterial?: FlatBuildingMaterial;
  window?: Window;
  heating?: FlatHeating;
  propertyCondition?: FlatPropertyCondition;
  /**
   * Media
   * @example ["Telefon"]
   */
  media?: FlatMedia[];
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Wyposażenie mieszkania
   * @example ["Lodówka"]
   */
  equipment?: FlatEquipment[];
  /**
   * Dodatkowe informacje
   * @example ["Balkon"]
   */
  additionalInformation?: FlatAdditionalInformation[];
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @example 1000000
   */
  price: number;
  /** Moduł ogłoszenia */
  module: SaleFlatDetailDtoModuleEnum;
  profileDto: ProfileDto;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
}

export interface SalePlotEditDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  /**
   * Wymiary działki
   * Użytkownik podje je samodzielnie
   * @example "10x20"
   */
  dimensions?: string;
  buildingType?: PlotBuildingType;
  /**
   * Media
   * @example ["Prąd"]
   */
  media?: PlotMedia[];
  /**
   * Dojazd
   * @example ["Nieutwardzony"]
   */
  access?: Access[];
  /**
   * Okolica
   * @example ["Jezioro"]
   */
  neighborhood?: PlotNeighborhood[];
  /** @example ["Ogrodzenie"] */
  additionalInformation?: SalePlotEditDetailDtoAdditionalInformationEnum;
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /** Moduł ogłoszenia */
  module: SalePlotEditDetailDtoModuleEnum;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
  /**
   * Encrypted id oryginalnego ogłoszenia
   * @example "asfafet32441sadd"
   */
  originalEncryptedId: string;
}

export interface SaleOtherEditDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  floor?: FloorLevel;
  propertyForm: PropertyForm;
  buildingType?: OtherBuildingType;
  construction?: OtherConstruction;
  location?: OtherLocation;
  /**
   * Wysokość nieruchomości
   * @example "30"
   */
  height?: string;
  parking?: OtherParking;
  flooring?: OtherFlooring;
  /**
   * Pomieszczenia użytkowe
   * @example ["Rampa"]
   */
  buildingRooms?: OtherBuildingRooms[];
  propertyCondition?: PropertyCondition;
  /**
   * Przeznaczenie budynku
   * @example ["Handlowe"]
   */
  buildingPurpose?: OtherBuildingPurpose[];
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Media
   * @example ["Woda"]
   */
  media?: OtherMedia[];
  /**
   * Dojazd
   * @example ["Asfaltowy"]
   */
  access?: Access[];
  /**
   * Dodatkowe informacje
   * @example ["Ogrzewanie"]
   */
  additionalInformation?: OtherAdditionalInformation[];
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /** Moduł ogłoszenia */
  module: SaleOtherEditDetailDtoModuleEnum;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
  /**
   * Encrypted id oryginalnego ogłoszenia
   * @example "asfafet32441sadd"
   */
  originalEncryptedId: string;
}

export interface SaleHouseEditDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w domu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType?: HouseBuildingType;
  floors?: HouseFloorLevel;
  propertyForm: PropertyForm;
  /**
   * Dom dostępne od
   * Czas w formacie Timestamp
   * @format date-time
   */
  availableFrom?: string;
  attic?: HouseAttic;
  roof?: HouseRoof;
  roofMaterial?: HouseRoofMaterial;
  buildingMaterial?: HouseBuildingMaterial;
  window?: Window;
  propertyCondition?: PropertyCondition;
  location?: HouseLocation;
  /**
   * Dom rekreacyjny
   * @example false
   */
  recreationalHouse: boolean;
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Ogrodzenie budynku
   * @example ["Murowane"]
   */
  fence?: HouseFence[];
  /**
   * Ogrzewanie budynku
   * @example ["Węglowe"]
   */
  heating?: HouseHeating[];
  /**
   * Media
   * @example ["Woda"]
   */
  media?: HouseMedia[];
  /**
   * Dojazd do budynku
   * @example ["Utwardzony"]
   */
  access?: Access[];
  /**
   * Otoczenie budynku
   * @example ["Morze"]
   */
  neighborhood?: HouseNeighborhood[];
  /**
   * Dodatkowe informacje
   * @example ["Klimatyzacja"]
   */
  additionalInformation?: HouseAdditionalInformation[];
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /** Moduł ogłoszenia */
  module: SaleHouseEditDetailDtoModuleEnum;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
  /**
   * Encrypted id oryginalnego ogłoszenia
   * @example "asfafet32441sadd"
   */
  originalEncryptedId: string;
}

export interface SaleFlatEditDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w mieszkaniu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType: FlatBuildingType;
  floor?: FloorLevel;
  /**
   * Liczba pięter w nieruchomości
   * @example "10"
   */
  numberOfFloors?: string;
  /**
   * Mieszkanie dostępne od
   * Czas w formacie Timestamp
   * @format date-time
   */
  availableFrom?: string;
  propertyForm: PropertyForm;
  buildingMaterial?: FlatBuildingMaterial;
  window?: Window;
  heating?: FlatHeating;
  propertyCondition?: FlatPropertyCondition;
  /**
   * Media
   * @example ["Telefon"]
   */
  media?: FlatMedia[];
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Wyposażenie mieszkania
   * @example ["Lodówka"]
   */
  equipment?: FlatEquipment[];
  /**
   * Dodatkowe informacje
   * @example ["Balkon"]
   */
  additionalInformation?: FlatAdditionalInformation[];
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /** Moduł ogłoszenia */
  module: SaleFlatEditDetailDtoModuleEnum;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
  /**
   * Encrypted id oryginalnego ogłoszenia
   * @example "asfafet32441sadd"
   */
  originalEncryptedId: string;
}

export interface InfoDto {
  /**
   * Liczba odwiedzających ogłoszenie
   * @format int32
   * @example 1
   */
  numberOfViews: number;
  /**
   * Liczba kliknięć w telefon w ogłoszeniu
   * @format int32
   * @example 1
   */
  numberOfPhoneClick: number;
}

export interface PageableObject {
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int64 */
  offset?: number;
  sort?: SortObject;
  paged?: boolean;
  unpaged?: boolean;
}

export interface SalePlotPageDto {
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId: string;
  /**
   * EncryptedId oryginalnego ogłoszenia
   * @example "7HuTRhlV"
   */
  originalEncryptedId?: string;
  /**
   * Data dodania ogłoszenia
   * @format date-time
   */
  dateAdded: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Zdjęcia ogłoszenia
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  infoDto?: InfoDto;
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  buildingType: PlotBuildingType;
  /** Moduł ogłoszenia */
  module: SalePlotPageDtoModuleEnum;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
}

export interface SalePlotPageWrapper {
  content?: SalePlotPageDto[];
  pageable?: PageableObject;
  last?: boolean;
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  size?: number;
  /** @format int32 */
  number?: number;
  sort?: SortObject;
  first?: boolean;
  /** @format int32 */
  numberOfElements?: number;
  empty?: boolean;
}

export interface SortObject {
  sorted?: boolean;
  empty?: boolean;
  unsorted?: boolean;
}

export interface SaleOtherPageDto {
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId: string;
  /**
   * EncryptedId oryginalnego ogłoszenia
   * @example "7HuTRhlV"
   */
  originalEncryptedId?: string;
  /**
   * Data dodania ogłoszenia
   * @format date-time
   */
  dateAdded: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Zdjęcia ogłoszenia
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  infoDto?: InfoDto;
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w mieszkaniu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType: OtherBuildingType;
  /** Moduł ogłoszenia */
  module: SaleOtherPageDtoModuleEnum;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
}

export interface SaleOtherPageWrapper {
  content?: SaleOtherPageDto[];
  pageable?: PageableObject;
  last?: boolean;
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  size?: number;
  /** @format int32 */
  number?: number;
  sort?: SortObject;
  first?: boolean;
  /** @format int32 */
  numberOfElements?: number;
  empty?: boolean;
}

export interface SaleHousePageDto {
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId: string;
  /**
   * EncryptedId oryginalnego ogłoszenia
   * @example "7HuTRhlV"
   */
  originalEncryptedId?: string;
  /**
   * Data dodania ogłoszenia
   * @format date-time
   */
  dateAdded: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Zdjęcia ogłoszenia
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  infoDto?: InfoDto;
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w mieszkaniu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType: HouseBuildingType;
  /** Moduł ogłoszenia */
  module: SaleHousePageDtoModuleEnum;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
}

export interface SaleHousePageWrapper {
  content?: SaleHousePageDto[];
  pageable?: PageableObject;
  last?: boolean;
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  size?: number;
  /** @format int32 */
  number?: number;
  sort?: SortObject;
  first?: boolean;
  /** @format int32 */
  numberOfElements?: number;
  empty?: boolean;
}

export interface SaleFlatPageDto {
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId: string;
  /**
   * EncryptedId oryginalnego ogłoszenia
   * @example "7HuTRhlV"
   */
  originalEncryptedId?: string;
  /**
   * Data dodania ogłoszenia
   * @format date-time
   */
  dateAdded: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Zdjęcia ogłoszenia
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  infoDto?: InfoDto;
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w mieszkaniu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType: FlatBuildingType;
  /** Moduł ogłoszenia */
  module: SaleFlatPageDtoModuleEnum;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
}

export interface SaleFlatPageWrapper {
  content?: SaleFlatPageDto[];
  pageable?: PageableObject;
  last?: boolean;
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  size?: number;
  /** @format int32 */
  number?: number;
  sort?: SortObject;
  first?: boolean;
  /** @format int32 */
  numberOfElements?: number;
  empty?: boolean;
}

export interface SalePageDto {
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId: string;
  /**
   * EncryptedId oryginalnego ogłoszenia
   * @example "7HuTRhlV"
   */
  originalEncryptedId?: string;
  /**
   * Data dodania ogłoszenia
   * @format date-time
   */
  dateAdded: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Zdjęcia ogłoszenia
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  infoDto?: InfoDto;
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w mieszkaniu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  /**
   * Typ budynku
   * @example "Blok"
   */
  buildingType: string;
  /** Moduł ogłoszenia */
  module: SalePageDtoModuleEnum;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
}

export interface SalePageWrapper {
  content?: SalePageDto[];
  pageable?: PageableObject;
  last?: boolean;
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  size?: number;
  /** @format int32 */
  number?: number;
  sort?: SortObject;
  first?: boolean;
  /** @format int32 */
  numberOfElements?: number;
  empty?: boolean;
}

export interface RentPlotOriginalDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  /**
   * Wymiary działki
   * Użytkownik podje je samodzielnie
   * @example "10x20"
   */
  dimensions?: string;
  buildingType?: PlotBuildingType;
  /**
   * Media
   * @example ["Prąd"]
   */
  media?: PlotMedia[];
  /**
   * Dojazd
   * @example ["Nieutwardzony"]
   */
  access?: Access[];
  /**
   * Okolica
   * @example ["Jezioro"]
   */
  neighborhood?: PlotNeighborhood[];
  /** @example ["Ogrodzenie"] */
  additionalInformation?: RentPlotOriginalDetailDtoAdditionalInformationEnum;
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @min 0
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @min 0
   * @example 200
   */
  depositPrice?: number;
  /** Moduł ogłoszenia */
  module: RentPlotOriginalDetailDtoModuleEnum;
}

export interface RentOtherOriginalDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  floor?: FloorLevel;
  propertyForm: PropertyForm;
  buildingType?: OtherBuildingType;
  construction?: OtherConstruction;
  location?: OtherLocation;
  /**
   * Wysokość nieruchomości
   * @example "30"
   */
  height?: string;
  parking?: OtherParking;
  flooring?: OtherFlooring;
  /**
   * Pomieszczenia użytkowe
   * @example ["Rampa"]
   */
  buildingRooms?: OtherBuildingRooms[];
  propertyCondition?: PropertyCondition;
  /**
   * Przeznaczenie budynku
   * @example ["Handlowe"]
   */
  buildingPurpose?: OtherBuildingPurpose[];
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Media
   * @example ["Woda"]
   */
  media?: OtherMedia[];
  /**
   * Dojazd
   * @example ["Asfaltowy"]
   */
  access?: Access[];
  /**
   * Dodatkowe informacje
   * @example ["Ogrzewanie"]
   */
  additionalInformation?: OtherAdditionalInformation[];
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @min 0
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @min 0
   * @example 200
   */
  depositPrice?: number;
  /** Moduł ogłoszenia */
  module: RentOtherOriginalDetailDtoModuleEnum;
}

export interface RentHouseOriginalDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w domu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType?: HouseBuildingType;
  floors?: HouseFloorLevel;
  propertyForm: PropertyForm;
  /**
   * Dom dostępne od
   * Czas w formacie Timestamp
   * @format date-time
   */
  availableFrom?: string;
  attic?: HouseAttic;
  roof?: HouseRoof;
  roofMaterial?: HouseRoofMaterial;
  buildingMaterial?: HouseBuildingMaterial;
  window?: Window;
  propertyCondition?: PropertyCondition;
  location?: HouseLocation;
  /**
   * Dom rekreacyjny
   * @example false
   */
  recreationalHouse: boolean;
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Ogrodzenie budynku
   * @example ["Murowane"]
   */
  fence?: HouseFence[];
  /**
   * Ogrzewanie budynku
   * @example ["Węglowe"]
   */
  heating?: HouseHeating[];
  /**
   * Media
   * @example ["Woda"]
   */
  media?: HouseMedia[];
  /**
   * Dojazd do budynku
   * @example ["Utwardzony"]
   */
  access?: Access[];
  /**
   * Otoczenie budynku
   * @example ["Morze"]
   */
  neighborhood?: HouseNeighborhood[];
  /**
   * Dodatkowe informacje
   * @example ["Klimatyzacja"]
   */
  additionalInformation?: HouseAdditionalInformation[];
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @min 0
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @min 0
   * @example 200
   */
  depositPrice?: number;
  /** Moduł ogłoszenia */
  module: RentHouseOriginalDetailDtoModuleEnum;
}

export interface RentFlatOriginalDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w mieszkaniu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType: FlatBuildingType;
  floor?: FloorLevel;
  /**
   * Liczba pięter w nieruchomości
   * @example "10"
   */
  numberOfFloors?: string;
  /**
   * Mieszkanie dostępne od
   * Czas w formacie Timestamp
   * @format date-time
   */
  availableFrom?: string;
  propertyForm: PropertyForm;
  buildingMaterial?: FlatBuildingMaterial;
  window?: Window;
  heating?: FlatHeating;
  propertyCondition?: FlatPropertyCondition;
  /**
   * Media
   * @example ["Telefon"]
   */
  media?: FlatMedia[];
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Wyposażenie mieszkania
   * @example ["Lodówka"]
   */
  equipment?: FlatEquipment[];
  /**
   * Dodatkowe informacje
   * @example ["Balkon"]
   */
  additionalInformation?: FlatAdditionalInformation[];
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @min 0
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @min 0
   * @example 200
   */
  depositPrice?: number;
  /** Moduł ogłoszenia */
  module: RentFlatOriginalDetailDtoModuleEnum;
}

export interface RentPlotDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @example 1000000
   */
  pricePerSquareMeter: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  /**
   * Wymiary działki
   * Użytkownik podje je samodzielnie
   * @example "10x20"
   */
  dimensions?: string;
  buildingType?: PlotBuildingType;
  /**
   * Media
   * @example ["Prąd"]
   */
  media?: PlotMedia[];
  /**
   * Dojazd
   * @example ["Nieutwardzony"]
   */
  access?: Access[];
  /**
   * Okolica
   * @example ["Jezioro"]
   */
  neighborhood?: PlotNeighborhood[];
  /** @example ["Ogrodzenie"] */
  additionalInformation?: RentPlotDetailDtoAdditionalInformationEnum;
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @example 200
   */
  depositPrice?: number;
  /** Moduł ogłoszenia */
  module: RentPlotDetailDtoModuleEnum;
  profileDto: ProfileDto;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
}

export interface RentOtherDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @example 1000000
   */
  pricePerSquareMeter: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  floor?: FloorLevel;
  propertyForm: PropertyForm;
  buildingType?: OtherBuildingType;
  construction?: OtherConstruction;
  location?: OtherLocation;
  /**
   * Wysokość nieruchomości
   * @example "30"
   */
  height?: string;
  parking?: OtherParking;
  flooring?: OtherFlooring;
  /**
   * Pomieszczenia użytkowe
   * @example ["Rampa"]
   */
  buildingRooms?: OtherBuildingRooms[];
  propertyCondition?: PropertyCondition;
  /**
   * Przeznaczenie budynku
   * @example ["Handlowe"]
   */
  buildingPurpose?: OtherBuildingPurpose[];
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Media
   * @example ["Woda"]
   */
  media?: OtherMedia[];
  /**
   * Dojazd
   * @example ["Asfaltowy"]
   */
  access?: Access[];
  /**
   * Dodatkowe informacje
   * @example ["Ogrzewanie"]
   */
  additionalInformation?: OtherAdditionalInformation[];
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @example 200
   */
  depositPrice?: number;
  /** Moduł ogłoszenia */
  module: RentOtherDetailDtoModuleEnum;
  profileDto: ProfileDto;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
}

export interface RentHouseDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @example 1000000
   */
  pricePerSquareMeter: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w domu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType?: HouseBuildingType;
  floors?: HouseFloorLevel;
  propertyForm: PropertyForm;
  /**
   * Dom dostępne od
   * Czas w formacie Timestamp
   * @format date-time
   */
  availableFrom?: string;
  attic?: HouseAttic;
  roof?: HouseRoof;
  roofMaterial?: HouseRoofMaterial;
  buildingMaterial?: HouseBuildingMaterial;
  window?: Window;
  propertyCondition?: PropertyCondition;
  location?: HouseLocation;
  /**
   * Dom rekreacyjny
   * @example false
   */
  recreationalHouse: boolean;
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Ogrodzenie budynku
   * @example ["Murowane"]
   */
  fence?: HouseFence[];
  /**
   * Ogrzewanie budynku
   * @example ["Węglowe"]
   */
  heating?: HouseHeating[];
  /**
   * Media
   * @example ["Woda"]
   */
  media?: HouseMedia[];
  /**
   * Dojazd do budynku
   * @example ["Utwardzony"]
   */
  access?: Access[];
  /**
   * Otoczenie budynku
   * @example ["Morze"]
   */
  neighborhood?: HouseNeighborhood[];
  /**
   * Dodatkowe informacje
   * @example ["Klimatyzacja"]
   */
  additionalInformation?: HouseAdditionalInformation[];
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @example 200
   */
  depositPrice?: number;
  /** Moduł ogłoszenia */
  module: RentHouseDetailDtoModuleEnum;
  profileDto: ProfileDto;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
}

export interface RentFlatDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @example 1000000
   */
  pricePerSquareMeter: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w mieszkaniu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType: FlatBuildingType;
  floor?: FloorLevel;
  /**
   * Liczba pięter w nieruchomości
   * @example "10"
   */
  numberOfFloors?: string;
  /**
   * Mieszkanie dostępne od
   * Czas w formacie Timestamp
   * @format date-time
   */
  availableFrom?: string;
  propertyForm: PropertyForm;
  buildingMaterial?: FlatBuildingMaterial;
  window?: Window;
  heating?: FlatHeating;
  propertyCondition?: FlatPropertyCondition;
  /**
   * Media
   * @example ["Telefon"]
   */
  media?: FlatMedia[];
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Wyposażenie mieszkania
   * @example ["Lodówka"]
   */
  equipment?: FlatEquipment[];
  /**
   * Dodatkowe informacje
   * @example ["Balkon"]
   */
  additionalInformation?: FlatAdditionalInformation[];
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @example 200
   */
  depositPrice?: number;
  /** Moduł ogłoszenia */
  module: RentFlatDetailDtoModuleEnum;
  profileDto: ProfileDto;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
}

export interface RentPlotEditDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  /**
   * Wymiary działki
   * Użytkownik podje je samodzielnie
   * @example "10x20"
   */
  dimensions?: string;
  buildingType?: PlotBuildingType;
  /**
   * Media
   * @example ["Prąd"]
   */
  media?: PlotMedia[];
  /**
   * Dojazd
   * @example ["Nieutwardzony"]
   */
  access?: Access[];
  /**
   * Okolica
   * @example ["Jezioro"]
   */
  neighborhood?: PlotNeighborhood[];
  /** @example ["Ogrodzenie"] */
  additionalInformation?: RentPlotEditDetailDtoAdditionalInformationEnum;
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @min 0
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @min 0
   * @example 200
   */
  depositPrice?: number;
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /** Moduł ogłoszenia */
  module: RentPlotEditDetailDtoModuleEnum;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
  /**
   * Encrypted id oryginalnego ogłoszenia
   * @example "asfafet32441sadd"
   */
  originalEncryptedId: string;
}

export interface RentOtherEditDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  floor?: FloorLevel;
  propertyForm: PropertyForm;
  buildingType?: OtherBuildingType;
  construction?: OtherConstruction;
  location?: OtherLocation;
  /**
   * Wysokość nieruchomości
   * @example "30"
   */
  height?: string;
  parking?: OtherParking;
  flooring?: OtherFlooring;
  /**
   * Pomieszczenia użytkowe
   * @example ["Rampa"]
   */
  buildingRooms?: OtherBuildingRooms[];
  propertyCondition?: PropertyCondition;
  /**
   * Przeznaczenie budynku
   * @example ["Handlowe"]
   */
  buildingPurpose?: OtherBuildingPurpose[];
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Media
   * @example ["Woda"]
   */
  media?: OtherMedia[];
  /**
   * Dojazd
   * @example ["Asfaltowy"]
   */
  access?: Access[];
  /**
   * Dodatkowe informacje
   * @example ["Ogrzewanie"]
   */
  additionalInformation?: OtherAdditionalInformation[];
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @min 0
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @min 0
   * @example 200
   */
  depositPrice?: number;
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /** Moduł ogłoszenia */
  module: RentOtherEditDetailDtoModuleEnum;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
  /**
   * Encrypted id oryginalnego ogłoszenia
   * @example "asfafet32441sadd"
   */
  originalEncryptedId: string;
}

export interface RentHouseEditDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w domu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType?: HouseBuildingType;
  floors?: HouseFloorLevel;
  propertyForm: PropertyForm;
  /**
   * Dom dostępne od
   * Czas w formacie Timestamp
   * @format date-time
   */
  availableFrom?: string;
  attic?: HouseAttic;
  roof?: HouseRoof;
  roofMaterial?: HouseRoofMaterial;
  buildingMaterial?: HouseBuildingMaterial;
  window?: Window;
  propertyCondition?: PropertyCondition;
  location?: HouseLocation;
  /**
   * Dom rekreacyjny
   * @example false
   */
  recreationalHouse: boolean;
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Ogrodzenie budynku
   * @example ["Murowane"]
   */
  fence?: HouseFence[];
  /**
   * Ogrzewanie budynku
   * @example ["Węglowe"]
   */
  heating?: HouseHeating[];
  /**
   * Media
   * @example ["Woda"]
   */
  media?: HouseMedia[];
  /**
   * Dojazd do budynku
   * @example ["Utwardzony"]
   */
  access?: Access[];
  /**
   * Otoczenie budynku
   * @example ["Morze"]
   */
  neighborhood?: HouseNeighborhood[];
  /**
   * Dodatkowe informacje
   * @example ["Klimatyzacja"]
   */
  additionalInformation?: HouseAdditionalInformation[];
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @min 0
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @min 0
   * @example 200
   */
  depositPrice?: number;
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /** Moduł ogłoszenia */
  module: RentHouseEditDetailDtoModuleEnum;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
  /**
   * Encrypted id oryginalnego ogłoszenia
   * @example "asfafet32441sadd"
   */
  originalEncryptedId: string;
}

export interface RentFlatEditDetailDto {
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId?: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Opis ogłoszenia
   * @example "Na sprzedaż nowe mieszkanie..."
   */
  description: string;
  /**
   * Zdjęcia ogłoszenia
   * @maxItems 16
   * @minItems 0
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w mieszkaniu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType: FlatBuildingType;
  floor?: FloorLevel;
  /**
   * Liczba pięter w nieruchomości
   * @example "10"
   */
  numberOfFloors?: string;
  /**
   * Mieszkanie dostępne od
   * Czas w formacie Timestamp
   * @format date-time
   */
  availableFrom?: string;
  propertyForm: PropertyForm;
  buildingMaterial?: FlatBuildingMaterial;
  window?: Window;
  heating?: FlatHeating;
  propertyCondition?: FlatPropertyCondition;
  /**
   * Media
   * @example ["Telefon"]
   */
  media?: FlatMedia[];
  /**
   * Zabezpieczenia budynku
   * @example ["Teren zamknięty"]
   */
  security?: Security[];
  /**
   * Wyposażenie mieszkania
   * @example ["Lodówka"]
   */
  equipment?: FlatEquipment[];
  /**
   * Dodatkowe informacje
   * @example ["Balkon"]
   */
  additionalInformation?: FlatAdditionalInformation[];
  /**
   * Cena wynajmu nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @min 0
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @min 0
   * @example 200
   */
  depositPrice?: number;
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /** Moduł ogłoszenia */
  module: RentFlatEditDetailDtoModuleEnum;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
  /**
   * Encrypted id oryginalnego ogłoszenia
   * @example "asfafet32441sadd"
   */
  originalEncryptedId: string;
}

export interface RentPlotPageDto {
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId: string;
  /**
   * EncryptedId oryginalnego ogłoszenia
   * @example "7HuTRhlV"
   */
  originalEncryptedId?: string;
  /**
   * Data dodania ogłoszenia
   * @format date-time
   */
  dateAdded: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Zdjęcia ogłoszenia
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  infoDto?: InfoDto;
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @min 0
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @min 0
   * @example 200
   */
  depositPrice?: number;
  buildingType: PlotBuildingType;
  /** Moduł ogłoszenia */
  module: RentPlotPageDtoModuleEnum;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
}

export interface RentPlotPageWrapper {
  content?: RentPlotPageDto[];
  pageable?: PageableObject;
  last?: boolean;
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  size?: number;
  /** @format int32 */
  number?: number;
  sort?: SortObject;
  first?: boolean;
  /** @format int32 */
  numberOfElements?: number;
  empty?: boolean;
}

export interface RentOtherPageDto {
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId: string;
  /**
   * EncryptedId oryginalnego ogłoszenia
   * @example "7HuTRhlV"
   */
  originalEncryptedId?: string;
  /**
   * Data dodania ogłoszenia
   * @format date-time
   */
  dateAdded: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Zdjęcia ogłoszenia
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  infoDto?: InfoDto;
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @min 0
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @min 0
   * @example 200
   */
  depositPrice?: number;
  typeOfMarket: TypeOfMarket;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType: OtherBuildingType;
  /** Moduł ogłoszenia */
  module: RentOtherPageDtoModuleEnum;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
}

export interface RentOtherPageWrapper {
  content?: RentOtherPageDto[];
  pageable?: PageableObject;
  last?: boolean;
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  size?: number;
  /** @format int32 */
  number?: number;
  sort?: SortObject;
  first?: boolean;
  /** @format int32 */
  numberOfElements?: number;
  empty?: boolean;
}

export interface RentHousePageDto {
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId: string;
  /**
   * EncryptedId oryginalnego ogłoszenia
   * @example "7HuTRhlV"
   */
  originalEncryptedId?: string;
  /**
   * Data dodania ogłoszenia
   * @format date-time
   */
  dateAdded: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Zdjęcia ogłoszenia
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  infoDto?: InfoDto;
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @min 0
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @min 0
   * @example 200
   */
  depositPrice?: number;
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w mieszkaniu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType: HouseBuildingType;
  /** Moduł ogłoszenia */
  module: RentHousePageDtoModuleEnum;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
}

export interface RentHousePageWrapper {
  content?: RentHousePageDto[];
  pageable?: PageableObject;
  last?: boolean;
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  size?: number;
  /** @format int32 */
  number?: number;
  sort?: SortObject;
  first?: boolean;
  /** @format int32 */
  numberOfElements?: number;
  empty?: boolean;
}

export interface RentFlatPageDto {
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId: string;
  /**
   * EncryptedId oryginalnego ogłoszenia
   * @example "7HuTRhlV"
   */
  originalEncryptedId?: string;
  /**
   * Data dodania ogłoszenia
   * @format date-time
   */
  dateAdded: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Zdjęcia ogłoszenia
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  infoDto?: InfoDto;
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @min 0
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @min 0
   * @example 200
   */
  depositPrice?: number;
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w mieszkaniu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  buildingType: FlatBuildingType;
  /** Moduł ogłoszenia */
  module: RentFlatPageDtoModuleEnum;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
}

export interface RentFlatPageWrapper {
  content?: RentFlatPageDto[];
  pageable?: PageableObject;
  last?: boolean;
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  size?: number;
  /** @format int32 */
  number?: number;
  sort?: SortObject;
  first?: boolean;
  /** @format int32 */
  numberOfElements?: number;
  empty?: boolean;
}

export interface RentPageDto {
  /**
   * Id ogłoszenia
   * @format int64
   * @example 1
   */
  advertisementId: number;
  /**
   * EncryptedId ogłoszenia
   * @example "7HuTRhlV"
   */
  encryptedId: string;
  /**
   * EncryptedId oryginalnego ogłoszenia
   * @example "7HuTRhlV"
   */
  originalEncryptedId?: string;
  /**
   * Data dodania ogłoszenia
   * @format date-time
   */
  dateAdded: string;
  /**
   * Powierzchnia nieruchomości
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Cena za metr
   * @format double
   * @example 100.5
   */
  pricePerSquareMeter?: number;
  city: LocationDto;
  /**
   * Tytuł
   * @example "Mieszkanie na sprzedaż"
   */
  title: string;
  /**
   * Zdjęcia ogłoszenia
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  infoDto?: InfoDto;
  /**
   * Cena sprzedaży nieruchomości
   * @format double
   * @min 0
   * @example 1000000
   */
  price: number;
  /**
   * Cena kaucji
   * @format double
   * @min 0
   * @example 200
   */
  rentPrice?: number;
  /**
   * Cena czynszu
   * @format double
   * @min 0
   * @example 200
   */
  depositPrice?: number;
  typeOfMarket: TypeOfMarket;
  /**
   * Liczba pokoi w mieszkaniu
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRooms?: number;
  /**
   * Rok budowy nieruchomości
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYear: number;
  /**
   * Typ budynku
   * @example "Blok"
   */
  buildingType: string;
  /** Moduł ogłoszenia */
  module: RentPageDtoModuleEnum;
  /**
   * Aktywacja ogłoszenia
   * @example true
   */
  activationStatus: boolean;
  /**
   * Numer oferty
   * @format int32
   */
  offerNumber: number;
}

export interface RentPageWrapper {
  content?: RentPageDto[];
  pageable?: PageableObject;
  last?: boolean;
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  size?: number;
  /** @format int32 */
  number?: number;
  sort?: SortObject;
  first?: boolean;
  /** @format int32 */
  numberOfElements?: number;
  empty?: boolean;
}

/** @example ["Ogrodzenie"] */
export enum SalePlotEditDtoAdditionalInformationEnum {
  Ogrodzenie = "Ogrodzenie",
}

/** @example ["Ogrodzenie"] */
export enum RentPlotEditDtoAdditionalInformationEnum {
  Ogrodzenie = "Ogrodzenie",
}

/** @example ["Ogrodzenie"] */
export enum SalePlotCreateDtoAdditionalInformationEnum {
  Ogrodzenie = "Ogrodzenie",
}

/** @example ["Ogrodzenie"] */
export enum RentPlotCreateDtoAdditionalInformationEnum {
  Ogrodzenie = "Ogrodzenie",
}

/** @example ["Ogrodzenie"] */
export enum SalePlotOriginalDetailDtoAdditionalInformationEnum {
  Ogrodzenie = "Ogrodzenie",
}

/** Moduł ogłoszenia */
export enum SalePlotOriginalDetailDtoModuleEnum {
  OFFER_SALE_FLAT = "OFFER_SALE_FLAT",
  OFFER_SALE_HOUSE = "OFFER_SALE_HOUSE",
  OFFER_SALE_PLOT = "OFFER_SALE_PLOT",
  OFFER_SALE_OTHER = "OFFER_SALE_OTHER",
}

/** Moduł ogłoszenia */
export enum SaleOtherOriginalDetailDtoModuleEnum {
  OFFER_SALE_FLAT = "OFFER_SALE_FLAT",
  OFFER_SALE_HOUSE = "OFFER_SALE_HOUSE",
  OFFER_SALE_PLOT = "OFFER_SALE_PLOT",
  OFFER_SALE_OTHER = "OFFER_SALE_OTHER",
}

/** Moduł ogłoszenia */
export enum SaleHouseOriginalDetailDtoModuleEnum {
  OFFER_SALE_FLAT = "OFFER_SALE_FLAT",
  OFFER_SALE_HOUSE = "OFFER_SALE_HOUSE",
  OFFER_SALE_PLOT = "OFFER_SALE_PLOT",
  OFFER_SALE_OTHER = "OFFER_SALE_OTHER",
}

/** Moduł ogłoszenia */
export enum SaleFlatOriginalDetailDtoModuleEnum {
  OFFER_SALE_FLAT = "OFFER_SALE_FLAT",
  OFFER_SALE_HOUSE = "OFFER_SALE_HOUSE",
  OFFER_SALE_PLOT = "OFFER_SALE_PLOT",
  OFFER_SALE_OTHER = "OFFER_SALE_OTHER",
}

/** @example ["Ogrodzenie"] */
export enum SalePlotDetailDtoAdditionalInformationEnum {
  Ogrodzenie = "Ogrodzenie",
}

/** Moduł ogłoszenia */
export enum SalePlotDetailDtoModuleEnum {
  OFFER_SALE_FLAT = "OFFER_SALE_FLAT",
  OFFER_SALE_HOUSE = "OFFER_SALE_HOUSE",
  OFFER_SALE_PLOT = "OFFER_SALE_PLOT",
  OFFER_SALE_OTHER = "OFFER_SALE_OTHER",
}

/** Moduł ogłoszenia */
export enum SaleOtherDetailDtoModuleEnum {
  OFFER_SALE_FLAT = "OFFER_SALE_FLAT",
  OFFER_SALE_HOUSE = "OFFER_SALE_HOUSE",
  OFFER_SALE_PLOT = "OFFER_SALE_PLOT",
  OFFER_SALE_OTHER = "OFFER_SALE_OTHER",
}

/** Moduł ogłoszenia */
export enum SaleHouseDetailDtoModuleEnum {
  OFFER_SALE_FLAT = "OFFER_SALE_FLAT",
  OFFER_SALE_HOUSE = "OFFER_SALE_HOUSE",
  OFFER_SALE_PLOT = "OFFER_SALE_PLOT",
  OFFER_SALE_OTHER = "OFFER_SALE_OTHER",
}

/** Moduł ogłoszenia */
export enum SaleFlatDetailDtoModuleEnum {
  OFFER_SALE_FLAT = "OFFER_SALE_FLAT",
  OFFER_SALE_HOUSE = "OFFER_SALE_HOUSE",
  OFFER_SALE_PLOT = "OFFER_SALE_PLOT",
  OFFER_SALE_OTHER = "OFFER_SALE_OTHER",
}

/** @example ["Ogrodzenie"] */
export enum SalePlotEditDetailDtoAdditionalInformationEnum {
  Ogrodzenie = "Ogrodzenie",
}

/** Moduł ogłoszenia */
export enum SalePlotEditDetailDtoModuleEnum {
  OFFER_SALE_FLAT = "OFFER_SALE_FLAT",
  OFFER_SALE_HOUSE = "OFFER_SALE_HOUSE",
  OFFER_SALE_PLOT = "OFFER_SALE_PLOT",
  OFFER_SALE_OTHER = "OFFER_SALE_OTHER",
}

/** Moduł ogłoszenia */
export enum SaleOtherEditDetailDtoModuleEnum {
  OFFER_SALE_FLAT = "OFFER_SALE_FLAT",
  OFFER_SALE_HOUSE = "OFFER_SALE_HOUSE",
  OFFER_SALE_PLOT = "OFFER_SALE_PLOT",
  OFFER_SALE_OTHER = "OFFER_SALE_OTHER",
}

/** Moduł ogłoszenia */
export enum SaleHouseEditDetailDtoModuleEnum {
  OFFER_SALE_FLAT = "OFFER_SALE_FLAT",
  OFFER_SALE_HOUSE = "OFFER_SALE_HOUSE",
  OFFER_SALE_PLOT = "OFFER_SALE_PLOT",
  OFFER_SALE_OTHER = "OFFER_SALE_OTHER",
}

/** Moduł ogłoszenia */
export enum SaleFlatEditDetailDtoModuleEnum {
  OFFER_SALE_FLAT = "OFFER_SALE_FLAT",
  OFFER_SALE_HOUSE = "OFFER_SALE_HOUSE",
  OFFER_SALE_PLOT = "OFFER_SALE_PLOT",
  OFFER_SALE_OTHER = "OFFER_SALE_OTHER",
}

/** Moduł ogłoszenia */
export enum SalePlotPageDtoModuleEnum {
  OFFER_SALE_FLAT = "OFFER_SALE_FLAT",
  OFFER_SALE_HOUSE = "OFFER_SALE_HOUSE",
  OFFER_SALE_PLOT = "OFFER_SALE_PLOT",
  OFFER_SALE_OTHER = "OFFER_SALE_OTHER",
}

/** Moduł ogłoszenia */
export enum SaleOtherPageDtoModuleEnum {
  OFFER_SALE_FLAT = "OFFER_SALE_FLAT",
  OFFER_SALE_HOUSE = "OFFER_SALE_HOUSE",
  OFFER_SALE_PLOT = "OFFER_SALE_PLOT",
  OFFER_SALE_OTHER = "OFFER_SALE_OTHER",
}

/** Moduł ogłoszenia */
export enum SaleHousePageDtoModuleEnum {
  OFFER_SALE_FLAT = "OFFER_SALE_FLAT",
  OFFER_SALE_HOUSE = "OFFER_SALE_HOUSE",
  OFFER_SALE_PLOT = "OFFER_SALE_PLOT",
  OFFER_SALE_OTHER = "OFFER_SALE_OTHER",
}

/** Moduł ogłoszenia */
export enum SaleFlatPageDtoModuleEnum {
  OFFER_SALE_FLAT = "OFFER_SALE_FLAT",
  OFFER_SALE_HOUSE = "OFFER_SALE_HOUSE",
  OFFER_SALE_PLOT = "OFFER_SALE_PLOT",
  OFFER_SALE_OTHER = "OFFER_SALE_OTHER",
}

/** Moduł ogłoszenia */
export enum SalePageDtoModuleEnum {
  OFFER_SALE_FLAT = "OFFER_SALE_FLAT",
  OFFER_SALE_HOUSE = "OFFER_SALE_HOUSE",
  OFFER_SALE_PLOT = "OFFER_SALE_PLOT",
  OFFER_SALE_OTHER = "OFFER_SALE_OTHER",
}

/** @example ["Ogrodzenie"] */
export enum RentPlotOriginalDetailDtoAdditionalInformationEnum {
  Ogrodzenie = "Ogrodzenie",
}

/** Moduł ogłoszenia */
export enum RentPlotOriginalDetailDtoModuleEnum {
  OFFER_RENT_FLAT = "OFFER_RENT_FLAT",
  OFFER_RENT_HOUSE = "OFFER_RENT_HOUSE",
  OFFER_RENT_PLOT = "OFFER_RENT_PLOT",
  OFFER_RENT_OTHER = "OFFER_RENT_OTHER",
}

/** Moduł ogłoszenia */
export enum RentOtherOriginalDetailDtoModuleEnum {
  OFFER_RENT_FLAT = "OFFER_RENT_FLAT",
  OFFER_RENT_HOUSE = "OFFER_RENT_HOUSE",
  OFFER_RENT_PLOT = "OFFER_RENT_PLOT",
  OFFER_RENT_OTHER = "OFFER_RENT_OTHER",
}

/** Moduł ogłoszenia */
export enum RentHouseOriginalDetailDtoModuleEnum {
  OFFER_RENT_FLAT = "OFFER_RENT_FLAT",
  OFFER_RENT_HOUSE = "OFFER_RENT_HOUSE",
  OFFER_RENT_PLOT = "OFFER_RENT_PLOT",
  OFFER_RENT_OTHER = "OFFER_RENT_OTHER",
}

/** Moduł ogłoszenia */
export enum RentFlatOriginalDetailDtoModuleEnum {
  OFFER_RENT_FLAT = "OFFER_RENT_FLAT",
  OFFER_RENT_HOUSE = "OFFER_RENT_HOUSE",
  OFFER_RENT_PLOT = "OFFER_RENT_PLOT",
  OFFER_RENT_OTHER = "OFFER_RENT_OTHER",
}

/** @example ["Ogrodzenie"] */
export enum RentPlotDetailDtoAdditionalInformationEnum {
  Ogrodzenie = "Ogrodzenie",
}

/** Moduł ogłoszenia */
export enum RentPlotDetailDtoModuleEnum {
  OFFER_RENT_FLAT = "OFFER_RENT_FLAT",
  OFFER_RENT_HOUSE = "OFFER_RENT_HOUSE",
  OFFER_RENT_PLOT = "OFFER_RENT_PLOT",
  OFFER_RENT_OTHER = "OFFER_RENT_OTHER",
}

/** Moduł ogłoszenia */
export enum RentOtherDetailDtoModuleEnum {
  OFFER_RENT_FLAT = "OFFER_RENT_FLAT",
  OFFER_RENT_HOUSE = "OFFER_RENT_HOUSE",
  OFFER_RENT_PLOT = "OFFER_RENT_PLOT",
  OFFER_RENT_OTHER = "OFFER_RENT_OTHER",
}

/** Moduł ogłoszenia */
export enum RentHouseDetailDtoModuleEnum {
  OFFER_RENT_FLAT = "OFFER_RENT_FLAT",
  OFFER_RENT_HOUSE = "OFFER_RENT_HOUSE",
  OFFER_RENT_PLOT = "OFFER_RENT_PLOT",
  OFFER_RENT_OTHER = "OFFER_RENT_OTHER",
}

/** Moduł ogłoszenia */
export enum RentFlatDetailDtoModuleEnum {
  OFFER_RENT_FLAT = "OFFER_RENT_FLAT",
  OFFER_RENT_HOUSE = "OFFER_RENT_HOUSE",
  OFFER_RENT_PLOT = "OFFER_RENT_PLOT",
  OFFER_RENT_OTHER = "OFFER_RENT_OTHER",
}

/** @example ["Ogrodzenie"] */
export enum RentPlotEditDetailDtoAdditionalInformationEnum {
  Ogrodzenie = "Ogrodzenie",
}

/** Moduł ogłoszenia */
export enum RentPlotEditDetailDtoModuleEnum {
  OFFER_RENT_FLAT = "OFFER_RENT_FLAT",
  OFFER_RENT_HOUSE = "OFFER_RENT_HOUSE",
  OFFER_RENT_PLOT = "OFFER_RENT_PLOT",
  OFFER_RENT_OTHER = "OFFER_RENT_OTHER",
}

/** Moduł ogłoszenia */
export enum RentOtherEditDetailDtoModuleEnum {
  OFFER_RENT_FLAT = "OFFER_RENT_FLAT",
  OFFER_RENT_HOUSE = "OFFER_RENT_HOUSE",
  OFFER_RENT_PLOT = "OFFER_RENT_PLOT",
  OFFER_RENT_OTHER = "OFFER_RENT_OTHER",
}

/** Moduł ogłoszenia */
export enum RentHouseEditDetailDtoModuleEnum {
  OFFER_RENT_FLAT = "OFFER_RENT_FLAT",
  OFFER_RENT_HOUSE = "OFFER_RENT_HOUSE",
  OFFER_RENT_PLOT = "OFFER_RENT_PLOT",
  OFFER_RENT_OTHER = "OFFER_RENT_OTHER",
}

/** Moduł ogłoszenia */
export enum RentFlatEditDetailDtoModuleEnum {
  OFFER_RENT_FLAT = "OFFER_RENT_FLAT",
  OFFER_RENT_HOUSE = "OFFER_RENT_HOUSE",
  OFFER_RENT_PLOT = "OFFER_RENT_PLOT",
  OFFER_RENT_OTHER = "OFFER_RENT_OTHER",
}

/** Moduł ogłoszenia */
export enum RentPlotPageDtoModuleEnum {
  OFFER_RENT_FLAT = "OFFER_RENT_FLAT",
  OFFER_RENT_HOUSE = "OFFER_RENT_HOUSE",
  OFFER_RENT_PLOT = "OFFER_RENT_PLOT",
  OFFER_RENT_OTHER = "OFFER_RENT_OTHER",
}

/** Moduł ogłoszenia */
export enum RentOtherPageDtoModuleEnum {
  OFFER_RENT_FLAT = "OFFER_RENT_FLAT",
  OFFER_RENT_HOUSE = "OFFER_RENT_HOUSE",
  OFFER_RENT_PLOT = "OFFER_RENT_PLOT",
  OFFER_RENT_OTHER = "OFFER_RENT_OTHER",
}

/** Moduł ogłoszenia */
export enum RentHousePageDtoModuleEnum {
  OFFER_RENT_FLAT = "OFFER_RENT_FLAT",
  OFFER_RENT_HOUSE = "OFFER_RENT_HOUSE",
  OFFER_RENT_PLOT = "OFFER_RENT_PLOT",
  OFFER_RENT_OTHER = "OFFER_RENT_OTHER",
}

/** Moduł ogłoszenia */
export enum RentFlatPageDtoModuleEnum {
  OFFER_RENT_FLAT = "OFFER_RENT_FLAT",
  OFFER_RENT_HOUSE = "OFFER_RENT_HOUSE",
  OFFER_RENT_PLOT = "OFFER_RENT_PLOT",
  OFFER_RENT_OTHER = "OFFER_RENT_OTHER",
}

/** Moduł ogłoszenia */
export enum RentPageDtoModuleEnum {
  OFFER_RENT_FLAT = "OFFER_RENT_FLAT",
  OFFER_RENT_HOUSE = "OFFER_RENT_HOUSE",
  OFFER_RENT_PLOT = "OFFER_RENT_PLOT",
  OFFER_RENT_OTHER = "OFFER_RENT_OTHER",
}

export enum EditAdvertisementStatusParamsModuleEnum {
  OFFER_SALE_FLAT = "OFFER_SALE_FLAT",
  OFFER_SALE_HOUSE = "OFFER_SALE_HOUSE",
  OFFER_SALE_PLOT = "OFFER_SALE_PLOT",
  OFFER_SALE_OTHER = "OFFER_SALE_OTHER",
}

export enum EditAdvertisementStatus1ParamsModuleEnum {
  OFFER_RENT_FLAT = "OFFER_RENT_FLAT",
  OFFER_RENT_HOUSE = "OFFER_RENT_HOUSE",
  OFFER_RENT_PLOT = "OFFER_RENT_PLOT",
  OFFER_RENT_OTHER = "OFFER_RENT_OTHER",
}

export enum CreateSaleAdvertisementPhotoParamsModuleEnum {
  OFFER_SALE_FLAT = "OFFER_SALE_FLAT",
  OFFER_SALE_HOUSE = "OFFER_SALE_HOUSE",
  OFFER_SALE_PLOT = "OFFER_SALE_PLOT",
  OFFER_SALE_OTHER = "OFFER_SALE_OTHER",
}

export enum CreateRentAdvertisementPhotoParamsModuleEnum {
  OFFER_RENT_FLAT = "OFFER_RENT_FLAT",
  OFFER_RENT_HOUSE = "OFFER_RENT_HOUSE",
  OFFER_RENT_PLOT = "OFFER_RENT_PLOT",
  OFFER_RENT_OTHER = "OFFER_RENT_OTHER",
}

export enum AdvertisementPhotoParamsPhotoSizeEnum {
  CHAT_PHOTO = "CHAT_PHOTO",
  MINI_FULL_SCREEN_PHOTO = "MINI_FULL_SCREEN_PHOTO",
  FULL_SCREEN_PHOTO = "FULL_SCREEN_PHOTO",
  MINI_PHOTO = "MINI_PHOTO",
  NORMAL_PHOTO = "NORMAL_PHOTO",
  PROFILE_SIZE = "PROFILE_SIZE",
}

export enum AdvertisementPhotoParamsModuleEnum {
  OFFER_RENT_FLAT = "OFFER_RENT_FLAT",
  OFFER_RENT_HOUSE = "OFFER_RENT_HOUSE",
  OFFER_RENT_PLOT = "OFFER_RENT_PLOT",
  OFFER_RENT_OTHER = "OFFER_RENT_OTHER",
  OFFER_SALE_FLAT = "OFFER_SALE_FLAT",
  OFFER_SALE_HOUSE = "OFFER_SALE_HOUSE",
  OFFER_SALE_PLOT = "OFFER_SALE_PLOT",
  OFFER_SALE_OTHER = "OFFER_SALE_OTHER",
}

export enum GenerateImageParamsModuleEnum {
  RENT_FLAT = "RENT_FLAT",
  RENT_HOUSE = "RENT_HOUSE",
  RENT_PLOT = "RENT_PLOT",
  RENT_OTHER = "RENT_OTHER",
  SALE_FLAT = "SALE_FLAT",
  SALE_HOUSE = "SALE_HOUSE",
  SALE_PLOT = "SALE_PLOT",
  SALE_OTHER = "SALE_OTHER",
}

export enum AdvertisementPhotoForUserParamsPhotoSizeEnum {
  CHAT_PHOTO = "CHAT_PHOTO",
  MINI_FULL_SCREEN_PHOTO = "MINI_FULL_SCREEN_PHOTO",
  FULL_SCREEN_PHOTO = "FULL_SCREEN_PHOTO",
  MINI_PHOTO = "MINI_PHOTO",
  NORMAL_PHOTO = "NORMAL_PHOTO",
  PROFILE_SIZE = "PROFILE_SIZE",
}

export enum AdvertisementPhotoForUserParamsModuleEnum {
  OFFER_RENT_FLAT = "OFFER_RENT_FLAT",
  OFFER_RENT_HOUSE = "OFFER_RENT_HOUSE",
  OFFER_RENT_PLOT = "OFFER_RENT_PLOT",
  OFFER_RENT_OTHER = "OFFER_RENT_OTHER",
  OFFER_SALE_FLAT = "OFFER_SALE_FLAT",
  OFFER_SALE_HOUSE = "OFFER_SALE_HOUSE",
  OFFER_SALE_PLOT = "OFFER_SALE_PLOT",
  OFFER_SALE_OTHER = "OFFER_SALE_OTHER",
}

export enum DeleteAdvertisementStatusParamsModuleEnum {
  OFFER_SALE_FLAT = "OFFER_SALE_FLAT",
  OFFER_SALE_HOUSE = "OFFER_SALE_HOUSE",
  OFFER_SALE_PLOT = "OFFER_SALE_PLOT",
  OFFER_SALE_OTHER = "OFFER_SALE_OTHER",
}

export enum DeleteAdvertisementStatus1ParamsModuleEnum {
  OFFER_RENT_FLAT = "OFFER_RENT_FLAT",
  OFFER_RENT_HOUSE = "OFFER_RENT_HOUSE",
  OFFER_RENT_PLOT = "OFFER_RENT_PLOT",
  OFFER_RENT_OTHER = "OFFER_RENT_OTHER",
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "http://192.168.31.12:8080";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title OpenAPI definition
 * @version v0
 * @baseUrl http://192.168.31.12:8080
 */
export class Api<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  brokerOffer = {
    /**
     * No description
     *
     * @tags Operacje związane z aktualizacją statystyk dla sprzedaży
     * @name UpdateAdvertisementViewedInfo
     * @summary Aktualizacja liczby odwiedzających ogłoszenie
     * @request PUT:/broker-offer/statistic/sale/update/viewed
     * @secure
     */
    updateAdvertisementViewedInfo: (
      query: {
        encryptedId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<object, object>({
        path: `/broker-offer/statistic/sale/update/viewed`,
        method: "PUT",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Operacje związane z aktualizacją statystyk dla sprzedaży
     * @name UpdateAdvertisementPhonesInfo
     * @summary Aktualizacja liczby kliknięć w numer telefonu
     * @request PUT:/broker-offer/statistic/sale/update/phone-click
     * @secure
     */
    updateAdvertisementPhonesInfo: (
      query: {
        encryptedId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<object, object>({
        path: `/broker-offer/statistic/sale/update/phone-click`,
        method: "PUT",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Operacje związane z aktualizacją statystyk dla wynajmu
     * @name UpdateAdvertisementViewedInfo1
     * @summary Aktualizacja liczby odwiedzających ogłoszenie
     * @request PUT:/broker-offer/statistic/rent/update/viewed
     * @secure
     */
    updateAdvertisementViewedInfo1: (
      query: {
        encryptedId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<object, object>({
        path: `/broker-offer/statistic/rent/update/viewed`,
        method: "PUT",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Operacje związane z aktualizacją statystyk dla wynajmu
     * @name UpdateAdvertisementPhonesInfo1
     * @summary Aktualizacja liczby kliknięć w numer telefonu
     * @request PUT:/broker-offer/statistic/rent/update/phone-click
     * @secure
     */
    updateAdvertisementPhonesInfo1: (
      query: {
        encryptedId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<object, object>({
        path: `/broker-offer/statistic/rent/update/phone-click`,
        method: "PUT",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami sprzedaży na liście ogłoszeń
     * @name EditAdvertisementStatus
     * @summary Edycja statusu widoczności ogłoszenia
     * @request PUT:/broker-offer/sale/update/status
     * @secure
     */
    editAdvertisementStatus: (
      query: {
        module: EditAdvertisementStatusParamsModuleEnum;
        /** @format int64 */
        advertisementId: number;
        status: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<object, object>({
        path: `/broker-offer/sale/update/status`,
        method: "PUT",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Edycja ogłoszeń sprzedaży
     * @name AdvertisementEditPlot
     * @summary Edycja ogłoszenia sprzedaży działek dla użytkownika
     * @request PUT:/broker-offer/sale/edit/plot
     * @secure
     */
    advertisementEditPlot: (data: SalePlotEditDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/broker-offer/sale/edit/plot`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Edycja ogłoszeń sprzedaży
     * @name AdvertisementEditOther
     * @summary Edycja ogłoszenia sprzedaży pozostałych dla użytkownika
     * @request PUT:/broker-offer/sale/edit/other
     * @secure
     */
    advertisementEditOther: (data: SaleOtherEditDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/broker-offer/sale/edit/other`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Edycja ogłoszeń sprzedaży
     * @name AdvertisementEditHouse
     * @summary Edycja ogłoszenia sprzedaży domów dla użytkownika
     * @request PUT:/broker-offer/sale/edit/house
     * @secure
     */
    advertisementEditHouse: (data: SaleHouseEditDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/broker-offer/sale/edit/house`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Edycja ogłoszeń sprzedaży
     * @name AdvertisementEditFlat
     * @summary Edycja ogłoszenia sprzedaży mieszkań dla użytkownika
     * @request PUT:/broker-offer/sale/edit/flat
     * @secure
     */
    advertisementEditFlat: (data: SaleFlatEditDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/broker-offer/sale/edit/flat`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami wynajmu na liście ogłoszeń
     * @name EditAdvertisementStatus1
     * @summary Edycja statusu widoczności ogłoszenia
     * @request PUT:/broker-offer/rent/update/status
     * @secure
     */
    editAdvertisementStatus1: (
      query: {
        module: EditAdvertisementStatus1ParamsModuleEnum;
        /** @format int64 */
        advertisementId: number;
        status: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<object, object>({
        path: `/broker-offer/rent/update/status`,
        method: "PUT",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Edycja ogłoszeń wynajmu
     * @name AdvertisementEditPlot1
     * @summary Edycja ogłoszenia wynajmu działek dla użytkownika
     * @request PUT:/broker-offer/rent/edit/plot
     * @secure
     */
    advertisementEditPlot1: (data: RentPlotEditDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/broker-offer/rent/edit/plot`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Edycja ogłoszeń wynajmu
     * @name AdvertisementEditOther1
     * @summary Edycja ogłoszenia wynajmu pozostałych dla użytkownika
     * @request PUT:/broker-offer/rent/edit/other
     * @secure
     */
    advertisementEditOther1: (data: RentOtherEditDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/broker-offer/rent/edit/other`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Edycja ogłoszeń wynajmu
     * @name AdvertisementEditHouse1
     * @summary Edycja ogłoszenia wynajmu domów dla użytkownika
     * @request PUT:/broker-offer/rent/edit/house
     * @secure
     */
    advertisementEditHouse1: (data: RentHouseEditDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/broker-offer/rent/edit/house`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Edycja ogłoszeń wynajmu
     * @name AdvertisementEditFlat1
     * @summary Edycja ogłoszenia wynajmu mieszkań dla użytkownika
     * @request PUT:/broker-offer/rent/edit/flat
     * @secure
     */
    advertisementEditFlat1: (data: RentFlatEditDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/broker-offer/rent/edit/flat`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Szczegóły pojedynczego ogłoszenia sprzedaży dla użytkownika
     * @name SendMessage
     * @summary Wysyłanie wiadomości do ogłoszenia przez klienta
     * @request POST:/broker-offer/sale/detail/send-message
     * @secure
     */
    sendMessage: (data: MessageCreateDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/broker-offer/sale/detail/send-message`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tworzenie ogłoszeń sprzedaży
     * @name AdvertisementCreatePlot
     * @summary Dodawanie ogłoszenia sprzedaży działki
     * @request POST:/broker-offer/sale/create/plot
     * @secure
     */
    advertisementCreatePlot: (data: SalePlotCreateDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/broker-offer/sale/create/plot`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tworzenie ogłoszeń sprzedaży
     * @name AdvertisementCreateOther
     * @summary Dodawanie ogłoszenia sprzedaży inne
     * @request POST:/broker-offer/sale/create/other
     * @secure
     */
    advertisementCreateOther: (data: SaleOtherCreateDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/broker-offer/sale/create/other`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tworzenie ogłoszeń sprzedaży
     * @name AdvertisementCreateHouse
     * @summary Dodawanie ogłoszenia sprzedaży domu
     * @request POST:/broker-offer/sale/create/house
     * @secure
     */
    advertisementCreateHouse: (data: SaleHouseCreateDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/broker-offer/sale/create/house`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tworzenie ogłoszeń sprzedaży
     * @name AdvertisementCreateFlat
     * @summary Dodawanie ogłoszenia sprzedaży mieszkania
     * @request POST:/broker-offer/sale/create/flat
     * @secure
     */
    advertisementCreateFlat: (data: SaleFlatCreateDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/broker-offer/sale/create/flat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Szczegóły pojedynczego ogłoszenia wynajmu dla użytkownika
     * @name SendMessage1
     * @summary Wysyłanie wiadomości do ogłoszenia przez klienta
     * @request POST:/broker-offer/rent/detail/send-message
     * @secure
     */
    sendMessage1: (data: MessageCreateDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/broker-offer/rent/detail/send-message`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tworzenie ogłoszeń wynajmu
     * @name AdvertisementCreatePlot1
     * @summary Dodawanie ogłoszenia wynajmu działki
     * @request POST:/broker-offer/rent/create/plot
     * @secure
     */
    advertisementCreatePlot1: (data: RentPlotCreateDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/broker-offer/rent/create/plot`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tworzenie ogłoszeń wynajmu
     * @name AdvertisementCreateOther1
     * @summary Dodawanie ogłoszenia wynajmu inne
     * @request POST:/broker-offer/rent/create/other
     * @secure
     */
    advertisementCreateOther1: (data: RentOtherCreateDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/broker-offer/rent/create/other`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tworzenie ogłoszeń wynajmu
     * @name AdvertisementCreateHouse1
     * @summary Dodawanie ogłoszenia wynajmu domu
     * @request POST:/broker-offer/rent/create/house
     * @secure
     */
    advertisementCreateHouse1: (data: RentHouseCreateDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/broker-offer/rent/create/house`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tworzenie ogłoszeń wynajmu
     * @name AdvertisementCreateFlat1
     * @summary Dodawanie ogłoszenia wynajmu mieszkania
     * @request POST:/broker-offer/rent/create/flat
     * @secure
     */
    advertisementCreateFlat1: (data: RentFlatCreateDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/broker-offer/rent/create/flat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Dostępne formaty: JPG, JPEG, PNG, WEBP, TIFF, BMP, GIF
     *
     * @tags Operacje związane ze zdjęciami
     * @name CreateSaleAdvertisementPhoto
     * @summary Tworzenie zdjęć podczas dodawania ogłoszenia dla sprzedaży
     * @request POST:/broker-offer/photo/create/sale
     * @secure
     */
    createSaleAdvertisementPhoto: (
      query: {
        module: CreateSaleAdvertisementPhotoParamsModuleEnum;
      },
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<object, object>({
        path: `/broker-offer/photo/create/sale`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description Dostępne formaty: JPG, JPEG, PNG, WEBP, TIFF, BMP, GIF
     *
     * @tags Operacje związane ze zdjęciami
     * @name CreateRentAdvertisementPhoto
     * @summary Tworzenie zdjęć podczas dodawania ogłoszenia dla wynajmu
     * @request POST:/broker-offer/photo/create/rent
     * @secure
     */
    createRentAdvertisementPhoto: (
      query: {
        module: CreateRentAdvertisementPhotoParamsModuleEnum;
      },
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<object, object>({
        path: `/broker-offer/photo/create/rent`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Operacje wykorzystujące AI
     * @name GenerateTitle
     * @summary Generowanie tytułu do ogłoszenia
     * @request POST:/broker-offer/ai/generate/title
     * @secure
     */
    generateTitle: (data: AiGenerateDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/broker-offer/ai/generate/title`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Operacje wykorzystujące AI
     * @name GenerateDescription
     * @summary Generowanie opisu do ogłoszenia
     * @request POST:/broker-offer/ai/generate/description
     * @secure
     */
    generateDescription: (data: AiGenerateDto, params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/broker-offer/ai/generate/description`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pobieranie danych z oryginalnego ogłoszenia dla sprzedaży
     * @name AdvertisementDetailPlot
     * @summary Pobieranie szczegółów ogłoszenia sprzedaży działek do tworzenia ogłoszenia dla pośrednika
     * @request GET:/broker-offer/sale/original-detail/plot
     * @secure
     */
    advertisementDetailPlot: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SalePlotOriginalDetailDto, object>({
        path: `/broker-offer/sale/original-detail/plot`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pobieranie danych z oryginalnego ogłoszenia dla sprzedaży
     * @name AdvertisementDetailOther
     * @summary Pobieranie szczegółów ogłoszenia sprzedaży innych do tworzenia ogłoszenia dla pośrednika
     * @request GET:/broker-offer/sale/original-detail/other
     * @secure
     */
    advertisementDetailOther: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SaleOtherOriginalDetailDto, object>({
        path: `/broker-offer/sale/original-detail/other`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pobieranie danych z oryginalnego ogłoszenia dla sprzedaży
     * @name AdvertisementDetailHouse
     * @summary Pobieranie szczegółów ogłoszenia sprzedaży domów do tworzenia ogłoszenia dla pośrednika
     * @request GET:/broker-offer/sale/original-detail/house
     * @secure
     */
    advertisementDetailHouse: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SaleHouseOriginalDetailDto, object>({
        path: `/broker-offer/sale/original-detail/house`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pobieranie danych z oryginalnego ogłoszenia dla sprzedaży
     * @name AdvertisementDetailFlat
     * @summary Pobieranie szczegółów ogłoszenia sprzedaży mieszkań do tworzenia ogłoszenia dla pośrednika
     * @request GET:/broker-offer/sale/original-detail/flat
     * @secure
     */
    advertisementDetailFlat: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SaleFlatOriginalDetailDto, object>({
        path: `/broker-offer/sale/original-detail/flat`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Szczegóły pojedynczego ogłoszenia sprzedaży dla użytkownika
     * @name AdvertisementDetailPlot1
     * @summary Pobieranie szczegółów ogłoszenia sprzedaży działek dla użytkownika
     * @request GET:/broker-offer/sale/detail/plot
     * @secure
     */
    advertisementDetailPlot1: (
      query: {
        encryptedId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SalePlotDetailDto, object>({
        path: `/broker-offer/sale/detail/plot`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Szczegóły pojedynczego ogłoszenia sprzedaży dla użytkownika
     * @name AdvertisementDetailOther1
     * @summary Pobieranie szczegółów ogłoszenia sprzedaży pozostałych dla użytkownika
     * @request GET:/broker-offer/sale/detail/other
     * @secure
     */
    advertisementDetailOther1: (
      query: {
        encryptedId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SaleOtherDetailDto, object>({
        path: `/broker-offer/sale/detail/other`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Szczegóły pojedynczego ogłoszenia sprzedaży dla użytkownika
     * @name AdvertisementDetailHouse1
     * @summary Pobieranie szczegółów ogłoszenia sprzedaży domów dla użytkownika
     * @request GET:/broker-offer/sale/detail/house
     * @secure
     */
    advertisementDetailHouse1: (
      query: {
        encryptedId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SaleHouseDetailDto, object>({
        path: `/broker-offer/sale/detail/house`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Szczegóły pojedynczego ogłoszenia sprzedaży dla użytkownika
     * @name AdvertisementDetailFlat1
     * @summary Pobieranie szczegółów ogłoszenia sprzedaży mieszkań dla użytkownika
     * @request GET:/broker-offer/sale/detail/flat
     * @secure
     */
    advertisementDetailFlat1: (
      query: {
        encryptedId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SaleFlatDetailDto, object>({
        path: `/broker-offer/sale/detail/flat`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Edycja ogłoszeń sprzedaży
     * @name AdvertisementDetailPlot2
     * @summary Pobieranie szczegółów ogłoszenia sprzedaży działek do edycji dla pośrednika
     * @request GET:/broker-offer/sale/detail-for-edit/plot
     * @secure
     */
    advertisementDetailPlot2: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SalePlotEditDetailDto, object>({
        path: `/broker-offer/sale/detail-for-edit/plot`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Edycja ogłoszeń sprzedaży
     * @name AdvertisementDetailOther2
     * @summary Pobieranie szczegółów ogłoszenia sprzedaży innych do edycji dla pośrednika
     * @request GET:/broker-offer/sale/detail-for-edit/other
     * @secure
     */
    advertisementDetailOther2: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SaleOtherEditDetailDto, object>({
        path: `/broker-offer/sale/detail-for-edit/other`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Edycja ogłoszeń sprzedaży
     * @name AdvertisementDetailHouse2
     * @summary Pobieranie szczegółów ogłoszenia sprzedaży domów do edycji dla pośrednika
     * @request GET:/broker-offer/sale/detail-for-edit/house
     * @secure
     */
    advertisementDetailHouse2: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SaleHouseEditDetailDto, object>({
        path: `/broker-offer/sale/detail-for-edit/house`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Edycja ogłoszeń sprzedaży
     * @name AdvertisementDetailFlat2
     * @summary Pobieranie szczegółów ogłoszenia sprzedaży mieszkań do edycji dla pośrednika
     * @request GET:/broker-offer/sale/detail-for-edit/flat
     * @secure
     */
    advertisementDetailFlat2: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SaleFlatEditDetailDto, object>({
        path: `/broker-offer/sale/detail-for-edit/flat`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami sprzedaży na liście ogłoszeń
     * @name PlotAdvertisementList
     * @summary Lista wszystkich ogłoszeń sprzedaży działek dla pośrednika
     * @request GET:/broker-offer/sale/advertisements-list/plot
     * @secure
     */
    plotAdvertisementList: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 25
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SalePlotPageWrapper, object>({
        path: `/broker-offer/sale/advertisements-list/plot`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami sprzedaży na liście ogłoszeń
     * @name OtherAdvertisementList
     * @summary Lista wszystkich ogłoszeń sprzedaży pozostałych dla pośrednika
     * @request GET:/broker-offer/sale/advertisements-list/other
     * @secure
     */
    otherAdvertisementList: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 25
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SaleOtherPageWrapper, object>({
        path: `/broker-offer/sale/advertisements-list/other`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami sprzedaży na liście ogłoszeń
     * @name HouseAdvertisementList
     * @summary Lista wszystkich ogłoszeń sprzedaży domów dla pośrednika
     * @request GET:/broker-offer/sale/advertisements-list/house
     * @secure
     */
    houseAdvertisementList: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 25
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SaleHousePageWrapper, object>({
        path: `/broker-offer/sale/advertisements-list/house`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami sprzedaży na liście ogłoszeń
     * @name FlatAdvertisementList
     * @summary Lista wszystkich ogłoszeń sprzedaży mieszkań dla pośrednika
     * @request GET:/broker-offer/sale/advertisements-list/flat
     * @secure
     */
    flatAdvertisementList: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 25
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SaleFlatPageWrapper, object>({
        path: `/broker-offer/sale/advertisements-list/flat`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami sprzedaży na liście ogłoszeń
     * @name AllAdvertisementList
     * @summary Lista wszystkich ogłoszeń sprzedaży dla pośrednika
     * @request GET:/broker-offer/sale/advertisements-list/all
     * @secure
     */
    allAdvertisementList: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 25
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SalePageWrapper, object>({
        path: `/broker-offer/sale/advertisements-list/all`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pobieranie danych z oryginalnego ogłoszenia dla wynajmu
     * @name AdvertisementDetailPlot3
     * @summary Pobieranie szczegółów ogłoszenia wynajmu działek do tworzenia ogłoszenia dla pośrednika
     * @request GET:/broker-offer/rent/original-detail/plot
     * @secure
     */
    advertisementDetailPlot3: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<RentPlotOriginalDetailDto, object>({
        path: `/broker-offer/rent/original-detail/plot`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pobieranie danych z oryginalnego ogłoszenia dla wynajmu
     * @name AdvertisementDetailOther3
     * @summary Pobieranie szczegółów ogłoszenia wynajmu innych do tworzenia ogłoszenia dla pośrednika
     * @request GET:/broker-offer/rent/original-detail/other
     * @secure
     */
    advertisementDetailOther3: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<RentOtherOriginalDetailDto, object>({
        path: `/broker-offer/rent/original-detail/other`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pobieranie danych z oryginalnego ogłoszenia dla wynajmu
     * @name AdvertisementDetailHouse3
     * @summary Pobieranie szczegółów ogłoszenia wynajmu domów do tworzenia ogłoszenia dla pośrednika
     * @request GET:/broker-offer/rent/original-detail/house
     * @secure
     */
    advertisementDetailHouse3: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<RentHouseOriginalDetailDto, object>({
        path: `/broker-offer/rent/original-detail/house`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pobieranie danych z oryginalnego ogłoszenia dla wynajmu
     * @name AdvertisementDetailFlat3
     * @summary Pobieranie szczegółów ogłoszenia wynajmu mieszkań do tworzenia ogłoszenia dla pośrednika
     * @request GET:/broker-offer/rent/original-detail/flat
     * @secure
     */
    advertisementDetailFlat3: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<RentFlatOriginalDetailDto, object>({
        path: `/broker-offer/rent/original-detail/flat`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Szczegóły pojedynczego ogłoszenia wynajmu dla użytkownika
     * @name AdvertisementDetailPlot4
     * @summary Pobieranie szczegółów ogłoszenia wynajem działek dla użytkownika
     * @request GET:/broker-offer/rent/detail/plot
     * @secure
     */
    advertisementDetailPlot4: (
      query: {
        encryptedId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<RentPlotDetailDto, object>({
        path: `/broker-offer/rent/detail/plot`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Szczegóły pojedynczego ogłoszenia wynajmu dla użytkownika
     * @name AdvertisementDetailOther4
     * @summary Pobieranie szczegółów ogłoszenia wynajem pozostałych dla użytkownika
     * @request GET:/broker-offer/rent/detail/other
     * @secure
     */
    advertisementDetailOther4: (
      query: {
        encryptedId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<RentOtherDetailDto, object>({
        path: `/broker-offer/rent/detail/other`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Szczegóły pojedynczego ogłoszenia wynajmu dla użytkownika
     * @name AdvertisementDetailHouse4
     * @summary Pobieranie szczegółów ogłoszenia wynajem domów dla użytkownika
     * @request GET:/broker-offer/rent/detail/house
     * @secure
     */
    advertisementDetailHouse4: (
      query: {
        encryptedId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<RentHouseDetailDto, object>({
        path: `/broker-offer/rent/detail/house`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Szczegóły pojedynczego ogłoszenia wynajmu dla użytkownika
     * @name AdvertisementDetailFlat4
     * @summary Pobieranie szczegółów ogłoszenia wynajem mieszkań dla użytkownika
     * @request GET:/broker-offer/rent/detail/flat
     * @secure
     */
    advertisementDetailFlat4: (
      query: {
        encryptedId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<RentFlatDetailDto, object>({
        path: `/broker-offer/rent/detail/flat`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Edycja ogłoszeń wynajmu
     * @name AdvertisementDetailPlot5
     * @summary Pobieranie szczegółów ogłoszenia wynajmu działek do edycji dla pośrednika
     * @request GET:/broker-offer/rent/detail-for-edit/plot
     * @secure
     */
    advertisementDetailPlot5: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<RentPlotEditDetailDto, object>({
        path: `/broker-offer/rent/detail-for-edit/plot`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Edycja ogłoszeń wynajmu
     * @name AdvertisementDetailOther5
     * @summary Pobieranie szczegółów ogłoszenia wynajmu innych do edycji dla pośrednika
     * @request GET:/broker-offer/rent/detail-for-edit/other
     * @secure
     */
    advertisementDetailOther5: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<RentOtherEditDetailDto, object>({
        path: `/broker-offer/rent/detail-for-edit/other`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Edycja ogłoszeń wynajmu
     * @name AdvertisementDetailHouse5
     * @summary Pobieranie szczegółów ogłoszenia wynajmu domów do edycji dla pośrednika
     * @request GET:/broker-offer/rent/detail-for-edit/house
     * @secure
     */
    advertisementDetailHouse5: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<RentHouseEditDetailDto, object>({
        path: `/broker-offer/rent/detail-for-edit/house`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Edycja ogłoszeń wynajmu
     * @name AdvertisementDetailFlat5
     * @summary Pobieranie szczegółów ogłoszenia wynajmu mieszkań do edycji dla pośrednika
     * @request GET:/broker-offer/rent/detail-for-edit/flat
     * @secure
     */
    advertisementDetailFlat5: (
      query: {
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<RentFlatEditDetailDto, object>({
        path: `/broker-offer/rent/detail-for-edit/flat`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami wynajmu na liście ogłoszeń
     * @name PlotAdvertisementList1
     * @summary Lista wszystkich ogłoszeń wynajmu działek dla pośrednika
     * @request GET:/broker-offer/rent/advertisements-list/plot
     * @secure
     */
    plotAdvertisementList1: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 25
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<RentPlotPageWrapper, object>({
        path: `/broker-offer/rent/advertisements-list/plot`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami wynajmu na liście ogłoszeń
     * @name OtherAdvertisementList1
     * @summary Lista wszystkich ogłoszeń wynajmu pozostałych dla pośrednika
     * @request GET:/broker-offer/rent/advertisements-list/other
     * @secure
     */
    otherAdvertisementList1: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 25
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<RentOtherPageWrapper, object>({
        path: `/broker-offer/rent/advertisements-list/other`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami wynajmu na liście ogłoszeń
     * @name HouseAdvertisementList1
     * @summary Lista wszystkich ogłoszeń wynajmu domów dla pośrednika
     * @request GET:/broker-offer/rent/advertisements-list/house
     * @secure
     */
    houseAdvertisementList1: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 25
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<RentHousePageWrapper, object>({
        path: `/broker-offer/rent/advertisements-list/house`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami wynajmu na liście ogłoszeń
     * @name FlatAdvertisementList1
     * @summary Lista wszystkich ogłoszeń wynajmu mieszkań dla pośrednika
     * @request GET:/broker-offer/rent/advertisements-list/flat
     * @secure
     */
    flatAdvertisementList1: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 25
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<RentFlatPageWrapper, object>({
        path: `/broker-offer/rent/advertisements-list/flat`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami wynajmu na liście ogłoszeń
     * @name AllAdvertisementList1
     * @summary Lista wszystkich ogłoszeń wynajmu dla pośrednika
     * @request GET:/broker-offer/rent/advertisements-list/all
     * @secure
     */
    allAdvertisementList1: (
      query?: {
        /**
         * @format int32
         * @default 1
         */
        page?: number;
        /**
         * @format int32
         * @default 25
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<RentPageWrapper, object>({
        path: `/broker-offer/rent/advertisements-list/all`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Przykładowo zdjęcia z oryginalnego ogłoszenia bez watermarków
     *
     * @tags Operacje związane ze zdjęciami
     * @name AdvertisementPhoto
     * @summary Pobieranie zdjęć podczas tworzenia ogłoszenia
     * @request GET:/broker-offer/photo/offer-create/find
     * @secure
     */
    advertisementPhoto: (
      query: {
        photoName: string;
        photoSize: AdvertisementPhotoParamsPhotoSizeEnum;
        module: AdvertisementPhotoParamsModuleEnum;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, any>({
        path: `/broker-offer/photo/offer-create/find`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Należy podać pojedyncze zdjęcie po chwili zostanie zwrócona jego nazwa, Do odczytania obrobionego zdjęcia należy użyć endpointu /broker-offer/photo/find, pod którym są zdjęcia które są używane podczas tworzenia ogłoszenia
     *
     * @tags Operacje związane ze zdjęciami
     * @name GenerateImage
     * @summary Kopiowanie zdjęć do ogłoszenia z oryginalnego ogłoszenia
     * @request GET:/broker-offer/photo/generate-image
     * @secure
     */
    generateImage: (
      query: {
        photoName: string;
        module: GenerateImageParamsModuleEnum;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<object, object>({
        path: `/broker-offer/photo/generate-image`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Endpoint dostępny dla zalogowanych i niezalogowanych użytkowników. Pobierane są z niego tylko zdjęcia zapisane do jakiegoś ogłoszenia
     *
     * @tags Operacje związane ze zdjęciami
     * @name AdvertisementPhotoForUser
     * @summary Pobieranie zdjęć dla wszystkich
     * @request GET:/broker-offer/photo/find
     * @secure
     */
    advertisementPhotoForUser: (
      query: {
        fileName: string;
        photoSize: AdvertisementPhotoForUserParamsPhotoSizeEnum;
        module: AdvertisementPhotoForUserParamsModuleEnum;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, any>({
        path: `/broker-offer/photo/find`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami sprzedaży na liście ogłoszeń
     * @name DeleteAdvertisementStatus
     * @summary Usuwanie istniejącego ogłoszenia
     * @request DELETE:/broker-offer/sale/delete
     * @secure
     */
    deleteAdvertisementStatus: (
      query: {
        module: DeleteAdvertisementStatusParamsModuleEnum;
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<object, object>({
        path: `/broker-offer/sale/delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami wynajmu na liście ogłoszeń
     * @name DeleteAdvertisementStatus1
     * @summary Usuwanie istniejącego ogłoszenia
     * @request DELETE:/broker-offer/rent/delete
     * @secure
     */
    deleteAdvertisementStatus1: (
      query: {
        module: DeleteAdvertisementStatus1ParamsModuleEnum;
        /** @format int64 */
        advertisementId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<object, object>({
        path: `/broker-offer/rent/delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),
  };
}
