export const floorsOptions = [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' }
];

export const floorOptions = [
    { label: 'Sutenera', value: 'Sutenera' },
    { label: 'Parter', value: 'Parter' },
    { label: '1 piętro', value: '1' },
    { label: '2 piętro', value: '2' },
    { label: '3 piętro', value: '3' },
    { label: '4 piętro', value: '4' },
    { label: '5 piętro', value: '5' },
    { label: '6 piętro', value: '6' },
    { label: '7 piętro', value: '7' },
    { label: '8 piętro', value: '8' },
    { label: '9 piętro', value: '9' },
    { label: '10 piętro', value: '10' },
    { label: 'Więcej niż 10', value: '>10' },
    { label: 'Poddasze', value: 'Poddasze' }
];
