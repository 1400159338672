import React from 'react';
import { useUser } from 'store/User/Context';

import { HeaderStyle, LegendList, SectionBox } from 'components/analysis/AnalysisSaleList';
import BarWrapper from 'components/common/BarWrapper';

interface TimeRangeSquareMeterBarsProps {
    averagePriceByTime: { name: string; bar1: number; bar2: number; }[];
}

export const TimeRangeSquareMeterBars = ({ averagePriceByTime }: TimeRangeSquareMeterBarsProps) => {
    const { user } = useUser();

    return (
        <SectionBox data-pdf-section>
            <HeaderStyle>
                <h2>Cena m² według przedziałów czasu</h2>
                <LegendList darkMode={user.isDarkMode}>
                    <li>Średnia cena za m² brutto [PLN]</li>
                    <li>Mediana ceny za m² brutto [PLN]</li>
                </LegendList>
                <BarWrapper className="avg-price-time" barData={averagePriceByTime}/>
            </HeaderStyle>
        </SectionBox>
    );
};
