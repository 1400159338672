import { BrokerOfferType } from 'utils/types/BrokerAdvert';

export const getModuleByBrokerType = (type: string) => {
    switch (type) {
    case BrokerOfferType.RENT_FLAT:
    case BrokerOfferType.OFFER_RENT_FLAT:
    case BrokerOfferType.RENT_HOUSE:
    case BrokerOfferType.OFFER_RENT_HOUSE:
    case BrokerOfferType.RENT_OTHER:
    case BrokerOfferType.OFFER_RENT_OTHER:
    case BrokerOfferType.RENT_PLOT:
    case BrokerOfferType.OFFER_RENT_PLOT:
        return 'rent';
    case BrokerOfferType.SALE_HOUSE:
    case BrokerOfferType.OFFER_SALE_HOUSE:
    case BrokerOfferType.SALE_OTHER:
    case BrokerOfferType.OFFER_SALE_OTHER:
    case BrokerOfferType.SALE_PLOT:
    case BrokerOfferType.OFFER_SALE_PLOT:
    case BrokerOfferType.SALE_FLAT:
    case BrokerOfferType.OFFER_SALE_FLAT:
    default:
        return 'sale';
    }
};
