export enum BrokerOfferModule {
  OFFER_SALE_FLAT = 'OFFER_SALE_FLAT',
  OFFER_SALE_HOUSE = 'OFFER_SALE_HOUSE',
  OFFER_SALE_PLOT = 'OFFER_SALE_PLOT',
  OFFER_SALE_OTHER = 'OFFER_SALE_OTHER',
  OFFER_RENT_FLAT = 'OFFER_RENT_FLAT',
  OFFER_RENT_HOUSE = 'OFFER_RENT_HOUSE',
  OFFER_RENT_PLOT = 'OFFER_RENT_PLOT',
  OFFER_RENT_OTHER = 'OFFER_RENT_OTHER'
}
