import { AgentDetailDto } from 'utils/types/BrokerModels';

export interface AdminPanelStateType {
    isLoading: boolean;
    agencyName: string;
    numberOfAgents: string;
    packageDate: string;
    totalSmsAvailableToNewAccounts: number;
    smsPackage: string;
    agents?: AgentDetailDto[];
    totalSmsUsed: number;
    totalSmsNumber: number;
    existedAccountNumber: number;
    totalAccountNumber: number;
}

export const adminPanelInitialState: AdminPanelStateType = {
    isLoading: false,
    agencyName: '',
    numberOfAgents: '',
    packageDate: '',
    totalSmsAvailableToNewAccounts: 0,
    smsPackage: '',
    agents: [],
    totalSmsUsed: 0,
    totalSmsNumber: 0,
    existedAccountNumber: 0,
    totalAccountNumber: 0
};
