import React, { useCallback, useContext, useEffect, useState } from 'react';
import { isTouchDevice } from 'utils/checkIsTouchDevice';
import TenderContext from 'utils/context/TenderContext';
import { formatFilterPrice } from 'utils/formatFilterPrice';
import { generateSingleOfferLink } from 'utils/generateSingleOfferLink';
import { getStringFromDate } from 'utils/getStringFromDate';
import { getTenderFiltersFromState, getTenderValueFromState } from 'utils/state-managment/tender/tenderFilter';
import { TenderOfferResponseContent } from 'utils/state-managment/tender/tenderOffer';
import { LocationChangeType } from 'utils/types/InputTypes';
import { UserReactions } from 'utils/types/OfferData';
import { ReactionType } from 'utils/types/ReactionType';
import { TenderFiltersStateType } from 'utils/types/Tender';

import OfferActions from 'components/common/Card/common/OfferActions/OfferActions';
import { InteractiveElements } from 'components/common/Card/common/V2InteractiveIconsWrapper/V2InteractiveIconsWrapper';
import { IconEnum } from 'components/common/Icon';
import { SliderImages } from 'components/common/Slider/Slider';
import { TabType } from 'components/common/Tabs/V2Tabs';
import { convertToThumbnailPath, createImagesForSlider } from 'components/functions/imagesFunctions';
import { tenderOfferListFunctions } from 'components/functions/offerListFunctions/tenderOfferListFunctions/tenderOfferListFunctions';
import { generateOfferActionsElements } from 'components/functions/shared/generateOfferActionsElements';
import V2OfferList, { RenderList } from 'components/offerList/V2OfferList';

const TenderOffers = () => {
    const {
        tenderFiltersState,
        handleChange,
        tenderOfferState,
        loadTenderOffers,
        deleteNote,
        updateNote,
        reactionUpdate,
        loadOfferDetailsAsync,
        loadNotesAsync
    } = useContext(TenderContext);

    const [renderList, setRenderList] = useState<RenderList[]>([]);
    const [moreButtonActiveId, setMoreButtonActiveId] = useState<string | null>(null);
    const [detailsMoreButtonActiveId, setDetailsMoreButtonActiveId] = useState<string | null>(null);

    const handleChangePage = useCallback((currentPage: number, rows = 25) => {
        loadTenderOffers({ page: currentPage, rows });
    }, [tenderFiltersState]);

    const handleActiveFiltersChange = () => {
        loadTenderOffers({ page: 1, rows: 25 });
    };

    const handleOlderAdverts = () => {
        const date = tenderFiltersState.offerAdded.from ? new Date(new Date(tenderFiltersState.offerAdded.from).setDate(new Date(tenderFiltersState.offerAdded.from).getDate() - 6)) : null;
        const dateString = date && getStringFromDate(date.toDateString());

        loadTenderOffers({
            requestData: {
                ...getTenderValueFromState(tenderFiltersState),
                ...{ offerAddedFrom: !tenderFiltersState.daysAmount ? dateString ?? undefined : undefined },
                ...{ daysAmount: tenderFiltersState.daysAmount ? tenderFiltersState.daysAmount + 6 : undefined }
            },
            page: tenderOfferState.tenderOfferList?.pageable?.pageNumber,
            rows: tenderOfferState.tenderOfferList && tenderOfferState.tenderOfferList.size ? tenderOfferState.tenderOfferList.size : 25
        });
    };

    const onNoteAdd = () => {
        // TODO: INVEST-257 Czy ta funkcja jest potrzebna?
        // if (!showNote && showLastNote) {
        //     showLastNote();
        // }
        //
        // if (!data.note?.noteId) {
        //     openOfferTab('note');
        // }
    };

    const onMessageClick = () => {
        onNoteAdd();
    };

    const handleDelete = (noteId: number, id: number) => {
        deleteNote(id, noteId);
    };

    const handleSaveNote = async (value: string, advertId: number, id?: number) => {
        updateNote(value, advertId, id || 0);
    };

    const toggleContainerDetails = (detailsOpen: boolean, offer: TenderOfferResponseContent) => {
        if (!detailsOpen) {
            if (offer.reaction && !offer.reaction.viewedStatus) {
                reactionUpdate(offer.tenderId, offer.location!, {
                    ...offer.reaction,
                    openedStatus: true,
                    viewedStatus: true
                } as ReactionType);
            }

            loadOfferDetailsAsync(offer.tenderId);
        }
    };

    const handleChangeReaction = (name: keyof ReactionType, value: boolean, offer: TenderOfferResponseContent) => {
        const userReaction = Object.assign({}, offer.reaction);

        if (name === 'likeStatus' && userReaction.unlikeStatus && value) {
            userReaction.unlikeStatus = false;
        } else if (name === 'unlikeStatus' && userReaction.likeStatus && value) {
            userReaction.likeStatus = false;
        }

        (userReaction[name] as boolean) = value;
        const city = offer.location;

        reactionUpdate(offer.tenderId, city, userReaction as UserReactions);
    };

    const handleDeleteFilterValue = (filterName: string, value?: string | number | null | string[], index?: number) => {
        const stateField = tenderFiltersState[filterName as keyof TenderFiltersStateType];

        if (index !== undefined && Array.isArray(stateField)) {
            const array = [...stateField] as string[];
            array.splice(index, 1);
            handleChange(filterName, array);
        } else if (filterName === 'offerAdded') {
            handleChange('dateRangeFilter', false);
            handleChange('daysAmount', 3);
        } else if (filterName === 'savedLocations') {
            const savedLocations = { ...tenderFiltersState.savedLocations };

            if (typeof value === 'string') {
                delete savedLocations[value];
                handleChange('savedLocations', savedLocations);
                handleChange('city', Object.values(savedLocations) as LocationChangeType);
            }
        } else {
            handleChange(filterName, {
                from: '',
                to: ''
            });
        }
    };

    const generateInteractiveElements = (offer: TenderOfferResponseContent): InteractiveElements[] => {
        const offerLink = generateSingleOfferLink(offer.encryptedId, 'tender');
        const isMoreButtonActive = moreButtonActiveId === offer.tenderId.toString();
        const offerActionsElements = generateOfferActionsElements(offerLink, offer.link);

        return [
            {
                tooltipText: 'Wyświetlono',
                icon: IconEnum.EYE,
                active: offer.reaction?.viewedStatus,
                disabled: offer.reaction?.viewedStatus === undefined,
                onClick: () => handleChangeReaction('viewedStatus', !offer.reaction?.viewedStatus, offer)
            },
            {
                tooltipText: 'Lubię to',
                icon: IconEnum.LIKE,
                active: offer.reaction?.likeStatus,
                onClick: () => handleChangeReaction('likeStatus', !offer.reaction?.likeStatus, offer)
            },
            {
                tooltipText: 'Nie lubię tego',
                icon: IconEnum.DISLIKE,
                active: offer.reaction?.unlikeStatus,
                disabled: offer.reaction?.unlikeStatus === undefined,
                onClick: () => handleChangeReaction('unlikeStatus', !offer.reaction?.unlikeStatus, offer)
            },
            {
                tooltipText: 'Notatki',
                icon: IconEnum.MESSAGE,
                active: offer.numberOfNotes ? offer.numberOfNotes > 0 : false,
                counter: offer.numberOfNotes ? offer.numberOfNotes || 1 : undefined,
                openTabOnClick: TabType.NOTES,
                onClick: onMessageClick
            },
            {
                tooltipText: 'Ogłoszenie nieaktualne',
                icon: IconEnum.BLOCKED,
                active: offer.reaction?.hideStatus,
                disabled: offer.reaction?.hideStatus === undefined,
                onClick: () => handleChangeReaction('hideStatus', !offer.reaction?.hideStatus, offer)
            },
            {
                tooltipText: 'Przeprowadzona rozmowa',
                icon: IconEnum.PHONE,
                active: offer.reaction?.phoneStatus,
                disabled: offer.reaction?.phoneStatus === undefined,
                onClick: () => handleChangeReaction('phoneStatus', !offer.reaction?.phoneStatus, offer)
            },
            {
                tooltipText: 'Umówione spotkanie',
                icon: IconEnum.PEOPLES_3,
                active: offer.reaction?.arrangedStatus,
                disabled: offer.reaction?.arrangedStatus === undefined,
                onClick: () => handleChangeReaction('arrangedStatus', !offer.reaction?.arrangedStatus, offer)
            },
            ...offer.photos && offer.photos.length > 0
                ? [
                    {
                        icon: IconEnum.PHOTOS,
                        isModalOpen: true,
                        isLink: true,
                        text: <p>Zobacz zdjęcia</p>,
                        isPhotoBtn: true,
                        visibleDuringModal: true,
                        sliderElements: {
                            photos: createImagesForSlider(offer.photos, offer.module) as SliderImages[],
                            title: offer.title
                        }
                    }
                ]
                : [],
            {
                icon: 'squares' as IconEnum,
                tooltipText: isTouchDevice() ? undefined : 'Więcej',
                onClick: () => isMoreButtonActive ? setMoreButtonActiveId(null) : setMoreButtonActiveId(offer.tenderId.toString()),
                additionalJSXSibling: isMoreButtonActive ? <OfferActions elements={offerActionsElements} /> : undefined
            }
        ];
    };

    const generateSquareIcon = (offerId: string, isDetail: boolean, offer: TenderOfferResponseContent) => {
        const buttonId = isDetail ? `${offerId}-detail` : offerId;
        const isMoreButtonActive = isDetail ? detailsMoreButtonActiveId === buttonId : moreButtonActiveId === buttonId;
        const offerLink = generateSingleOfferLink(offer.encryptedId, 'tender');
        const offerActionsElements = generateOfferActionsElements(offerLink, offer.link);

        return {
            icon: 'squares' as IconEnum,
            tooltipText: isTouchDevice() ? undefined : 'Więcej',
            onClick: () => {
                if (isDetail) {
                    isMoreButtonActive
                        ? setDetailsMoreButtonActiveId(null)
                        : setDetailsMoreButtonActiveId(buttonId);
                } else {
                    isMoreButtonActive
                        ? setMoreButtonActiveId(null)
                        : setMoreButtonActiveId(buttonId);
                }
            },
            additionalJSXSibling: isMoreButtonActive
                ? <OfferActions
                    onClickOutside={() => isDetail ? setDetailsMoreButtonActiveId(null) : setMoreButtonActiveId(null)}
                    isInModal={isDetail}
                    elements={offerActionsElements}
                />
                : undefined
        };
    };

    const handleNotes = async (offer: TenderOfferResponseContent) => {
        loadNotesAsync(offer.tenderId.toString());
    };

    const generateCardData = (offer: TenderOfferResponseContent) => {
        const subMainValues: React.ReactNode[] = [];

        if (offer.pricePerSquareMeter) {
            subMainValues.push(
                <><b>{offer.pricePerSquareMeter.toString()}</b> zł / m<sup>2</sup></>
            );
        }

        return {
            additionalInfoBadge: offer.tenderDate ? `Data przetargu: ${offer.tenderDate}` : undefined,
            infoElements: tenderOfferListFunctions.generateInfoElements(offer),
            preTitle: offer.location,
            date: offer.dateAdded,
            title: offer.title,
            interactiveElements: generateInteractiveElements(offer),
            note: offer.newestNote,
            mainValue: offer.price ? `${formatFilterPrice(offer.price)} zł` : '- zł',
            subMainValues,
            images: createImagesForSlider(offer.photos || [], offer.module),
            thumbnailPath: offer.photos && offer.photos.length > 0 ? convertToThumbnailPath(offer.photos[0], offer.module) : './defaultImg.png',
            link: {
                url: offer.link,
                name: offer.portal
            }
        };
    };

    useEffect(() => {
        const offersList = tenderOfferState.tenderOfferList.content
            ? tenderOfferState.tenderOfferList?.content.map((offer): RenderList => {
                const interactiveElements = generateInteractiveElements(offer);
                interactiveElements.splice(-1);
                const detailsTabContent = tenderOfferListFunctions.generateOfferDetailsTabContent(offer);

                return {
                    id: offer.tenderId,
                    onSingleOfferClick: (_: React.MouseEvent, detailsOpen: boolean) => toggleContainerDetails(detailsOpen, offer),
                    baseCardContent: {
                        bigImage: true,
                        cardData: generateCardData(offer),
                        openOfferTab: () => {
                        }
                    },
                    detailsCardData: {
                        interactiveElements: [
                            ...interactiveElements,
                            generateSquareIcon(offer.tenderId.toString(), true, offer)
                        ],
                        thumbnailPath: offer.photos && offer.photos.length > 0 ? convertToThumbnailPath(offer.photos[0], offer.module) : './defaultImg.png',
                        date: offer.dateAdded ? `${offer.dateAdded}` : '',
                        additionalInfoBadge: offer.tenderDate ? `Data przetargu: ${offer.tenderDate}` : undefined,
                        onToggleContainerDetails: () => {
                        },
                        tabsContent: {
                            toggleContainerDetails: () => {
                            },
                            interactiveElements: [
                                ...interactiveElements,
                                generateSquareIcon(offer.tenderId.toString(), true, offer)
                            ],
                            detailsTabContent,
                            notesTabContent: {
                                notes: tenderOfferState.notes,
                                handleDelete: (noteId: string) => handleDelete(Number(noteId), offer.tenderId),
                                handleSaveNote: (value: string, id?: string) => handleSaveNote(value, offer.tenderId, Number(id))
                            },
                            numberOfNotes: offer.numberOfNotes || 0,
                            handleLoadNotes: () => handleNotes(offer)
                        }
                    }
                };
            })
            : [];

        setRenderList(offersList);
    }, [
        tenderOfferState.tenderOfferList?.content,
        tenderOfferState.notes,
        moreButtonActiveId,
        detailsMoreButtonActiveId
    ]);

    return (
        <div>
            <V2OfferList totalPages={tenderOfferState.tenderOfferList.totalPages} handleChangePage={handleChangePage}
                pageNumber={tenderOfferState.tenderOfferList?.pageable?.pageNumber || 1}
                lastPage={tenderOfferState.tenderOfferList.last}
                handleOlderAdverts={handleOlderAdverts}
                isLoadMoreButton
                handleActiveFiltersChange={handleActiveFiltersChange}
                showList={!tenderOfferState.tenderOfferList.empty && tenderOfferState.tenderOfferList.content?.length > 0}
                filters={getTenderFiltersFromState(tenderFiltersState)}
                activeFiltersState={tenderFiltersState}
                onDeleteFilter={handleDeleteFilterValue}
                showNoItemsText={tenderOfferState.tenderOfferList && tenderOfferState.tenderOfferList.empty && tenderOfferState.tenderOfferList.content.length === 0 && tenderOfferState.tenderOfferList.afterSearch}
                renderList={renderList}
            />
        </div>
    );
};

export default TenderOffers;
