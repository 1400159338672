import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';

import Icon, { IconEnum } from 'components/common/Icon';

import TextInput from './TextInput';

const Container = styled.span`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;

    input {
        caret-color: transparent;
    }

    input:focus {
        cursor: pointer;
    }
`;

const AutoCompleteSuggestions = styled.div`
    position: absolute;
    z-index: 1;
    top: 95px;
    display: flex;
    flex-direction: column;
    background: var(--color-white);
    left: 0;
    width: 100%;
    min-width: fit-content;
    max-height: 330px;
    overflow-y: auto;
    padding: 10px;
    gap: 6px;
    border-radius: 10px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid var(--color-primary);

    &.oneLine {
        top: 50px;
    }

    &.goTop {
        box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.1);
        top: unset;
        bottom: 50px;
    }

    &.hidden {
        display: none;
    }
`;

const Suggestion = styled.label`
    width: 100%;
    font-weight: 500;
    font-size: 13px;
    line-height: 36px;
    color: var(--primary-color);
    background: var(--color-alt-second);
    border-radius: 10px;
    padding: 7px 17px 7px 7px;
    border: 1px solid transparent;
    transition: border 0.1s ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.smaller {
        line-height: 20px;
    }

    div {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        gap: 10px;

        span {
            width: min-content;
            padding: 0 10px;
        }
    }

    input {
        --checkbox-background-color: transparent;

        cursor: pointer;
    }

    input[type="checkbox"]:checked {
        --checkbox-color: var(--color-white);
    }

    &:hover, &:focus, &:active, &:target, &.preferred {
        transition: border 0.1s ease-in-out;
        border: 1px solid var(--color-alt-hover);
        cursor: pointer;
    }

    &.active {
        background: ${(props) => props.darkMode ? 'var(--color-primary)' : 'var(--color-alt)'};
        color: var(--color-alt-second);

        div {
            color: var(--color-white);
        }

        img {
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%);
        }
    }
`;

const Action = styled.div`
    position: absolute;
    right: 10px;
    background: var(--color-white);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    pointer-events: none;
    cursor: pointer;
`;

const Checkbox = styled.input`
    pointer-events: none;
`;

interface MultiSelectInputProperties {
    value: string[],
    onChange: (value?: string[]) => void,
    disabled?: boolean,
    placeholder?: string,
    options: { label: string, value: string }[]
    icon?: IconEnum,
    optionsIcon?: IconEnum,
    noAction?: boolean,
    oneLine?: boolean,
    goTop?: boolean,
}

const MultiSelectInput: FC<MultiSelectInputProperties> = ({
    noAction,
    icon,
    options,
    placeholder,
    disabled,
    value,
    onChange,
    oneLine,
    goTop,
    optionsIcon
}) => {
    const { user } = useUser();
    const inputRef = React.createRef<HTMLInputElement>();
    const autocompleteRef = React.createRef<HTMLDivElement>();
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [preferredOptionIndex, setPreferredOptionIndex] = useState<number | null>(null);

    const loweredValue = value ? value.map((x) => String(x).toLowerCase()) : [];

    const handleSelectSuggestion = (e: Event, newValue: string) => {
        e.preventDefault();
        e.stopPropagation();

        onChange(loweredValue.includes(String(newValue).toLowerCase()) ? value?.filter((x) => String(x).toLowerCase() !== String(newValue).toLowerCase()) : [...value ?? [], newValue]);
        inputRef?.current?.focus();
        setTimeout(() => setShowSuggestions(true), 200);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.keyCode === 32) {
            e.key = 'SpaceBar';
        }

        if (e.key === 'Enter' || e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'SpaceBar') {
            e.preventDefault();
            e.stopPropagation();
        }

        switch (e.key) {
        case 'Enter':
        case 'SpaceBar':
            if (preferredOptionIndex !== null && options[preferredOptionIndex]?.value) {
                const newValue = options[preferredOptionIndex].value;
                onChange(loweredValue.includes(String(newValue).toLowerCase()) ? value?.filter((x) => String(x).toLowerCase() !== String(newValue).toLowerCase()) : [...value ?? [], newValue]);
            }
            break;
        case 'ArrowDown':
            if (preferredOptionIndex === null) {
                setPreferredOptionIndex(0);
            } else if (preferredOptionIndex < options.length - 1) {
                setPreferredOptionIndex(preferredOptionIndex + 1);
            }
            break;
        case 'ArrowUp':
            if (preferredOptionIndex === null) {
                setPreferredOptionIndex(0);
            } else if (preferredOptionIndex > 0) {
                setPreferredOptionIndex(preferredOptionIndex - 1);
            }
            break;
        default:
            if (!options && e.key !== 'Tab') {
                e.preventDefault();
                e.stopPropagation();

                return;
            }

            setPreferredOptionIndex(null);
        }
    };

    const handleClick = () => {
        if (showSuggestions) {
            setTimeout(() => setShowSuggestions(false), 200);

            return;
        }

        setShowSuggestions(true);
        setPreferredOptionIndex(options.findIndex((x) => String(x.value).toLowerCase() === String(value).toLowerCase()) ?? null);
    };

    const getPreparedValue = () => {
        if (value && value.length > 0) {
            return value.map((x) => options.find((y) => String(y.value).toLowerCase() === String(x).toLowerCase())?.label).join(', ');
        }

        return '';
    };

    const handleClickOutside = (e: MouseEvent) => {
        if (autocompleteRef.current && !autocompleteRef.current.contains(e.target as Node) && inputRef.current && !inputRef.current.contains(e.target as Node)) {
            setShowSuggestions(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    return (
        <Container>
            {icon && <Icon icon={icon}/>}
            <TextInput
                ref={inputRef}
                disabled={disabled}
                placeholder={placeholder}
                value={getPreparedValue()}
                onClick={handleClick}
                onKeyDown={handleKeyPress}
            />
            {!noAction &&
                <Action>
                    {showSuggestions ? <Icon icon={IconEnum.ARROW_UP}/> : <Icon icon={IconEnum.ARROW_DOWN}/>}
                </Action>
            }
            <AutoCompleteSuggestions className={clsx({
                hidden: !(showSuggestions && options.length > 0),
                oneLine: oneLine,
                goTop: goTop
            })} ref={autocompleteRef}>
                {options
                    .map((x) => <Suggestion
                        darkMode={user.isDarkMode}
                        key={x.value}
                        onClick={(e: Event) => handleSelectSuggestion(e, x.value)}
                        className={clsx({
                            active: loweredValue.includes(String(x.value).toLowerCase()),
                            preferred: preferredOptionIndex === options.indexOf(x),
                            smaller: x.label.length >= 29
                        })}
                    >
                        <div>
                            {optionsIcon ? <Icon icon={optionsIcon}/> : null}
                            {x.label}
                        </div>
                        <Checkbox
                            readOnly
                            checked={loweredValue.includes(String(x.value).toLowerCase())}
                            type="checkbox"
                            autocomplete="off"/>
                    </Suggestion>)}
            </AutoCompleteSuggestions>
        </Container>
    );
};

export default MultiSelectInput;
