import React from 'react';
import { useUser } from 'store/User/Context';

import { HeaderStyle, LegendList, SectionBox } from 'components/analysis/AnalysisSaleList';
import LineBarWrapper from 'components/common/LineBarWrapper';

interface TimeRangeAverageMeterAndAreaProps {
    averagePriceMeterByTime: { name: string; line: number; bar: number; }[];
}

export const TimeRangeAverageMeterAndArea = ({ averagePriceMeterByTime }: TimeRangeAverageMeterAndAreaProps) => {
    const { user } = useUser();
    const legendListText = [
        ' zł/m² średnia cena',
        ' m² średnia powierzchnia'
    ];

    return (
        <SectionBox data-pdf-section>
            <HeaderStyle>
                <h2>Średnia cena metra kwadratowego oraz średnia powierzchnia w okresach czasowych</h2>
                <LegendList darkMode={user.isDarkMode}>
                    <li>Średnia cena za m² brutto [PLN]</li>
                    <li>Średnia powierzchnia [m²]</li>
                </LegendList>
                <LineBarWrapper lineBarData={averagePriceMeterByTime} legendListText={legendListText} type="linear"/>
            </HeaderStyle>
        </SectionBox>
    );
};
