import React, { FC, ReactElement, useEffect, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AddAgentFormPage from 'pages/AdminPanelPage/AddAgentFormPage';
import AdminPanelPage from 'pages/AdminPanelPage/AdminPanelPage';
import EditAgentFormPage from 'pages/AdminPanelPage/EditAgentFormPage';
import BrokerEditOfferPage from 'pages/AdvertManagementPage/BrokerEditOfferPage';
import BrokerOffersPage from 'pages/BrokerOffersPage';
import CooperativePage from 'pages/CooperaivePage';
import SingleCooperativeDetailPage from 'pages/SingleDetailsPages/SingleCooperativeDetailPage';
import styled from 'styled-components';
import { AdminPanelProvider } from 'utils/context/AdminPanelContex';
import { CooperativeProvider } from 'utils/context/CooperativeContext';
import { RentProvider } from 'utils/context/RentContext';

import HeaderMenu from 'components/HeaderMenu.tsx';

import Header from './components/Header';
import Navigation from './components/Navigation';
import { getToken, messaging, onMessage } from './features/firebase/firebaseConfig.ts';
import AdvertManagementPage from './pages/AdvertManagementPage/AdvertManagementPage';
import AnalysisPage from './pages/AnalysisPage';
import AuctionPage from './pages/AuctionPage';
import BailiffPage from './pages/BailiffPage';
import FavoritesPage from './pages/FavoritesPage';
import MyOffersPage from './pages/MyOffersPage';
import MySettingsPage from './pages/MySettingsPage';
import OwnerProfilePage from './pages/OwnerProfilePage';
import RentPage from './pages/RentPage';
import SalesPage from './pages/SalesPage';
import SingleAuctionDetailPage from './pages/SingleDetailsPages/SingleAuctionDetailPage';
import SingleBailiffDetailPage from './pages/SingleDetailsPages/SingleBailiffDetailPage';
import SingleRentDetailPage from './pages/SingleDetailsPages/SingleRentDetailPage';
import SingleSaleDetailPage from './pages/SingleDetailsPages/SingleSaleDetailPage';
import SingleTenderDetailPage from './pages/SingleDetailsPages/SingleTenderDetailPage';
import TenderPage from './pages/TenderPage';
import UserAddAdvertPage from './pages/UserAddAdvertPage';
import UserAdvertsPage from './pages/UserAdvertsPage';
import UserEditAdvertPage from './pages/UserEditAdvertPage';
import UserMessagesPage from './pages/UserMessagesPage';
import UserOffersMiniPage from './pages/UserOffersMiniPage';
import { useUser } from './store/User/Context';
import { userApiRequests } from './utils/api-requests/user.tsx';
import { AnalysisSaleProvider } from './utils/context/AnalysisSaleContext';
import { AuctionProvider } from './utils/context/AuctionContext';
import { BailiffProvider } from './utils/context/BailiffContext';
import { BrokerAdvertProvider } from './utils/context/BrokerAdvertContext';
import { SaleProvider } from './utils/context/SaleContext';
import { TenderProvider } from './utils/context/TenderContext';
import { UserAddAdvertProvider } from './utils/context/UserAddAdvertContext';
import { Packages } from './utils/enums/packages';

import 'react-notifications-component/dist/theme.css';

const AddPagesForm = React.lazy(() => import('./components/adminForm/AddPagesForm'));

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 768px) {
        width: calc(100% - 100px);
        margin-left: 100px;
        margin-top: 80px;
    }

`;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1600px;
    margin: auto;
    position: relative;
`;

const App: FC = () => {
    const { user, dispatch } = useUser();

    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();

        return (/iphone|ipad|ipod/).test(userAgent);
    };

    useEffect(() => {
        if (isIos()) {
            // eslint-disable-next-line no-console
            console.log('Push notifications pominięte na iOS Safari.');

            return;
        }

        if ('serviceWorker' in navigator) {
            // eslint-disable-next-line no-console
            console.log('Service Worker dostępny.');
        }

        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY })
                    .then(async (currentToken: string) => {
                        if (currentToken) {
                            // eslint-disable-next-line no-console
                            console.log('Token FCM:', currentToken);
                            await userApiRequests.sendPushNotificationToken(currentToken);
                        } else {
                            // eslint-disable-next-line no-console
                            console.log('Nie udało się uzyskać tokenu.');
                        }
                    })
                    .catch((err: string) => {
                        // eslint-disable-next-line no-console
                        console.error('Błąd podczas pobierania tokenu:', err);
                    });
            } else {
                // eslint-disable-next-line no-console
                console.log('Uprawnienia do powiadomień nie zostały przyznane.');
            }
        });

        onMessage(messaging, (payload: { data: { url: string; title: string; body: string; image: string; icon: string} }) => {
            if (Notification.permission === 'granted') {
                const { title, body, image, icon, url } = payload.data;

                new Notification(title, {
                    body,
                    icon,
                    image,
                    data: { link: url }
                } as NotificationOptions);
            }
        });
    }, []);

    const MainWrapper = useMemo(() => ({ children }: { children: ReactElement }) => {
        useEffect(() => {
            document.body.classList.remove('light', 'dark');
            document.body.classList.add(user.isDarkMode ? 'dark' : 'light');
        }, [dispatch, user.isDarkMode]);

        return (
            <Header>
                <HeaderMenu />
                <Navigation activePackages={user.activePackages}/>
                <ContentContainer>
                    {children}
                </ContentContainer>
            </Header>
        );
    }, [
        user.activePackages,
        dispatch,
        user.isDarkMode
    ]);

    return (
        <MainContainer>
            <Routes>
                <Route
                    path="/users-offers-mini"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <UserOffersMiniPage/>
                        </React.Suspense>
                    }
                />
                <Route
                    path="/favorites"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <CooperativeProvider>
                                    <AuctionProvider>
                                        <TenderProvider>
                                            <BailiffProvider>
                                                <RentProvider>
                                                    <SaleProvider>
                                                        <FavoritesPage/>
                                                    </SaleProvider>
                                                </RentProvider>
                                            </BailiffProvider>
                                        </TenderProvider>
                                    </AuctionProvider>
                                </CooperativeProvider>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/my-settings"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <MySettingsPage/>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/admin-panel"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <AdminPanelProvider>
                                    <AdminPanelPage/>
                                </AdminPanelProvider>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/admin-panel/add"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <AdminPanelProvider>
                                    <AddAgentFormPage/>
                                </AdminPanelProvider>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/edit-agent/:agentId"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <AdminPanelProvider>
                                    <EditAgentFormPage/>
                                </AdminPanelProvider>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/broker"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <BrokerAdvertProvider>
                                    <BrokerOffersPage/>
                                </BrokerAdvertProvider>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/user"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <UserAdvertsPage type={'users'}/>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/add-offer"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <UserAddAdvertProvider>
                                    <UserAddAdvertPage/>
                                </UserAddAdvertProvider>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/my-offers"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <UserAddAdvertProvider>
                                    <MyOffersPage/>
                                </UserAddAdvertProvider>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/messages"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <UserMessagesPage/>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/profile/:id"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <OwnerProfilePage/>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/edit-offer"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <UserAddAdvertProvider>
                                    <UserEditAdvertPage/>
                                </UserAddAdvertProvider>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                {user.activePackages.includes(Packages.ROLE_ADVERTISEMENT)
                                    ? <SaleProvider>
                                        <SalesPage/>
                                    </SaleProvider>
                                    : <Navigate to={{ pathname: '/' }}/>}
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/tender"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                {user.activePackages.includes(Packages.ROLE_AUCTION)
                                    ? <TenderProvider>
                                        <TenderPage/>
                                    </TenderProvider>
                                    : <Navigate to={{ pathname: '/' }}/>}
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/auction"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                {user.activePackages.includes(Packages.ROLE_AUCTION)
                                    ? <AuctionProvider>
                                        <AuctionPage/>
                                    </AuctionProvider>
                                    : <Navigate to={{ pathname: '/' }}/>}
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/bailiff-notices"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                {user.activePackages.includes(Packages.ROLE_BAILIFFNOTICE)
                                    ? <BailiffProvider>
                                        <BailiffPage/>
                                    </BailiffProvider>
                                    : <Navigate to={{ pathname: '/' }}/>}
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/cooperative"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                {user.activePackages.includes(Packages.ROLE_COOPERATIVE)
                                    ? <CooperativeProvider>
                                        <CooperativePage/>
                                    </CooperativeProvider>
                                    : <Navigate to={{ pathname: '/' }}/>}
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/analysis"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                {user.activePackages.includes(Packages.ROLE_ANALYSIS)
                                    ? <AnalysisSaleProvider>
                                        <AnalysisPage/>
                                    </AnalysisSaleProvider>
                                    : <Navigate to={{ pathname: '/' }}/>}
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/rent"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                {user.activePackages.includes(Packages.ROLE_RENT)
                                    ? <RentProvider>
                                        <RentPage/>
                                    </RentProvider>
                                    : <Navigate to={{ pathname: '/' }}/>}
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/sale/:hash"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <SingleSaleDetailPage/>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/rent/:hash"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <SingleRentDetailPage/>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/bailiff-notice/:hash"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <SingleBailiffDetailPage/>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/tender/:hash"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <SingleTenderDetailPage/>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/auction/:hash"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <SingleAuctionDetailPage/>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/cooperative/:hash"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <SingleCooperativeDetailPage/>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/:type/:subtype/:id/copy"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <BrokerAdvertProvider>
                                    <AdvertManagementPage />
                                </BrokerAdvertProvider>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/broker/:type/:subtype/:id/copy"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <BrokerAdvertProvider>
                                    <AdvertManagementPage/>
                                </BrokerAdvertProvider>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/:type/:subtype/:id/edit"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                <BrokerAdvertProvider>
                                    <BrokerEditOfferPage/>
                                </BrokerAdvertProvider>
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
                <Route
                    path="/admin-form"
                    element={
                        <MainWrapper>
                            <React.Suspense fallback={<>...</>}>
                                {user.activePackages.includes(Packages.ROLE_ADMIN)
                                    ? <AddPagesForm/>
                                    : <Navigate to={{ pathname: '/' }}/>}
                            </React.Suspense>
                        </MainWrapper>
                    }
                />
            </Routes>
        </MainContainer>
    );
};

export default App;
