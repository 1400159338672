import { ManageAdvertStructureType } from 'pages/AdvertManagementPage/AdvertManagementPage';
import { generateOptionsFromEnum } from 'utils/generateOptionsFromEnum';
import { floorOptions } from 'utils/modules/broker/inputsOptions';
import { buildYearOptions, roomOptions } from 'utils/options';
import { BrokerCreateAdvertStateType } from 'utils/types/BrokerAdvert';
import {
    FlatBuildingMaterial,
    FlatBuildingType, FlatPropertyCondition,
    PropertyForm,
    Window
} from 'utils/types/BrokerOfferModels';
import { InputType, ObjectAndSingleValueChangeType } from 'utils/types/InputTypes';
import { TypeOfMarketEnum } from 'utils/types/SaleModels';
import { AdvertValidatorType } from 'utils/validators/advertAddingValidator';

import { IconEnum } from 'components/common/Icon';

type HandleChangeType = (name: string, value: ObjectAndSingleValueChangeType) => void;
type ValidateFieldType = (name: AdvertValidatorType, value: ObjectAndSingleValueChangeType) => boolean

export const getSaleFlatStructure = (brokerAdvertFieldsState: BrokerCreateAdvertStateType, handleChange: HandleChangeType, triggerValidation: boolean, validateField: ValidateFieldType): ManageAdvertStructureType => {
    return {
        Parametry: [
            {
                title: 'Metraż',
                type: InputType.NUMERIC,
                icon: IconEnum.SQUARE,
                placeholder: 'Wpisz metraż...',
                options: {
                    [InputType.NUMERIC]: {
                        modulesProps: {
                            value: brokerAdvertFieldsState.area || '',
                            fieldName: 'area',
                            suffix: 'm²'
                        },
                        contextFunctions: {
                            handleChange: (name, value) => {
                                handleChange(name, value);
                                handleChange('pricePerSquareMeter', brokerAdvertFieldsState.price ? (brokerAdvertFieldsState.price / Number(value)).toFixed(2) : 0);
                            }
                        }
                    }
                }
            },
            {
                title: 'Cena',
                type: InputType.NUMERIC,
                icon: IconEnum.CALENDAR,
                placeholder: 'Wpisz kwotę...',
                options: {
                    [InputType.NUMERIC]: {
                        contextFunctions: {
                            handleChange: (name, value) => {
                                handleChange(name, value);
                                handleChange('pricePerSquareMeter', brokerAdvertFieldsState.area ? (Number(value) / brokerAdvertFieldsState.area).toFixed(2) : 0);
                            }
                        },
                        modulesProps: {
                            fieldName: 'price',
                            value: brokerAdvertFieldsState.price || '',
                            suffix: 'PLN'
                        }
                    }
                }
            },
            {
                title: 'Cena za metr',
                type: InputType.NUMERIC,
                icon: IconEnum.PRICE_PART,
                placeholder: '',
                options: {
                    [InputType.NUMERIC]: {
                        modulesProps: {
                            value: brokerAdvertFieldsState.pricePerSquareMeter || '',
                            fieldName: 'pricePerSquareMeter',
                            suffix: 'zł/m²',
                            disabled: true
                        },
                        contextFunctions: {
                            handleChange
                        }
                    }
                }
            },
            {
                title: 'Liczba pokoi',
                type: InputType.AUTOCOMPLETE,
                icon: IconEnum.BED,
                placeholder: 'Wybierz liczbę pokoi...',
                options: {
                    [InputType.AUTOCOMPLETE]: {
                        modulesProps: {
                            value: brokerAdvertFieldsState.numberOfRooms || '',
                            options: roomOptions,
                            maxInputLength: 3,
                            fieldName: 'numberOfRooms'
                        },
                        contextFunctions: {
                            handleChange
                        }
                    }
                }
            }
        ],
        'Parametry 2': [
            {
                title: 'Rodzaj rynku',
                type: InputType.AUTOCOMPLETE,
                icon: IconEnum.WORK,
                placeholder: 'Wybierz rodzaj rynku...',
                options: {
                    [InputType.AUTOCOMPLETE]: {
                        modulesProps: {
                            value: brokerAdvertFieldsState.typeOfMarket || '',
                            options: generateOptionsFromEnum(TypeOfMarketEnum),
                            optionsIcon: IconEnum.COG,
                            fieldName: 'typeOfMarket',
                            noTyping: true
                        },
                        contextFunctions: {
                            handleChange
                        }
                    }
                }
            },
            {
                title: 'Rok budowy',
                type: InputType.AUTOCOMPLETE,
                icon: IconEnum.CALENDAR,
                placeholder: 'Wybierz rok...',
                options: {
                    [InputType.AUTOCOMPLETE]: {
                        modulesProps: {
                            value: brokerAdvertFieldsState.builtYear || '',
                            options: buildYearOptions,
                            maxInputLength: 4,
                            fieldName: 'builtYear'
                        },
                        contextFunctions: {
                            handleChange
                        }
                    }
                }
            },
            {
                title: 'Rodzaj nieruchomości',
                type: InputType.AUTOCOMPLETE,
                icon: IconEnum.LEAVE,
                placeholder: 'Wybierz typ...',
                options: {
                    [InputType.AUTOCOMPLETE]: {
                        contextFunctions: {
                            handleChange
                        },
                        modulesProps: {
                            value: brokerAdvertFieldsState.buildingType || '',
                            options: generateOptionsFromEnum(FlatBuildingType),
                            fieldName: 'buildingType'
                        }
                    }
                }
            },
            {
                title: 'Piętro',
                type: InputType.AUTOCOMPLETE,
                icon: IconEnum.LEAVE,
                placeholder: 'Wybierz piętro...',
                options: {
                    [InputType.AUTOCOMPLETE]: {
                        contextFunctions: {
                            handleChange
                        },
                        modulesProps: {
                            value: brokerAdvertFieldsState.floor || '',
                            options: floorOptions,
                            fieldName: 'floor'
                        }
                    }
                }
            },
            {
                title: 'Liczba pięter',
                type: InputType.AUTOCOMPLETE,
                icon: IconEnum.BED,
                placeholder: 'Wybierz liczbę pięter...',
                options: {
                    [InputType.AUTOCOMPLETE]: {
                        modulesProps: {
                            value: brokerAdvertFieldsState.numberOfFloors || '',
                            options: floorOptions,
                            maxInputLength: 3,
                            fieldName: 'numberOfFloors'
                        },
                        contextFunctions: {
                            handleChange
                        }
                    }
                }
            },
            {
                title: 'Od kiedy dostępne',
                type: InputType.DATE,
                icon: IconEnum.CALENDAR,
                placeholder: 'Wybierz datę...',
                options: {
                    [InputType.DATE]: {
                        modulesProps: {
                            value: brokerAdvertFieldsState.availableFrom || '',
                            fieldName: 'availableFrom'
                        },
                        contextFunctions: {
                            handleChange
                        }
                    }
                }
            },
            {
                title: 'Forma własności nieruchomości',
                type: InputType.AUTOCOMPLETE,
                icon: IconEnum.TWO_TYPE,
                placeholder: 'Wybierz rodzaj...',
                options: {
                    [InputType.AUTOCOMPLETE]: {
                        contextFunctions: {
                            handleChange,
                            inputValidation: (value) => !validateField(AdvertValidatorType.NO_EMPTY_TEXT, value!)
                        },
                        modulesProps: {
                            value: brokerAdvertFieldsState.propertyForm || '',
                            options: generateOptionsFromEnum(PropertyForm),
                            fieldName: 'propertyForm',
                            triggerValidation,
                            errorMessage: 'Pole nie może być puste'
                        }
                    }
                }
            },
            {
                title: 'Materiał budynku',
                type: InputType.AUTOCOMPLETE,
                icon: IconEnum.TWO_TYPE,
                placeholder: 'Wybierz rodzaj...',
                options: {
                    [InputType.AUTOCOMPLETE]: {
                        contextFunctions: {
                            handleChange
                        },
                        modulesProps: {
                            value: brokerAdvertFieldsState.buildingMaterial || '',
                            options: generateOptionsFromEnum(FlatBuildingMaterial),
                            fieldName: 'buildingMaterial'
                        }
                    }
                }
            },
            {
                title: 'Okna',
                type: InputType.AUTOCOMPLETE,
                icon: IconEnum.TWO_TYPE,
                placeholder: 'Wybierz rodzaj...',
                options: {
                    [InputType.AUTOCOMPLETE]: {
                        contextFunctions: {
                            handleChange
                        },
                        modulesProps: {
                            value: brokerAdvertFieldsState.window || '',
                            options: generateOptionsFromEnum(Window),
                            fieldName: 'window'
                        }
                    }
                }
            },
            {
                title: 'Stan nieruchomości',
                type: InputType.AUTOCOMPLETE,
                icon: IconEnum.LEAVE,
                placeholder: 'Wybierz typ...',
                options: {
                    [InputType.AUTOCOMPLETE]: {
                        contextFunctions: {
                            handleChange
                        },
                        modulesProps: {
                            fieldName: 'propertyCondition',
                            value: brokerAdvertFieldsState.propertyCondition || '',
                            options: generateOptionsFromEnum(FlatPropertyCondition)
                        }
                    }
                }
            }
        ]
    };
};
