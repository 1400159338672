import React, { Dispatch, FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon, IconEnum } from '@investoro/core';
import { useUser } from 'store/User/Context';
import { Action } from 'store/User/Reducer';
import styled from 'styled-components';
import { userApiRequests } from 'utils/api-requests/user';

import Divider from './common/Divider/Divider';

const HeaderMenuWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background-color: var(--color-white);
    border-bottom: 1px solid #030D451A;
    z-index: 10;
    display: flex;

    @media (max-width: 767px) {
        display: none;
    }
`;
const HeaderMenuContent = styled.div`
    max-width: 1600px;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 120px;
    
    img {
        height: 40px;
        cursor: pointer;
    }

    @media (max-width: 768px) {
        margin-left: 20px;
    }
`;

const MenuContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 30px;
`;

const MobileLogoContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 15px;
    z-index: 30;
    position: relative;
    
    img {
        height: 35px;
        cursor: pointer;
    }
`;

const MobileMenuContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-right: 60px;
    position: relative;
    z-index: 30;
`;

interface MenuElementProps {
    darkMode?: boolean;
}

const MenuElement = styled.div<MenuElementProps>`
    display: flex;
    gap: 10px;
    padding: 12px 18px;
    background: var(--color-alt-second);
    border-radius: var(--border-radius);
    position: relative;

    span {
        color: var(--color-primary);
    }

    img {
        filter:  ${(props) => props.darkMode ? 'invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%)' : 'none'};
    }

    &:hover {
        background: ${(props) => props.darkMode ? 'var(--color-primary)' : 'var(--color-alt)'};
        cursor: pointer;
        span {
            color: ${(props) => props.darkMode ? 'black' : '#ffff'};
        }
        
        img {
            filter:  ${(props) => props.darkMode ? 'none' : 'invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%)'};
        }

    }
`;

interface OptionProps {
    isMobile?: boolean;
}

const Options = styled.div<OptionProps>`
    position: absolute;
    top: 100%;
    right: ${(props) => props.isMobile ? '0' : '40px'}; 
    background: var(--color-white);
    border-radius: var(--box-border-radius);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: auto;
    z-index: 100;
`;

interface OptionWrapperProps {
    darkMode: boolean;
}

const OptionsWrapper = styled.div<OptionWrapperProps>`
    background-color: var(--color-alt-second);
    border-radius: 20px;
    min-width: 240px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    gap: 8px;
    
    p {
        text-align: center;
    }

    &:hover {
        ${({ darkMode }) => `
            transition: background-color ease-in .2s;
            background-color: var(--color-alt);
            cursor: pointer;

            p {
                color: white;
            }

            img {
                filter: ${darkMode ? 'none' : 'brightness(0) invert(1)'};
            }
        `}
    }
`;

interface HeaderMenuProps {
    isMobile?: boolean;
}

const HeaderMenu: FC<HeaderMenuProps> = ({ isMobile }) => {
    const navigate = useNavigate();
    const { user, dispatch } = useUser();
    const [showOptions, setShowOptions] = useState(false);

    const handleClickOutside = (event: MouseEvent) => {
        if (event.target instanceof HTMLElement && !event.target.closest('.options')) {
            setShowOptions(false);
        }
    };

    useEffect(() => {
        if (showOptions) {
            document.addEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showOptions]);

    const handleOpenLink = () => {
        window.open('https://investoro.pl/cenytransakcyjne/', '_blank');
    };

    const handleShowOptions = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setShowOptions(!showOptions);
    };

    const logout = async (dispatch: Dispatch<Action>): Promise<void> => {
        localStorage.removeItem('MonitorApiToken');
        localStorage.removeItem('MonitorApiRefreshToken');
        localStorage.removeItem('MonitorRoles');

        await userApiRequests.logout();
        dispatch({ type: 'LOGOUT' });
    };

    const optionsElements = [
        {
            children:
                    <>
                        <p>Ustawienia</p>
                    </>,
            onClick: (e: React.MouseEvent) => {
                e.stopPropagation();
                navigate('/my-settings');
                setShowOptions(false);
            }
        },
        {
            children:
                    <>
                        <p>Jak używać</p>
                    </>,
            onClick: (e: React.MouseEvent) => {
                e.stopPropagation();
                window.open('https://investoro.pl/jak-uzywac/', '_blank');
                setShowOptions(false);
            }
        },
        {
            children:
                    <>
                        <p>Wyloguj się</p>
                    </>,
            onClick: (e: React.MouseEvent) => {
                e.stopPropagation();
                setShowOptions(false);
                logout(dispatch);
            }
        },
        {
            children:
                    <>
                        <p>Pobierz aplikację na androida</p>
                    </>,
            onClick: async (e: React.MouseEvent) => {
                e.stopPropagation();
                setShowOptions(false);
                window.open('https://play.google.com/store/apps/details?id=com.investoro.app', '_blank');
            }
        }
    ];

    return (
        <>
            {isMobile
                ? <MobileMenuContainer>
                    <MobileLogoContainer>
                        <img src="/Investoro_2021_50px.png" alt="Investoro Logo" onClick={() => window.open('https://investoro.pl/', '_blank')}/>
                    </MobileLogoContainer>
                    <MenuElement darkMode={user.isDarkMode} onClick={handleShowOptions}>
                        <span>Moje konto</span>
                        <Icon icon={IconEnum.ACCORDION_DOWN} />
                    </MenuElement>
                    {showOptions &&
                            <Options className={'options'} isMobile={isMobile}>
                                <OptionsWrapper darkMode={user.isDarkMode} onClick={handleOpenLink}>
                                    <p>Ceny mieszkań</p>
                                </OptionsWrapper>
                                <Divider />
                                {optionsElements.map((element, index) => <OptionsWrapper darkMode={user.isDarkMode} key={index} onClick={element.onClick}>
                                    {element.children}
                                </OptionsWrapper>)}
                            </Options>}
                </MobileMenuContainer>
                : <HeaderMenuWrapper>
                    <HeaderMenuContent>
                        <LogoContainer>
                            <img src="/Investoro_2021_50px.png" alt="Investoro Logo" onClick={() => window.open('https://investoro.pl/', '_blank')}/>
                        </LogoContainer>
                        <MenuContainer>
                            <MenuElement darkMode={user.isDarkMode} onClick={handleOpenLink}>
                                <span>Ceny mieszkań</span>
                            </MenuElement>
                            <MenuElement darkMode={user.isDarkMode} onClick={handleShowOptions}>
                                <span>Moje konto</span>
                                <Icon icon={IconEnum.ACCORDION_DOWN} />
                            </MenuElement>
                            {showOptions &&
                           <Options className={'options'}>
                               {optionsElements.map((element, index) => <OptionsWrapper darkMode={user.isDarkMode} key={index} onClick={element.onClick}>
                                   {element.children}
                               </OptionsWrapper>)}
                           </Options>}
                        </MenuContainer>
                    </HeaderMenuContent>
                </HeaderMenuWrapper>
            }
        </>
    );
};

export default HeaderMenu;
