import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import HeaderMenu from './HeaderMenu';

const Wrapper = styled.div`
    @media (min-width: 768px) {
        display: none;
    }


    display: flex;
    align-items: center;
    justify-conent: space-between;
    height: 70px;
    width: 100%;
    background-color: var(--color-white);
    padding: 0 15px;
    justify-content: flex-end;
    position: relative;
    z-index: 25; 
`;

const Header: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <>
            <div className="overlay"></div>
            <div className="search-overlay"></div>
            <Wrapper
                className="sub-header-container">
                <HeaderMenu isMobile={true} />
            </Wrapper>
            {children}
        </>
    );
};

export default Header;
