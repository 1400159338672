import { AxiosResponse } from 'axios';
import { FavoritePageFiltersType } from 'pages/FavoritesPage';
import qs from 'qs';
import { axiosApiInstance } from 'Store';
import { convertPrice } from 'utils/convertPrice';
import { formatFilterPrice } from 'utils/formatFilterPrice';
import { NormalizedPropertyType } from 'utils/formatters/getNormalizedPropertyType';
import { getNormalizedRentModuleName } from 'utils/formatters/getNormalizedRentModuleName';
import { getPropertyByTypeRent } from 'utils/formatters/getPropertyByType';
import { stringToNumber } from 'utils/formatters/stringToNumber';
import { RentOfferDetailsResponse, RentOfferDuplicatesResponse, RentOfferHashResponse, RentOfferResponseContent, RentOffersResponse } from 'utils/state-managment/rent/rentOffer';
import { SavedFilter } from 'utils/types/Filter';
import { LocationOptionsType, SavedLocationsType } from 'utils/types/Locations';
import { RentFilterReturnData } from 'utils/types/Rent';
import { Module } from 'utils/types/RentModels';
import { OpinionUpdateInformation, RentOpinionResult } from 'utils/types/RentModels';

import { activeNotification } from 'components/functions/activeNotification';
import { compareDateDescending } from 'components/Sort';

const addOpinion = (advertisementId: number, content: string, numberOfStars: number, module: Module): Promise<OpinionUpdateInformation> => {
    return axiosApiInstance({
        method: 'post',
        url: `/rent-offer/opinion/create?module=${module}`,
        data: {
            advertisementId,
            content,
            numberOfStars
        }
    })
        .then((response) => {
            activeNotification('Udało się!', 'Opinia została dodana', 'success');

            return response.data;
        })
        .catch(() => {
            activeNotification('Problem z dodaniem opinii', 'Niestety nie udało się dodać opinii', 'danger');
        });
};

const updateOpinion = (opinionId: number, content: string, numberOfStars: number, module: Module): Promise<OpinionUpdateInformation> => {
    return axiosApiInstance({
        method: 'put',
        url: `/rent-offer/opinion/update?module=${module}`,
        data: {
            opinionId,
            content,
            numberOfStars
        }
    })
        .then((response) => {
            activeNotification('Udało się!', 'Opinia została za aktualizowana', 'success');

            return response.data;
        })
        .catch(() => {
            activeNotification('Problem z dodaniem opinii', 'Niestety nie udało się aktualizować opinii', 'danger');
        });
};

const deleteOpinion = (opinionId: number, module: Module): Promise<OpinionUpdateInformation> => {
    return axiosApiInstance({
        method: 'delete',
        url: `/rent-offer/opinion/delete?module=${module}&opinionId=${opinionId}`
    })
        .then((response) => {
            activeNotification('Udało się!', 'Opinia została usunięta', 'success');

            return response.data;
        })
        .catch(() => {
            activeNotification('Problem z dodaniem opinii', 'Niestety nie udało się usunąć opinii', 'danger');
        });
};

const getOpinions = (advertisementId: number, module: Module): Promise<RentOpinionResult> => {
    return axiosApiInstance({
        method: 'get',
        url: `/rent-offer/opinion/find?module=${module}&rentId=${advertisementId}`
    })
        .then((resp) => {
            return resp.data;
        })
        .catch(() => {
            activeNotification('Problem z pobraniem opinii', 'Niestety nie udało się pobrać opinii', 'danger');
        });
};

const getRentOfferByHashId = async (hash: string): Promise<RentOfferHashResponse> => {
    return axiosApiInstance
        .get(`/rent-offer/single-advertisement/detail?hash=${hash}`)
        .then((resp) => {
            if (resp.data) {
                return {
                    ...resp.data,
                    price: resp.data.price ? convertPrice(resp.data.price) : ''
                };
            }
        })
        .catch(() => {
            activeNotification(
                'Nie udało się pobrać ogłoszenia',
                'Nie udało się pobrać danych szczegółowych ogłoszenia',
                'warning'
            );

            return null;
        });
};

const getRentOffers = (params: RentFilterReturnData, propertyType: Module, page: number, size: number): Promise<RentOffersResponse> => {
    return axiosApiInstance({
        method: 'post',
        url: `/rent-offer/advertisement/${getNormalizedRentModuleName(propertyType)}`,
        params: {
            page,
            size
        },
        data: {
            ...params
        }
    })
        .then((resp) => {
            if (resp.data) {
                return {
                    ...resp.data,
                    content: resp.data.content.map((el: RentOfferResponseContent) => ({
                        ...el,
                        city: el.location,
                        price: el.price ? convertPrice(el.price) : '',
                        pricePerSquareMeter: el.pricePerSquareMeter ? convertPrice(el.pricePerSquareMeter) : '',
                        reaction: {
                            likeStatus: el.reaction?.likeStatus,
                            unlikeStatus: el.reaction?.unlikeStatus,
                            viewedStatus: el.reaction?.viewedStatus,
                            outdatedStatus: el.reaction?.outdatedStatus,
                            arrangedStatus: el.reaction?.arrangedStatus,
                            phoneStatus: el.reaction?.phoneStatus,
                            hideStatus: el.reaction?.hideStatus,
                            openedStatus: el.reaction?.openedStatus
                        }
                    }))
                };
            }

            return { empty: true, content: [], afterSearch: true };
        })
        .catch(() => {
            activeNotification(
                'Nie znaleziono wyników',
                'Wyszukiwanie nie powiodło się, spróbuj jeszcze raz.',
                'warning'
            );

            return { empty: true, content: [], afterSearch: true };
        });
};

const getRentOfferDuplicates = async (propertyType: Module, id: number): Promise<RentOfferDuplicatesResponse[]> => {
    return axiosApiInstance
        .get(`/rent-offer/duplicate/find/${getNormalizedRentModuleName(propertyType)}?advertisementId=${id}`)
        .then((resp) => {
            if (resp.data) {
                return resp.data;
            }
        })
        .catch(() => {
            activeNotification(
                'Nie udało się pobrać duplikatów ogłoszenia',
                'Nie udało się pobrać danych szczegółowych duplikatów ogłoszenia',
                'warning'
            );

            return [];
        });
};

const getRentOfferDetails = async (propertyType: Module, id: number): Promise<RentOfferDetailsResponse> => {
    return axiosApiInstance
        .get(`/rent-offer/advertisement/detail/${getNormalizedRentModuleName(propertyType)}?advertisementId=${id}`)
        .then((resp) => {
            if (resp.data) {
                return {
                    ...resp.data,
                    price: resp.data.price ? convertPrice(resp.data.price) : ''
                };
            }
        })
        .catch(() => {
            activeNotification(
                'Nie udało się pobrać ogłoszenia',
                'Nie udało się pobrać danych szczegółowych ogłoszenia',
                'warning'
            );

            return [{ empty: true }];
        });
};

const getOffersCSVFile = (params: RentFilterReturnData, propertyType: NormalizedPropertyType) => {
    return axiosApiInstance({
        method: 'post',
        responseType: 'blob',
        url: `/rent-offer/excel/${propertyType}`,
        data: {
            ...params,
            offerAdded: { from: params.offerAddedFrom, to: params.offerAddedTo },
            priceFrom: stringToNumber(params.priceFrom),
            priceTo: stringToNumber(params.priceTo),
            areaFrom: stringToNumber(params.areaFrom),
            areaTo: stringToNumber(params.areaTo),
            pricePerSquareMeterFrom: stringToNumber(params.pricePerSquareMeterFrom),
            pricePerSquareMeterTo: stringToNumber(params.pricePerSquareMeterTo),
            rentPriceFrom: stringToNumber(params.rentPriceFrom),
            rentPriceTo: stringToNumber(params.rentPriceTo),
            depositPriceFrom: stringToNumber(params.depositPriceFrom),
            depositPriceTo: stringToNumber(params.depositPriceTo)
        }
    })
        .then((resp) => {
            const url = URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');

            link.setAttribute('href', url);
            link.setAttribute('download', 'offers.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        });
};

const getSavedFilters = async (): Promise<SavedFilter[]> => {
    return axiosApiInstance
        .get<SavedFilter[]>('/rent-offer/filter/list')
        .then((resp) => {
            if (resp.data) {
                return resp.data.map((el) => ({
                    ...el,
                    name: el.name
                }));
            }

            return [];
        })
        .catch((err) => {
            if (err?.response?.status === 401) {
                window.parent.location.reload();
            }

            return [];
        });
};

const getFilterDetailsRent = async (filterId: number, filterPropertyType: Module): Promise<RentFilterReturnData> => {
    return axiosApiInstance
        .get(`/rent-offer/filter/detail/${getNormalizedRentModuleName(filterPropertyType)}?id=${filterId}`)
        .then((resp) => {
            const filters = resp.data;
            const savedLocations: SavedLocationsType[] = [];

            filters.city.forEach((city: LocationOptionsType) => {
                // @ts-expect-error TODO: INVEST-254
                savedLocations[city.fullName] = { ...city, label: city.fullName };
            });

            return {
                ...filters,
                city: filters.city ? filters.city : [],
                wantedKeywords: filters.wantedKeywords ? filters.wantedKeywords : [],
                unwantedKeywords: filters.unwantedKeywords ? filters.unwantedKeywords : [],
                wantedPhoneNumbers: filters.wantedPhoneNumbers ? filters.wantedPhoneNumbers : [],
                unwantedPhoneNumbers: filters.unwantedPhoneNumbers ? filters.unwantedPhoneNumbers : [],
                buildingType: filters.buildingType ? filters.buildingType : [],
                typeOfMarket: filters.typeOfMarket ? filters.typeOfMarket : [],
                propertyType: filters.propertyType ? filters.propertyType : [],
                id: filterId,
                name: filters.name ? filters.name : '',
                pushAlert: filters.pushAlert ? filters.pushAlert : false,
                alertEmail: filters.alertEmail ? filters.alertEmail : false,
                alertSms: filters.alertSms ? filters.alertSms : false,
                priceFrom: filters.priceFrom ? formatFilterPrice(filters.priceFrom) : '',
                priceTo: filters.priceTo ? formatFilterPrice(filters.priceTo) : '',
                depositPriceFrom: filters.depositPriceFrom ? formatFilterPrice(filters.depositPriceFrom) : '',
                depositPriceTo: filters.depositPriceTo ? formatFilterPrice(filters.depositPriceTo) : '',
                areaFrom: filters.areaFrom ? filters.areaFrom : '',
                areaTo: filters.areaTo ? filters.areaTo : '',
                pricePerSquareMeterFrom: filters.pricePerSquareMeterFrom ? formatFilterPrice(filters.pricePerSquareMeterFrom) : '',
                pricePerSquareMeterTo: filters.pricePerSquareMeterTo ? formatFilterPrice(filters.pricePerSquareMeterTo) : '',
                numberOfRoomsFrom: filters.numberOfRoomsFrom ? filters.numberOfRoomsFrom : '',
                numberOfRoomsTo: filters.numberOfRoomsTo ? filters.numberOfRoomsTo : '',
                floorFrom: filters.floorFrom ? filters.floorFrom : '',
                floorTo: filters.floorTo ? filters.floorTo : '',
                builtYearFrom: filters.builtYearFrom ? filters.builtYearFrom : '',
                builtYearTo: filters.builtYearTo ? filters.builtYearTo : '',
                module: filters.module ? filters.module : '',
                tossedUp: filters.tossedUp ? filters.tossedUp : false,
                notificationsDelay: filters.notificationsDelay !== null && filters.notificationsDelay !== undefined
                    ? filters.notificationsDelay.toString()
                    : '0',
                groupDuplicate: filters.groupDuplicate ? filters.groupDuplicate : false,
                includeZeroArea: filters.includeZeroArea ? filters.includeZeroArea : false,
                modification: filters.modification ? true : filters.modification,
                offerAddedFrom: filters.offerAddedFrom ? filters.offerAddedFrom : '',
                offerAddedTo: filters.offerAddedTo ? filters.offerAddedTo : '',
                savedFilter: filters.savedFilter ? filters.savedFilter : false,
                locations: [],
                savedLocations: savedLocations,
                searchLocationResult: []
            };
        })
        .catch(() => {
            activeNotification('Problem z załadowaniem danych', 'Niestety nie udało się załadować danych. Spróbuj ponownie później', 'danger');
        });
};

const removeSavedFilter = (filterId: number, adType: Module) => {
    return axiosApiInstance
        .delete(`/rent-offer/filter/delete?id=${filterId}&module=${getPropertyByTypeRent(adType)}`)
        .then(() => {
            activeNotification('Udało się!', 'Filtr został usunięty', 'success');
        })
        .catch(() => {
            activeNotification('Problem z usunięciem', 'Niestety nie udało się usunąć danych. Spróbuj ponownie później', 'danger');
        });
};

const addFilter = (params: RentFilterReturnData, propertyType: NormalizedPropertyType): Promise<AxiosResponse<number>> => {
    const savedLocations = Object.values(params.savedLocations).map((x) => {
        // @ts-expect-error dont need districts and cities
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { districts, cities, ...rest } = x;

        return { ...rest };
    });

    return axiosApiInstance({
        method: 'post',
        url: `/rent-offer/filter/add/${propertyType}`,
        data: {
            ...params,
            city: savedLocations,
            notificationsDelay: params.notificationsDelay ? stringToNumber(params.notificationsDelay) : 0
        },
        paramsSerializer: function (params) {
            return qs.stringify(params);
        }
    })
        .then((resp) => {
            activeNotification('Udało się!', 'Filtr został dodany', 'success');

            return resp;
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                activeNotification(
                    'Błąd',
                    err.response.data,
                    'danger'
                );

                return Promise.reject(err);
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );

                return Promise.reject(err);
            }
        });
};

const updateFilter = async (params: RentFilterReturnData, propertyType: string) => {
    return axiosApiInstance({
        method: 'put',
        url: `/rent-offer/filter/edit/${propertyType}`,
        params: { id: params.id },
        data: {
            ...params,
            notificationsDelay: params.notificationsDelay ? stringToNumber(params.notificationsDelay) : 0
        },
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        }
    })
        .then((resp) => {
            activeNotification('Udało się!', 'Filtr został zaktualizowany', 'success');

            return resp;
        })
        .catch((err) => {
            activeNotification('Problem z zapisem', 'Niestety nie udało się zapisać danych. Spróbuj ponownie później', 'danger');

            return Promise.reject(err);
        });
};

const getRentFavoriteOffers = (modules: string[], page?: number, size?: number, filters?: FavoritePageFiltersType): Promise<RentOffersResponse> => {
    return axiosApiInstance({
        method: 'get',
        url: '/favorite/find/rent',
        params: {
            module: modules,
            ...filters,
            page,
            size
        },
        paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        }
    })
        .then((resp) => {
            if (resp.data.content && resp.data.content.length > 0) {
                return {
                    ...resp.data,
                    pageable: {
                        ...resp.data.pageable,
                        pageNumber: resp.data.pageable.pageNumber + 1
                    },
                    content: resp.data.content.sort(compareDateDescending)
                };
            }

            return { empty: true, content: [], afterSearch: true };
        })
        .catch(() => {
            activeNotification(
                'Nie znaleziono wyników',
                'Obecnie w naszej bazie nie posiadamy ogłoszeń, które spełniają twoje kryteria. Spróbuj zmienić kryteria i wyszukaj jeszcze raz.',
                'warning'
            );

            return { empty: true, content: [], afterSearch: true };
        });
};

export const rentOffersApiRequests = {
    addOpinion,
    updateOpinion,
    deleteOpinion,
    getOpinions,
    getRentOfferByHashId,
    getRentOffers,
    getRentOfferDuplicates,
    getRentOfferDetails,
    getOffersCSVFile,
    getRentFavoriteOffers,
    getFilterDetailsRent,
    getSavedFilters,
    removeSavedFilter,
    addFilter,
    updateFilter
};
