import { SaleModule } from './types/AnalysisModels';
import { AuctionSearchingRequestSortTypeEnum } from './types/AuctionModels';
import { BailiffNoticeSearchingRequestSortTypeEnum } from './types/BailiffModels';
import { Module } from './types/RentModels';
import { SaleModuleType } from './types/Sale';
import { SortType as SaleSortType } from './types/SaleModels';
import { SortType } from './types/SortType';
import { TenderSearchingRequestSortTypeEnum } from './types/TenderModels';
import {
    CapitalBaseDtoFormOfInvestmentEnum,
    CapitalBaseDtoFormOfProfitEnum,
    CapitalBaseDtoInvestmentSecurityEnum,
    DealBaseDtoLegalStatusEnum, FilterDealDtoFeaturesEnum,
    InvestorBaseDtoInvestmentSecurityEnum,
    InvestorBaseDtoLegalStatusEnum,
    InvestorBoxDtoBuildingTypeEnum, InvestorBoxDtoPropertyConditionEnum
} from './types/UserModels';
import { V2OfferType } from './types/UsersAdvert';

const toSameValueOptions = (values: string[]): { value: string, label: string }[] => {
    return values.map((x) => ({
        value: x,
        label: x
    }));
};

export const scheduleOptions = [
    { label: 'Natychmiast', value: '0' },
    { label: 'co 5 minut', value: '5' },
    { label: 'co 10 minut', value: '10' },
    { label: 'co 15 minut', value: '15' },
    { label: 'co 30 minut', value: '30' },
    { label: 'co 1 godzinę', value: '60' },
    { label: 'co 2 godziny', value: '120' },
    { label: 'co 3 godziny', value: '180' },
    { label: 'co 5 godzin', value: '300' },
    { label: 'co 12 godzin', value: '720' },
    { label: 'co 24 godziny', value: '1440' }
];

export const typeOfMarketOptions = [
    { label: 'Pierwotny', value: 'Pierwotny' },
    { label: 'Wtórny', value: 'Wtórny' }
];

export const offerFromOptions = [
    { label: 'Agencja', value: 'Agencja' },
    { label: 'Osoba prywatna', value: 'Osoba prywatna' }
];

export const roomOptions = [
    { label: '1 pokój', value: '1' },
    { label: '2 pokoje', value: '2' },
    { label: '3 pokoje', value: '3' },
    { label: '4 pokoje', value: '4' },
    { label: '5 pokoi', value: '5' },
    { label: '6 pokoi', value: '6' },
    { label: '7 pokoi', value: '7' },
    { label: '8 pokoi', value: '8' },
    { label: '9 pokoi', value: '9' },
    { label: '10 pokoi', value: '10' }
];

export const investmentTimeOptions = [
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' }
];

export const userFloorOptions = [
    { label: 'Parter', value: '0' },
    { label: '1 piętro', value: '1' },
    { label: '2 piętro', value: '2' },
    { label: '3 piętro', value: '3' },
    { label: '4 piętro', value: '4' },
    { label: '5 piętro', value: '5' },
    { label: '6 piętro', value: '6' },
    { label: '7 piętro', value: '7' },
    { label: '8 piętro', value: '8' },
    { label: '9 piętro', value: '9' },
    { label: '10 piętro', value: '10' }
];

export const userFloorsOptions = [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' }
];

export const areaOptions = [
    { label: '25 m²', value: '25' },
    { label: '35 m²', value: '35' },
    { label: '40 m²', value: '40' },
    { label: '50 m²', value: '50' },
    { label: '60 m²', value: '60' },
    { label: '70 m²', value: '70' },
    { label: '80 m²', value: '80' }
];

export const plotAreaOptions = [
    { label: '100 m2', value: '100' },
    { label: '200 m2', value: '200' },
    { label: '300 m2', value: '300' },
    { label: '400 m2', value: '400' },
    { label: '500 m2', value: '500' },
    { label: '600 m2', value: '600' },
    { label: '800 m2', value: '800' },
    { label: '1000 m2', value: '1000' },
    { label: '1500 m2', value: '1500' },
    { label: '2000 m2', value: '2000' },
    { label: '3000 m2', value: '3000' },
    { label: '4000 m2', value: '4000' },
    { label: '5000 m2', value: '5000' }
];

export const pricePerSquareMeterOptions = [
    { label: '10 zł', value: '10' },
    { label: '20 zł', value: '20' },
    { label: '30 zł', value: '30' },
    { label: '40 zł', value: '40' },
    { label: '50 zł', value: '50' },
    { label: '70 zł', value: '70' },
    { label: '100 zł', value: '100' },
    { label: '130 zł', value: '130' },
    { label: '150 zł', value: '150' }
];

export const pricePerSquareMeterSaleOptions = [
    { label: '3 000 zł', value: '3000' },
    { label: '4 000 zł', value: '4000' },
    { label: '5 000 zł', value: '5000' },
    { label: '6 000 zł', value: '6000' },
    { label: '7 000 zł', value: '7000' },
    { label: '8 000 zł', value: '8000' },
    { label: '9 000 zł', value: '9000' },
    { label: '10 000 zł', value: '10000' },
    { label: '11 000 zł', value: '11000' },
    { label: '12 000 zł', value: '12000' },
    { label: '13 000 zł', value: '13000' },
    { label: '14 000 zł', value: '14000' },
    { label: '15 000 zł', value: '15000' },
    { label: '18 000 zł', value: '18000' },
    { label: '20 000 zł', value: '20000' }
];

export const priceOptions = [
    { label: '50 000 zł', value: '50 000' },
    { label: '100 000 zł', value: '100 000' },
    { label: '150 000 zł', value: '150 000' },
    { label: '200 000 zł', value: '200 000' },
    { label: '250 000 zł', value: '250 000' },
    { label: '300 000 zł', value: '300 000' },
    { label: '350 000 zł', value: '350 000' },
    { label: '400 000 zł', value: '400 000' },
    { label: '500 000 zł', value: '500 000' },
    { label: '600 000 zł', value: '600 000' },
    { label: '700 000 zł', value: '700 000' },
    { label: '800 000 zł', value: '800 000' }
];

export const rentPriceOptions = [
    { label: '500 zł', value: '500' },
    { label: '1 000 zł', value: '1 000' },
    { label: '2 000 zł', value: '2 000' },
    { label: '3 000 zł', value: '3 000' },
    { label: '5 000 zł', value: '5 000' },
    { label: '7 000 zł', value: '7 000' },
    { label: '10 000 zł', value: '10 000' },
    { label: '15 000 zł', value: '15 000' },
    { label: '20 000 zł', value: '20 000' },
    { label: '25 000 zł', value: '25 000' },
    { label: '30 000 zł', value: '30 000' }
];

export const investPriceOptions = [
    { label: '30 000 zł', value: '30 000' },
    { label: '50 000 zł', value: '50 000' },
    { label: '75 000 zł', value: '75 000' },
    { label: '100 000 zł', value: '100 000' },
    { label: '125 000 zł', value: '125 000' },
    { label: '150 000 zł', value: '150 000' },
    { label: '200 000 zł', value: '200 000' },
    { label: '300 000 zł', value: '300 000' },
    { label: '500 000 zł', value: '500 000' },
    { label: '750 000 zł', value: '750 000' },
    { label: '1 000 000 zł', value: '1 000 000' },
    { label: '1 500 000 zł', value: '1 500 000' },
    { label: '2 000 000 zł', value: '2 000 000' }
];

export const percentageOptions = [
    { label: '5%', value: '5' },
    { label: '10%', value: '10' },
    { label: '15%', value: '15' },
    { label: '20%', value: '20' },
    { label: '30%', value: '30' },
    { label: '40%', value: '40' },
    { label: '50%', value: '50' },
    { label: '60%', value: '60' },
    { label: '70%', value: '70' },
    { label: '80%', value: '80' },
    { label: '90%', value: '90' },
    { label: '100%', value: '100' }
];

export const buildYearOptions = toSameValueOptions([
    '1920',
    '1950',
    '1980',
    '1990',
    '2000',
    '2004',
    '2006',
    '2008',
    '2009',
    '2010',
    '2011',
    '2012',
    '2013',
    '2014',
    '2015',
    '2016',
    '2017',
    '2018',
    '2019',
    '2020',
    '2021',
    '2022',
    '2023',
    '2024',
    '2025'
]);

export const housesBuildingTypeOptions = toSameValueOptions([
    'Wolnostojący',
    'Bliźniak',
    'Szeregowiec',
    'Pozostałe'
]);

export const flatsBuildingTypeOptions = toSameValueOptions([
    'Blok',
    'Kamienica',
    'Apartamentowiec',
    'Pozostałe'
]);

export const plotsBuildingTypeOptions = toSameValueOptions([
    'Budowlana',
    'Rolna',
    'Leśna',
    'Pozostałe'
]);

export const otherBuildingTypeOptions = toSameValueOptions([
    'Garaże',
    'Hale',
    'Pozostałe'
]);

export const dateOptionsLabels = [
    { value: 1, label: 'ostatni dzień' },
    { value: 2, label: 'ostatnie 2 dni' },
    { value: 3, label: 'ostatnie 3 dni' },
    { value: 4, label: 'ostatnie 4 dni' },
    { value: 5, label: 'ostatnie 5 dni' },
    { value: 6, label: 'ostatnie 6 dni' },
    { value: 7, label: 'ostatnie 7 dni' },
    { value: 14, label: 'ostatnie 14 dni' },
    { value: 30, label: 'ostatnie 30 dni' },
    { value: 60, label: 'ostatnie 60 dni' },
    { value: 180, label: 'ostatnie 180 dni' },
    { value: 360, label: 'ostatnie 360 dni' },
    { value: -1, label: 'Wszystkie' }
];

export const tenderSortOptions = [
    { label: 'po dacie malejąco', value: TenderSearchingRequestSortTypeEnum.DATE_DESC },
    { label: 'po dacie rosnąco', value: TenderSearchingRequestSortTypeEnum.DATE_ASC }
];

export const auctionSortOptions = [
    { label: 'po dacie malejąco', value: AuctionSearchingRequestSortTypeEnum.DATE_DESC },
    { label: 'po dacie rosnąco', value: AuctionSearchingRequestSortTypeEnum.DATE_ASC }
];

export const cooperativeSortOptions = [
    { label: 'po dacie malejąco', value: TenderSearchingRequestSortTypeEnum.DATE_DESC },
    { label: 'po dacie rosnąco', value: TenderSearchingRequestSortTypeEnum.DATE_ASC }
];

export const sortOptions = [
    { label: 'po dacie malejąco', value: SortType.DATE_DESC },
    { label: 'po dacie rosnąco', value: SortType.DATE_ASC },
    { label: 'po czynszu rosnąco', value: SortType.PRICE_ASC },
    { label: 'po czynszu malejąco', value: SortType.PRICE_DESC },
    // { label: 'po czynszu za m2 rosnąco', value: SortType.PRICE_PER_SQUARE_METER_ASC },
    // { label: 'po czynszu za m2 malejąco', value: SortType.PRICE_PER_SQUARE_METER_DESC },
    { label: 'od największej różnicy dat modyfikacji i dodania', value: SortType.MODIFICATION_DATE }
];

export const saleSortOptions = [
    { label: 'po dacie malejąco', value: SaleSortType.DATE_DESC },
    { label: 'po dacie rosnąco', value: SaleSortType.DATE_ASC },
    { label: 'po cenie rosnąco', value: SaleSortType.PRICE_ASC },
    { label: 'po cenie malejąco', value: SaleSortType.PRICE_DESC },
    { label: 'po cenie za m2 rosnąco', value: SaleSortType.PRICE_PER_SQUARE_METER_ASC },
    { label: 'po cenie za m2 malejąco', value: SaleSortType.PRICE_PER_SQUARE_METER_DESC },
    { label: 'od największej różnicy dat modyfikacji i dodania', value: SaleSortType.MODIFICATION_DATE },
    { label: 'po najlepiej ocenianych ogłoszeniach rosnąco', value: SaleSortType.SCORE_ASC },
    { label: 'po najlepiej ocenianych ogłoszeniach malejąco', value: SaleSortType.SCORE_DESC }
];

export const bailiffSortOptions = [
    { label: 'po dacie malejąco', value: BailiffNoticeSearchingRequestSortTypeEnum.DATE_DESC },
    { label: 'po dacie rosnąco', value: BailiffNoticeSearchingRequestSortTypeEnum.DATE_ASC }
];

export const rentPropertyTypeOptions = [
    { label: 'Mieszkanie', value: Module.RENT_FLAT },
    { label: 'Dom', value: Module.RENT_HOUSE },
    { label: 'Działka', value: Module.RENT_PLOT },
    { label: 'Inne', value: Module.RENT_OTHER }
];

export const salePropertyTypeOptions = [
    { label: 'Mieszkanie', value: SaleModuleType.SALE_FLAT },
    { label: 'Dom', value: SaleModuleType.SALE_HOUSE },
    { label: 'Działka', value: SaleModuleType.SALE_PLOT },
    { label: 'Inne', value: SaleModuleType.SALE_OTHER }
];

export const analysiSalePropertyTypeOptions = [
    { label: 'Mieszkanie', value: SaleModule.SALE_FLAT },
    { label: 'Dom', value: SaleModule.SALE_HOUSE },
    { label: 'Działka', value: SaleModule.SALE_PLOT },
    { label: 'Inne', value: SaleModule.SALE_OTHER }
];
export const v2offerTypeOptions = [
    { label: 'Odstąpię deal', value: V2OfferType.DEAL },
    { label: 'Szukam inwestora', value: V2OfferType.INVESTOR },
    { label: 'Mam kapitał', value: V2OfferType.CAPITAL }
];

export const usersBuildingOptions = [
    { label: 'Dom', value: InvestorBoxDtoBuildingTypeEnum.Dom },
    { label: 'Działka', value: InvestorBoxDtoBuildingTypeEnum.Dzialka },
    { label: 'Inwestycja deweloperska', value: InvestorBoxDtoBuildingTypeEnum.InwestycjaDeweloperska },
    { label: 'Lokal', value: InvestorBoxDtoBuildingTypeEnum.Lokal },
    { label: 'Kamienica', value: InvestorBoxDtoBuildingTypeEnum.Kamienica },
    { label: 'Mieszkanie', value: InvestorBoxDtoBuildingTypeEnum.Mieszkanie }
];

export const featureOptions = [
    { label: 'Balkon', value: FilterDealDtoFeaturesEnum.Balkon },
    { label: 'Ogródek', value: FilterDealDtoFeaturesEnum.Ogrodek },
    { label: 'Piwnica', value: FilterDealDtoFeaturesEnum.Piwnica },
    { label: 'Taras', value: FilterDealDtoFeaturesEnum.Taras },
    { label: 'Winda', value: FilterDealDtoFeaturesEnum.Winda },
    { label: 'Klimatyzacja', value: FilterDealDtoFeaturesEnum.Klimatyzacja },
    { label: 'Dwupoziomowe', value: FilterDealDtoFeaturesEnum.Dwupoziomowe },
    { label: 'Oddzielna kuchnia', value: FilterDealDtoFeaturesEnum.OddzielnaKuchnia },
    { label: 'Pomieszczenie użytkowe', value: FilterDealDtoFeaturesEnum.PomUzytkowe },
    { label: 'Strych', value: FilterDealDtoFeaturesEnum.Strych }
];

export const propertyConditionOptions = [
    { label: 'Do remontu', value: InvestorBoxDtoPropertyConditionEnum.DoRemontu },
    { label: 'Do lekkich poprawek', value: InvestorBoxDtoPropertyConditionEnum.DoLekkichPoprawek },
    { label: 'Surowy', value: InvestorBoxDtoPropertyConditionEnum.Surowy },
    { label: 'Nie dotyczy', value: InvestorBoxDtoPropertyConditionEnum.NieDotyczy }
];

export const legalStatusDealOptions = [
    { label: 'Bez KW', value: DealBaseDtoLegalStatusEnum.BezKW },
    { label: 'Odrębna własność', value: DealBaseDtoLegalStatusEnum.OdrebnaWlasnosc },
    { label: 'Spółdzielcze własnościowe z KW', value: DealBaseDtoLegalStatusEnum.SpoldzielczeWlasnoscioweZKW },
    { label: 'Udział', value: DealBaseDtoLegalStatusEnum.Udzial }
];

export const legalStatusInvestorOptions = [
    { label: 'Własność', value: InvestorBaseDtoLegalStatusEnum.Wlasnosc },
    { label: 'Współwłasność', value: InvestorBaseDtoLegalStatusEnum.Wspolwlasnosc },
    { label: 'Udział', value: InvestorBaseDtoLegalStatusEnum.Udzial }
];

export const formOfProfitOptions = [
    { label: 'Hybryda', value: CapitalBaseDtoFormOfProfitEnum.Hybryda },
    { label: 'Procentowa', value: CapitalBaseDtoFormOfProfitEnum.Procentowa },
    { label: 'Udział w zysku', value: CapitalBaseDtoFormOfProfitEnum.UdzialWZysku }
];

export const investmentSecurityCapitalOptions = [
    { label: 'Hipoteka na innej nieruchomości', value: CapitalBaseDtoInvestmentSecurityEnum.HipotekaNaInnejNieruchomosci },
    { label: 'Hipoteka na nieruchomości kupowanej', value: CapitalBaseDtoInvestmentSecurityEnum.HipotekaNaNieruchomosciKupowanej },
    { label: 'Umowa pożyczki', value: CapitalBaseDtoInvestmentSecurityEnum.UmowaPozyczki },
    { label: 'Weksel', value: CapitalBaseDtoInvestmentSecurityEnum.Weksel },
    { label: 'Własność nabywanej nieruchomości poręczenie prywatne', value: CapitalBaseDtoInvestmentSecurityEnum.WlasnoscNabywanejNieruchomosciPoreczeniePrywatne }
];

export const investmentSecurityInvestorOptions = [
    { label: 'Hipoteka na innej nieruchomości', value: InvestorBaseDtoInvestmentSecurityEnum.HipotekaNaInnejNieruchomosci },
    { label: 'Hipoteka na nieruchomości kupowanej', value: InvestorBaseDtoInvestmentSecurityEnum.HipotekaNaNieruchomosciKupowanej },
    { label: 'Umowa pożyczki', value: InvestorBaseDtoInvestmentSecurityEnum.UmowaPozyczki },
    { label: 'Weksel', value: InvestorBaseDtoInvestmentSecurityEnum.Weksel },
    { label: 'Poręczenie prywatne', value: InvestorBaseDtoInvestmentSecurityEnum.PoreczeniePrywatne }
];

export const formOfInvestmentCapitalOptions = [
    { label: 'Umowa pożyczki', value: CapitalBaseDtoFormOfInvestmentEnum.UmowaPozyczki },
    { label: 'Umowa inwestycyjna', value: CapitalBaseDtoFormOfInvestmentEnum.UmowaInwestycyjna },
    { label: 'Umowa o współpracy', value: CapitalBaseDtoFormOfInvestmentEnum.UmowaOWspolpracy }
];

export const extraSmsToBuyOptions = [
    { label: '100', value: '100' },
    { label: '200', value: '200' },
    { label: '300', value: '300' },
    { label: '400', value: '400' },
    { label: '500', value: '500' },
    { label: '600', value: '600' },
    { label: '700', value: '700' },
    { label: '800', value: '800' }
];
