import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'store/User/Context.tsx';
import { isTouchDevice } from 'utils/checkIsTouchDevice';
import RentContext from 'utils/context/RentContext';
import { formatFilterPrice } from 'utils/formatFilterPrice';
import { getSubModuleByType } from 'utils/formatters/getSubModuleByType';
import { getStringFromDate } from 'utils/getStringFromDate';
import { getRentFiltersFromState, getValueFromState } from 'utils/state-managment/rent/rentFilter';
import { RentOfferDuplicatesResponse, RentOfferResponseContent } from 'utils/state-managment/rent/rentOffer';
import { MultipleFilterType, ObjectAndSingleValueChangeType } from 'utils/types/InputTypes';
import { UserReactions } from 'utils/types/OfferData';
import { ReactionType } from 'utils/types/ReactionType';
import { RentFiltersStateType } from 'utils/types/Rent';
import { Module } from 'utils/types/RentModels';

import OfferActions from 'components/common/Card/common/OfferActions/OfferActions';
import { InteractiveElements } from 'components/common/Card/common/V2InteractiveIconsWrapper/V2InteractiveIconsWrapper';
import { IconEnum } from 'components/common/Icon';
import { SliderImages } from 'components/common/Slider/Slider';
import { V2DuplicatesTabProps } from 'components/common/Tabs/tabs/V2DuplicatesTab';
import { TabType } from 'components/common/Tabs/V2Tabs';
import { convertToThumbnailPath, createImagesForSlider } from 'components/functions/imagesFunctions';
import { rentOfferListFunctions } from 'components/functions/offerListFunctions/rentOfferListFunctions/rentOfferListFunctions';
import { generateOfferActionsElements } from 'components/functions/shared/generateOfferActionsElements';
import V2OfferList, { RenderList } from 'components/offerList/V2OfferList';

const RentOffers = () => {
    const {
        rentFiltersState,
        handleChange,
        rentOfferState,
        loadDuplicates,
        loadRentOffers,
        loadOfferDetailsAsync,
        deleteNote,
        updateNote,
        loadNotesAsync,
        reactionUpdate,
        updateOpinion,
        loadOpinionsAsync,
        deleteOpinion
    } = useContext(RentContext);
    const { user } = useUser();
    const [moreButtonActiveId, setMoreButtonActiveId] = useState<string | null>(null);
    const navigate = useNavigate();
    const [renderList, setRenderList] = useState<RenderList[]>([]);

    const handleChangePage = useCallback((currentPage: number, rows = 25) => {
        loadRentOffers({ page: currentPage, rows });
    }, [rentFiltersState]);

    const handleActiveFiltersChange = () => {
        loadRentOffers({ page: 1, rows: 25 });
    };

    const handleOlderAdverts = () => {
        const date = rentFiltersState.offerAdded.from ? new Date(new Date(rentFiltersState.offerAdded.from).setDate(new Date(rentFiltersState.offerAdded.from).getDate() - 6)) : null;
        const dateString = date && getStringFromDate(date.toDateString());

        loadRentOffers({
            requestData: {
                ...getValueFromState(rentFiltersState),
                ...{ offerAddedFrom: !rentFiltersState.daysAmount ? dateString ?? undefined : undefined },
                ...{ daysAmount: rentFiltersState.daysAmount ? rentFiltersState.daysAmount + 6 : undefined }
            },
            page: rentOfferState.rentOfferList?.pageable?.pageNumber,
            rows: rentOfferState.rentOfferList && rentOfferState.rentOfferList.size ? rentOfferState.rentOfferList.size : 25
        });
    };

    const handleSaveOrUpdateOpinion = async (offer: RentOfferResponseContent, rating: number, description: string) => {
        const opinionId = offer.myOpinion?.id || undefined;
        updateOpinion(offer.module, description, rating, offer.advertisementId, opinionId);
    };

    const onMessageClick = (offer: RentOfferResponseContent) => {
        toggleContainerDetails(false, offer);
    };

    const handleDelete = (noteId: number, id: number, type: Module) => {
        deleteNote(id, type, noteId);
    };

    const handleSaveNote = async (value: string, advertId: number, type: Module, id?: number) => {
        updateNote(type, value, advertId, id || 0);
    };

    const toggleContainerDetails = (detailsOpen: boolean, offer: RentOfferResponseContent) => {
        if (!detailsOpen) {
            offer.reaction && !offer.reaction.viewedStatus
                ? reactionUpdate(offer.advertisementId, offer.location!, {
                    ...offer.reaction,
                    openedStatus: true,
                    viewedStatus: true
                } as ReactionType, offer.module)
                : null;
            loadOfferDetailsAsync(offer.advertisementId, offer.module);
        }
    };

    const handleChangeReaction = (name: keyof ReactionType, value: boolean, offer: RentOfferResponseContent) => {
        const userReaction = Object.assign({}, offer.reaction);

        if (name === 'likeStatus' && userReaction.unlikeStatus && value) {
            userReaction.unlikeStatus = false;
        } else if (name === 'unlikeStatus' && userReaction.likeStatus && value) {
            userReaction.likeStatus = false;
        }

        (userReaction[name] as boolean) = value;
        const city = offer.location;

        reactionUpdate(offer.advertisementId, city, userReaction as UserReactions, offer.module);
    };

    const handleDeleteFilterValue = (filterName: string, value?: string | number | null | string[], index?: number) => {
        const newState = { ...rentFiltersState };

        if (index !== undefined && Array.isArray(newState[filterName as keyof RentFiltersStateType])) {
            const array = newState[filterName as keyof RentFiltersStateType] as (string | number | null)[];
            array.splice(index, 1);
            (newState[filterName as keyof RentFiltersStateType] as (string | number | null)[]) = array;
        } else if (
            typeof newState[filterName as keyof RentFiltersStateType] === 'object' &&
            newState[filterName as keyof RentFiltersStateType] !== null &&
            'from' in (newState[filterName as keyof RentFiltersStateType] as object) &&
            'to' in (newState[filterName as keyof RentFiltersStateType] as object)
        ) {
            const rangeFilter = newState[filterName as keyof RentFiltersStateType] as MultipleFilterType;

            if (value === rangeFilter.from) {
                rangeFilter.from = null;
            } else if (value === rangeFilter.to) {
                rangeFilter.to = null;
            }
        } else {
            (newState[filterName as keyof RentFiltersStateType] as string | number | null | undefined) = null;
        }
        handleChange(filterName, newState[filterName as keyof RentFiltersStateType] as ObjectAndSingleValueChangeType);
    };

    const generateInteractiveElements = (offer: RentOfferResponseContent): InteractiveElements[] => {
        const offerLink = `${window.location.origin}/rent/${offer.encryptedId}`;
        const isMoreButtonActive = moreButtonActiveId === offer.advertisementId.toString();
        const submodule = getSubModuleByType(offer.module);
        const offerActionsElements = generateOfferActionsElements(offerLink, offer.link);
        user.isInOrganization
            ? offerActionsElements.push({
                children:
                <>
                    <p>Kopiuj ogłoszenie</p>
                </>,
                onClick: async (e: MouseEvent) => {
                    e.stopPropagation();

                    navigate(`/rent/${submodule}/${offer.advertisementId}/copy`);
                }
            })
            : null;

        return [
            {
                tooltipText: 'Wyświetlono',
                icon: IconEnum.EYE,
                active: offer.reaction?.viewedStatus,
                disabled: offer.reaction?.viewedStatus === undefined,
                onClick: () => handleChangeReaction('viewedStatus', !offer.reaction?.viewedStatus, offer)
            },
            {
                tooltipText: 'Lubię to',
                icon: IconEnum.LIKE,
                active: offer.reaction?.likeStatus,
                onClick: () => handleChangeReaction('likeStatus', !offer.reaction?.likeStatus, offer)
            },
            {
                tooltipText: 'Nie lubię tego',
                icon: IconEnum.DISLIKE,
                active: offer.reaction?.unlikeStatus,
                disabled: offer.reaction?.unlikeStatus === undefined,
                onClick: () => handleChangeReaction('unlikeStatus', !offer.reaction?.unlikeStatus, offer)
            },
            {
                tooltipText: 'Notatki',
                icon: IconEnum.MESSAGE,
                active: offer.numberOfNotes ? offer.numberOfNotes > 0 : false,
                counter: offer.numberOfNotes ? offer.numberOfNotes || 1 : undefined,
                openTabOnClick: TabType.NOTES,
                onClick: () => onMessageClick(offer)
            },
            {
                tooltipText: 'Ogłoszenie nieaktualne',
                icon: IconEnum.BLOCKED,
                active: offer.reaction?.hideStatus,
                disabled: offer.reaction?.hideStatus === undefined,
                onClick: () => handleChangeReaction('hideStatus', !offer.reaction?.hideStatus, offer)
            },
            {
                tooltipText: 'Przeprowadzona rozmowa',
                icon: IconEnum.PHONE,
                active: offer.reaction?.phoneStatus,
                disabled: offer.reaction?.phoneStatus === undefined,
                onClick: () => handleChangeReaction('phoneStatus', !offer.reaction?.phoneStatus, offer)
            },
            {
                tooltipText: 'Umówione spotkanie',
                icon: IconEnum.PEOPLES_3,
                active: offer.reaction?.arrangedStatus,
                disabled: offer.reaction?.arrangedStatus === undefined,
                onClick: () => handleChangeReaction('arrangedStatus', !offer.reaction?.arrangedStatus, offer)
            },
            ...offer.phoneNumber
                ? [
                    {
                        icon: IconEnum.PHONE,
                        isLink: true,
                        active: false,
                        isPhoneBtn: false,
                        text: <a href={`tel:${offer.phoneNumber}`}>{offer.phoneNumber}</a>
                    }
                ]
                : [],
            ...offer.photos && offer.photos.length > 0
                ? [
                    {
                        icon: IconEnum.PHOTOS,
                        isModalOpen: true,
                        isLink: true,
                        text: <p>Zobacz zdjęcia</p>,
                        isPhotoBtn: true,
                        visibleDuringModal: true,
                        sliderElements: {
                            photos: createImagesForSlider(offer.photos, offer.module) as SliderImages[],
                            title: offer.title
                        }
                    }
                ]
                : [],
            {
                icon: 'squares' as IconEnum,
                tooltipText: isTouchDevice() ? undefined : 'Więcej',
                onClick: () => isMoreButtonActive ? setMoreButtonActiveId(null) : setMoreButtonActiveId(offer.advertisementId.toString()),
                additionalJSXSibling: isMoreButtonActive ? <OfferActions onClickOutside={() => setMoreButtonActiveId(null)} elements={offerActionsElements} /> : undefined
            }
        ];
    };

    const handleDuplicates = async (offer: RentOfferResponseContent) => {
        loadDuplicates(offer.module, offer.advertisementId);
    };

    const handleNotes = async (offer: RentOfferResponseContent) => {
        loadNotesAsync(offer.advertisementId.toString(), offer.module);
    };

    const generateCardData = (offer: RentOfferResponseContent) => {
        const subMainValues: React.ReactNode[] = [];

        if (offer.pricePerSquareMeter) {
            subMainValues.push(
                <><b>{offer.pricePerSquareMeter.toString()}</b> zł / m<sup>2</sup></>
            );
        }

        if (offer.depositPrice) {
            subMainValues.push(
                <><b>Kaucja: </b>{offer.depositPrice} zł</>
            );
        }

        return {
            preTitle: offer.location,
            lowestPriceLabel: offer.duplicateWithLowestPrice,
            privateOfferLabel: offer.duplicateWithPrivateOffer,
            oldPrice: offer.priceBeforeModification?.toString(),
            date: offer.dateAdded,
            title: offer.title,
            infoElements: rentOfferListFunctions.generateInfoElements(offer),
            interactiveElements: generateInteractiveElements(offer),
            modifications: {
                duplicationsNumber: offer.numberOfDuplicates ?? 0,
                modificationsNumber: offer.numberOfModifications ?? 0,
                boostsNumber: offer.numberOfRaises ?? 0
            },
            note: offer.note,
            modificationDate: offer.lastUpdated,
            thumbnailPath: offer.photos && offer.photos.length > 0 ? convertToThumbnailPath(offer.photos[0], offer.module) : './defaultImg.png',
            ratingScore: offer.score ?? 0,
            scorePrecision: offer.scorePrecision ?? 0,
            additionalInfoBadge: offer.offerFrom,
            images: createImagesForSlider(offer.photos || [], offer.module),
            mainValue: offer.price ? `${formatFilterPrice(offer.price)} zł` : '- zł',
            subMainValues,
            link: {
                url: offer.link,
                name: offer.portal
            },
            ratingContainer: {
                opinionsNumber: offer.numberOfCommunityOpinions,
                opinionsRating: offer.communityScore,
                commentsCount: offer.numberOfCommunityComments,
                title: offer.title,
                isMyOpinion: !!offer.myOpinion,
                userRatingValue: offer.myOpinion?.numberOfStars,
                userRatingComment: offer.myOpinion?.content || undefined,
                handleGetComments: () => handleNotes(offer),
                handleSendRating: (rating: number, description: string) => handleSaveOrUpdateOpinion(offer, rating, description)
            }
        };
    };

    const generateDuplicateCardData = (offer: RentOfferDuplicatesResponse) => {
        return {
            preTitle: offer.location,
            oldPrice: formatFilterPrice(offer.priceBeforeModification)?.toString(),
            date: offer.dateAdded,
            title: offer.title,
            infoElements: rentOfferListFunctions.generateInfoElements(offer as RentOfferResponseContent),
            interactiveElements: generateInteractiveElements(offer as RentOfferResponseContent),
            modifications: {
                modificationsNumber: offer.numberOfModifications ?? 0,
                boostsNumber: offer.numberOfRaises ?? 0
            },
            note: offer.note,
            modificationDate: offer.lastUpdated,
            thumbnailPath: offer.photos ? offer.photos[0] : undefined,
            ratingScore: offer.score ?? 0,
            additionalInfoBadge: offer.offerFrom,
            images: createImagesForSlider(offer.photos || [], offer.module!),
            mainValue: offer.price ? `${formatFilterPrice(offer.price)} zł` : '- zł',
            link: {
                url: offer.link,
                name: offer.portal
            }
        };
    };

    useEffect(() => {
        const offersList = rentOfferState.rentOfferList.content
            ? rentOfferState.rentOfferList?.content.map((offer): RenderList => {
                const isMoreButtonActive = moreButtonActiveId === `${offer.advertisementId}-detail`;
                const offerLink = `${window.location.origin}/rent/${offer.encryptedId}`;
                const offerActionsElements = generateOfferActionsElements(offerLink, offer.link);
                const interactiveElements = generateInteractiveElements(offer);
                interactiveElements.splice(-1);
                const detailsTabContent = rentOfferListFunctions.generateOfferDetailsTabContent(offer);

                const duplicatesTabContent: V2DuplicatesTabProps = {
                    duplicates: offer.duplicates?.map((duplicate) => {
                        return {
                            id: duplicate.advertisementId,
                            bigImage: true,
                            cardData: generateDuplicateCardData({ ...duplicate, module: offer.module }),
                            onClick: () => {},
                            openOfferTab: () => {}
                        };
                    })
                };

                return {
                    id: offer.advertisementId,
                    onSingleOfferClick: (_: React.MouseEvent, detailsOpen: boolean) => toggleContainerDetails(detailsOpen, offer),
                    baseCardContent: {
                        bigImage: true,
                        cardData: generateCardData(offer),
                        openOfferTab: () => {} // openoffertab
                    },
                    detailsCardData: {
                        interactiveElements: [
                            ...interactiveElements, {
                                icon: 'squares' as IconEnum,
                                tooltipText: isTouchDevice() ? undefined : 'Więcej',
                                onClick: () => isMoreButtonActive ? setMoreButtonActiveId(null) : setMoreButtonActiveId(`${offer.advertisementId}-detail`),
                                additionalJSXSibling: isMoreButtonActive ? <OfferActions onClickOutside={() => setMoreButtonActiveId(null)} isInModal elements={offerActionsElements} /> : undefined
                            }
                        ],
                        modificationDate: offer.lastUpdated,
                        additionalInfoBadge: offer.offerFrom,
                        thumbnailPath: offer.photos && offer.photos.length > 0 ? convertToThumbnailPath(offer.photos[0], offer.module) : './defaultImg.png',
                        date: offer.dateAdded,
                        onToggleContainerDetails: () => {},
                        tabsContent: {
                            toggleContainerDetails: () => {},
                            interactiveElements,
                            detailsTabContent,
                            duplicatesTabContent,
                            modificationTabContent: {
                                modifications: offer.detailedContent?.modifications || []
                            },
                            notesTabContent: {
                                notes: rentOfferState.notes,
                                handleDelete: (noteId: string) => handleDelete(Number(noteId), offer.advertisementId, offer.module),
                                handleSaveNote: (value: string, id?: string) => handleSaveNote(value, offer.advertisementId, offer.module, Number(id))
                            },
                            opinionTabContent: {
                                handleSaveOpinion: (rating: number, content: string) => handleSaveOrUpdateOpinion(offer, rating, content),
                                comments: rentOfferState.scoreAndOpinions?.opinions?.map((opinion) => ({
                                    id: opinion.opinionId,
                                    date: opinion.dateAdded,
                                    content: opinion.content || '',
                                    rating: opinion.numberOfStars,
                                    isUserComment: opinion.loggedUserOpinion
                                })),
                                applicationScore: offer.score,
                                applicationScorePrecision: offer.scorePrecision,
                                commentsCount: offer.numberOfCommunityComments,
                                opinionsNumber: offer.numberOfCommunityOpinions,
                                opinionsRating: rentOfferState.scoreAndOpinions?.communityScore || 0,
                                handleDeleteOpinion: (id: number) => deleteOpinion(offer.advertisementId, offer.module, id)
                            },
                            numberOfModifications: offer.numberOfModifications || 0,
                            numberOfDuplicates: offer.numberOfDuplicates || 0,
                            numberOfNotes: offer.numberOfNotes || 0,
                            handleLoadDuplicates: () => handleDuplicates(offer),
                            handleLoadNotes: () => handleNotes(offer),
                            handleLoadComments: () => loadOpinionsAsync(offer.advertisementId, offer.module)
                        }
                    }
                };
            })
            : [];

        setRenderList(offersList);
    }, [
        rentOfferState.rentOfferList?.content,
        rentOfferState.notes,
        rentOfferState.scoreAndOpinions?.opinions,
        rentOfferState.scoreAndOpinions,
        moreButtonActiveId
    ]);

    return (
        <div>
            <V2OfferList
                totalPages={rentOfferState.rentOfferList.totalPages}
                handleChangePage={handleChangePage}
                pageNumber={rentOfferState.rentOfferList?.pageable?.pageNumber || 1}
                lastPage={rentOfferState.rentOfferList?.last}
                handleOlderAdverts={handleOlderAdverts}
                handleActiveFiltersChange={handleActiveFiltersChange}
                showList={!rentOfferState.rentOfferList.empty && rentOfferState.rentOfferList.content?.length > 0}
                activeFiltersState={rentFiltersState}
                filters={getRentFiltersFromState(rentFiltersState)}
                showNoItemsText={rentOfferState.rentOfferList && rentOfferState.rentOfferList.empty && rentOfferState.rentOfferList.content.length === 0 && rentOfferState.rentOfferList.afterSearch}
                renderList={renderList}
                isLoadMoreButton
                onDeleteFilter={handleDeleteFilterValue}
            />
        </div>
    );
};

export default RentOffers;
