// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck TODO: Needs refactor
import React, { useState } from 'react';

import {
    AnalysisElements,
    HeaderStyle,
    RadioContainer,
    SectionBox,
    Table,
    TableContainer,
    Top
} from 'components/analysis/AnalysisSaleList';

interface TimeRangeTableProps {
    analysis: AnalysisElements;
    isPrintMode?: boolean;
}

export const TimeRangeTable = ({ analysis, isPrintMode = false }: TimeRangeTableProps) => {
    const [hideTimeCols, setHideTimeCols] = useState(true);

    return (
        <SectionBox data-pdf-section>
            <HeaderStyle>
                <Top>
                    <h2>Dane według przedziałów powierzchni</h2>
                    {!isPrintMode
                        ? <RadioContainer>
                            <input onChange={(e) => setHideTimeCols(!e.target.checked)}
                                checked={!hideTimeCols}
                                type="checkbox"
                                id="time"/>
                            <label htmlFor="time">Więcej danych</label>
                        </RadioContainer>
                        : null}
                </Top>
            </HeaderStyle>
            <TableContainer>
                <Table id="time-table">
                    <thead>
                        <tr>
                            <th>Okres</th>
                            <th colSpan={2}>Liczba Ofert</th>
                            <th colSpan={2}>Średnia powierzchnia m<sup>2</sup></th>
                            {!hideTimeCols && <th colSpan={2}>Min powierzchnia m<sup>2</sup></th>}
                            {!hideTimeCols && <th colSpan={2}>Max powierzchnia m<sup>2</sup></th>}
                            {!hideTimeCols && <th colSpan={2}>Odchylenie standardowe powierzchni</th>}
                            <th colSpan={2}>Średnia cena m<sup>2</sup></th>
                            {!hideTimeCols && <th colSpan={2}>Min cena m<sup>2</sup></th>}
                            {!hideTimeCols && <th colSpan={2}>Max cena m<sup>2</sup></th>}
                            {!hideTimeCols && <th colSpan={2}>Mediana ceny m<sup>2</sup></th>}
                            {!hideTimeCols && <th colSpan={2}>Odchylenie standardowe ceny m<sup>2</sup></th>}
                            <th colSpan={2}>Średnia cena całkowita</th>
                            {!hideTimeCols && <th colSpan={2}>Min cena całkowita</th>}
                            {!hideTimeCols && <th colSpan={2}>Max cena całkowita</th>}
                            {!hideTimeCols && <th colSpan={2}>Mediana ceny całkowitej</th>}
                            {!hideTimeCols && <th colSpan={2}>Odchylenie standardowe ceny całkowitej</th>}
                        </tr>
                    </thead>

                    <tbody>
                        {analysis.timeAnalysisList.map((item) => {
                            if (item.growthList) {
                                item.growthList.forEach((element) => {
                                    element.first = element.first > 10000 ? '∞' : element.first;
                                });
                            }

                            return (
                                <tr key={item.timeRangeLabel}>
                                    <td>{item.timeRangeLabel}</td>
                                    <td className="border-td-none">
                                        {item.transactionAmount.toLocaleString('fr', { useGrouping: true })}
                                    </td>
                                    <td className="procent">
                                        <span
                                            className={item.growthList && (item.growthList[0].second ? 'growth-green' : 'growth-red')}>{item.growthList ? `${item.growthList[0].first}%` : ''} </span>
                                    </td>

                                    <td className="border-td-none">
                                        {item.avgArea.toLocaleString('fr', { useGrouping: true })}
                                    </td>
                                    <td className="procent">
                                        <span
                                            className={item.growthList && (item.growthList[1].second ? 'growth-green' : 'growth-red')}>{item.growthList ? `${item.growthList[1].first}%` : ''} </span>
                                    </td>

                                    {!hideTimeCols &&
                                    <>
                                        <td className="border-td-none">{item.minArea.toLocaleString('fr', { useGrouping: true })}</td>
                                        <td className="procent">
                                            <span
                                                className={item.growthList && (item.growthList[2].second ? 'growth-green' : 'growth-red')}>{item.growthList ? `${item.growthList[2].first}%` : ''}
                                            </span>
                                        </td>
                                    </>}
                                    {
                                        !hideTimeCols &&
                                    <>
                                        <td className="border-td-none">{item.maxArea.toLocaleString('fr', { useGrouping: true })}</td>
                                        <td className="procent">
                                            <span
                                                className={item.growthList && (item.growthList[3].second ? 'growth-green' : 'growth-red')}>{item.growthList ? `${item.growthList[3].first}%` : ''} </span>
                                        </td>
                                    </>
                                    }
                                    {
                                        !hideTimeCols &&
                                    <>
                                        <td className="border-td-none">{item.sdArea.toLocaleString('fr', { useGrouping: true })}</td>
                                        <td className="procent">
                                            <span
                                                className={item.growthList && (item.growthList[4].second ? 'growth-green' : 'growth-red')}>{item.growthList ? `${item.growthList[4].first}%` : ''}
                                            </span>
                                        </td>
                                    </>
                                    }
                                    <td className="border-td-none">{item.avgPricePerSquareMeter.toLocaleString('fr', { useGrouping: true })}</td>
                                    <td className="procent">
                                        <span
                                            className={item.growthList && (item.growthList[5].second ? 'growth-green' : 'growth-red')}>{item.growthList ? `${item.growthList[5].first}%` : ''} </span>
                                    </td>
                                    {
                                        !hideTimeCols &&
                                    <>
                                        <td className="border-td-none">{item.minPricePerSquareMeter.toLocaleString('fr', { useGrouping: true })}</td>
                                        <td className="procent">
                                            <span
                                                className={item.growthList && (item.growthList[6].second ? 'growth-green' : 'growth-red')}>{item.growthList ? `${item.growthList[6].first}%` : ''} </span>
                                        </td>
                                    </>
                                    }
                                    {
                                        !hideTimeCols &&
                                    <>
                                        <td className="border-td-none">{item.maxPricePerSquareMeter.toLocaleString('fr', { useGrouping: true })}</td>
                                        <td className="procent">
                                            <span
                                                className={item.growthList && (item.growthList[7].second ? 'growth-green' : 'growth-red')}>{item.growthList ? `${item.growthList[7].first}%` : ''} </span>
                                        </td>
                                    </>
                                    }
                                    {
                                        !hideTimeCols &&
                                    <>
                                        <td className="border-td-none">{item.medianPricePerSquareMeter.toLocaleString('fr', { useGrouping: true })}</td>
                                        <td className="procent">
                                            <span
                                                className={item.growthList && (item.growthList[8].second ? 'growth-green' : 'growth-red')}>{item.growthList ? `${item.growthList[8].first}%` : ''} </span>
                                        </td>
                                    </>
                                    }
                                    {
                                        !hideTimeCols &&
                                    <>
                                        <td className="border-td-none">{item.sdPricePerSquareMeter.toLocaleString('fr', { useGrouping: true })}</td>
                                        <td className="procent">
                                            <span
                                                className={item.growthList && (item.growthList[9].second ? 'growth-green' : 'growth-red')}>{item.growthList ? `${item.growthList[9].first}%` : ''} </span>
                                        </td>
                                    </>
                                    }
                                    <td className="border-td-none">{item.avgPrice.toLocaleString('fr', { useGrouping: true })}</td>
                                    <td className="procent">
                                        <span
                                            className={item.growthList && (item.growthList[10].second ? 'growth-green' : 'growth-red')}>{item.growthList ? `${item.growthList[10].first}%` : ''} </span>
                                    </td>
                                    {
                                        !hideTimeCols &&
                                    <>
                                        <td className="border-td-none">{item.minPrice.toLocaleString('fr', { useGrouping: true })}</td>
                                        <td className="procent">
                                            <span
                                                className={item.growthList && (item.growthList[11].second ? 'growth-green' : 'growth-red')}>{item.growthList ? `${item.growthList[11].first}%` : ''} </span>
                                        </td>
                                    </>
                                    }
                                    {
                                        !hideTimeCols &&
                                    <>
                                        <td className="border-td-none">{item.maxPrice.toLocaleString('fr', { useGrouping: true })}</td>
                                        <td className="procent">
                                            <span
                                                className={item.growthList && (item.growthList[12].second ? 'growth-green' : 'growth-red')}>{item.growthList ? `${item.growthList[12].first}%` : ''}</span>
                                        </td>
                                    </>
                                    }
                                    {
                                        !hideTimeCols &&
                                    <>
                                        <td className="border-td-none">{item.medianPrice.toLocaleString('fr', { useGrouping: true })}</td>
                                        <td className="procent">
                                            <span
                                                className={item.growthList && (item.growthList[13].second ? 'growth-green' : 'growth-red')}>{item.growthList ? `${item.growthList[13].first}%` : ''} </span>
                                        </td>
                                    </>
                                    }
                                    {
                                        !hideTimeCols &&
                                    <>
                                        <td className="border-td-none">{item.sdPrice.toLocaleString('fr', { useGrouping: true })}</td>
                                        <td className="procent">
                                            <span
                                                className={item.growthList && (item.growthList[14].second ? 'growth-green' : 'growth-red')}>{item.growthList ? `${item.growthList[14].first}%` : ''} </span>
                                        </td>
                                    </>
                                    }
                                </tr>);
                        })}
                    </tbody>
                </Table>
            </TableContainer>
        </SectionBox>
    );
};
