import React, { FC, useRef } from 'react';
import { Title } from '@investoro/core';
import styled from 'styled-components';
import { useWindowWidth } from 'utils/useWindowWidth';
import { isNumber } from 'utils/validators/numbers';

import RatingBar from 'components/common/RatingBar/RatingBar';
import { TabType } from 'components/common/Tabs/V2Tabs';

import InfoElements, { InfoElementType } from './InfoElements';
import RatingContainer, { RatingProps } from './RatingContainer';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    grid-area: ${(props) => props.isUpperLabel ? '3 / left' : '2 / left'};
`;

interface InfoContainerProps {
    title: string;
    infoElements?: InfoElementType[];
    isUpperLabel?: boolean;
    ratingContainer?: RatingProps;
    openOfferTab?: (tab: TabType) => void;
    ratingScore?: number | null;
    scorePrecision?: number | null;
}

const InfoContainer: FC<InfoContainerProps> = ({
    title,
    infoElements,
    isUpperLabel,
    ratingContainer,
    openOfferTab,
    ratingScore,
    scorePrecision
}) => {
    const wrapperRef = useRef<HTMLDivElement>();
    const titleRef = useRef<HTMLHeadingElement>();
    const width = useWindowWidth();

    return (
        <Wrapper ref={wrapperRef} isUpperLabel={isUpperLabel} role="list">
            {width > 490 && <Title ref={titleRef}>{title}</Title>}
            {width < 490 && isNumber(ratingScore) && <RatingBar score={ratingScore!} scorePrecision={scorePrecision!} isCardBox/>}
            {width > 490 && infoElements && infoElements.length > 0 &&
                <InfoElements infoElements={infoElements}/>
            }
            {ratingContainer
                ? <RatingContainer ratingContainer={ratingContainer} openOfferTab={openOfferTab} />
                : null}
        </Wrapper>
    );
};

export default InfoContainer;
