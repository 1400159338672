import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import getTitleByType from 'utils/getTitleByType';
import { ModulesType } from 'utils/types/ModulesType';

export const OffersHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
    padding: 0 0 24px;
    border-bottom: 1px solid #030D451A;
    flex-direction: row;
     
    @media (max-width: 1100px) {
        padding: 0 0 10px;
    }

    &.favorites-page {
        margin-right: 45px;
    }

    & > div {
        align-items: flex-start;
        display: flex;
        gap: 40px;

        &:last-child {
            align-self: flex-end;
        }

        a {
            display: block;

            @media (max-width: 1100px) {
                display: block;
                position: absolute;
                left: 40px;
                top: 20px;
                white-space: nowrap;
            }
        }
    }

    @media (max-width: 1100px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
`;

const TitleWrapper = styled.div`
    display: flex;
    align-items: space-between;
    gap: 16px;
    width: 100%;
    
  
    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
    }
`;

export const Title = styled.span`
    height: 50px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 10px 0 0 15px;
    font-weight: var(--font-weight);
    font-size: 20px;
    line-height: 30px;
    color: var(--color-primary);

    span {
        font-size: 20px;
        font-weight: 400;
        color: var(--color-primary);
    }
    
     @media (max-width: 768px) {
        margin: 0;
    }
`;

const AddOfferButton = styled.div`
    cursor: pointer;
    color: var(--color-primary);
    font-weight: var(--font-weight);
    margin: 10px 0 0 15px;
    display: flex;
    align-items: center;
    font-size: 15px;
    height: 50px;
    border: 2px solid var(--color-primary);
    border-radius: 10px;
    padding: 12px;

    @media (max-width: 768px) {
        margin: -25px 0 10px 0;
        height: auto;
        align-self: flex-start;
    }

`;

export const BaseClickableLink = styled.a`
    margin-top: 20px;
    font-weight: var(--font-weight);
    font-size: 14px;
    line-height: 20px;
    color: var(--color-primary);
    cursor: pointer;
    text-decoration-line: underline;

    &:hover {
        color: var(--color-alt);
    }
`;

interface HeaderSectionProperties {
    type: string,
    analysisPrepared?: boolean,
    handlePDFClick?: () => void,
    activeFavoriteOfferType?: ModulesType,
}

const HeaderSection: FC<HeaderSectionProperties> = ({
    type
}) => {
    const { user } = useUser();
    const navigate = useNavigate();

    const getOffersNumber = (type: string) => {
        switch (type) {
        case 'profile':
            return user.profileOfferList.totalElements;
        case 'users':
        default:
            return user.usersOfferList.totalElements;
        }
    };

    return (
        <>
            <OffersHeader className={clsx({ 'favorites-page': type === 'favorites' })}>
                <TitleWrapper>
                    <Title>{getTitleByType(type)}
                        <span>
                            {type === 'users' && user.usersOfferList.content?.length > 0 || type === 'profile' && user.profileOfferList.content?.length > 0 ? `(${getOffersNumber(type)})` : null}
                        </span>
                    </Title>
                    {type === 'users' && <AddOfferButton onClick={() => navigate('/add-offer')}>+ Dodaj własne ogłoszenie</AddOfferButton>}
                </TitleWrapper>
            </OffersHeader>
        </>
    );
};

export default HeaderSection;
