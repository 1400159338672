import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'components/atom/Button';
import { IconEnum } from 'components/common/Icon';

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
    padding: 30px 100px;
    background: var(--color-white);
    border-radius: var(--box-border-radius);
    min-height: 400px;

    h1 {
        --color-primary: ${(props) => props.darkMode ? '#fff' : '#000'};
        line-height: 30px;
        padding: 40px;
        margin-bottom: 40px;
    }
`;

interface CustomNoOffersMessageProps {
    darkMode: boolean;
    addOfferButton?: boolean;
}

export const CustomNoOffersMessage: React.FC<CustomNoOffersMessageProps> = ({ darkMode, addOfferButton }) => {
    const navigate = useNavigate();

    return (
        <InfoWrapper darkMode={darkMode}>
            <h1>Nie masz ogłoszeń w tym module</h1>
            {addOfferButton && <Button onClick={() => navigate('/add-offer')} icon={IconEnum.PLUS}>Dodaj ogłoszenie</Button>}
        </InfoWrapper>
    );
};
