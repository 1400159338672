import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosApiInstance } from 'Store.tsx';
import { brokerApiRequests } from 'utils/api-requests/broker';
import BrokerAdvertContext from 'utils/context/BrokerAdvertContext';
import { generateOptionsFromEnum } from 'utils/generateOptionsFromEnum';
import {
    BrokerAdditionalInformationType,
    BrokerCreateAdvertStateType,
    BrokerHeatingType,
    BrokerMediaType, BrokerNeighborhoodType,
    BrokerOfferType
} from 'utils/types/BrokerAdvert';
import {
    Access,
    FlatAdditionalInformation,
    FlatEquipment,
    FlatHeating,
    FlatMedia, HouseAdditionalInformation,
    HouseFence,
    HouseHeating,
    HouseMedia,
    HouseNeighborhood,
    OtherAdditionalInformation,
    OtherMedia,
    PlotMedia,
    PlotNeighborhood,
    RentPlotEditDetailDtoAdditionalInformationEnum,
    SalePlotEditDetailDtoAdditionalInformationEnum,
    Security
} from 'utils/types/BrokerOfferModels';
import { UploadRequestData } from 'utils/types/File';
import { InputType, LocationChangeType } from 'utils/types/InputTypes';
import { SavedLocationsType } from 'utils/types/Locations';
import { AdvertValidatorType } from 'utils/validators/advertAddingValidator';

import V2FiltersRenderer, {
    ElementsToRender
} from 'components/common/FiltersRenderer/V2FiltersRenderer';
import { IconEnum } from 'components/common/Icon';
import { getVoivodeshipCities } from 'components/functions/locations';
import V2HeaderSection from 'components/V2HeaderSection';

import { getParametersSection } from './sections/getParametersSection';

const BrokerEditOfferPage = () => {
    const { type, subtype, id } = useParams();
    const navigate = useNavigate();

    const { handleChange, brokerAdvertFieldsState, validateField, validateAllFields, handleFillAdvertFields } = useContext(BrokerAdvertContext);
    const [triggerValidation, setTriggerValidation] = useState<boolean>(false);
    const houseMediaOptions = generateOptionsFromEnum(HouseMedia);
    const plotMediaOptions = generateOptionsFromEnum(PlotMedia);
    const otherMediaOptions = generateOptionsFromEnum(OtherMedia);
    const flatMediaOptions = generateOptionsFromEnum(FlatMedia);
    const mediaOptions = brokerAdvertFieldsState.offerType === BrokerOfferType.SALE_HOUSE || brokerAdvertFieldsState.offerType === BrokerOfferType.RENT_HOUSE
        ? houseMediaOptions
        : brokerAdvertFieldsState.offerType === BrokerOfferType.SALE_PLOT || brokerAdvertFieldsState.offerType === BrokerOfferType.RENT_PLOT
            ? plotMediaOptions
            : brokerAdvertFieldsState.offerType === BrokerOfferType.SALE_OTHER || brokerAdvertFieldsState.offerType === BrokerOfferType.RENT_OTHER
                ? otherMediaOptions
                : flatMediaOptions;
    const securityOptions = generateOptionsFromEnum(Security);
    const equipmentOptions = generateOptionsFromEnum(FlatEquipment);
    const houseFenceOptions = generateOptionsFromEnum(HouseFence);
    const houseHeatingOptions = generateOptionsFromEnum(HouseHeating);
    const flatHeatingOptions = generateOptionsFromEnum(FlatHeating);
    const heatingOptions = brokerAdvertFieldsState.offerType === BrokerOfferType.SALE_HOUSE || brokerAdvertFieldsState.offerType === BrokerOfferType.RENT_HOUSE ? houseHeatingOptions : flatHeatingOptions;
    const accessOptions = generateOptionsFromEnum(Access);
    const houseNeighborhoodOptions = generateOptionsFromEnum(HouseNeighborhood);
    const plotNeighborhoodOptions = generateOptionsFromEnum(PlotNeighborhood);
    const neighborhoodOptions = brokerAdvertFieldsState.offerType === BrokerOfferType.SALE_HOUSE || brokerAdvertFieldsState.offerType === BrokerOfferType.RENT_HOUSE
        ? houseNeighborhoodOptions
        : plotNeighborhoodOptions;
    const houseAdditionalInformationOptions = generateOptionsFromEnum(HouseAdditionalInformation);
    const plotAdditionalInformationOptions = generateOptionsFromEnum(SalePlotEditDetailDtoAdditionalInformationEnum);
    const rentPlotAdditionalInformationOptions = generateOptionsFromEnum(RentPlotEditDetailDtoAdditionalInformationEnum);
    const flatAdditionalInformationOptions = generateOptionsFromEnum(FlatAdditionalInformation);
    const otherAdditionalInformationOptions = generateOptionsFromEnum(OtherAdditionalInformation);

    const getAdditionalInformationOptions = (offerType: BrokerOfferType) => {
        switch (offerType) {
        case BrokerOfferType.SALE_HOUSE:
        case BrokerOfferType.RENT_HOUSE:
            return houseAdditionalInformationOptions;

        case BrokerOfferType.SALE_PLOT:
            return plotAdditionalInformationOptions;

        case BrokerOfferType.RENT_PLOT:
            return rentPlotAdditionalInformationOptions;

        case BrokerOfferType.RENT_OTHER:
        case BrokerOfferType.SALE_OTHER:
            return otherAdditionalInformationOptions;

        case BrokerOfferType.SALE_FLAT:
        case BrokerOfferType.RENT_FLAT:
            return flatAdditionalInformationOptions;

        default:
            return flatAdditionalInformationOptions;
        }
    };

    const additionalInformationOptions = getAdditionalInformationOptions(brokerAdvertFieldsState.offerType);

    useEffect(() => {
        if (type === 'sale') {
            brokerApiRequests.getBrokerOfferDetailsForEdit(type, subtype, id).then(async (response) => {
                const offerType = response.module.replace('OFFER_', '');
                handleChange('offerType', offerType as unknown as BrokerOfferType);
                const savedLocations = [] as unknown as SavedLocationsType;
                const downloadedPhotos = await generateImagePreview(response.module, response.photos);
                // @ts-expect-error TODO: INVEST-237
                savedLocations[response.city.fullName!] = { ...response.city, label: response.city.fullName! };
                // @ts-expect-error TODO: INVEST-237
                handleFillAdvertFields({
                    ...response,
                    searchLocationResult: [],
                    savedLocations,
                    downloadedPhotos,
                    cityName: response.city.fullName,
                    locations: [],
                    // price: response.price?.replace(/\s/g, ''),
                    price: response.price,
                    offerType: response.module as unknown as BrokerOfferType
                });
            });
        } else if (type === 'rent') {
            brokerApiRequests.getBrokerOfferDetailsForEdit(type, subtype, id).then(async (response) => {
                const offerType = response.module.replace('OFFER_', '');
                handleChange('offerType', offerType as unknown as BrokerOfferType);
                const savedLocations = [] as unknown as SavedLocationsType;
                const downloadedPhotos = await generateImagePreview(response.module, response.photos);
                // @ts-expect-error TODO: INVEST-237
                savedLocations[response.city.fullName!] = { ...response.city, label: response.city.fullName! };
                // @ts-expect-error TODO: INVEST-237
                handleFillAdvertFields({
                    ...response,
                    searchLocationResult: [],
                    savedLocations,
                    downloadedPhotos,
                    cityName: response.city.fullName!,
                    locations: [],
                    offerType: response.module as unknown as BrokerOfferType
                });
            });
        }
    }, [type]);

    const handleUploadImage = async (params: UploadRequestData) => {
        return await brokerApiRequests.addImage(params, brokerAdvertFieldsState.offerType, brokerAdvertFieldsState.module);
    };

    async function fetchImage (name: string, module: string) {
        const response = await axiosApiInstance.get(`/broker-offer/photo/find?module=${module}&fileName=${name}&photoSize=NORMAL_PHOTO`, { responseType: 'blob' });

        if (!response) {
            throw new Error('Image fetch failed');
        }

        const url = URL.createObjectURL(response.data);

        return url;
    }

    const generateImagePreview = async (module: string, image?: string[]) => {
        if (!image) return [];

        const previews = [];

        for (const name of image) {
            const preview = await fetchImage(name, module);

            previews.push({
                preview,
                name
            });
        }

        return previews;
    };

    const getCities = async (voivodeship: string) => {
        const newLocations = await getVoivodeshipCities(voivodeship, brokerAdvertFieldsState.locations);

        handleChange('locations', newLocations || []);
    };

    const handleRemoveSavedLocation = (location: string) => {
        const savedLocations = brokerAdvertFieldsState.savedLocations;

        delete savedLocations[location];

        handleChange('savedLocations', savedLocations);
        handleChange('city', Object.values(savedLocations as LocationChangeType));
        handleChange('cityName', '');
    };

    const handleUpdateOffer = () => {
        setTriggerValidation(true);

        if (validateAllFields()) {
            brokerApiRequests.handleUpdateOffer({ ...brokerAdvertFieldsState }, brokerAdvertFieldsState.offerType).then(
                () => {
                    setTriggerValidation(false);
                    navigate('/broker');
                }
            );
        }
    };

    const handleCancel = () => {
        navigate('/broker');
    };

    const handleChangeCheckbox = (name: string, value: boolean, field: string) => {
        const fieldValue = brokerAdvertFieldsState[field as keyof BrokerCreateAdvertStateType] as string[] || [];

        if (value) {
            fieldValue.push(name);
        } else {
            const index = fieldValue.indexOf(name);
            fieldValue.splice(index, 1);
        }

        handleChange(field, fieldValue);
    };

    const parametersStructure = getParametersSection(brokerAdvertFieldsState, handleChange, brokerAdvertFieldsState.offerType, triggerValidation, validateField);

    const manageAdvertStructure = useMemo<ElementsToRender[]>(() => [
        {
            accordionTitle: 'Informacje podstawowe',
            oneItem: true,
            fullWidth: true,
            hideTitle: true,
            filters: [
                {
                    title: 'Tytuł ogłoszenia',
                    type: InputType.TEXT,
                    icon: IconEnum.CALENDAR,
                    placeholder: 'Wpisz tytuł ogłoszenia...',
                    additionalText: `Pozostało ${50 - (brokerAdvertFieldsState.title?.length || 0)} znaków`,
                    options: {
                        [InputType.TEXT]: {
                            contextFunctions: {
                                handleChange,
                                inputValidation: (value) => !validateField(AdvertValidatorType.NO_EMPTY_TEXT, value as string)
                            },
                            modulesProps: {
                                fieldName: 'title',
                                value: brokerAdvertFieldsState.title || '',
                                max: '50',
                                maxLength: 50,
                                errorMessage: 'Pole nie może być puste',
                                triggerValidation,
                                required: true
                            }
                        }
                    }
                }
            ]
        },
        {
            accordionTitle: 'Parametry',
            hideTitle: true,
            filters: parametersStructure.First
        },
        {
            accordionTitle: 'Dodaj zdjęcia',
            oneItem: true,
            fullWidth: true,
            additionalText: 'Maksymalna ilość zdjęć to 8. Aby zmienić kolejność fotografii, wystarczy przytrzymać miniaturkę i przeciągnąć ją w miejsce, w które chcemy.',
            filters: [
                {
                    title: '',
                    type: InputType.IMAGE_UPLOAD,
                    icon: '' as IconEnum,
                    placeholder: '',
                    options: {
                        [InputType.IMAGE_UPLOAD]: {
                            contextFunctions: {
                                handleChange,
                                fileUploadHandler: handleUploadImage
                            },
                            modulesProps: {
                                value: brokerAdvertFieldsState.downloadedPhotos ?? [],
                                fieldName: 'photos'
                            }
                        }
                    }
                }
            ]
        },
        {
            accordionTitle: 'Lokalizacja',
            hideTitle: true,
            filters: [
                {
                    title: 'Lokalizacja',
                    type: InputType.LOCATION,
                    helperText: 'Podaj miasto. Możesz również dodać dzielnicę. Rozdziel je przecinkiem, wpisując miasto jako pierwsze, np. "Warszawa, Mokotów". Możesz podać kilka dzielnic, klikając "Wiele lokacji" np. "Warszawa, Mokotów, Służew". Więcej w zakładce "Pomoc"',
                    icon: IconEnum.MAP_PIN,
                    placeholder: 'Szukaj lokalizacji...',
                    options: {
                        [InputType.LOCATION]: {
                            modulesProps: {
                                locations: brokerAdvertFieldsState.locations,
                                searchLocationResult: brokerAdvertFieldsState.searchLocationResult,
                                savedLocations: brokerAdvertFieldsState.savedLocations,
                                singleValue: true,
                                errorMessage: 'Pole nie może być puste',
                                cityName: brokerAdvertFieldsState.cityName,
                                triggerValidation,
                                required: true,
                                savedLocationsFieldName: 'savedLocations',
                                cityFieldName: 'city'
                            },
                            contextFunctions: {
                                getCities,
                                handleChangeSingleValueField: handleChange,
                                handleChange,
                                inputValidation: (value) => !validateField(AdvertValidatorType.NO_EMPTY_TEXT, value as string),
                                handleRemoveSavedLocation
                            }
                        }
                    }
                }
            ]
        },
        {
            accordionTitle: 'Informacje szczegółowe',
            hideTitle: true,
            oneItem: true,
            fullWidth: true,
            filters: [
                {
                    title: 'Szczegółowy opis',
                    type: InputType.TEXT,
                    icon: IconEnum.PRICE,
                    placeholder: 'Wpisz treść opisu...',
                    additionalText: `Pozostało ${10000 - (brokerAdvertFieldsState.description?.length || 0)} znaków`,
                    options: {
                        [InputType.TEXT]: {
                            modulesProps: {
                                value: brokerAdvertFieldsState.description || '',
                                fieldName: 'description',
                                textareaMode: true,
                                maxLength: 10000,
                                triggerValidation,
                                errorMessage: 'Pole nie może być puste',
                                required: true
                            },
                            contextFunctions: {
                                handleChange,
                                inputValidation: (value) => !validateField(AdvertValidatorType.NO_EMPTY_TEXT, value as string)
                            }
                        }
                    }
                }
            ]
        },
        {
            accordionTitle: 'Parametry 2',
            hideTitle: true,
            filters: parametersStructure.Second
        },
        {
            accordionTitle: 'Media',
            inputsWithoutLabel: true,
            filters: mediaOptions.map((option) => ({
                title: option.label,
                type: InputType.CHECKBOX,
                icon: IconEnum.CALENDAR,
                placeholder: '',
                options: {
                    [InputType.CHECKBOX]: {
                        contextFunctions: {
                            handleChange: handleChangeCheckbox
                        },
                        modulesProps: {
                            fieldName: 'media',
                            value: brokerAdvertFieldsState.media?.includes(option.label as BrokerMediaType),
                            biggerPadding: true
                        }
                    }
                }
            }))
        },
        ...brokerAdvertFieldsState.offerType === BrokerOfferType.SALE_HOUSE || brokerAdvertFieldsState.offerType === BrokerOfferType.SALE_FLAT || brokerAdvertFieldsState.offerType === BrokerOfferType.SALE_OTHER || brokerAdvertFieldsState.offerType === BrokerOfferType.RENT_FLAT || brokerAdvertFieldsState.offerType === BrokerOfferType.RENT_HOUSE || brokerAdvertFieldsState.offerType === BrokerOfferType.RENT_OTHER
            ? [
                {
                    accordionTitle: 'Zabezpieczenia',
                    inputsWithoutLabel: true,
                    filters: securityOptions.map((option) => ({
                        title: option.label,
                        type: InputType.CHECKBOX,
                        icon: IconEnum.CALENDAR,
                        placeholder: '',
                        options: {
                            [InputType.CHECKBOX]: {
                                contextFunctions: {
                                    handleChange: handleChangeCheckbox
                                },
                                modulesProps: {
                                    fieldName: 'security',
                                    value: brokerAdvertFieldsState.security?.includes(option.label as Security),
                                    biggerPadding: true
                                }
                            }
                        }
                    }))
                }
            ]
            : [],
        ...brokerAdvertFieldsState.offerType === BrokerOfferType.RENT_FLAT || brokerAdvertFieldsState.offerType === BrokerOfferType.SALE_FLAT
            ? [
                {
                    accordionTitle: 'Wyposażenie',
                    inputsWithoutLabel: true,
                    filters: equipmentOptions.map((option) => ({
                        title: option.label,
                        type: InputType.CHECKBOX,
                        icon: IconEnum.CALENDAR,
                        placeholder: '',
                        options: {
                            [InputType.CHECKBOX]: {
                                contextFunctions: {
                                    handleChange: handleChangeCheckbox
                                },
                                modulesProps: {
                                    fieldName: 'equipment',
                                    value: brokerAdvertFieldsState.equipment?.includes(option.label as FlatEquipment),
                                    biggerPadding: true
                                }
                            }
                        }
                    }))
                }
            ]
            : [],
        ...brokerAdvertFieldsState.offerType === BrokerOfferType.SALE_HOUSE || brokerAdvertFieldsState.offerType === BrokerOfferType.RENT_HOUSE
            ? [
                {
                    accordionTitle: 'Ogrodzenie budynku',
                    inputsWithoutLabel: true,
                    filters: houseFenceOptions.map((option) => ({
                        title: option.label,
                        type: InputType.CHECKBOX,
                        icon: IconEnum.CALENDAR,
                        placeholder: '',
                        options: {
                            [InputType.CHECKBOX]: {
                                contextFunctions: {
                                    handleChange: handleChangeCheckbox
                                },
                                modulesProps: {
                                    fieldName: 'fence',
                                    value: brokerAdvertFieldsState.fence?.includes(option.label as HouseFence),
                                    biggerPadding: true
                                }
                            }
                        }
                    }))
                }
            ]
            : [],
        ...brokerAdvertFieldsState.offerType === BrokerOfferType.SALE_HOUSE || brokerAdvertFieldsState.offerType === BrokerOfferType.RENT_HOUSE
            ? [
                {
                    accordionTitle: 'Ogrzewanie budynku',
                    inputsWithoutLabel: true,
                    filters: heatingOptions.map((option) => ({
                        title: option.label,
                        type: InputType.CHECKBOX,
                        icon: IconEnum.CALENDAR,
                        placeholder: '',
                        options: {
                            [InputType.CHECKBOX]: {
                                contextFunctions: {
                                    handleChange: handleChangeCheckbox
                                },
                                modulesProps: {
                                    fieldName: 'heating',
                                    value: brokerAdvertFieldsState.heating?.includes(option.label as BrokerHeatingType),
                                    biggerPadding: true
                                }
                            }
                        }
                    }))
                }
            ]
            : [],
        ...brokerAdvertFieldsState.offerType === BrokerOfferType.SALE_FLAT || brokerAdvertFieldsState.offerType === BrokerOfferType.RENT_FLAT
            ? [
                {
                    accordionTitle: 'Ogrzewanie budynku',
                    filters: [
                        {
                            title: 'Ogrzewanie',
                            type: InputType.AUTOCOMPLETE,
                            icon: IconEnum.CALENDAR,
                            placeholder: 'Wybierz rodzaj ogrzewania...',
                            options: {
                                [InputType.AUTOCOMPLETE]: {
                                    modulesProps: {
                                        value: brokerAdvertFieldsState.heating as unknown as FlatHeating,
                                        options: generateOptionsFromEnum(FlatHeating),
                                        noTyping: true,
                                        fieldName: 'heating'
                                    },
                                    contextFunctions: {
                                        handleChange
                                    }
                                }
                            }
                        }
                    ]
                }
            ]
            : [],
        {
            accordionTitle: 'Dojazd do budynku',
            inputsWithoutLabel: true,
            filters: accessOptions.map((option) => ({
                title: option.label,
                type: InputType.CHECKBOX,
                icon: IconEnum.CALENDAR,
                placeholder: '',
                options: {
                    [InputType.CHECKBOX]: {
                        contextFunctions: {
                            handleChange: handleChangeCheckbox
                        },
                        modulesProps: {
                            fieldName: 'access',
                            value: brokerAdvertFieldsState.access?.includes(option.label as Access),
                            biggerPadding: true
                        }
                    }
                }
            }))
        },
        ...brokerAdvertFieldsState.offerType === BrokerOfferType.SALE_HOUSE || brokerAdvertFieldsState.offerType === BrokerOfferType.SALE_PLOT || brokerAdvertFieldsState.offerType === BrokerOfferType.RENT_HOUSE || brokerAdvertFieldsState.offerType === BrokerOfferType.RENT_PLOT
            ? [
                {
                    accordionTitle: 'Otoczenie budynku',
                    inputsWithoutLabel: true,
                    filters: neighborhoodOptions.map((option) => ({
                        title: option.label,
                        type: InputType.CHECKBOX,
                        icon: IconEnum.CALENDAR,
                        placeholder: '',
                        options: {
                            [InputType.CHECKBOX]: {
                                contextFunctions: {
                                    handleChange: handleChangeCheckbox
                                },
                                modulesProps: {
                                    fieldName: 'neighborhood',
                                    value: brokerAdvertFieldsState.neighborhood?.includes(option.label as BrokerNeighborhoodType),
                                    biggerPadding: true
                                }
                            }
                        }
                    }))
                }
            ]
            : [],
        {
            accordionTitle: 'Informacje dodatkowe',
            inputsWithoutLabel: true,
            filters: additionalInformationOptions.map((option) => ({
                title: option.label,
                type: InputType.CHECKBOX,
                icon: IconEnum.CALENDAR,
                placeholder: '',
                options: {
                    [InputType.CHECKBOX]: {
                        contextFunctions: {
                            handleChange: handleChangeCheckbox
                        },
                        modulesProps: {
                            fieldName: 'additionalInformation',
                            value: brokerAdvertFieldsState.additionalInformation?.includes(option.label as BrokerAdditionalInformationType),
                            biggerPadding: true
                        }
                    }
                }
            }))
        },
        {
            accordionTitle: 'Podsumowanie',
            isWideSpace: true,
            lastElement: true,
            filters: [
                { title: 'Wróć do moich ofert',
                    type: InputType.BUTTON,
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Anuluj',
                    options: {
                        [InputType.BUTTON]: {
                            contextFunctions: {
                                onClick: handleCancel
                            },
                            modulesProps: {
                                important: true
                            }
                        }
                    } },
                {
                    title: 'Zapisz ofertę',
                    type: InputType.BUTTON,
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Zapisz ofertę',
                    options: {
                        [InputType.BUTTON]: {
                            contextFunctions: {
                                onClick: handleUpdateOffer
                            },
                            modulesProps: {}
                        }
                    }
                }
            ]
        }
    ], [brokerAdvertFieldsState, triggerValidation]);

    return (
        <div>
            <V2HeaderSection title="Edytuj ogłoszenie" />
            <V2FiltersRenderer type={'edit_broker_offer'} elementsToRender={manageAdvertStructure} bottomActions={null} />
        </div>
    );
};

export default BrokerEditOfferPage;
