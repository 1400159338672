import {
    Access,
    AdvertisementPhotoParamsModuleEnum,
    FlatBuildingMaterial,
    FlatBuildingType,
    FlatEquipment,
    FlatHeating,
    FlatMedia,
    FlatPropertyCondition,
    FloorLevel,
    HouseAdditionalInformation,
    HouseAttic,
    HouseBuildingMaterial,
    HouseBuildingType,
    HouseFence,
    HouseFloorLevel,
    HouseHeating,
    HouseLocation,
    HouseMedia,
    HouseNeighborhood,
    HouseRoof,
    HouseRoofMaterial,
    LocationDto,
    OtherBuildingPurpose,
    OtherBuildingRooms,
    OtherBuildingType,
    OtherConstruction,
    OtherFlooring,
    OtherMedia,
    OtherParking,
    PlotBuildingType,
    PlotMedia,
    PlotNeighborhood,
    PropertyCondition,
    RentFlatEditDetailDto, RentFlatOriginalDetailDto,
    RentHouseEditDetailDto, RentHouseOriginalDetailDto,
    RentOtherEditDetailDto, RentOtherOriginalDetailDto,
    RentPlotEditDetailDto,
    RentPlotEditDetailDtoAdditionalInformationEnum, RentPlotOriginalDetailDto,
    SaleFlatEditDetailDto,
    SaleHouseEditDetailDto,
    SaleOtherEditDetailDto,
    SalePlotEditDetailDto,
    SalePlotEditDetailDtoAdditionalInformationEnum,
    Security,
    TypeOfMarket,
    Window
} from './BrokerOfferModels';
import { LocationOptionsType, SavedLocationsType, SearchLocationResultType } from './Locations';

export enum BrokerOfferType {
    RENT_FLAT = 'RENT_FLAT',
    OFFER_RENT_FLAT = 'OFFER_RENT_FLAT',
    RENT_HOUSE = 'RENT_HOUSE',
    OFFER_RENT_HOUSE = 'OFFER_RENT_HOUSE',
    RENT_OTHER = 'RENT_OTHER',
    OFFER_RENT_OTHER = 'OFFER_RENT_OTHER',
    RENT_PLOT = 'RENT_PLOT',
    OFFER_RENT_PLOT = 'OFFER_RENT_PLOT',
    SALE_HOUSE = 'SALE_HOUSE',
    OFFER_SALE_HOUSE = 'OFFER_SALE_HOUSE',
    SALE_OTHER = 'SALE_OTHER',
    OFFER_SALE_OTHER = 'OFFER_SALE_OTHER',
    SALE_PLOT = 'SALE_PLOT',
    OFFER_SALE_PLOT = 'OFFER_SALE_PLOT',
    SALE_FLAT = 'SALE_FLAT',
    OFFER_SALE_FLAT = 'OFFER_SALE_FLAT',
}

type RentOfferEditDetailDto = RentFlatEditDetailDto & RentHouseEditDetailDto & RentOtherEditDetailDto & RentPlotEditDetailDto;
type SaleOfferEditDetailDto = SaleHouseEditDetailDto & SaleOtherEditDetailDto & SalePlotEditDetailDto & SaleFlatEditDetailDto;
type BrokerOfferEditDto = RentOfferEditDetailDto & SaleOfferEditDetailDto;

export type BrokerMediaType = PlotMedia | HouseMedia | OtherMedia | FlatMedia;
export type BrokerHeatingType = HouseHeating[] | FlatHeating;
export type BrokerNeighborhoodType = HouseNeighborhood | PlotNeighborhood;
export type BrokerAdditionalInformationType = HouseAdditionalInformation | SalePlotEditDetailDtoAdditionalInformationEnum | RentPlotEditDetailDtoAdditionalInformationEnum;
export type BrokerRentOfferDetailDto = RentPlotOriginalDetailDto | RentHouseOriginalDetailDto | RentFlatOriginalDetailDto | RentOtherOriginalDetailDto;

export interface BrokerCreateAdvertStateType {
    offerType: BrokerOfferType;
    encryptedId: string;
    title: string;
    photos?: string[];
    downloadedPhotos?: { name: string; preview: string; }[];
    description?: string;
    media: BrokerMediaType[];
    typeOfMarket?: TypeOfMarket;
    numberOfRooms?: string;
    builtYear?: string;
    floors?: string;
    propertyForm: string;
    availableFrom?: string;
    attic?: HouseAttic;
    roof?: HouseRoof;
    roofMaterial?: HouseRoofMaterial;
    buildingMaterial?: HouseBuildingMaterial | FlatBuildingMaterial;
    window?: Window;
    propertyCondition?: PropertyCondition | FlatPropertyCondition;
    location?: HouseLocation;
    recreationalHouse: boolean;
    security: Security[];
    fence: HouseFence[];
    heating: BrokerHeatingType[];
    access: Access[];
    neighborhood: BrokerNeighborhoodType[];
    additionalInformation: BrokerAdditionalInformationType[];
    dimensions?: string;
    buildingType?: PlotBuildingType | HouseBuildingType | OtherBuildingType | FlatBuildingType;
    floor?: FloorLevel;
    construction?: OtherConstruction;
    height?: string;
    parking?: OtherParking;
    flooring?: OtherFlooring;
    buildingRooms?: OtherBuildingRooms[];
    buildingPurpose?: OtherBuildingPurpose[];
    numberOfFloors?: string;
    equipment: FlatEquipment[];
    price?: number;
    rentPrice?: number;
    depositPrice?: number;
    pricePerSquareMeter?: number;
    locations: LocationOptionsType[];
    searchLocationResult: SearchLocationResultType[];
    savedLocations: SavedLocationsType;
    city?: LocationDto[];
    cityName?: string;
    module: AdvertisementPhotoParamsModuleEnum;
    area?: number;
}

// @ts-expect-error TODO: INVEST-254 fix later
export interface BrokerAdvertEditReturnData extends BrokerOfferEditDto {
    city: LocationDto[];
    cityName: string;
    offerType: BrokerOfferType;
    locations?: LocationOptionsType[];
    searchLocationResult?: SearchLocationResultType[];
    savedLocations: SavedLocationsType;
    floor?: FloorLevel;
    floors?: HouseFloorLevel;
    availableFrom?: string;
    advertisementId: string;
    title: string;
    description?: string;
    media: BrokerMediaType[];
    heating: BrokerHeatingType;
    fence: HouseFence[];
    security: Security[];
    access: Access[];
    neighborhood: BrokerNeighborhoodType[];
    equipment: FlatEquipment[];
    additionalInformation: BrokerAdditionalInformationType[];
}
