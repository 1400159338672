import { FiltersStateType } from 'utils/types/FiltersStateType';
import { ModulesType } from 'utils/types/ModulesType';
import {
    DuplicateFlatDto,
    DuplicateHouseDto,
    DuplicateOtherDto,
    DuplicatePlotDto,
    NoteDto,
    RentFlatDetailDto,
    RentFlatDto, RentHashDto,
    RentHouseDetailDto,
    RentHouseDto, RentOpinionResult,
    RentOtherDetailDto,
    RentOtherDto,
    RentPlotDetailDto,
    RentPlotDto,
    SortType
} from 'utils/types/RentModels';

export interface RentStateType {
    isLoading: boolean,
    rentOfferList: RentOffersResponse,
    sortType: SortType;
    filtersState: FiltersStateType;
    pageType: ModulesType;
    filteredCity: string;
    notes?: NoteDto[],
    scoreAndOpinions?: RentOpinionResult
}

export type RentOfferDetailsResponse = RentFlatDetailDto & RentPlotDetailDto & RentHouseDetailDto & RentOtherDetailDto;

export type RentOfferHashResponse = RentHashDto;

type DuplicateDto = DuplicateFlatDto & DuplicatePlotDto & DuplicateHouseDto & DuplicateOtherDto;

type RentOffersDto = RentHouseDto & RentFlatDto & RentPlotDto & RentOtherDto;

export type RentOfferDuplicatesResponse = DuplicateDto

export interface RentOfferResponseContent extends RentOffersDto {
    detailedContent?: RentOfferDetailsResponse;
    duplicates?: RentOfferDuplicatesResponse[];
}

export interface RentOffersResponse {
    empty: boolean,
    content: RentOfferResponseContent[],
    pageable: {
        pageNumber: number,
        pageSize: number,
    },
    numberOfElements: number,
    totalPages: number,
    totalElements: number,
    last: boolean,
    size: number,
    afterSearch: boolean,
}

export const rentInitialState: RentStateType = {
    isLoading: false,
    rentOfferList: {
        empty: false,
        content: [],
        pageable: {
            pageNumber: 1,
            pageSize: 0
        },
        numberOfElements: 0,
        size: 25,
        totalPages: 0,
        totalElements: 0,
        last: false,
        afterSearch: false
    },
    sortType: SortType.DATE_ASC,
    filtersState: {} as FiltersStateType,
    pageType: 'advertisement',
    filteredCity: ''
};
