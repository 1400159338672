import React, { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import AdminPanelContext from 'utils/context/AdminPanelContex';

import Button from 'components/atom/Button';
import { IconEnum } from 'components/common/Icon';
import InputContainer from 'components/common/InputContainer';
import TextInput from 'components/common/Inputs/TextInput';
import { activeNotification } from 'components/functions/activeNotification';
import V2HeaderSection from 'components/V2HeaderSection';

const Wrapper = styled.div`
  padding: 0 20px;
`;

const FormContainer = styled.div`
    padding: 24px 15px 32px 15px;
    border-radius: var(--box-border-radius);
    background: var(--color-white);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin-bottom: 30px;
    
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const FullWidthItem = styled.div`
    grid-column: span 2;
    
    @media (max-width: 768px) {
        grid-column: span 1;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-top: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const AddAgentFormPage: FC = () => {
    const { createAgent, createAgentFieldsState, handleChange, validateCreateAgentFields, adminPanelState, clearFields } = useContext(AdminPanelContext);
    const navigate = useNavigate();
    const [triggerValidation, setTriggerValidation] = useState(false);

    const handleSubmit = () => {
        setTriggerValidation(true);
        const errors = validateCreateAgentFields(createAgentFieldsState);
        const hasErrors = Object.values(errors).some((error) => error !== '');

        if (hasErrors) {
            activeNotification('Dane', 'Wypełnij poprawnie wszystkie wymagane pola', 'warning');

            return;
        }

        createAgent(createAgentFieldsState);
        navigate('/admin-panel');
    };

    const handleCancel = () => {
        clearFields();
        navigate('/admin-panel');
    };

    // todo: stworzyć NumberInput zamiast i użyć go tutaj zamiast TextInput

    return (
        <Wrapper>
            <V2HeaderSection title="Dodaj agenta" />
            <FormContainer>
                <InputContainer title="Imię" icon={IconEnum.USER} required>
                    <TextInput
                        value={createAgentFieldsState.firstName}
                        onChange={(e) => handleChange('firstName', e.target.value)}
                        placeholder="Wprowadź imię agenta..."
                        required
                        errorMessage={validateCreateAgentFields(createAgentFieldsState).firstName}
                        inputValidation={() => !!validateCreateAgentFields(createAgentFieldsState).firstName}
                        triggerValidation={triggerValidation}
                    />
                </InputContainer>

                <InputContainer title="Nazwisko" icon={IconEnum.USER} required>
                    <TextInput
                        value={createAgentFieldsState.lastName}
                        onChange={(e) => handleChange('lastName', e.target.value)}
                        placeholder="Wprowadź nazwisko agenta..."
                        required
                        errorMessage={validateCreateAgentFields(createAgentFieldsState).lastName}
                        inputValidation={() => !!validateCreateAgentFields(createAgentFieldsState).lastName}
                        triggerValidation={triggerValidation}
                    />
                </InputContainer>

                <InputContainer title="Email" icon={IconEnum.EMAIL} required>
                    <TextInput
                        value={createAgentFieldsState.email}
                        onChange={(e) => handleChange('email', e.target.value)}
                        placeholder="Wprowadź adres email..."
                        required
                        errorMessage={validateCreateAgentFields(createAgentFieldsState).email}
                        inputValidation={() => !!validateCreateAgentFields(createAgentFieldsState).email}
                        triggerValidation={triggerValidation}
                    />
                </InputContainer>

                <InputContainer title="Numer telefonu" icon={IconEnum.PHONE} required>
                    <TextInput
                        value={createAgentFieldsState.phoneNumber}
                        onChange={(e) => {
                            const onlyDigits = e.target.value.replace(/\D/g, '');
                            handleChange('phoneNumber', onlyDigits);
                        }}
                        placeholder="Wprowadź 9 cyfrowy numer telefonu..."
                        required
                        maxLength={9}
                        errorMessage={validateCreateAgentFields(createAgentFieldsState).phoneNumber}
                        inputValidation={() => !!validateCreateAgentFields(createAgentFieldsState).phoneNumber}
                        triggerValidation={triggerValidation}
                    />
                </InputContainer>

                <InputContainer title="Liczba SMS" icon={IconEnum.MESSAGE} helperText={`Dostępna pula SMS: ${adminPanelState.totalSmsAvailableToNewAccounts}`} required>
                    <TextInput
                        value={createAgentFieldsState.smsPackage ? createAgentFieldsState.smsPackage.toString() : '0'}
                        onChange={(e) => handleChange('smsPackage', parseInt(e.target.value) || 0)}
                        placeholder="Wprowadź liczbę SMS..."
                        required
                        errorMessage={validateCreateAgentFields(createAgentFieldsState).smsPackage}
                        inputValidation={() => !!validateCreateAgentFields(createAgentFieldsState).smsPackage}
                        triggerValidation={triggerValidation}
                    />
                </InputContainer>

                <InputContainer title="Hasło" icon={IconEnum.BLOCKED} required>
                    <TextInput
                        type="password"
                        value={createAgentFieldsState.password}
                        onChange={(e) => handleChange('password', e.target.value)}
                        placeholder="Min. 8 znaków, duża litera i cyfra"
                        required
                        errorMessage={validateCreateAgentFields(createAgentFieldsState).password}
                        inputValidation={() => !!validateCreateAgentFields(createAgentFieldsState).password}
                        triggerValidation={triggerValidation}
                    />
                </InputContainer>

                <InputContainer title="Potwierdź hasło" icon={IconEnum.BLOCKED} required>
                    <TextInput
                        type="password"
                        value={createAgentFieldsState.confirmPassword}
                        onChange={(e) => handleChange('confirmPassword', e.target.value)}
                        placeholder="Potwierdź hasło..."
                        required
                        errorMessage={validateCreateAgentFields(createAgentFieldsState).confirmPassword}
                        inputValidation={() => !!validateCreateAgentFields(createAgentFieldsState).confirmPassword}
                        triggerValidation={triggerValidation}
                    />
                </InputContainer>

                <FullWidthItem>
                    <ButtonsContainer>
                        <InputContainer
                            title={'Wróć do panelu admina'}
                            actionText={''}
                            icon={IconEnum.DICTIONARY}
                            onActionClick={handleCancel}
                        >

                            <Button important onClick={handleCancel}>Anuluj</Button>
                        </InputContainer>
                        <InputContainer
                            title={'Zapisz dane agenta'}
                            icon={IconEnum.DICTIONARY}
                        >
                            <Button onClick={handleSubmit}>Zapisz</Button>
                        </InputContainer>

                    </ButtonsContainer>
                </FullWidthItem>
            </FormContainer>
        </Wrapper>
    );
};

export default AddAgentFormPage;
