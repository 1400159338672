import React, { useState } from 'react';
import {
    Bar,
    CartesianGrid,
    ComposedChart, Dot,
    Line,
    Rectangle,
    ReferenceDot,
    ResponsiveContainer, Tooltip as TooltipRecharts, TooltipProps,
    XAxis,
    YAxis
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';

import { Box, BoxContainer, HeaderStyle, LegendList, SectionBox } from 'components/analysis/AnalysisSaleList';
import { ComposedChartType } from 'components/analysis/models';
import { Cloud } from 'components/common/Tooltip/Tooltip';

const Triangle = styled.div`
    :after {
        content: '';
        position: absolute;
        ${(props) => props.bottomTooltip ? 'bottom: 27px;' : 'top: -18px;'}
        left: -5px;
        width: 10px;
        height: 10px;
        background: ${(props) => props.darkMode ? 'var(--color-alt-hover)' : 'var(--color-alt-second)'};
        transform: ${(props) => props.bottomTooltip ? 'rotate(225deg)' : 'rotate(45deg)'};
        border-bottom: 1px solid rgba(3, 13, 69, 0.05);
        border-right: 1px solid rgba(3, 13, 69, 0.05);
        border-bottom-right-radius: 2px;
        z-index: 1;
    }
`;

const TriangleWrapper = styled(Triangle)`
  :after {
    top: 108px;
    left: 50%;
  }
`;

const LegendWrapper = styled.div`
  display: block;
  text-align: left;
  padding: 5px;
  
  p:first-child {
    margin-bottom: 10px;
  }
`;

const LegendDescription = styled.p`
  --font-size-body: var(--font-size-body-2);
  font-weight: 400;

  margin-bottom: 5px;
  line-height: 15px;
`;

interface TrendRangeAverageTotalPriceAndAreaProps {
    analysis: {
        trendResult?: string[];
    };
    trendDataChart: ComposedChartType[];
}

interface BarProps {
    x: number;
    y: number;
    payload: ComposedChartType;
}

export const TrendRangeAverageTotalPriceAndArea = ({ analysis, trendDataChart }: TrendRangeAverageTotalPriceAndAreaProps) => {
    const { user } = useUser();
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

    const CustomBar = (props: BarProps) => {
        const { x, y, payload } = props;
        const cartesianGrid = document.querySelector('.recharts-cartesian-grid')?.getBoundingClientRect().height;
        const max = payload.y * cartesianGrid! / (cartesianGrid! - y);
        const dotPosition = cartesianGrid! - payload.dot * cartesianGrid! / max;

        return <>
            <Rectangle radius={[
                6,
                6,
                6,
                6
            ]} fill={'transparent'} x={x - 50} y={0} height={385} width={90} />
            <Rectangle radius={[
                6,
                6,
                6,
                6
            ]} fill={user.isDarkMode ? '#CDCED3' : '#0255AA'} x={x - 26} y={dotPosition - 2}
            height={4} width={40}/>
            <Dot fill={user.isDarkMode ? '#0F0F0F' : '#F2F5F9'} stroke={user.isDarkMode ? '#CDCED3' : '#0255AA'}
                strokeWidth={4} cx={x - 8} cy={dotPosition} r={5}/>
        </>;
    };

    const CustomTooltip = <TValue extends ValueType, TName extends NameType>({ active, payload, label }: TooltipProps<TValue, TName>) => {
        if (active && payload && payload.length) {
            return (
                <>
                    <Cloud darkMode={user.isDarkMode}>
                        <LegendWrapper darkMode={user.isDarkMode} className="tooltip-legend">
                            <p>{label}</p>
                            <LegendDescription>
                                Minimalna cena <span>{new Intl.NumberFormat().format(payload[0].payload.errorY[0])} złotych</span>
                            </LegendDescription>
                            <LegendDescription>
                                Średnia cena <span>{new Intl.NumberFormat().format(payload[0].payload.dot)} złotych</span>
                            </LegendDescription>
                            <LegendDescription>
                                Maksymalna cena <span>{new Intl.NumberFormat().format(payload[0].payload.errorY[1])} złotych</span>
                            </LegendDescription>
                        </LegendWrapper>
                    </Cloud>
                    <TriangleWrapper darkMode={user.isDarkMode} />
                </>
            );
        }

        return null;
    };

    // @ts-expect-error TODO: fix types
    const CustomizedCursor = (props) => {
        const { height, points } = props;

        return (
            <>
                <defs>
                    <linearGradient id="myGradient" gradientTransform="rotate(90)">
                        <stop offset="0%" stopColor={user.isDarkMode ? '#CDCED300' : '#0255AA00'}/>
                        <stop offset="62.5%" stopColor={user.isDarkMode ? '#CDCED31A' : '#0255AA1A'}/>
                    </linearGradient>
                </defs>
                <Rectangle radius={[
                    0,
                    0,
                    6,
                    6
                ]} fill="url('#myGradient')" x={points[0].x - 50} y={points[0].y} width={100}
                height={height + 35}/>
            </>
        );
    };

    // @ts-expect-error TODO: fix types
    const onMouseEnter = (e) => {
        const tooltipHeight = 135;
        setTooltipPosition({ x: e.x - 135, y: e.y - tooltipHeight });
    };

    return (
        <SectionBox data-pdf-section>
            <HeaderStyle>
                <h2>Trend ceny metra kwadratowego</h2>
                <LegendList darkMode={user.isDarkMode}>
                    <li>Średnia cena w danym okresie</li>
                    <li>Linia trendu</li>
                </LegendList>
                <BoxContainer>
                    <Box>
                        <p>Trend miesięczny</p>
                        <p>{analysis.trendResult?.[0]}</p>
                    </Box>
                    <Box>
                        <p>Trend kwartalny</p>
                        <p>{analysis.trendResult?.[1]}</p>
                    </Box>
                    <Box>
                        <p>Trend roczny</p>
                        <p>{analysis.trendResult?.[2]}</p>
                    </Box>
                </BoxContainer>
                <ResponsiveContainer width="100%" height={400}>
                    <ComposedChart margin={{ left: 20, bottom: 20, top: 20 }} data={trendDataChart}>
                        <CartesianGrid strokeWidth={1} stroke={user.isDarkMode ? '#F2F5F9' : '#030D45'} strokeOpacity={0.2}
                            vertical={false}
                            strokeDasharray="0"/>
                        <XAxis dataKey="name" tickLine={false}
                            stroke={user.isDarkMode ? '#F2F5F9' : '#030D45'} strokeOpacity={0.1}/>
                        <YAxis dataKey="y" tick={{ fill: user.isDarkMode ? '#F2F5F9' : '#030D45', fontWeight: 400, fontSize: 13 }}
                            axisLine={false}
                            tickLine={false}
                            tickFormatter={(x) => new Intl.NumberFormat().format(x)}/>
                        <ReferenceDot y="1200" r={15} fill="red" stroke="none"/>
                        <Line dataKey="line" activeDot={false} dot={false} stroke={user.isDarkMode ? '#F2F5F9' : '#030D45'}
                            strokeWidth={4}/>
                        <TooltipRecharts allowEscapeViewBox={{ x: true, y: true }} content={<CustomTooltip/>} position={tooltipPosition}
                            cursor={<CustomizedCursor/>}/>
                        <Bar dataKey="errorY" fill={user.isDarkMode ? '#7E7F85' : '#A2C0DF'} barSize={8}
                            radius={[
                                6,
                                6,
                                6,
                                6
                            ]} />
                        {/* @ts-expect-error TODO: fix types*/}
                        <Bar dataKey="errorY" shape={<CustomBar/>} fill={user.isDarkMode ? '#7E7F85' : '#A2C0DF'} onMouseOver={onMouseEnter} barSize={0}/>
                    </ComposedChart>
                </ResponsiveContainer>
            </HeaderStyle>
        </SectionBox>
    );
};
