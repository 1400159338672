import React, { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import { Module } from 'utils/types/SaleModels';

import InteractiveIcons from 'components/common/Card/common/InteractiveIcons';
import OfferActions from 'components/common/Card/common/OfferActions/OfferActions';
import Icon, { IconEnum } from 'components/common/Icon';
import AutocompleteInput from 'components/common/Inputs/AutocompleteInput/AutocompleteInput';
import Modal from 'components/common/Modal/Modal';

const OrganizationWrapper = styled.div`
    @media (min-width: 586px) {
        margin-left: auto;
    }
`;
const Button = styled.div`
    position: relative;
    background-color: var(--color-alt-second);
    min-width: 40px;
    height: 40px;
    display: flex;
    gap: 10px;
    padding: 10px 15px;
    border-radius: 20px;
    align-content: center;
    justify-content: center;

    @media (max-width: 586px) {
        margin-top: 20px;
    }

    &:hover {
        cursor: pointer;
        background-color: var(--color-alt);

        span {
            color: white;
        }

        img {
            filter: ${({ darkMode }) => darkMode ? 'unset' : 'invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%)'};
        }
    }
`;

const OrganizationModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 20px;
    align-items: center;
    margin-top: 45px;

    span {
        max-width: 425px;
    }

    p {
        max-width: 260px;
        text-align: center;
    }

    .rating-setter {
        margin-top: 15px;
        margin-bottom: 20px;

        img {
            width: 16px;
            height: 15px;
        }
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    gap: 10px;
    max-width: 330px;
    width: 100%;
    justify-content: center;

    div {
        padding: 10px 40px;
        width: 100%;
        justify-content: center;
        
        :hover {
            color: white;
        }
        
        &:nth-child(2) {
            color: white;
            background-color: var(--color-alt);
        }
    }
`;

const ReservationInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    span {
        --font-weight: 400;

        font-size: 11px;
    }

    b {
        font-weight: 500;
    }

    img {
        width: 20px;
        height: 18px;
    }
    
    .active {
        background-color: ${({ isBlocked }) => isBlocked ? 'var(--color-success)' : '#AC0B0B'};
        
        &:hover {
            background-color: ${({ isBlocked }) => isBlocked ? 'var(--color-success)' : '#AC0B0B'};
        }
    }
`;

const ReservationInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

const ReservationWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 15px;
    position: relative;
`;

const Line = styled.div`
    height: 1px;
    width: 100%;
    background-color: var(--color-alt-second);
    margin: 20px 0;
`;

const InputWrapper = styled.div`
    margin-top: 16.5px;
    width: 100%;
    max-width: 330px;
    position: relative;
`;

export interface OrganizationProps {
    isReserved: boolean;
    myBlockage?: boolean;
    reservationDate?: string;
    userName?: string;
    isPermanentlyReserved?: boolean;
    handleReservation: (days: number) => void;
    removeReservation: () => void;
    handleReservationPermanently: () => void;
    handleToggleReservation: () => void;
    notificationStatus?: boolean;
    offerId: number;
    offerModule: Module;
}

interface OrganizationContainerProps {
    organizationContainer: OrganizationProps;
}

const OrganizationContainer: FC<OrganizationContainerProps> = ({ organizationContainer }) => {
    const { user } = useUser();
    const [isOrganizationModalOpen, setIsOrganizationModalOpen] = useState(false);
    const [isManageReservationTooltipOpen, setIsManageReservationTooltipOpen] = useState(false);
    const [reservationLength, setReservationLength] = useState(1);
    const [isReservedByMe, setIsReservedByMe] = useState(organizationContainer.myBlockage);
    const [notificationStatus, setNotificationStatus] = useState(organizationContainer.notificationStatus);

    useEffect(() => {
        setNotificationStatus(organizationContainer.notificationStatus);
    }, [organizationContainer.notificationStatus]);

    useEffect(() => {
        setIsReservedByMe(organizationContainer.myBlockage);
    }, [organizationContainer.myBlockage]);

    const reservationActionElements = [

        ...isReservedByMe
            ? [
                {
                    children:
                        <>
                            <p>Anuluj rezerwacje</p>
                        </>,
                    onClick: async (e: MouseEvent) => {
                        e.stopPropagation();

                        organizationContainer.removeReservation();
                        setIsManageReservationTooltipOpen(false);
                        setIsReservedByMe(false);
                    }
                }
            ]
            : [],
        ...organizationContainer.isPermanentlyReserved
            ? []
            : [
                {
                    children:
                <>
                    <p>Zarezerwuj na stałe</p>
                </>,
                    onClick: async (e: MouseEvent) => {
                        e.stopPropagation();
                        setIsManageReservationTooltipOpen(false);
                        organizationContainer.handleReservationPermanently();
                        setIsReservedByMe(true);
                    }
                }
            ]
    ];

    const createLabelForOption = (value: number) => {
        if (value === 1) {
            return '1 dzień';
        }

        return `${value} dni`;
    };
    const reservationLengthOptions = Array.from({ length: 14 }, (_, i) => ({ value: i + 1, label: createLabelForOption(i + 1) }));

    const handleOrganizationModal = (e: React.MouseEvent | KeyboardEvent, isOpen: boolean) => {
        e.stopPropagation();
        setIsOrganizationModalOpen(isOpen);
    };

    const handleSendReservation = () => {
        organizationContainer.handleReservation(reservationLength);
        setIsOrganizationModalOpen(false);
        setIsReservedByMe(true);
    };

    const handleManageReservation = (e: React.MouseEvent | KeyboardEvent) => {
        e.stopPropagation();
        setIsOrganizationModalOpen(false);
        setIsManageReservationTooltipOpen(!isManageReservationTooltipOpen);
    };

    const handleNotification = (e: React.MouseEvent | KeyboardEvent) => {
        e.stopPropagation();
        organizationContainer.handleToggleReservation();
    };

    return (
        <OrganizationWrapper>
            {organizationContainer.isReserved
                ? <ReservationWrapper>
                    <ReservationInfoWrapper isBlocked={organizationContainer.isPermanentlyReserved}>
                        {organizationContainer.myBlockage}
                        {organizationContainer.myBlockage !== true && organizationContainer.myBlockage !== undefined &&
                            <InteractiveIcons active={notificationStatus} icon={IconEnum.ALERT} onClick={handleNotification} />}
                        {!organizationContainer.isPermanentlyReserved
                            ? <InteractiveIcons onClick={handleNotification} active icon={IconEnum.BLOCKED} />
                            : <InteractiveIcons active icon={IconEnum.BLOCKED} />}
                        <ReservationInfo>
                            {!organizationContainer.isPermanentlyReserved
                                ? <span><b>Zarezerwowane do {`${dayjs(organizationContainer.reservationDate).format('DD.MM')}`}</b></span>
                                : <span><b>Zablokowane na stałe</b></span>}
                            <span>Przez {`${organizationContainer.userName}`}</span>
                        </ReservationInfo>
                    </ReservationInfoWrapper>

                    {isReservedByMe &&
                        <>
                            <Button onClick={handleManageReservation}>
                                <span>Zarządzaj</span>
                            </Button>
                            {isManageReservationTooltipOpen ? <OfferActions elements={reservationActionElements} /> : null}
                        </>
                    }

                </ReservationWrapper>
                : <Button onClick={(e: React.MouseEvent) => handleOrganizationModal(e, true)}>
                    <Icon icon={IconEnum.BLOCKED}/>
                    <span>Zarezerwuj ogłoszenie</span>
                </Button>}
            <Modal overflowing isOpen={isOrganizationModalOpen} onClose={(e) => handleOrganizationModal(e, false)} centeredContent
                strictContentHeight backgroundColor={user.isDarkMode ? 'var(--color-alt-hover)' : '#fff'}>
                <OrganizationModalContainer>
                    <h1>Na jak długo chcesz zarezerwować to ogłoszenie?</h1>
                    <p>Wybierz jedną z poniższych opcji</p>
                    <InputWrapper>
                        <AutocompleteInput
                            noTyping
                            value={reservationLength}
                            onChange={(value) => setReservationLength(value! as number)}
                            placeholder={'Długość rezerwacji'}
                            options={reservationLengthOptions}
                        />
                    </InputWrapper>
                    <Line />
                    <ButtonWrapper>
                        <Button defaultVariant actionIconVisible={false}
                            onClick={(e: React.MouseEvent) => handleOrganizationModal(e, false)}>Anuluj</Button>
                        <Button secondary actionIconVisible={false} onClick={handleSendReservation}>Zarezerwuj</Button>
                    </ButtonWrapper>
                </OrganizationModalContainer>
            </Modal>
        </OrganizationWrapper>
    );
};

export default OrganizationContainer;
