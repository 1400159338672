import { axiosApiInstance } from 'Store';
import { UserReactions } from 'utils/types/OfferData';
import { NoteDto } from 'utils/types/SaleModels';

import { activeNotification } from 'components/functions/activeNotification';

const getNotes = (type: string, id: string) => {
    return axiosApiInstance({
        method: 'get',
        url: 'note/get-notes',
        params: {
            ownerId: id,
            module: type
        }
    })
        .then((resp) => {
            if (resp.data && resp.data.length > 0) {
                return resp.data;
            }

            return [];
        })
        .catch(() => {
            return [];
        });
};

const updateNote = (offerType: string, note: string, id: number, noteId?: number): Promise<NoteDto> => {
    return axiosApiInstance({
        method: 'put',
        url: 'note/process',
        params: {
            module: offerType
        },
        data: {
            ownerId: id,
            content: note,
            noteId: !noteId ? undefined : noteId
        }
    })
        .then((resp) => {
            activeNotification(
                'Notatka zaaktualizowana',
                'Aktualizacja notatki przebiegła pomyślnie',
                'success'
            );

            return { ...resp.data, advertisementId: resp.data.advertisementId ? resp.data.advertisementId : id };
        })
        .catch(() => {
            activeNotification(
                'Coś poszło nie tak',
                'Niestety nie udało się zaaktualizować notatki. Spróbuj ponownie.',
                'warning'
            );
        });
};

const deleteNote = (offerType: string, noteId: number) => {
    return axiosApiInstance({
        method: 'delete',
        url: `note/delete/${noteId}`,
        params: {
            module: offerType
        }
    })
        .then(() => {
            activeNotification(
                'Notatka usunięta',
                'Pomyślnie usunięto notatkę',
                'success'
            );

            return [];
        })
        .catch(() => {
            activeNotification(
                'Coś poszło nie tak',
                'Niestety nie udało się usunąć notatki. Spróbuj ponownie.',
                'warning'
            );

            return [];
        });
};

const setOfferReaction = async (id: number, city: string, reactions: UserReactions, propertyType: string) => {
    return axiosApiInstance({
        method: 'PUT',
        url: 'reaction/process',
        params: {
            module: propertyType
        },
        data: {
            ownerId: id,
            ...reactions,
            city
        }
    })
        .then((resp) => {
            return resp.data;
        })
        .catch(() => {
            activeNotification(
                'Coś poszło nie tak!',
                'Spróbuj ponownie',
                'danger'
            );

            return false;
        });
};

export const commonApiRequests = {
    getNotes,
    updateNote,
    deleteNote,
    setOfferReaction
};
