import dayjs from 'dayjs';
import { stringToNumber } from 'utils/formatters/stringToNumber';
import { getArrayFilters, getRangeFilters } from 'utils/generateActiveFiltersContent';
import { AuctionFilterReturnData, AuctionFiltersStateType } from 'utils/types/Auction';
import { AuctionSearchingRequestSortTypeEnum, FilterLocation } from 'utils/types/AuctionModels';
import { CitiesType, DistrictType } from 'utils/types/Locations';

import { FilterItem } from 'components/common/V2ActiveFilters';
import { dateFormat, dateFromFunc, dateToFunc } from 'components/FilterFunctions';

export const setAuctionStateValues = (state: AuctionFilterReturnData): AuctionFiltersStateType => {
    const dateFromWithDefault = state.dateAddedFrom || dayjs().subtract(3, 'day').toISOString();

    return {
        filterId: state.filterId === 0 ? null : state.filterId,
        wantedKeywords: state.wantedKeywords ?? [],
        unwantedKeywords: state.unwantedKeywords ?? [],
        locations: state.locations ?? [],
        offerAdded: {
            from: dateFromWithDefault && dateFromWithDefault.includes('T') ? dateFromWithDefault.split('T')[0] : dateFromWithDefault,
            to: state.dateAddedTo && state.dateAddedTo.includes('T') ? state.dateAddedTo.split('T')[0] : state.dateAddedTo || ''
        },
        types: state.types ?? undefined,
        savedFilter: state.savedFilter ?? [],
        daysAmount: state.daysAmount ?? 3,
        dateRangeFilter: !!state.dateAddedTo,
        includeZeroArea: state.includeZeroArea ?? true,
        notificationsDelay: state.notificationsDelay?.toString() ?? '',
        alertSms: state.alertSms ?? false,
        alertEmail: state.alertEmail ?? false,
        alertPush: state.alertPush ?? false,
        name: state.name ?? '',
        module: state.module ?? 'Auction',
        city: state.city ?? [],
        searchLocationResult: [],
        savedLocations: state.savedLocations ?? {},
        sortType: state.sortType,
        price: {
            from: state.priceFrom ?? '',
            to: state.priceTo ?? ''
        },
        pricePerSquareMeter: {
            from: state.pricePerSquareMeterFrom ?? '',
            to: state.pricePerSquareMeterTo ?? ''
        },
        area: {
            from: state.areaFrom ?? '',
            to: state.areaTo ?? ''
        },
        builtYear: {
            from: state.builtYearFrom ?? '',
            to: state.builtYearTo ?? ''
        },
        floor: {
            from: state.floorFrom ?? '',
            to: state.floorTo ?? ''
        },
        floors: {
            from: state.floorsFrom ?? '',
            to: state.floorsTo ?? ''
        },
        numberOfRooms: {
            from: state.numberOfRoomsFrom ?? null,
            to: state.numberOfRoomsTo ?? null
        }
    };
};

export const getAuctionValueFromState = (state: AuctionFiltersStateType): AuctionFilterReturnData => {
    return {
        filterId: state.filterId!,
        name: state.name || '',
        notificationsDelay: Number(state.notificationsDelay),
        alertSms: state.alertSms,
        alertEmail: state.alertEmail,
        alertPush: state.alertPush,
        locations: state.locations,
        wantedKeywords: state.wantedKeywords,
        unwantedKeywords: state.unwantedKeywords,
        types: state.types,
        daysAmount: state.dateRangeFilter ? undefined : state.daysAmount,
        dateAddedFrom: state.dateRangeFilter ? dateFormat(dateToFunc(state.offerAdded.from)) : undefined,
        dateAddedTo: state.dateRangeFilter && state.offerAdded.to ? dateFormat(dateFromFunc(state.offerAdded.to)) : undefined,
        includeZeroArea: state.includeZeroArea,
        module: state.module,
        savedLocations: state.savedLocations,
        searchLocationResult: state.searchLocationResult,
        sortType: AuctionSearchingRequestSortTypeEnum.DATE_DESC,
        city: state.city,
        areaFrom: stringToNumber(state.area.from),
        areaTo: stringToNumber(state.area.to),
        priceFrom: stringToNumber(state.price.from),
        priceTo: stringToNumber(state.price.to),
        pricePerSquareMeterFrom: stringToNumber(state.pricePerSquareMeter.from),
        pricePerSquareMeterTo: stringToNumber(state.pricePerSquareMeter.to),
        floorFrom: stringToNumber(state.floor.from),
        floorTo: stringToNumber(state.floor.to),
        floorsFrom: stringToNumber(state.floors.from),
        floorsTo: stringToNumber(state.floors.to),
        numberOfRoomsFrom: stringToNumber(state.numberOfRooms.from),
        numberOfRoomsTo: stringToNumber(state.numberOfRooms.to)
    };
};

const auctionFilterInitialState = setAuctionStateValues({
    filterId: 0,
    name: '',
    unwantedKeywords: [],
    wantedKeywords: [],
    locations: [],
    city: [],
    types: [],
    alertSms: false,
    alertEmail: false,
    alertPush: false,
    savedFilter: [],
    notificationsDelay: 0,
    includeZeroArea: true,
    dateAddedFrom: '',
    dateAddedTo: '',
    daysAmount: 30,
    module: 'AUCTION',
    savedLocations: {},
    searchLocationResult: [],
    sortType: AuctionSearchingRequestSortTypeEnum.DATE_DESC,
    areaFrom: undefined,
    areaTo: undefined,
    numberOfRoomsFrom: undefined,
    numberOfRoomsTo: undefined,
    floorFrom: undefined,
    floorTo: undefined,
    floorsFrom: undefined,
    floorsTo: undefined
});

export const initialState = {
    auctionFilterInitialState: () => {
        return auctionFilterInitialState;
    }
};

export const getAuctionFiltersFromState = (state: AuctionFiltersStateType): FilterItem[] => {
    return [
        ...getArrayFilters('Lokalizacja', Object.values(state.savedLocations).map((x: CitiesType | DistrictType | FilterLocation) => x.name ?? '').filter(Boolean), 'savedLocations', true),
        ...getArrayFilters('Typ nieruchomości', state.types || [], 'types'),
        ...getRangeFilters('Cena', state.price, 'price', 'zł'),
        ...getRangeFilters('Cena za m²', state.pricePerSquareMeter, 'pricePerSquareMeter'),
        ...getRangeFilters('Metraż', state.area, 'area', 'm2'),
        ...getRangeFilters('Liczba pokoi', state.numberOfRooms, 'numberOfRooms'),
        ...getRangeFilters('Piętro', state.floor, 'floor'),
        ...getRangeFilters('Liczba pięter', state.floors, 'floors'),
        ...getRangeFilters('Rok budowy', state.builtYear, 'builtYear'),
        ...getArrayFilters('Słowa pożądane', state.wantedKeywords, 'wantedKeywords'),
        ...getArrayFilters('Słowa niepożądane', state.unwantedKeywords, 'unwantedKeywords'),
        ...state.daysAmount && !state.dateRangeFilter ? [{ label: 'Ogłoszenia od', value: state.daysAmount === -1 ? 'wszystkie dni' : `${state.daysAmount} dni`, noDeleteButton: true }] : [],
        ...state.dateRangeFilter
            ? [
                { label: 'Data od', value: state.offerAdded.from, noDeleteButton: true },
                { label: 'Data do', value: state.offerAdded.to, filterName: 'offerAdded' }
            ]
            : []
    ];
};

