
import { AxiosResponse } from 'axios/index';
import { axiosApiInstance } from 'Store';
import { convertPrice } from 'utils/convertPrice';
import { getModuleByBrokerType } from 'utils/formatters/getModuleByBrokerType';
import { getSubModuleByBrokerType } from 'utils/formatters/getSubModuleByBrokerType';
import { getSubModuleByType } from 'utils/formatters/getSubModuleByType';
import { stringToNumber } from 'utils/formatters/stringToNumber';
import { BrokerOfferResponse, BrokerOfferResponseContent,
    BrokerSaleOfferDetailsResponse } from 'utils/state-managment/broker/brokerOffer';
import {
    BrokerCreateAdvertStateType,
    BrokerOfferType,
    BrokerRentOfferDetailDto
} from 'utils/types/BrokerAdvert';
import {
    BlockageAdvertisementDto,
    BlockageInfoDto,
    Module,
    PermanentBlockAdvertisementDto,
    UnblockAdvertisementDto
} from 'utils/types/BrokerModels';
import {
    AdvertisementPhotoParamsModuleEnum, SaleFlatOriginalDetailDto,
    SaleHouseOriginalDetailDto,
    SaleOtherOriginalDetailDto,
    SalePlotOriginalDetailDto
} from 'utils/types/BrokerOfferModels';
import { UploadRequestData } from 'utils/types/File';

import { activeNotification } from 'components/functions/activeNotification';

const addTempBlockage = async (data: BlockageAdvertisementDto): Promise<BlockageInfoDto | null> => {
    return axiosApiInstance
        .post('/broker/advertisement/blockage/temporary', {
            ...data
        })
        .then(async () => {
            activeNotification(
                'Ogłoszenie zarezerwowane',
                'Udało się zarezerwować wybrane ogłoszenie',
                'success'
            );

            return await getBlockageInfo(data.advertisementId, data.module);
        })
        .catch(() => {
            activeNotification(
                'Nie udało się zarezerwować ogłoszenia',
                'Nie udało się zarezerwować wybranego ogłoszenia',
                'warning'
            );

            return null;
        });
};

const addBlockage = async (data: PermanentBlockAdvertisementDto): Promise<BlockageInfoDto | null> => {
    return axiosApiInstance
        .post('/broker/advertisement/blockage/permanent', {
            ...data
        })
        .then(async () => {
            activeNotification(
                'Ogłoszenie zarezerwowane',
                'Udało się zarezerwować wybrane ogłoszenie na stałe',
                'success'
            );

            return await getBlockageInfo(data.advertisementId, data.module);
        })
        .catch(() => {
            activeNotification(
                'Nie udało się zarezerwować ogłoszenia',
                'Nie udało się zarezerwować wybranego ogłoszenia na stałe',
                'warning'
            );

            return null;
        });
};

const removeBlockage = async (data: UnblockAdvertisementDto): Promise<boolean | null> => {
    return axiosApiInstance
        .post('/broker/advertisement/blockage/unblock', {
            ...data
        })
        .then(async () => {
            activeNotification(
                'Ogłoszenie odblokowane',
                'Udało się odblokować wybrane ogłoszenie',
                'success'
            );

            return true;
        })
        .catch(() => {
            activeNotification(
                'Nie udało się odblokować ogłoszenia',
                'Nie udało się odblokować wybranego ogłoszenia',
                'warning'
            );

            return null;
        });
};

const getBlockageInfo = async (advertisementId: number, module: Module): Promise<BlockageInfoDto> => {
    return axiosApiInstance
        .get('/broker/advertisement/blockage/info', {
            params: {
                advertisementId,
                module
            }
        })
        .then((resp) => {
            return resp.data;
        })
        .catch(() => {
            activeNotification(
                'Nie udało się pobrać informacji o rezerwacji',
                'Nie udało się pobrać danych szczegółowych rezerwacji',
                'warning'
            );

            return {};
        });
};

const generateImage = async (photoName: string, module: BrokerOfferType): Promise<string> => {
    return axiosApiInstance
        .get('/broker-offer/photo/generate-image', {
            params: {
                photoName,
                module
            }
        })
        .then((resp) => {
            return resp.data;
        })
        .catch(() => {
            activeNotification(
                'Nie udało się pobrać zdjęcia',
                'Nie udało się wygenerować zdjęcia',
                'warning'
            );

            return '';
        });
};

const addImage = (params: UploadRequestData, offerType: BrokerOfferType, module: AdvertisementPhotoParamsModuleEnum): Promise<AxiosResponse<string>> => {
    const formData = new FormData();
    formData.append('file', params.file);

    return axiosApiInstance.post(`/broker-offer/photo/create/${getModuleByBrokerType(offerType)}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        params: {
            module
        }
    })
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                activeNotification(
                    'Błąd',
                    err.response.data,
                    'danger'
                );

                return Promise.reject(err);
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );

                return Promise.reject(err);
            }
        });
};

const handleAddOffer = (params: BrokerCreateAdvertStateType, offerType: BrokerOfferType): Promise<AxiosResponse> => {
    const requestParams = {
        ...params,
        price: stringToNumber(params.price),
        depositPrice: stringToNumber(params.depositPrice),
        rentPrice: stringToNumber(params.rentPrice),
        area: stringToNumber(params.area),
        floor: stringToNumber(params.floor),
        floors: stringToNumber(params.floors),
        numberOfFloors: stringToNumber(params.numberOfFloors),
        numberOfRooms: stringToNumber(params.numberOfRooms),
        availableFrom: params.availableFrom ? new Date(params.availableFrom) : undefined,
        originalEncryptedId: params.encryptedId
    };

    return axiosApiInstance({
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        url: `/broker-offer/${getModuleByBrokerType(offerType)}/create/${getSubModuleByBrokerType(params.module)}`,
        data: requestParams
    })
        .then((resp) => {
            activeNotification(
                'Udało się!',
                'Ogłoszenie zostało zapisane',
                'success'
            );

            return resp;
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                activeNotification(
                    'Błąd',
                    err.response.data,
                    'danger'
                );

                return Promise.reject(err);
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );

                return Promise.reject(err);
            }
        });
};

const handleUpdateOffer = (params: BrokerCreateAdvertStateType, offerType: BrokerOfferType): Promise<AxiosResponse> => {
    const requestParams = {
        ...params,
        price: stringToNumber(params.price),
        depositPrice: stringToNumber(params.depositPrice),
        rentPrice: stringToNumber(params.rentPrice),
        area: stringToNumber(params.area),
        floor: stringToNumber(params.floor),
        floors: stringToNumber(params.floors),
        numberOfFloors: stringToNumber(params.numberOfFloors),
        numberOfRooms: stringToNumber(params.numberOfRooms),
        availableFrom: params.availableFrom ? new Date(params.availableFrom) : undefined
    };

    return axiosApiInstance({
        headers: { 'Content-Type': 'application/json' },
        method: 'PUT',
        url: `/broker-offer/${getModuleByBrokerType(offerType)}/edit/${getSubModuleByBrokerType(params.module)}`,
        data: requestParams
    })
        .then((resp) => {
            activeNotification(
                'Udało się!',
                'Ogłoszenie zostało zaktualizowane',
                'success'
            );

            return resp;
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                activeNotification(
                    'Błąd',
                    err.response.data,
                    'danger'
                );

                return Promise.reject(err);
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );

                return Promise.reject(err);
            }
        });
};

const getOfferToEdit = (offerId: string, offerType: BrokerOfferType): Promise<AxiosResponse<BrokerCreateAdvertStateType>> => {
    return axiosApiInstance.get(`/broker-offer/${getModuleByBrokerType(offerType)}/detail-for-edit/${getSubModuleByType(offerType)}?advertisementId=${offerId}`)
        .then((resp) => {
            return resp;
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                activeNotification(
                    'Błąd',
                    err.response.data,
                    'danger'
                );

                return Promise.reject(err);
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );

                return Promise.reject(err);
            }
        });
};

const getSaleBrokerOffers = (page: number, size: number, submodel?: string): Promise<BrokerOfferResponse> => {
    // @todo: zmienić na moduleParams po zmianie api

    const subModuleParams = submodel ? submodel : 'all';

    return axiosApiInstance({
        method: 'get',
        url: `/broker-offer/sale/advertisements-list/${subModuleParams}`,
        params: {
            page,
            size
        }
    })
        .then((resp) => {
            if (resp.status === 204) {
                return { empty: true };
            }

            if (resp.data) {
                return {
                    ...resp.data,
                    content: resp.data.content.map((el: BrokerOfferResponseContent) => ({
                        ...el,
                        price: el.price ? convertPrice(el.price) : ''
                    }))

                };
            }
        })
        .catch(() => {
            activeNotification(
                'Nie znaleziono wyników',
                'Wyszukiwanie nie powiodło się, spróbuj jeszcze raz.',
                'warning'
            );

            return [{ empty: true }];
        });
};

const getRentBrokerOffers = (page: number, size: number, submodel?: string): Promise<BrokerOfferResponse> => {
    const subModuleParams = submodel ? submodel : 'all';

    // @todo: zmienić na moduleParams po zmianie api
    return axiosApiInstance({
        method: 'get',
        url: `/broker-offer/rent/advertisements-list/${subModuleParams}`,
        params: {
            page,
            size
        }
    })
        .then((resp) => {
            if (resp.status === 204) {
                return { empty: true };
            }

            if (resp.data) {
                return {
                    ...resp.data
                };
            }
        })
        .catch(() => {
            activeNotification(
                'Nie znaleziono wyników',
                'Wyszukiwanie nie powiodło się, spróbuj jeszcze raz.',
                'warning'
            );

            return [{ empty: true }];
        });
};

const getSaleOfferDetails = (subtype: string, offerId: string): Promise<SaleOtherOriginalDetailDto | SaleFlatOriginalDetailDto | SalePlotOriginalDetailDto | SaleHouseOriginalDetailDto> => {
    return axiosApiInstance.get(`/broker-offer/sale/original-detail/${subtype}?advertisementId=${offerId}`)
        .then((resp) => {
            if (resp.data) {
                return resp.data;
            }
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                activeNotification(
                    'Błąd',
                    err.response.data,
                    'danger'
                );

                return Promise.reject(err);
            } else {
                activeNotification(
                    'Nie udało się pobrać ogłoszenia',
                    'Nie udało się pobrać danych szczegółowych ogłoszenia',
                    'warning'
                );

                return Promise.reject(err);
            }
        });
};

const getRentOfferDetails = (subtype: string, offerId: string): Promise<BrokerRentOfferDetailDto> => {
    return axiosApiInstance.get(`/broker-offer/rent/original-detail/${subtype}?advertisementId=${offerId}`)
        .then((resp) => {
            if (resp.data) {
                return resp.data;
            }
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                activeNotification(
                    'Błąd',
                    err.response.data,
                    'danger'
                );

                return null;
            } else {
                activeNotification(
                    'Nie udało się pobrać ogłoszenia',
                    'Nie udało się pobrać danych szczegółowych ogłoszenia',
                    'warning'
                );

                return null;
            }
        });
};

const getBrokerOfferDetailsForEdit = (type: string, subtype: string, offerId: number): Promise<BrokerSaleOfferDetailsResponse> => {
    return axiosApiInstance.get(`/broker-offer/${type}/detail-for-edit/${subtype}?advertisementId=${offerId}`)
        .then((resp) => {
            if (resp.data) {
                return resp.data as BrokerSaleOfferDetailsResponse;
            }
            throw new Error('No data received');
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                activeNotification(
                    'Błąd',
                    err.response.data,
                    'danger'
                );
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );
            }

            return Promise.reject(err);
        });
};

const deleteBrokerOffer = (offerId: number, subtype: string) => {
    return axiosApiInstance.delete(`/broker-offer/${getModuleByBrokerType(subtype)}/delete?module=${subtype}&advertisementId=${offerId}`)
        .then(() => {
            activeNotification('Udało się!', 'Oferta została usunięta', 'success');
        })
        .catch((err) => {
            if (err?.response?.status === 404) {
                activeNotification('Problem z usunięciem', 'Niestety nie udało się usunąć danych. Spróbuj ponownie później', 'danger');
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );
            }

            return Promise.reject(err);
        });
};

const changeActivationStatus = (offerId: number, subtype: string, status: boolean) => {
    return axiosApiInstance.put(`broker-offer/${getModuleByBrokerType(subtype)}/update/status?module=${subtype}&advertisementId=${offerId}&status=${status}`)
        .then(() => {
            activeNotification('Udało się!', 'Status został zmieniony', 'success');
        })
        .catch((err) => {
            if (err?.response?.status === 404) {
                activeNotification('Problem z zmianą statusu', 'Niestety nie udało się zmienić statusu. Spróbuj ponownie później', 'danger');
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );
            }

            return Promise.reject(err);
        });
};

const generateAiTitle = (encryptedId: string): Promise<string> => {
    return axiosApiInstance.post('broker-offer/ai/generate/title', {
        encryptedId
    })
        .then((response) => {
            if (response.data) {
                activeNotification('Udało się!', 'Tytuł został wygenerowany', 'success');

                return response.data;
            }
        })
        .catch((err) => {
            if (err?.response?.status === 404) {
                activeNotification('Problem z wygenerowaniem tytułu', 'Niestety nie udało się wygenerować tytułu. Spróbuj ponownie później', 'danger');
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );
            }

            return Promise.reject(err);
        });
};

const generateAiDescription = (encryptedId: string): Promise<string> => {
    return axiosApiInstance.post('broker-offer/ai/generate/description', {
        encryptedId
    })
        .then((response) => {
            if (response.data) {
                activeNotification('Udało się!', 'Opis został wygenerowany', 'success');

                return response.data;
            }
        })
        .catch((err) => {
            if (err?.response?.status === 404) {
                activeNotification('Problem z wygenerowaniem opisu', 'Niestety nie udało się wygenerować opisu. Spróbuj ponownie później', 'danger');
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );
            }

            return Promise.reject(err);
        });
};

const activeNotificationAboutEndBlockage = (advertisementId: number, module: string) => {
    return axiosApiInstance.post('broker/advertisement/notification/add', {
        advertisementId,
        module
    })
        .then(() => {
            activeNotification('Udało się!', 'Powiadomienie o odblokowanej ofercie zostało aktywowane', 'success');
        })
        .catch((err) => {
            if (err?.response?.status === 404) {
                activeNotification('Problem z aktualizacją powiadomieniem', 'Niestety nie udało się aktywować powiadomienia o odblokowanej ofercie. Spróbuj ponownie później', 'danger');
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );
            }

            return Promise.reject(err);
        });
};

const deactiveNotificationAboutEndBlockage = (advertisementId: number, module: string) => {
    return axiosApiInstance.delete(`broker/advertisement/notification/delete?advertisementId=${advertisementId}&module=${module}`)
        .then(() => {
            activeNotification('Udało się!', 'Powiadomienie o odblokowanej ofercie zostało wyłączone', 'success');
        })
        .catch((err) => {
            if (err?.response?.status === 404) {
                activeNotification('Problem z aktualizacją powiadomienia', 'Niestety nie udało się dezaktywować powiadomienia o odblokowanej ofercie. Spróbuj ponownie później', 'danger');
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );
            }

            return Promise.reject(err);
        });
};

export const brokerApiRequests = {
    addTempBlockage,
    getBlockageInfo,
    removeBlockage,
    addBlockage,
    addImage,
    handleAddOffer,
    getOfferToEdit,
    getSaleOfferDetails,
    getRentOfferDetails,
    getSaleBrokerOffers,
    getRentBrokerOffers,
    getBrokerOfferDetailsForEdit,
    deleteBrokerOffer,
    changeActivationStatus,
    handleUpdateOffer,
    generateImage,
    generateAiTitle,
    generateAiDescription,
    activeNotificationAboutEndBlockage,
    deactiveNotificationAboutEndBlockage
};
