import { AdvertisementPhotoParamsModuleEnum } from 'utils/types/BrokerOfferModels';

export const getSubModuleByBrokerType = (type: AdvertisementPhotoParamsModuleEnum) => {
    switch (type) {
    case AdvertisementPhotoParamsModuleEnum.OFFER_SALE_FLAT:
    case AdvertisementPhotoParamsModuleEnum.OFFER_RENT_FLAT:
        return 'flat';
    case AdvertisementPhotoParamsModuleEnum.OFFER_SALE_HOUSE:
    case AdvertisementPhotoParamsModuleEnum.OFFER_RENT_HOUSE:
        return 'house';
    case AdvertisementPhotoParamsModuleEnum.OFFER_SALE_OTHER:
    case AdvertisementPhotoParamsModuleEnum.OFFER_RENT_OTHER:
        return 'other';
    case AdvertisementPhotoParamsModuleEnum.OFFER_SALE_PLOT:
    case AdvertisementPhotoParamsModuleEnum.OFFER_RENT_PLOT:
        return 'plot';
    default:
        return '';
    }
};
