import dayjs from 'dayjs';
import { stringToNumber } from 'utils/formatters/stringToNumber';
import { getArrayFilters, getRangeFilters } from 'utils/generateActiveFiltersContent';
import { getNameFromModule } from 'utils/getNameFromModule';
import { RentFilterReturnData, RentFiltersStateType } from 'utils/types/Rent';
import { Module, SortType } from 'utils/types/RentModels';

import { FilterItem } from 'components/common/V2ActiveFilters';
import { dateFormat, dateFromFunc, dateToFunc } from 'components/FilterFunctions';

export const setStateValues = (state: RentFilterReturnData): RentFiltersStateType => {
    const dateFromWithDefault = state.offerAddedFrom || dayjs().subtract(3, 'day').toISOString() as string;

    return {
        ...state,
        id: state.id === 0 ? null : state.id,
        tossedUp: state.tossedUp ?? true,
        notificationsDelay: state.notificationsDelay?.toString() ?? '0',
        groupDuplicate: state.groupDuplicate ?? true,
        includeZeroArea: state.includeZeroArea ?? true,
        modification: state.modification ?? true,
        module: state.module,
        alertEmail: state.alertEmail ?? false,
        alertSms: state.alertSms ?? false,
        pushAlert: state.pushAlert ?? false,
        locations: state.locations ?? [],
        searchLocationResult: state.searchLocationResult ?? [],
        savedLocations: state.savedLocations ?? {},
        offerFrom: state.offerFrom ?? [],
        sortType: state.sortType,
        // @ts-expect-error TODO: INVEST-254 fix this
        city: state.city ?? [],
        wantedKeywords: state.wantedKeywords ?? [],
        unwantedKeywords: state.unwantedKeywords ?? [],
        wantedPhoneNumbers: state.wantedPhoneNumbers ?? [],
        unwantedPhoneNumbers: state.unwantedPhoneNumbers ?? [],
        numberOfRooms: {
            from: state.numberOfRoomsFrom ?? null,
            to: state.numberOfRoomsTo ?? null
        },
        offerAdded: {
            from: dateFromWithDefault && dateFromWithDefault.includes('T') ? dateFromWithDefault.split('T')[0] : dateFromWithDefault,
            to: state.offerAddedTo && state.offerAddedTo.includes('T') ? state.offerAddedTo.split('T')[0] : state.offerAddedTo || ''
        },
        floor: {
            from: state.floorFrom ?? '',
            to: state.floorTo ?? ''
        },
        floors: {
            from: state.floorsFrom ?? '',
            to: state.floorsTo ?? ''
        },
        area: {
            from: state.areaFrom ?? '',
            to: state.areaTo ?? ''
        },
        pricePerSquareMeter: {
            from: state.pricePerSquareMeterFrom ?? '',
            to: state.pricePerSquareMeterTo ?? ''
        },
        buildingType: state.buildingType ?? [],
        price: {
            from: state.priceFrom ?? '',
            to: state.priceTo ?? ''
        },
        depositPrice: {
            from: state.depositPriceFrom ?? '',
            to: state.depositPriceTo ?? ''
        },
        rentPrice: {
            from: state.rentPriceFrom ?? '',
            to: state.rentPriceTo ?? ''
        },
        builtYear: {
            from: state.builtYearFrom ?? '',
            to: state.builtYearTo ?? ''
        },
        savedFilter: state.savedFilter ?? [],
        daysAmount: state.daysAmount ?? 3,
        dateRangeFilter: !!state.offerAddedTo,
        lowerOriginalPrice: state.lowerOriginalPrice ?? false,
        unlockedAdvertisements: state.unlockedAdvertisements ?? false
    };
};

export const getValueFromState = (state: RentFiltersStateType): RentFilterReturnData => {
    const dataToReturn = {
        ...state,
        name: state.name ? state.name : '',
        module: state.module,
        city: state.city ? state.city : [],
        offerAddedTo: state.offerAdded.to ? dateFormat(dateFromFunc(state.offerAdded.to)) : null,
        offerAddedFrom: state.offerAdded?.from ? dateFormat(dateToFunc(state.offerAdded.from)) : null,
        areaFrom: stringToNumber(state.area.from),
        areaTo: stringToNumber(state.area.to),
        priceFrom: stringToNumber(state.price.from),
        priceTo: stringToNumber(state.price.to),
        depositPriceFrom: stringToNumber(state.depositPrice.from),
        depositPriceTo: stringToNumber(state.depositPrice.to),
        rentPriceFrom: stringToNumber(state.rentPrice.from),
        rentPriceTo: stringToNumber(state.rentPrice.to),
        pricePerSquareMeterFrom: stringToNumber(state.pricePerSquareMeter.from),
        pricePerSquareMeterTo: stringToNumber(state.pricePerSquareMeter.to),
        numberOfRoomsFrom: stringToNumber(state.numberOfRooms.from),
        numberOfRoomsTo: stringToNumber(state.numberOfRooms.to),
        builtYearFrom: stringToNumber(state.builtYear.from),
        builtYearTo: stringToNumber(state.builtYear.to),
        buildingType:
        state.buildingType?.length > 0
            ? state.buildingType
            : [],
        floorFrom: stringToNumber(state.floor.from),
        floorTo: stringToNumber(state.floor.to),
        floorsFrom: stringToNumber(state.floors.from),
        floorsTo: state.floors?.to ? Number(state.floors.to) : undefined,
        typeOfMarket: state.typeOfMarket?.length > 0 ? state.typeOfMarket : [],
        savedFilter: undefined,
        lowerOriginalPrice: state.lowerOriginalPrice ?? false,
        unlockedAdvertisements: state.unlockedAdvertisements ?? false,
        dateRangeFilter: !!state.offerAdded

    };

    // @todo sprawdzić jak naprawić typy dla buidingType
    return dataToReturn as unknown as RentFilterReturnData;
};

const rentFilterInitialState = setStateValues({
    id: 0,
    name: '',
    numberOfRoomsFrom: undefined, // flats, houses
    numberOfRoomsTo: undefined, // flats, houses
    floorFrom: undefined, // flats, houses
    floorTo: undefined, // flats, houses
    floorsFrom: undefined, // flats
    floorsTo: undefined, // flats
    unwantedKeywords: [],
    wantedKeywords: [],
    unwantedPhoneNumbers: [],
    wantedPhoneNumbers: [],
    city: [],
    locations: [],
    offerFrom: [],
    savedLocations: {},
    searchLocationResult: [],
    areaFrom: undefined,
    areaTo: undefined,
    typeOfMarket: [], // flats, other, houses
    buildingType: [], // flats, houses
    priceFrom: undefined,
    priceTo: undefined,
    rentPriceFrom: undefined,
    rentPriceTo: undefined,
    pricePerSquareMeterFrom: undefined,
    pricePerSquareMeterTo: undefined,
    builtYearFrom: undefined, // flats, houses, other
    builtYearTo: undefined, // flats, houses, other
    alertSms: false,
    alertEmail: false,
    pushAlert: false,
    savedFilter: [],
    module: Module.RENT_FLAT,
    tossedUp: true,
    notificationsDelay: 0,
    includeZeroArea: true,
    groupDuplicate: true,
    depositPriceFrom: undefined,
    depositPriceTo: undefined,
    offerAddedFrom: '',
    offerAddedTo: '',
    modification: true,
    daysAmount: 3,
    sortType: SortType.DATE_DESC,
    dateRangeFilter: false,
    lowerOriginalPrice: false,
    unlockedAdvertisements: false
});

export const initialState = {
    filterInitialState: () => {
        return rentFilterInitialState;
    }
};

export const getRentFiltersFromState = (state: RentFiltersStateType): FilterItem[] => {
    return [
        ...getArrayFilters('Lokalizacja', Object.values(state.savedLocations).map((x) => x.fullName), 'savedLocations', true),
        ...getRangeFilters('Cena', state.price, 'price', 'zł'),
        ...getRangeFilters('Metraż', state.area, 'area', 'm2'),
        ...getRangeFilters('Liczba pokoi', state.numberOfRooms, 'numberOfRooms'),
        ...getRangeFilters('Piętro', state.floor, 'floor'),
        ...getRangeFilters('Liczba pięter', state.floors, 'floors'),
        ...getRangeFilters('Rok budowy', state.builtYear, 'builtYear'),
        ...getRangeFilters('Cena za m²', state.pricePerSquareMeter, 'pricePerSquareMeter'),
        ...getRangeFilters('Czynsz', state.rentPrice, 'rentPrice', 'zł'),
        ...getRangeFilters('Kaucja', state.depositPrice, 'depositPrice', 'zł'),
        ...getArrayFilters('Typ budynku', state.buildingType, 'buildingType'),
        ...getArrayFilters('Oferta od', state.offerFrom, 'offerFrom'),
        ...getArrayFilters('Rynek', state.typeOfMarket, 'typeOfMarket'), // @todo: sprawdzić dlaczego jak jest odkomentowane powoduje błąd
        ...getArrayFilters('Słowa pożądane', state.wantedKeywords, 'wantedKeywords'),
        ...getArrayFilters('Słowa niepożądane', state.unwantedKeywords, 'unwantedKeywords'),
        ...getArrayFilters('Numery pożądane', state.wantedPhoneNumbers, 'wantedPhoneNumbers'),
        ...getArrayFilters('Numery niepożądane', state.unwantedPhoneNumbers, 'unwantedPhoneNumbers'),
        { label: 'Typ nieruchomości', value: getNameFromModule(state.module), index: 0, noDeleteButton: true },
        ...state.daysAmount && !state.dateRangeFilter ? [{ label: 'Ogłoszenia od', value: state.daysAmount === -1 ? 'wszystkie dni' : `${state.daysAmount} dni`, noDeleteButton: true }] : [],
        ...state.dateRangeFilter
            ? [
                { label: 'Data od', value: state.offerAdded.from, noDeleteButton: true },
                { label: 'Data do', value: state.offerAdded.to, filterName: 'offerAdded' }
            ]
            : [],
        ...state.lowerOriginalPrice ? [{ label: 'Pokaż z niższą ceną', value: 'tak', noDeleteButton: true }] : [],
        ...state.unlockedAdvertisements ? [{ label: 'Odblokowane', value: 'tak', noDeleteButton: true }] : []
    ];
};
