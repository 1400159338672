import React, { FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import AdminPanelContext from 'utils/context/AdminPanelContex';

import Button from 'components/atom/Button';
import { IconEnum } from 'components/common/Icon';
import InputContainer from 'components/common/InputContainer';
import TextInput from 'components/common/Inputs/TextInput';
import Modal from 'components/common/Modal/Modal';
import { activeNotification } from 'components/functions/activeNotification';
import V2HeaderSection from 'components/V2HeaderSection';

const Wrapper = styled.div`
  padding: 0 20px;
`;

const Container = styled.div`
    padding: 34px 15px 32px 15px;
    border-radius: var(--box-border-radius);
    background: var(--color-white);
`;

const FormContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin-bottom: 30px;
  
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const FormButtonsWrapper = styled.div`
    grid-column: span 2;
    margin: 60px auto 30px;
    
    @media (max-width: 768px) {
        grid-column: span 1;
    }
`;

const ButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    margin-top: 20px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const LeftButtonsGroup = styled.div`
    display: flex;
    gap: 15px;
    width: 100%;
    justify-content: space-between;

    > span {
        width: 45%;
    }

    button {
        width: 100%;
    }

    @media (max-width: 768px) {
        flex-direction: column;

        > span {
        width: 100%;
        }
    }
`;

const RightButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;

    > span {
        width: 40%;
    }

    button {
        width: 100%;
    }

    @media (max-width: 768px) {
        width: 100%;
        display: block;
         
        > span {
            width: 100%;
        }

    }
`;

const PasswordModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    padding: 40px;

`;

const ModalContainer = styled.div`
    display: flex;
    gap: 50px;
    justify-content: center;
    width: 90%;

    > span {
        width: 100%;
    }
    
    &:last-child {
        margin-top: 20px;

        button {
            min-width: 220px;
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 15px;
    }
`;

const EditAgentFormPage: FC = () => {
    const { user } = useUser();
    const { editAgent, clearFields, editAgentFieldsState, handleEditChange, validateAgentFields, changeAgentPassword, validatePasswordFields, passwordFieldsState, handlePasswordChange, loadAgentDetails, adminPanelState } = useContext(AdminPanelContext);
    const navigate = useNavigate();
    const { agentId } = useParams();
    const [triggerValidation, setTriggerValidation] = useState(false);
    const [showPasswordForm, setShowPasswordForm] = useState(false);

    useEffect(() => {
        if (agentId) {
            loadAgentDetails(agentId);
        }
    }, [agentId]);

    const handleSubmit = () => {
        setTriggerValidation(true);
        const errors = validateAgentFields(editAgentFieldsState);
        const hasErrors = Object.values(errors).some((error) => error !== '');

        if (hasErrors) {
            activeNotification('Dane', 'Wypełnij poprawnie wszystkie wymagane pola', 'warning');

            return;
        }

        editAgent(editAgentFieldsState);
        navigate('/admin-panel');
    };

    const handlePasswordSubmit = () => {
        const errors = validatePasswordFields(passwordFieldsState.password, passwordFieldsState.confirmPassword);
        const hasErrors = Object.values(errors).some((error) => error !== '');

        if (hasErrors) {
            activeNotification('Dane', 'Wypełnij poprawnie wszystkie pola', 'warning');

            return;
        }

        changeAgentPassword({
            agentId: editAgentFieldsState.agentId,
            password: passwordFieldsState.password,
            confirmPassword: passwordFieldsState.confirmPassword
        });

        setShowPasswordForm(false);
        handlePasswordChange('password', '');
        handlePasswordChange('confirmPassword', '');
    };

    const handleCancel = () => {
        clearFields();
        navigate('/admin-panel');
    };

    return (
        <Wrapper>
            <V2HeaderSection title="Edytuj agenta" />
            <Container>
                <FormContainer>
                    <InputContainer title="Imię" icon={IconEnum.USER} required>
                        <TextInput
                            value={editAgentFieldsState.firstName}
                            onChange={(e) => handleEditChange('firstName', e.target.value)}
                            placeholder="Wprowadź imię agenta..."
                            required
                            errorMessage={validateAgentFields(editAgentFieldsState).firstName}
                            inputValidation={() => !!validateAgentFields(editAgentFieldsState).firstName}
                            triggerValidation={triggerValidation}
                        />
                    </InputContainer>
                    <InputContainer title="Nazwisko" icon={IconEnum.USER} required>
                        <TextInput
                            value={editAgentFieldsState.lastName}
                            onChange={(e) => handleEditChange('lastName', e.target.value)}
                            placeholder="Wprowadź nazwisko agenta..."
                            required
                            errorMessage={validateAgentFields(editAgentFieldsState).lastName}
                            inputValidation={() => !!validateAgentFields(editAgentFieldsState).lastName}
                            triggerValidation={triggerValidation}
                        />
                    </InputContainer>

                    <InputContainer title="Email" icon={IconEnum.EMAIL} required>
                        <TextInput
                            value={editAgentFieldsState.email}
                            onChange={(e) => handleEditChange('email', e.target.value)}
                            placeholder="Wprowadź adres email..."
                            required
                            errorMessage={validateAgentFields(editAgentFieldsState).email}
                            inputValidation={() => !!validateAgentFields(editAgentFieldsState).email}
                            triggerValidation={triggerValidation}
                        />
                    </InputContainer>

                    <InputContainer title="Numer telefonu" icon={IconEnum.PHONE} required>
                        <TextInput
                            value={editAgentFieldsState.phoneNumber}
                            onChange={(e) => {
                                const onlyDigits = e.target.value.replace(/\D/g, '');
                                const limitedDigits = onlyDigits.slice(0, 9);
                                handleEditChange('phoneNumber', limitedDigits);
                            }}
                            placeholder="Wprowadź 9 cyrowy numer telefonu..."
                            required
                            errorMessage={validateAgentFields(editAgentFieldsState).phoneNumber}
                            inputValidation={() => !!validateAgentFields(editAgentFieldsState).phoneNumber}
                            triggerValidation={triggerValidation}
                        />
                    </InputContainer>

                    <InputContainer title="Liczba SMS" icon={IconEnum.MESSAGE} helperText={`Dostępna pula SMS: ${adminPanelState.totalSmsAvailableToNewAccounts}`} required>
                        <TextInput
                            value={editAgentFieldsState.smsPackage ? editAgentFieldsState.smsPackage.toString() : '0'}
                            onChange={(e) => handleEditChange('smsPackage', parseInt(e.target.value) || 0)}
                            placeholder="Wprowadź liczbę SMS..."
                            required
                            errorMessage={validateAgentFields(editAgentFieldsState).smsPackage}
                            inputValidation={() => !!validateAgentFields(editAgentFieldsState).smsPackage}
                            triggerValidation={triggerValidation}
                        />
                    </InputContainer>

                </FormContainer>

                <FormButtonsWrapper>
                    <ButtonsContainer>
                        <LeftButtonsGroup>
                            <InputContainer
                                title={'Wróć do panelu admina'}
                                icon={IconEnum.DICTIONARY}
                                onActionClick={handleCancel}
                            >
                                <Button important onClick={handleCancel}>Anuluj</Button>
                            </InputContainer>
                            <InputContainer
                                title={'Zmień hasło'}
                                icon={IconEnum.DICTIONARY}
                            >
                                <Button onClick={() => setShowPasswordForm(true)} >Zmień hasło</Button>
                            </InputContainer>
                        </LeftButtonsGroup>

                        <RightButtonGroup>
                            <InputContainer
                                title={'Zapisz'}
                                icon={IconEnum.DICTIONARY}
                            >
                                <Button onClick={handleSubmit}>Zapisz zmiany</Button>
                            </InputContainer>
                        </RightButtonGroup>
                    </ButtonsContainer>
                </FormButtonsWrapper>

            </Container>

            <Modal
                isOpen={showPasswordForm}
                onClose={() => setShowPasswordForm(false)}
                centeredContent
                backgroundColor={user.isDarkMode ? 'var(--color-alt-hover)' : '#fff'}
                strictContentHeight
            >
                <PasswordModalContainer>
                    <h1>Zmiana hasła</h1>
                    <ModalContainer>
                        <InputContainer title="Nowe hasło" icon={IconEnum.BLOCKED} required>
                            <TextInput
                                type="password"
                                value={passwordFieldsState.password}
                                onChange={(e) => handlePasswordChange('password', e.target.value)}
                                placeholder="Min. 8 znaków, duża litera i cyfra"
                                required
                                errorMessage={validatePasswordFields(passwordFieldsState.password, passwordFieldsState.confirmPassword).password}
                                inputValidation={() => !!validatePasswordFields(passwordFieldsState.password, passwordFieldsState.confirmPassword).password}
                                triggerValidation={triggerValidation}
                            />
                        </InputContainer>

                        <InputContainer title="Potwierdź hasło" icon={IconEnum.BLOCKED} required>
                            <TextInput
                                type="password"
                                value={passwordFieldsState.confirmPassword}
                                onChange={(e) => handlePasswordChange('confirmPassword', e.target.value)}
                                placeholder="Potwierdź nowe hasło..."
                                required
                                errorMessage={validatePasswordFields(passwordFieldsState.password, passwordFieldsState.confirmPassword).confirmPassword}
                                inputValidation={() => !!validatePasswordFields(passwordFieldsState.password, passwordFieldsState.confirmPassword).confirmPassword}
                                triggerValidation={triggerValidation}
                            />
                        </InputContainer>
                    </ModalContainer>

                    <ModalContainer>
                        <Button onClick={handlePasswordSubmit}>
                            Zapisz hasło
                        </Button>
                    </ModalContainer>
                </PasswordModalContainer>
            </Modal>

        </Wrapper>
    );
};

export default EditAgentFormPage;
