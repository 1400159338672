import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { isTouchDevice } from 'utils/checkIsTouchDevice';

import Tooltip from 'components/common/Tooltip/Tooltip';

const RatingBarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: var(--color-alt-second);
 
`;

const RatingTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    div {
        display: flex;
        align-items: center;
        gap: 4px;
        background: var(--color-alt-second);
        border-radius: 4px;
        padding: 0 8px;
        font-size: var(--font-size-body-4);
        line-height: 20px;
    }
    
`;

const RatingBottom = styled.div`
    display: flex;
    border-radius: 4px;
    background: var(--color-alt-second);
    padding: 8px;
    overflow: hidden;
    position: relative;
`;

const ProgressContainer = styled.div`
    display: flex;
    width: 126px; // 9 prostokątów * 14px
    border-radius: 4px;
    
    position: relative;
    height: 8px;
`;

const ColorBlock = styled.div<{ color: string; isFirst: boolean; isLast: boolean; isHalf: boolean, isActive: boolean }>`
    height: 8px;
    background-color: ${({ color }) => color};
    border-radius: ${({ isFirst, isLast, isActive }) => `
        ${isFirst ? '4px' : '0'} ${isLast && !isActive ? '4px' : '0'}  ${isLast && !isActive ? '4px' : '0'} ${isFirst ? '4px' : '0'}
    `};
    width: ${({ isHalf }) => isHalf ? '8px' : '14px'};
`;

const Divider = styled.div<{ left: string }>`
    height: 12px;
    color: var(--color-alt-second);
    width: 2px;
    background: #000000;
    z-index: 1;
    position: absolute;
    left: ${({ left }) => left};
    top: 50%;
    transform: translateY(-50%); 
`;

const ToolipContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

interface TooltipContentBoxProps {
    smallFontSize?: boolean,
}

const TooltipContentBox = styled.div<TooltipContentBoxProps>`
    display: flex;
    flex-direction: column;
    gap: ${({ smallFontSize }) => smallFontSize ? '10px' : '0'};

    span {
        font-size: ${({ smallFontSize }) => smallFontSize ? 'var(--font-size-body-3)' : 'var(--font-size-body-1)'};
        max-width: 375px;
    }
`;

interface RatingBarProps {
    score: number;
}

const blockColors = [
    '#880000',
    '#BA000B',
    '#ED811D',
    '#EDBC1D',
    '#EDDF1D',
    '#A1ED1D',
    '#6ED20B',
    '#009118',
    '#056200'
];

interface RatingBarProps {
    score: number;
    scorePrecision?: number;
    isCardBox?: boolean;
}

const RatingBar: FC<RatingBarProps> = ({ score, scorePrecision, isCardBox }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [isTouch, setIsTouch] = useState(false);

    const getPercentageScorePrecision = (scorePrecision: number | null) => {
        if (scorePrecision === null) return null;

        if (scorePrecision >= 0 && scorePrecision <= 10) {
            return `${scorePrecision * 10}%`;
        }

        return null;
    };

    useEffect(() => {
        setIsTouch(isTouchDevice());
    }, []);

    const handleTooltipToggle = (e: React.MouseEvent | React.TouchEvent) => {
        e.stopPropagation();

        if (isTouch) {
            setShowTooltip((prev) => !prev);
        }
    };

    const tooltipRatingDetails = score && scorePrecision !== null
        ? <ToolipContainer>
            <TooltipContentBox>
                <span>* Ocena {score}/6</span>
                <span>** Precyzja oceny {getPercentageScorePrecision(scorePrecision || 0)}</span>
            </TooltipContentBox>
            <TooltipContentBox smallFontSize>
                <span>* Ocena - wskazuje, jak cena nieruchomości wypada na tle innych ofert o podobnych parametrach w okolicy.</span>
                <span>** Precyzja - określa dokładność porównania: im wyższy procent, tym bardziej zbliżone parametry nieruchomości</span>
            </TooltipContentBox>

        </ToolipContainer>
        : null;

    const activeBlocks = useMemo(() => score / 6 * 9, [score]);
    const fullBlocksCount = Math.floor(activeBlocks);
    const hasHalfBlock = activeBlocks % 1 !== 0;
    const dividerPosition = `${fullBlocksCount * 14 + (hasHalfBlock ? 8 : 0)}px`;

    const colorBlockProperties = useMemo(() => {
        return blockColors.map((defaultColor, index) => {
            const isActive = index < fullBlocksCount || index === fullBlocksCount && hasHalfBlock;
            const color = isActive ? defaultColor : '#FFFFFF';
            const isHalf = hasHalfBlock && index === fullBlocksCount;

            return {
                key: index,
                color: color,
                isFirst: index === 0,
                isLast: index === 8,
                isHalf: isHalf,
                isActive: isActive
            };
        });
    }, [
        activeBlocks,
        fullBlocksCount,
        hasHalfBlock
    ]);

    return (
        <>
            <RatingBarWrapper ref={setReferenceElement}
                onClick={handleTooltipToggle}
                onMouseEnter={() => !isTouch && setShowTooltip(true)}
                onMouseLeave={() => !isTouch && setShowTooltip(false)}
                data-testid="rating-bar"
            >
                <RatingTop>
                    <div>Powyżej | Średnia | Poniżej</div>
                </RatingTop>
                <RatingBottom>
                    <ProgressContainer>
                        {colorBlockProperties.map(({ key, ...props }) => <ColorBlock role="progressbar" key={key} {...props} />)}
                        {activeBlocks > 0 && <Divider data-testid="rating-divider" left={dividerPosition} />}
                    </ProgressContainer>
                </RatingBottom>

            </RatingBarWrapper>
            {isCardBox && tooltipRatingDetails ? <Tooltip referenceElement={referenceElement} showTooltip={showTooltip} text={tooltipRatingDetails}/> : null}
        </>
    );
};

export default RatingBar;

