import React from 'react';
import { useUser } from 'store/User/Context';

import { HeaderStyle, LegendList, SectionBox } from 'components/analysis/AnalysisSaleList';
import LineBarWrapper from 'components/common/LineBarWrapper';

interface TimeRangeAverageMeterAndTotalPriceProps {
    averagePriceAreaByTime: { name: string; line: number; bar: number; }[];
}

export const TimeRangeAverageMeterAndTotalPrice = ({ averagePriceAreaByTime }: TimeRangeAverageMeterAndTotalPriceProps) => {
    const { user } = useUser();
    const legendListText = [' zł średnia cena za m²', ' zł średnia cena całkowita'];

    return (
        <SectionBox data-pdf-section>
            <HeaderStyle>
                <h2>Średnia cena metra kwadratowego oraz średnia cena całkowita w okresach czasowych</h2>
                <LegendList darkMode={user.isDarkMode}>
                    <li>Średnia cena za m² brutto [PLN]</li>
                    <li>Średnia cena całkowita brutto [PLN]</li>
                </LegendList>
                <LineBarWrapper lineBarData={averagePriceAreaByTime} legendListText={legendListText} type="linear"/>
            </HeaderStyle>
        </SectionBox>
    );
};
