import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Title, useWindowWidth } from '@investoro/core';
import { useUser } from 'store/User/Context';
import { MessageSenders, UserMessages } from 'store/User/State';
import styled from 'styled-components';
import { isNumber } from 'utils/validators/numbers';
import { isString } from 'utils/validators/strings';

import Messages from 'components/common/Chat/Messages';
import Icon, { IconEnum } from 'components/common/Icon';
import RatingBar from 'components/common/RatingBar/RatingBar';
import UserPhotoIcon from 'components/common/UserPhotoIcon';

const Container = styled.div`
    position: relative;
    display: grid;
    grid-area: ${(props) => props.isUpperLabel ? '2 / 1 / 2 / right' : '1 / 1 / 1 / right'};
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    gap: 15px;
`;

const RightPosition = styled.div`
    display: flex;
    align-items: center;
    height: fit-content;
    flex-wrap: nowrap;
    margin-left: auto;
    gap: 5px;
    grid-column: 3;
`;

const Options = styled.div`
    position: absolute;
    right: 0;
    top: 40px;
    background: var(--color-white);
    border-radius: var(--box-border-radius);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: auto;
    z-index: var(--z-index-options-menu);
`;

const ButtonContainer = styled.div`
    display: flex;
    margin-left: auto;
    align-items: center;
    padding: 20px;
    border-radius: 50px;
    color: var(--color-primary);
    gap: 8px;
    background-color: var(--color-alt-second);
    min-width: 40px;
    height: 50px;

    &:hover {
        transition: background-color ease-in .2s;
        background-color: var(--color-alt);
        cursor: pointer;

        p {
            color: white;
        }

        img {
            filter: ${(props) => props.darkMode ? 'unset' : 'invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%)'};
        }

        & .user-photo-icon-img {
            filter: none;
        }
    }
`;

const LabelsContainer = styled.div`
    grid-area: 1 / 1;
    display: flex;
    gap: 10px;
`;

const Label = styled.span`
    --font-size-body: var(--font-size-body-3);

    padding: 5px 10px;
    background: ${(props) => props.isDarkMode
        ? props.darkerColor ? '#724836' : '#916D3D'
        : props.darkerColor ? '#EFC9B9' : '#FAE0C1'};
    
    ${(props) => props.isDarkMode ? 'color: var(--color-white)' : 'color: var(--color-primary)'};
    border-radius: 5px;
    height: min-content;
`;

const ActivationLabel = styled.span`
    --font-size-body: var(--font-size-body-3);

    padding: 5px 10px;
    background: ${(props) => props.isDarkMode
        ? props.isActiveStatus ? '#0BAC25' : '#724836'
        : props.isActiveStatus ? 'rgba(11, 172, 37, 0.3)' : '#EFC9B9'};
    
    ${(props) => props.isDarkMode ? '#FFFF' : 'color: var(--color-primary)'};
    border-radius: 5px;
    height: min-content;  
`;

const PreTitle = styled.div`
    line-height: 20px;
    grid-area: 1;
`;

const PreTitileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: fit-content;
    align-items: flex-start;

`;

export interface User {
    profileId: string;
    userName: string;
    title: string;
    userPhoto?: string;
    price: string;
    offerPhoto: string;
    handleSendMessages: (message: string) => void;
    userMessages: UserMessages[];
    showMessageButton?: boolean;
}

interface TopProperties {
    title: string;
    preTitle?: string;
    ratingScore?: number | null; // jest zawsze zwracana w module sale i rent, ale nie jest przekazywana w modułach, które nie mają ratingu
    scorePrecision?: number | null; // jak wyżej
    showUserOptions?: boolean;
    userDetails?: User;
    lowestPriceLabel?: boolean;
    privateOfferLabel?: boolean;
    activationStatusTextLabel?: string;
    activationStatus?: boolean
}

const Top: FC<TopProperties> = ({
    preTitle,
    ratingScore,
    scorePrecision,
    showUserOptions,
    userDetails,
    lowestPriceLabel,
    activationStatusTextLabel,
    activationStatus,
    privateOfferLabel,
    title
}) => {
    const [isUserNameOptionsShown, setIsUserNameOptionsShown] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [showMessages, setShowMessages] = useState(false);
    const { user } = useUser();
    const navigate = useNavigate();
    const width = useWindowWidth();

    const handleUserNameOptions = (e: Event) => {
        e.stopPropagation();
        setIsUserNameOptionsShown((prev) => !prev);
    };

    const goToProfile = (e: Event) => {
        e.stopPropagation();
        navigate(`/profile/${userDetails!.profileId}`);
    };

    const handleMessages = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        // scrollPositionRef.current = window.scrollY;
        setShowMessages((prev) => !prev);
    };

    return (
        <>
            <LabelsContainer>
                {lowestPriceLabel ? <Label isDarkMode={user.isDarkMode} role="label" aria-label="Duplikat z niższą ceną" isActivationStatus={activationStatus}>Duplikat z niższą ceną</Label> : null}
                {privateOfferLabel ? <Label isDarkMode={user.isDarkMode} role="label" aria-label="Duplikat od os. prywatnej">Duplikat od os. prywatnej</Label> : null}
            </LabelsContainer>
            <Container isUpperLabel={lowestPriceLabel || privateOfferLabel}>
                <PreTitileWrapper>
                    {activationStatusTextLabel ? <ActivationLabel isDarkMode={user.isDarkMode} role="label" aria-label="Status ogłoszenia" isActiveStatus={activationStatus}>{activationStatusTextLabel}</ActivationLabel> : null}
                    {isString(preTitle) && <PreTitle role="heading" aria-level={2} aria-label={preTitle}>{preTitle}</PreTitle>}
                    {width < 490 && <Title>{title}</Title>}
                </PreTitileWrapper>
                {userDetails && <RightPosition role="button" aria-label={userDetails.userName} onClick={(e: Event) => handleUserNameOptions(e)}>
                    <UserPhotoIcon img={userDetails.userPhoto}/>
                    {userDetails.userName}</RightPosition>}
                {width > 490 && isNumber(ratingScore) && <RightPosition><RatingBar score={ratingScore!} scorePrecision={scorePrecision!} isCardBox/></RightPosition>}
                {isUserNameOptionsShown && userDetails && userDetails.showMessageButton && showUserOptions
                    ? <Options ref={setReferenceElement} role="menu">
                        <ButtonContainer darkMode={user.isDarkMode} onClick={(e: Event) => goToProfile(e)} role="menuitem" aria-label="Inne oferty użytkownika">
                            {userDetails.userPhoto ? <UserPhotoIcon img={userDetails.userPhoto}/> : <Icon icon={IconEnum.USER}/>}
                            <p>Inne oferty użytkownika</p>
                        </ButtonContainer>
                        <ButtonContainer darkMode={user.isDarkMode} onClick={handleMessages} role="menuitem" aria-label="Wyślij wiadomość">
                            <Icon icon={IconEnum.EMAIL}/>
                            <p>Wyślij wiadomość</p>
                        </ButtonContainer></Options>
                    : null}
                {showMessages && userDetails && userDetails.userName &&
                    <Messages username={userDetails.userName} referenceElement={referenceElement}
                        onClose={handleMessages} title={userDetails.title}
                        photo={userDetails.offerPhoto}
                        price={userDetails.price}
                        onSendMessage={userDetails.handleSendMessages}
                        messages={userDetails.userMessages}
                        messageOwnerType={MessageSenders.INTERESTED}
                        isPopup
                    />}
            </Container>
        </>
    );
};

export default Top;

