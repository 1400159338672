import { FlatAdditionalInformation, FlatBuildingMaterial, FlatBuildingType, FlatHeating, FlatMedia, FlatPropertyCondition, HouseAdditionalInformation, HouseBuildingMaterial, HouseBuildingType, HouseHeating, HouseLocation, HouseMedia, LocationDto, OtherAdditionalInformation, OtherBuildingType, OtherLocation, OtherMedia, PlotAdditionalInformation, PlotBuildingType, PlotMedia, PropertyCondition, RentFlatEditDetailDto, RentHouseEditDetailDto, RentOtherEditDetailDto, RentPageDto, RentPageDtoModuleEnum, RentPlotEditDetailDto, SaleFlatEditDetailDto, SaleHouseEditDetailDto, SaleOtherEditDetailDto, SalePageDto, SalePageDtoModuleEnum, SalePlotEditDetailDto } from 'utils/types/BrokerOfferModels';

import { Access, FlatEquipment, FloorLevel, HouseAttic, HouseFence, HouseFloorLevel, HouseNeighborhood, HouseRoof, HouseRoofMaterial, OtherBuildingPurpose, OtherBuildingRooms, OtherConstruction, OtherFlooring, OtherParking, PlotNeighborhood, PropertyForm, Security, TypeOfMarket, Window } from './../../types/BrokerOfferModels';

export type BrokerSaleOfferDetailsResponse = BaseOfferDetails & (
    Omit<SaleFlatEditDetailDto, keyof BaseOfferDetails> |
    Omit<SalePlotEditDetailDto, keyof BaseOfferDetails> |
    Omit<SaleHouseEditDetailDto, keyof BaseOfferDetails> |
    Omit<SaleOtherEditDetailDto, keyof BaseOfferDetails>
);

type BrokerRentOfferDetailsResponse = BaseOfferDetails & (
    Omit<RentFlatEditDetailDto, keyof BaseOfferDetails> |
    Omit<RentHouseEditDetailDto, keyof BaseOfferDetails> |
    Omit<RentOtherEditDetailDto, keyof BaseOfferDetails> |
    Omit<RentPlotEditDetailDto, keyof BaseOfferDetails>
);

type BrokerOffersDto = SalePageDto | RentPageDto;

interface CommonOfferFields {
    encryptedId?: string;
    area?: number;
    city: LocationDto;
    title: string;
    description: string;
    photos?: string[];
    price: number;
    advertisementId: number;
    activeStatus: boolean;
    dateAdded: string;
    typeOfMarket: TypeOfMarket;
    numberOfRooms?: number;

}

interface BaseOfferDetails extends CommonOfferFields {
    module: SalePageDtoModuleEnum | RentPageDtoModuleEnum;
    TypeOfMarket?: TypeOfMarket;
    buildingType?: FlatBuildingType | PlotBuildingType | HouseBuildingType | OtherBuildingType;
    heating?: FlatHeating | HouseHeating;
    propertyCondition?: FlatPropertyCondition | PropertyCondition;
    media?: FlatMedia[] | PlotMedia[] | OtherMedia[] | HouseMedia[];
    buildingMaterial?: FlatBuildingMaterial | HouseBuildingMaterial;
    additionalInformation?: FlatAdditionalInformation | PlotAdditionalInformation | HouseAdditionalInformation | OtherAdditionalInformation;
    numberOfRooms?: number;
    builtYear?: number;
    floors?: HouseFloorLevel | string;
    propertyForm?: PropertyForm;
    availableFrom?: string;
    attic?: HouseAttic;
    roof?: HouseRoof;
    roofMaterial?: HouseRoofMaterial;
    window?: Window;
    location?: HouseLocation | OtherLocation;
    recreationalHouse?: boolean;
    security?: Security[];
    fence?: HouseFence[];
    access?: Access[];
    neighborhood?: HouseNeighborhood[] | PlotNeighborhood[];
    dimensions?: string;
    floor?: FloorLevel;
    numberOfFloors?: string;
    construction?: OtherConstruction;
    height?: string;
    parking?: OtherParking;
    flooring?: OtherFlooring;
    buildingRooms?: OtherBuildingRooms[];
    buildingPurpose?: OtherBuildingPurpose[];
    equipment?: FlatEquipment[];
}

export type BrokerOfferResponseContent = BrokerOffersDto & {
  detailedContent?: BrokerSaleOfferDetailsResponse | BrokerRentOfferDetailsResponse;
};

export interface BrokerOfferResponse {
    content: BrokerOfferResponseContent[],
    pageable: {
        pageNumber: number,
        pageSize: number,
    },
    totalPages: number,
    numberOfElements: number,
    totalElements: number,
    last?: boolean,
    size: number,
    empty: boolean,
}

export interface BrokerStateType {
    isLoading: boolean,
    brokerOfferList: BrokerOfferResponse,
}

export const brokerOffersInitialState: BrokerStateType = {
    isLoading: false,
    brokerOfferList: {
        empty: false,
        content: [],
        pageable: {
            pageNumber: 1,
            pageSize: 0
        },
        numberOfElements: 0,
        size: 25,
        totalPages: 0,
        totalElements: 0,
        last: false
    }
};
