import React from 'react';
import { useUser } from 'store/User/Context';

import { HeaderStyle, LegendList, SectionBox } from 'components/analysis/AnalysisSaleList';
import LineBarWrapper from 'components/common/LineBarWrapper';

interface TimeRangeAverageTotalPriceAndAreaProps {
    averageAllPriceByTime: { name: string; line: number; bar: number; }[];
}

export const TimeRangeAverageTotalPriceAndArea = ({ averageAllPriceByTime }: TimeRangeAverageTotalPriceAndAreaProps) => {
    const { user } = useUser();
    const legendListText = [
        ' zł średnia cena całkowita',
        ' m2 średnia powierzchnia'
    ];

    return (
        <SectionBox data-pdf-section>
            <HeaderStyle>
                <h2>Średnia cena całkowita oraz średnia powierzchnia w okresach czasowych</h2>
                <LegendList darkMode={user.isDarkMode}>
                    <li>Średnia cena całkowita [PLN]</li>
                    <li>Średnia powierzchnia [m²]</li>
                </LegendList>
                <LineBarWrapper lineBarData={averageAllPriceByTime} legendListText={legendListText} type="linear"/>
            </HeaderStyle>
        </SectionBox>
    );
};
