export const getSubModuleByType = (type: string) => {
    switch (type) {
    case 'RENT_FLAT':
    case 'SALE_FLAT':
        return 'flat';
    case 'RENT_HOUSE':
    case 'SALE_HOUSE':
        return 'house';
    case 'RENT_OTHER':
    case 'SALE_OTHER':
        return 'other';
    case 'RENT_PLOT':
    case 'SALE_PLOT':
        return 'plot';
    default:
        return '';
    }
};
