import { FC } from 'react';
import React from 'react';
import { MessageSenders } from 'store/User/State';

import { BoxContainer } from 'components/common/BoxContainer';
import { User } from 'components/common/Card/common/V2CardBodyDefault/Top/Top';
import Messages from 'components/common/Chat/Messages';

interface V2MessagesTabProps {
    userDetails: User;
}

const V2MessagesTab: FC<V2MessagesTabProps> = ({ userDetails }) => {
    return (
        <BoxContainer>
            <Messages
                onSendMessage={userDetails.handleSendMessages}
                messages={userDetails.userMessages}
                username={userDetails.userName}
                title={userDetails.title}
                userPhoto={userDetails.userPhoto}
                photo={userDetails.offerPhoto}
                price={userDetails.price}
                messageOwnerType={MessageSenders.INTERESTED}
                isTab
            />
        </BoxContainer>
    );
};

export default V2MessagesTab;
