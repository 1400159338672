import React, { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import { UserActions } from 'store/User/Actions';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import { userApiRequests } from 'utils/api-requests/user';
import { AdvertValidatorType, ensureString, isFieldValid } from 'utils/validators/advertAddingValidator';

import Indicator from 'components/atom/Indicator';
import Icon, { IconEnum } from 'components/common/Icon';
import InputContainer from 'components/common/InputContainer';
import TextInput from 'components/common/Inputs/TextInput';
import { activeNotification } from 'components/functions/activeNotification';
import { SaveButton } from 'components/mySettings/SaveButton';
import { Title } from 'components/mySettings/Title';

const Wrapper = styled.div`
`;

const MyDataContainer = styled.div`
    padding: 24px 15px 32px 15px;
    border-radius: var(--box-border-radius);
    background: var(--color-white);
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-bottom: 60px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const ProfileImage = styled.div`
    height: fit-content;
    position: relative;
    border-radius: var(--image-border-radius);

    & > img {
        border-radius: var(--image-border-radius);
        max-width: 315px;
        height: 300px;
        object-fit: cover;
        width: 100%;
        display: block;
        margin: auto;
    }
`;

const UserDataContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    padding: 0 20px;

     @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }

`;

const StyledInputContainer = styled(InputContainer)`
  grid-column: ${(props) => props.fullWidth ? 'span 2' : 'span 1'};

  @media (max-width: 768px) {
    grid-column: span 1;
  }
`;

const DescriptionAndButtonWrapper = styled.div`
    grid-column: span 3;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 24px 15px 0 15px;

  @media (max-width: 768px) {
    grid-column: span 1;
  }
`;

const Actions = styled.div`
    position: absolute;
    width: 100%;
    justify-content: center;
    align-items: center;
    bottom: 30px;
    margin: auto;
    display: flex;
    gap: 10px;

    img {
        width: 100%;
        height: 100%;
    }
`;

const ActionElement = styled.div`
    background: var(--color-white);
    border-radius: var(--border-radius);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        transition: background-color ease-in .2s;
        background-color: var(--color-alt);
        cursor: pointer;

        img {
            filter: ${(props) => props.darkMode ? 'unset' : 'var(--dark-mode-filter)'};
        }
    }
`;

const HiddenInput = styled.input`
    display: none;
`;

const UserNameContainer = styled.div`
    grid-column: span 2;
    font-weight: 600;
    font-size: 16px;
    color: var(--color-dark-primary);
    padding: 10px 0;

    .p {
        line-height: 20px;
    }

    .h1 {
        line-height: 30px;
    }

    @media (max-width: 768px) {
        grid-column: span 1;
    }
`;

interface MyDataProps {
    title: string;
}

const MyDataSettings = forwardRef<HTMLDivElement, MyDataProps>(({ title }, ref: ForwardedRef<HTMLDivElement>) => {
    const { user, dispatch } = useUser();
    const [profileFields, setProfileFields] = useState(user.profile || {});
    const [triggerValidation, setTriggerValidation] = useState(false);
    const [profileImage, setProfileImage] = useState({ url: '', fileName: '' });
    const [isWithoutProfile, setIsWithoutProfile] = useState(false);
    const [isLoadingPhoto, setIsLoadingPhoto] = useState(false);
    const photoUrl = profileFields?.userPhoto
        ? `${process.env.REACT_APP_API_URL}image/find?module=PROFILE&fileName=${profileFields.userPhoto}&photoSize=NORMAL_PHOTO`
        : '/defaultImg.png';

    const [photoUrlState, setPhotoUrlState] = useState(photoUrl);

    useEffect(() => {
        setPhotoUrlState(photoUrl);

        if (profileFields.userPhoto) {
            setProfileImage({ ...profileImage, fileName: profileFields.userPhoto });
        }
    }, [photoUrl]);

    useEffect(() => {
        UserActions.getProfile(dispatch);
    }, [dispatch]);

    useEffect(() => {
        setProfileFields(user.profile);

        if (user.profile.profileName === '' || user.profile.profileEmail === '' || user.profile.profilePhone === '') {
            setIsWithoutProfile(true);
        } else {
            setIsWithoutProfile(false);
        }
    }, [user.profile]);

    const handleChange = (field: string, value: string) => {
        setProfileFields((fields) => ({
            ...fields,
            [field]: value
        }));
    };

    const handleImg = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files.length > 0 && e.target.files[0];

        if (file) {
            setIsLoadingPhoto(true);
            setPhotoUrlState('/defaultImg.png');
            userApiRequests.saveProfilePhoto(file).then((imgName) => {
                setProfileImage({ url: URL.createObjectURL(file), fileName: imgName });
                setPhotoUrlState(URL.createObjectURL(file));
                setIsLoadingPhoto(false);
            });
        }
    };

    const handleDeleteProfilePhoto = () => {
        if (!profileFields.userPhoto) {
            activeNotification('Zdjęcie profilowe', 'Nie masz zdjęcia profilowego', 'warning');

            return;
        } else {
            setIsLoadingPhoto(true);
            userApiRequests.updateProfile({ ...profileFields, userPhoto: '' }, true).then(() => {
                setIsLoadingPhoto(false);
                setPhotoUrlState('/defaultImg.png');
            });
        }
    };

    const handleSaveProfile = () => {
        setTriggerValidation(true);

        if (isFieldValid(AdvertValidatorType.NO_EMPTY_TEXT, ensureString(profileFields.profileName)) &&
            isFieldValid(AdvertValidatorType.NO_EMPTY_TEXT, ensureString(profileFields.profileEmail)) &&
            isFieldValid(AdvertValidatorType.NO_EMPTY_TEXT, ensureString(profileFields.profilePhone))) {
            if (isWithoutProfile) {
                userApiRequests.updateProfile({ ...profileFields, userPhoto: profileImage.fileName }).then(() => {
                    setTriggerValidation(false);
                    setIsWithoutProfile(false);
                    UserActions.getProfile(dispatch);
                });
            } else {
                userApiRequests.updateProfile({ ...profileFields, userPhoto: profileImage.fileName }).then(() => {
                    setTriggerValidation(false);
                    UserActions.getProfile(dispatch);
                });
            }
        }
    };

    return (

        <Wrapper ref={ref}>
            <Title>{title}</Title>
            <MyDataContainer>
                <ProfileImage>
                    {isLoadingPhoto && <Indicator fitParentContainer/>}
                    <img
                        src={photoUrlState}
                        alt="profile"/>
                    <Actions>
                        <label htmlFor="file-upload">
                            <ActionElement><Icon icon={IconEnum.UPLOAD}/></ActionElement>
                        </label>
                        <HiddenInput data-testid="file-upload" id="file-upload" role="input" type="file" onChange={handleImg}/>
                        <ActionElement role="button" aria-label="Usuń zdjęcie profilowe" onClick={handleDeleteProfilePhoto}><Icon icon={IconEnum.TRASH}/></ActionElement>
                    </Actions>
                </ProfileImage>
                <UserDataContainer>
                    <UserNameContainer>
                        <h1>{user.profile.name}</h1>
                        <p>{user.profile.email}</p>
                    </UserNameContainer>
                    <StyledInputContainer fullWidth required title={'Imię i nazwisko lub nazwa firmy'} icon={IconEnum.USER}>
                        <TextInput
                            value={profileFields.profileName || ''}
                            onChange={(e) => handleChange('profileName', e.target.value)}
                            placeholder={'Wpisz dane...'}
                            required
                            inputValidation={(value: string | undefined | null | number) => !isFieldValid(AdvertValidatorType.NO_EMPTY_TEXT, value as string)}
                            errorMessage={'Pole nie może być puste'}
                            triggerValidation={triggerValidation}
                        />
                    </StyledInputContainer>
                    <StyledInputContainer required title={'E-mail kontaktowy'} icon={IconEnum.EMAIL}>
                        <TextInput
                            value={profileFields.profileEmail || ''}
                            onChange={(e) => handleChange('profileEmail', e.target.value)}
                            placeholder={'Wpisz adres e-mail...'}
                            required
                            inputValidation={(value: string | undefined | null | number) => !isFieldValid(AdvertValidatorType.NO_EMPTY_TEXT, value as string)}
                            errorMessage={'Pole nie może być puste'}
                            triggerValidation={triggerValidation}
                        />
                    </StyledInputContainer>
                    <StyledInputContainer required title={'Telefon kontaktowy'} icon={IconEnum.PHONE}>
                        <TextInput
                            value={profileFields.profilePhone}
                            onChange={(e) => handleChange('profilePhone', e.target.value)}
                            placeholder={'Wpisz numer telefonu...'}
                            required
                            inputValidation={(value: string | undefined | null | number) => !isFieldValid(AdvertValidatorType.NO_EMPTY_TEXT, value as string)}
                            errorMessage={'Pole nie może być puste'}
                            triggerValidation={triggerValidation}
                        />
                    </StyledInputContainer>

                </UserDataContainer>
                <DescriptionAndButtonWrapper>
                    <InputContainer title={'Opis użytkownika'} icon={IconEnum.NOTE2}
                        additionalText={`Pozostało ${(400 - (profileFields.description ? profileFields.description.length : 0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} znaków`}
                    >
                        <TextInput
                            description
                            value={profileFields.description}
                            onChange={(e) => handleChange('description', e.target.value)}
                            placeholder={'Wpisz treść opisu....'}
                            max={400}
                            maxLength={400}
                        />
                    </InputContainer>
                    <SaveButton role="button" aria-label="Zapisz zmiany" darkMode={user.isDarkMode} onClick={handleSaveProfile}>Zapisz zmiany</SaveButton>
                </DescriptionAndButtonWrapper>
            </MyDataContainer>

        </Wrapper>
    );
});
export default MyDataSettings;
