import React, { useCallback, useContext, useEffect, useState } from 'react';
import { isTouchDevice } from 'utils/checkIsTouchDevice';
import CooperativeContext from 'utils/context/CooperativeContext';
import { generateSingleOfferLink } from 'utils/generateSingleOfferLink';
import { getStringFromDate } from 'utils/getStringFromDate';
import { getCooperativeFiltersFromState, getCooperativeValueFromState } from 'utils/state-managment/cooperative/cooperativeFilter';
import { CooperativeOfferResponseContent } from 'utils/state-managment/cooperative/cooperativeOffer';
import { CooperativeFiltersStateType } from 'utils/types/Cooperative';
import { LocationChangeType } from 'utils/types/InputTypes';
import { UserReactions } from 'utils/types/OfferData';
import { ReactionType } from 'utils/types/ReactionType';

import OfferActions from 'components/common/Card/common/OfferActions/OfferActions';
import { InteractiveElements } from 'components/common/Card/common/V2InteractiveIconsWrapper/V2InteractiveIconsWrapper';
import { IconEnum } from 'components/common/Icon';
import { TabType } from 'components/common/Tabs/V2Tabs';
import { cooperativeOfferListFunctions } from 'components/functions/offerListFunctions/cooperativeOfferListFunctions/cooperativeOfferListFunctions';
import { generatePortalsListInfoBox } from 'components/functions/offerListFunctions/cooperativeOfferListFunctions/generatePortalsListInfoBox';
import { generateOfferActionsElements } from 'components/functions/shared/generateOfferActionsElements';
import V2OfferList, { RenderList } from 'components/offerList/V2OfferList';

const CooperativeOffers = () => {
    const {
        cooperativeFiltersState,
        handleChange,
        cooperativeOfferState,
        loadCooperativeOffers,
        deleteNote,
        updateNote,
        reactionUpdate,
        loadOfferDetailsAsync,
        loadNotesAsync
    } = useContext(CooperativeContext);

    const [renderList, setRenderList] = useState<RenderList[]>([]);
    const [moreButtonActiveId, setMoreButtonActiveId] = useState<string | null>(null);
    const [detailsMoreButtonActiveId, setDetailsMoreButtonActiveId] = useState<string | null>(null);
    const handleChangePage = useCallback((currentPage: number, rows = 25) => {
        loadCooperativeOffers({ page: currentPage, rows });
    }, [cooperativeFiltersState]);

    const handleActiveFiltersChange = () => {
        loadCooperativeOffers({ page: 1, rows: 25 });
    };

    const handleOlderAdverts = () => {
        const date = cooperativeFiltersState.offerAdded.from ? new Date(new Date(cooperativeFiltersState.offerAdded.from).setDate(new Date(cooperativeFiltersState.offerAdded.from).getDate() - 6)) : null;
        const dateString = date && getStringFromDate(date.toDateString());

        loadCooperativeOffers({
            requestData: {
                ...getCooperativeValueFromState(cooperativeFiltersState),
                ...{ offerAddedFrom: !cooperativeFiltersState.daysAmount ? dateString ?? undefined : undefined },
                ...{ daysAmount: cooperativeFiltersState.daysAmount ? cooperativeFiltersState.daysAmount + 6 : undefined }
            },
            page: cooperativeOfferState.cooperativeOfferList?.pageable?.pageNumber,
            rows: cooperativeOfferState.cooperativeOfferList && cooperativeOfferState.cooperativeOfferList.size ? cooperativeOfferState.cooperativeOfferList.size : 25
        });
    };

    const onNoteAdd = () => {
        // TODO: INVEST-257 Czy ta funkcja jest potrzebna?
        // if (!showNote && showLastNote) {
        //     showLastNote();
        // }
        //
        // if (!data.note?.noteId) {
        //     openOfferTab('note');
        // }
    };

    const onMessageClick = () => {
        onNoteAdd();
    };

    const handleDelete = (noteId: number, id: number) => {
        deleteNote(id, noteId);
    };

    const handleSaveNote = async (value: string, advertId: number, id?: number) => {
        updateNote(value, advertId, id || 0);
    };

    const toggleContainerDetails = (detailsOpen: boolean, offer: CooperativeOfferResponseContent) => {
        if (!detailsOpen) {
            if (offer.reaction && !offer.reaction.viewedStatus) {
                reactionUpdate(offer.cooperativeId, offer.location!, {
                    ...offer.reaction,
                    openedStatus: true,
                    viewedStatus: true
                } as ReactionType);
            }

            loadOfferDetailsAsync(offer.cooperativeId);
        }
    };

    const handleChangeReaction = (name: keyof ReactionType, value: boolean, offer: CooperativeOfferResponseContent) => {
        const userReaction = Object.assign({}, offer.reaction);

        if (name === 'likeStatus' && userReaction.unlikeStatus && value) {
            userReaction.unlikeStatus = false;
        } else if (name === 'unlikeStatus' && userReaction.likeStatus && value) {
            userReaction.likeStatus = false;
        }

        (userReaction[name] as boolean) = value;
        const city = offer.location;

        reactionUpdate(offer.cooperativeId, city, userReaction as UserReactions);
    };

    const handleDeleteFilterValue = (filterName: string, value?: string | number | null | string[], index?: number) => {
        const stateField = cooperativeFiltersState[filterName as keyof CooperativeFiltersStateType];

        if (index !== undefined && Array.isArray(stateField)) {
            const array = [...stateField] as string[];
            array.splice(index, 1);
            handleChange(filterName, array);
        } else if (filterName === 'offerAdded') {
            handleChange('dateRangeFilter', false);
            handleChange('daysAmount', 3);
        } else if (filterName === 'savedLocations') {
            const savedLocations = { ...cooperativeFiltersState.savedLocations };

            if (typeof value === 'string') {
                delete savedLocations[value];
                handleChange('savedLocations', savedLocations);
                handleChange('city', Object.values(savedLocations) as LocationChangeType);
            }
        } else {
            handleChange(filterName, {
                from: '',
                to: ''
            });
        }
    };

    const generateInteractiveElements = (offer: CooperativeOfferResponseContent): InteractiveElements[] => {
        const offerLink = generateSingleOfferLink(offer.encryptedId, 'cooperative');
        const isMoreButtonActive = moreButtonActiveId === offer.cooperativeId.toString();
        const offerActionsElements = generateOfferActionsElements(offerLink, offer.link);

        return [
            {
                tooltipText: 'Wyświetlono',
                icon: IconEnum.EYE,
                active: offer.reaction?.viewedStatus,
                disabled: offer.reaction?.viewedStatus === undefined,
                onClick: () => handleChangeReaction('viewedStatus', !offer.reaction?.viewedStatus, offer)
            },
            {
                tooltipText: 'Lubię to',
                icon: IconEnum.LIKE,
                active: offer.reaction?.likeStatus,
                onClick: () => handleChangeReaction('likeStatus', !offer.reaction?.likeStatus, offer)
            },
            {
                tooltipText: 'Nie lubię tego',
                icon: IconEnum.DISLIKE,
                active: offer.reaction?.unlikeStatus,
                disabled: offer.reaction?.unlikeStatus === undefined,
                onClick: () => handleChangeReaction('unlikeStatus', !offer.reaction?.unlikeStatus, offer)
            },
            {
                tooltipText: 'Notatki',
                icon: IconEnum.MESSAGE,
                active: offer.numberOfNotes ? offer.numberOfNotes > 0 : false,
                counter: offer.numberOfNotes ? offer.numberOfNotes || 1 : undefined,
                openTabOnClick: TabType.NOTES,
                onClick: onMessageClick
            },
            {
                tooltipText: 'Ogłoszenie nieaktualne',
                icon: IconEnum.BLOCKED,
                active: offer.reaction?.hideStatus,
                disabled: offer.reaction?.hideStatus === undefined,
                onClick: () => handleChangeReaction('hideStatus', !offer.reaction?.hideStatus, offer)
            },
            {
                tooltipText: 'Przeprowadzona rozmowa',
                icon: IconEnum.PHONE,
                active: offer.reaction?.phoneStatus,
                disabled: offer.reaction?.phoneStatus === undefined,
                onClick: () => handleChangeReaction('phoneStatus', !offer.reaction?.phoneStatus, offer)
            },
            {
                tooltipText: 'Umówione spotkanie',
                icon: IconEnum.PEOPLES_3,
                active: offer.reaction?.arrangedStatus,
                disabled: offer.reaction?.arrangedStatus === undefined,
                onClick: () => handleChangeReaction('arrangedStatus', !offer.reaction?.arrangedStatus, offer)
            },
            {
                icon: 'squares' as IconEnum,
                tooltipText: isTouchDevice() ? undefined : 'Więcej',
                onClick: () => isMoreButtonActive ? setMoreButtonActiveId(null) : setMoreButtonActiveId(offer.cooperativeId.toString()),
                additionalJSXSibling: isMoreButtonActive ? <OfferActions onClickOutside={() => setMoreButtonActiveId(null)} elements={offerActionsElements} /> : undefined
            }
        ];
    };

    const generateSquareIcon = (offerId: string, isDetail: boolean, offer: CooperativeOfferResponseContent) => {
        const buttonId = isDetail ? `${offerId}-detail` : offerId;
        const isMoreButtonActive = isDetail ? detailsMoreButtonActiveId === buttonId : moreButtonActiveId === buttonId;
        const offerLink = generateSingleOfferLink(offer.encryptedId, 'cooperative');
        const offerActionsElements = generateOfferActionsElements(offerLink, offer.link);

        return {
            icon: 'squares' as IconEnum,
            tooltipText: isTouchDevice() ? undefined : 'Więcej',
            onClick: () => {
                if (isDetail) {
                    isMoreButtonActive
                        ? setDetailsMoreButtonActiveId(null)
                        : setDetailsMoreButtonActiveId(buttonId);
                } else {
                    isMoreButtonActive
                        ? setMoreButtonActiveId(null)
                        : setMoreButtonActiveId(buttonId);
                }
            },
            additionalJSXSibling: isMoreButtonActive
                ? <OfferActions
                    onClickOutside={() => isDetail ? setDetailsMoreButtonActiveId(null) : setMoreButtonActiveId(null)}
                    isInModal={isDetail}
                    elements={offerActionsElements}
                />
                : undefined
        };
    };

    const handleNotes = async (offer: CooperativeOfferResponseContent) => {
        loadNotesAsync(offer.cooperativeId.toString());
    };

    const generateCardData = (offer: CooperativeOfferResponseContent) => {
        const subMainValues: React.ReactNode[] = [];

        return {
            infoElements: cooperativeOfferListFunctions.generateInfoElements(offer),
            preTitle: offer.location,
            date: offer.dateAdded,
            title: offer.title,
            interactiveElements: generateInteractiveElements(offer),
            note: offer.newestNote,
            subMainValues,
            thumbnailPath: './defaultImg.png'
        };
    };

    const portalList = cooperativeOfferState.cooperativeOfferList.portalList && cooperativeOfferState.cooperativeOfferList.portalList.length > 0 ? generatePortalsListInfoBox(cooperativeOfferState.cooperativeOfferList.portalList) : undefined;

    useEffect(() => {
        const offersList = cooperativeOfferState.cooperativeOfferList.content
            ? cooperativeOfferState.cooperativeOfferList?.content.map((offer): RenderList => {
                const interactiveElements = generateInteractiveElements(offer);
                interactiveElements.splice(-1);
                const detailsTabContent = cooperativeOfferListFunctions.generateOfferDetailsTabContent(offer);

                return {
                    id: offer.cooperativeId,
                    onSingleOfferClick: (_: React.MouseEvent, detailsOpen: boolean) => toggleContainerDetails(detailsOpen, offer),
                    baseCardContent: {
                        bigImage: true,
                        cardData: {
                            ...generateCardData(offer),
                            interactiveElements: [
                                ...interactiveElements,
                                generateSquareIcon(offer.cooperativeId.toString(), false, offer)
                            ]
                        },
                        openOfferTab: () => {
                        }
                    },
                    detailsCardData: {
                        interactiveElements: [
                            ...interactiveElements,
                            generateSquareIcon(offer.cooperativeId.toString(), true, offer)
                        ],
                        thumbnailPath: './defaultImg.png',
                        date: offer.dateAdded ? `${offer.dateAdded}` : '',
                        onToggleContainerDetails: () => {
                        },
                        tabsContent: {
                            toggleContainerDetails: () => {
                            },
                            interactiveElements: [
                                ...interactiveElements,
                                generateSquareIcon(offer.cooperativeId.toString(), true, offer)
                            ],
                            detailsTabContent,
                            notesTabContent: {
                                notes: cooperativeOfferState.notes,
                                handleDelete: (noteId: string) => handleDelete(Number(noteId), offer.cooperativeId),
                                handleSaveNote: (value: string, id?: string) => handleSaveNote(value, offer.cooperativeId, Number(id))
                            },
                            numberOfNotes: offer.numberOfNotes || 0,
                            handleLoadNotes: () => handleNotes(offer)
                        }
                    }
                };
            })
            : [];

        setRenderList(offersList);
    }, [
        cooperativeOfferState.cooperativeOfferList?.content,
        cooperativeOfferState.notes,
        moreButtonActiveId,
        detailsMoreButtonActiveId
    ]);

    return (
        <div>
            <V2OfferList totalPages={cooperativeOfferState.cooperativeOfferList.totalPages} handleChangePage={handleChangePage}
                pageNumber={cooperativeOfferState.cooperativeOfferList?.pageable?.pageNumber || 1}
                lastPage={cooperativeOfferState.cooperativeOfferList.last}
                handleOlderAdverts={handleOlderAdverts}
                isLoadMoreButton
                handleActiveFiltersChange={handleActiveFiltersChange}
                showList={!cooperativeOfferState.cooperativeOfferList.empty && cooperativeOfferState.cooperativeOfferList.content?.length > 0}
                filters={getCooperativeFiltersFromState(cooperativeFiltersState)}
                activeFiltersState={cooperativeFiltersState}
                onDeleteFilter={handleDeleteFilterValue}
                showNoItemsText={cooperativeOfferState.cooperativeOfferList && cooperativeOfferState.cooperativeOfferList.empty && cooperativeOfferState.cooperativeOfferList.content.length === 0 && cooperativeOfferState.cooperativeOfferList.afterSearch}
                renderList={renderList}
                portalList={portalList}
            />
        </div>
    );
};

export default CooperativeOffers;
