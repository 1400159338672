import React, { FC, useState } from 'react';
import dayjs from 'dayjs';
import { UserMessageListResponse } from 'store/User/State';
import styled from 'styled-components';
import { formatFilterPrice } from 'utils/formatFilterPrice';

import Icon, { IconEnum } from 'components/common/Icon';
import UserPhotoIcon from 'components/common/UserPhotoIcon';

const CatalogInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
        width: 50px;
        height: 40px;
        border-radius: var(--image-border-radius);
        margin-right: 10px;
        object-fit: cover;
    }

    p {
        --font-size-body: 13px;

        line-height: 20px;
        margin: 0;
    }
`;

const Description = styled.div`
    display: flex;
    flex-direction: column;
`;

const Price = styled.p`
    --font-weight: 600;
    --font-size-body: 11px;

    color: var(--color-alt);
`;

const Wrapper = styled.div`
    background: var(--color-white);
    border-radius: var(--box-border-radius);
    margin-bottom: 5px;
`;

const FolderHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 15px;
    ${(props) => props.contentIsVisible ? 'border-bottom: 1px solid #030D451A' : null};

    &:hover {
        cursor: pointer;
    }
`;

const MessageItem = styled.div`
    padding: 20px 15px;
    ${(props) => props.active ? 'background: var(--color-last-message)' : null};

    &:hover {
        cursor: pointer;
    }
`;

const Arrow = styled.div`
    background: var(--color-alt-second);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MessageContainer = styled.div`
    display: flex;
    column-gap: 10px;
`;

const Left = styled.div`
    display: flex;
    flex-direction: column;
`;
const Right = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: auto;
`;

const Username = styled.p`
    --font-size-body: 14px;

    line-height: 20px;
    color: var(--color-primary);
`;

const Hour = styled.p`
    --font-size-body: 11px;

    color: var(--color-primary);
`;

const Top = styled.div`
    display: flex;
    align-items: center;
    line-height: 20px;
    justify-content: end;
`;

const Message = styled.p`
    --font-size-body: 11px;
    --font-weight: 400;
`;

const Date = styled.p`
    --font-size-body: 11px;
    --font-weight: 400;

    text-align: end;
`;

const Badge = styled.div`
    border-radius: 20px;
    background: var(--color-success);
    padding: 0 4px;
    display: flex;
    align-items: center;
    height: 15px;
    margin-right: 5px;

    p {
        color: var(--color-white);
        font-size: 10px;
        font-weight: 700;
    }
`;

interface FolderProps {
    item: UserMessageListResponse;
    onMessageClick: (id: string, name: string, title: string, mainPhoto: string | null, price: number | null, module: string, advertisementId: string) => void;
    setVisibleContentId: (visibleContentId: string) => void;
    visibleContentId: string | null;
}

const Folder: FC<FolderProps> = ({ item, onMessageClick, setVisibleContentId, visibleContentId }) => {
    const [activeMessageItem, setActiveMessageItem] = useState<string | null>(null);
    const { title, price, mainPhoto, messages, module, advertisementId } = item;
    const [catalogVisible, setCatalogVisible] = useState(false);

    const handleClickCatalog = () => {
        setCatalogVisible(!catalogVisible);
    };

    const truncate = (str: string, n: number) => {
        return str.length > n ? `${str.substring(0, n - 1)}...` : str;
    };

    const handleMessageClick = (id: string, name: string) => {
        setActiveMessageItem(id);
        setVisibleContentId(advertisementId);
        onMessageClick(id, name, title, mainPhoto, price, module, advertisementId);
    };

    return (
        <Wrapper>
            <FolderHeader contentIsVisible={visibleContentId === advertisementId} onClick={handleClickCatalog}>
                <CatalogInfo>
                    <img
                        src={mainPhoto ? `${process.env.REACT_APP_API_URL}image/find?&module=${module}&fileName=${mainPhoto}&photoSize=NORMAL_PHOTO` : '/defaultImg.png'}
                        alt={'offer'}/>
                    <Description>
                        <p>{title}</p>
                        <Price>{formatFilterPrice(price?.toString())}</Price>
                    </Description>
                </CatalogInfo>
                <Arrow>
                    {catalogVisible
                        ? <Icon icon={IconEnum.ACCORDION_UP} />
                        : <Icon icon={IconEnum.ACCORDION_DOWN} />}
                </Arrow>
            </FolderHeader>
            {catalogVisible && messages.map(({ name, content, newMessage, sendDate, lastMessageId, photo }, index) => <MessageItem key={index} onClick={() => handleMessageClick(lastMessageId, name)}
                active={activeMessageItem === lastMessageId && visibleContentId === advertisementId}>
                <MessageContainer>
                    <UserPhotoIcon img={photo ? `${process.env.REACT_APP_API_URL}image/find?&module=PROFILE&fileName=${photo}&photoSize=MINI_PHOTO` : undefined}/>
                    <Left>
                        <Username>{name}</Username>
                        <Message>{truncate(content, 20)}</Message>
                    </Left>
                    <Right>
                        <Top>
                            {newMessage ? <Badge><p>nowe</p></Badge> : null}
                            <Hour>{dayjs(sendDate).format('HH:mm')}</Hour>
                        </Top>
                        <Date>{dayjs(sendDate).format('DD.MM.YYYY')}</Date>
                    </Right>
                </MessageContainer>
            </MessageItem>)}
        </Wrapper>
    );
};

export default Folder;
