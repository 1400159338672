import React from 'react';
import { useUser } from 'store/User/Context';

import { HeaderStyle, LegendList, SectionBox } from 'components/analysis/AnalysisSaleList';
import LineBarWrapper from 'components/common/LineBarWrapper';

interface AreaRangeAverageMeterAndTotalPriceProps {
    averagePriceByMeter: { name: string; line: number; bar: number; }[];
}

export const AreaRangeAverageMeterAndTotalPrice = ({ averagePriceByMeter }: AreaRangeAverageMeterAndTotalPriceProps) => {
    const { user } = useUser();

    return (
        <SectionBox data-pdf-section>
            <HeaderStyle>
                <h2>Średnia cena metra kwadratowego oraz średnia cena całkowita według przedziałów</h2>
                <LegendList darkMode={user.isDarkMode}>
                    <li>Średnia cena za m² brutto [PLN]</li>
                    <li>Mediana ceny całkowitej [PLN]</li>
                </LegendList>
                <LineBarWrapper lineBarData={averagePriceByMeter} type="linear"/>
            </HeaderStyle>
        </SectionBox>
    );
};
