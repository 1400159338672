import { BrokerAdvertEditReturnData, BrokerCreateAdvertStateType, BrokerOfferType } from 'utils/types/BrokerAdvert';

const setStateValues = (state: BrokerAdvertEditReturnData): BrokerCreateAdvertStateType => {
    // @ts-expect-error TODO: INVEST-237
    return {
        ...state,
        city: state.city || [],
        ...{ floor: state.floor ?? '' },
        ...{ floors: state.floors ?? '' },
        availableFrom: state.availableFrom ? state.availableFrom : ''
    };
};

const brokerAdvertInitialState = setStateValues({
    offerType: BrokerOfferType.SALE_HOUSE,
    city: [],
    advertisementId: '',
    title: '',
    description: '',
    cityName: '',
    locations: [],
    media: [],
    heating: [],
    fence: [],
    security: [],
    access: [],
    neighborhood: [],
    equipment: [],
    additionalInformation: [],
    savedLocations: {},
    searchLocationResult: []
});

export const initialState = {
    initialState: () => {
        return brokerAdvertInitialState;
    }
};
