import React, { useCallback, useContext, useState } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { rentOffersApiRequests } from 'utils/api-requests/rent';
import RentContext from 'utils/context/RentContext';
import { getNormalizedPropertyType } from 'utils/formatters/getNormalizedPropertyType';
import { sortOptions } from 'utils/options';
import { getValueFromState } from 'utils/state-managment/rent/rentFilter';
import { SortType } from 'utils/types/SortType';

import Indicator from 'components/atom/Indicator';
import FiltersContentWrapper from 'components/common/FiltersContentWrapper';
import FiltersRent from 'components/rent/FiltersRent';
import RentOffers from 'components/rent/RentOffers';
import V2HeaderSection from 'components/V2HeaderSection';

const Container = styled.div`
    padding: 15px 20px 0;
`;

const RentPage = () => {
    const { handleChange, rentFiltersState, clearOffers, loadRentOffers, rentOfferState } = useContext(RentContext);
    const [isExcelDownloading, setIsExcelDownloading] = useState(false);

    const handleChangeSort = (sortType: string) => {
        handleChange('sortType', sortType);
        loadRentOffers({
            requestData: {
                ...getValueFromState(rentFiltersState),
                sortType: sortType as SortType
            },
            page: 1,
            rows: 25
        });
    };

    // const handleShowLowerOriginalPriceOffers = useCallback(() => {
    //     handleChange('lowerOriginalPrice', !rentFiltersState.lowerOriginalPrice);
    // }, [handleChange, rentFiltersState.lowerOriginalPrice]);

    const handleShowLowerOriginalPriceOffers = useCallback(() => {
        handleChange('lowerOriginalPrice', !rentFiltersState.lowerOriginalPrice);
        loadRentOffers({
            requestData: {
                ...getValueFromState(rentFiltersState),
                lowerOriginalPrice: !rentFiltersState.lowerOriginalPrice
            },
            page: 1,
            rows: 25
        });
    }, [
        handleChange,
        rentFiltersState.lowerOriginalPrice,
        loadRentOffers,
        getValueFromState
    ]);

    const handleCSVAPIFile = useCallback(() => {
        setIsExcelDownloading(true);
        rentOffersApiRequests.getOffersCSVFile(getValueFromState(rentFiltersState), getNormalizedPropertyType(rentFiltersState.module)).finally(() => {
            setIsExcelDownloading(false);
        });
    }, [rentFiltersState]);

    // @todo: uncomment after refactor (openApi)
    // const handleShowUnblockedOffers = useCallback(() => {
    //     handleChange('unlockedAdvertisements', !rentFiltersState.unlockedAdvertisements);
    // }, [handleChange, rentFiltersState.unlockedAdvertisements]);

    return (
        <div>
            <V2HeaderSection
                title={'Ogłoszenia wynajmu'}
                offersCount={rentOfferState.rentOfferList?.totalElements}
                sortType={rentOfferState.rentOfferList.content?.length ? rentFiltersState.sortType : undefined}
                sortOptions={sortOptions}
                handleChangeSort={handleChangeSort}
                handleBackToFilters={rentOfferState.rentOfferList.content?.length || rentOfferState.rentOfferList.empty ? clearOffers : undefined}
                handleShowLowerOriginalPriceOffers={handleShowLowerOriginalPriceOffers}
                showLowerOriginalPriceOffers={rentFiltersState.lowerOriginalPrice}
                showLowerPriceCheckInput={rentOfferState.rentOfferList && rentOfferState.rentOfferList?.totalElements > 0 || rentOfferState.rentOfferList?.afterSearch}
                handleCSVClick={handleCSVAPIFile}
                isExcelDownloading={isExcelDownloading}
                // @todo: uncomment after refactor (openApi)
                // handleShowUnblockedOffers={handleShowUnblockedOffers}
                // showUnblockedOffers={rentFiltersState.unlockedAdvertisements}
                // showUnblockedOffersInput={offers.rentList?.totalElements && offers.rentList?.totalElements > 0 || offers.rentList?.empty}
            />
            <FiltersContentWrapper className={clsx('offers-list', { active: rentOfferState.rentOfferList?.content && rentOfferState.rentOfferList?.content.length > 0 || rentOfferState.rentOfferList?.empty })}>
                <Container id="filters">
                    <FiltersRent />
                </Container>
                <RentOffers />
                {rentOfferState.isLoading && <Indicator/>}
            </FiltersContentWrapper>
        </div>
    );
};

export default RentPage;
