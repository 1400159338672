import { AxiosResponse } from 'axios';
import qs from 'qs';
import { axiosApiInstance } from 'Store';
import { convertPrice } from 'utils/convertPrice';
import {
    BailiffOfferDetailsResponse,
    BailiffOfferResponseContent,
    BailiffOffersResponse
} from 'utils/state-managment/bailiff/bailiffOffer';
import { BailiffFilterReturnData } from 'utils/types/Bailiff';
import { BailiffNoticeDetailsHashResponse, FilterLocation } from 'utils/types/BailiffModels';
import { BailiffSavedFilter } from 'utils/types/Filter';
import { SavedLocationsType } from 'utils/types/Locations';

import { activeNotification } from 'components/functions/activeNotification';

const getSavedFilters = async (): Promise< BailiffSavedFilter[]> => {
    return axiosApiInstance
        .get<BailiffSavedFilter[]>('/bailiff-notice-offer/filter/list')
        .then((resp) => {
            if (resp.data) {
                return resp.data.map((el) => ({
                    ...el,
                    name: el.name
                }));
            }

            return [];
        })
        .catch((err) => {
            if (err?.response?.status === 401) {
                window.parent.location.reload();
            }

            return [];
        });
};

const getFilterDetailsBailiff = async (filterId: number): Promise<BailiffFilterReturnData> => {
    return axiosApiInstance
        .get(`/bailiff-notice-offer/filter/details?id=${filterId}`)
        .then((resp) => {
            const filters = resp.data;
            const savedLocations: SavedLocationsType[] = [];
            const savedOfficeLocations: SavedLocationsType[] = [];

            filters.locations.forEach((city: FilterLocation) => {
                if (city.name) {
                    // @ts-expect-error TODO: INVEST-254
                    savedLocations[city.name] = { ...city, label: city.name };
                }
            });

            filters.officeLocations.forEach((city: FilterLocation) => {
                if (city.name) {
                    // @ts-expect-error TODO: INVEST-254
                    savedOfficeLocations[city.name] = { ...city, label: city.name };
                }
            });

            return {

                ...filters,
                city: filters.locations ? filters.locations : [],
                officeCity: filters.officeLocations ? filters.officeLocations : [],
                wantedKeywords: filters.wantedKeywords ? filters.wantedKeywords : [],
                unwantedKeywords: filters.unwantedKeywords ? filters.unwantedKeywords : [],
                filterId: filterId,
                name: filters.name ? filters.name : '',
                pushAlert: filters.alertPush ? filters.alertPush : false,
                alertEmail: filters.alertEmail ? filters.alertEmail : false,
                alertSms: filters.alertSms ? filters.alertSms : false,
                module: filters.module ? filters.module : '',
                tossedUp: filters.tossedUp ? filters.tossedUp : false,
                notificationsDelay: filters.notificationsDelay !== null && filters.notificationsDelay !== undefined
                    ? filters.notificationsDelay.toString()
                    : '0',
                includeZeroArea: filters.includeZeroArea ? filters.includeZeroArea : false,
                offerAddedFrom: filters.offerAddedFrom ? filters.offerAddedFrom : '',
                offerAddedTo: filters.offerAddedTo ? filters.offerAddedTo : '',
                savedFilter: filters.savedFilter ? filters.savedFilter : false,
                locations: [],
                savedLocations: savedLocations,
                savedOfficeLocations: savedOfficeLocations,
                searchLocationResult: []
            };
        })
        .catch(() => {
            activeNotification('Problem z załadowaniem danych', 'Niestety nie udało się załadować danych. Spróbuj ponownie później', 'danger');
        });
};

const removeSavedFilter = (filterId: number) => {
    return axiosApiInstance
        .delete(`/bailiff-notice-offer/filter/delete?id=${filterId}`)
        .then(() => {
            activeNotification('Udało się!', 'Filtr został usunięty', 'success');
        })
        .catch(() => {
            activeNotification('Problem z usunięciem', 'Niestety nie udało się usunąć danych. Spróbuj ponownie później', 'danger');
        });
};

const updateFilter = async (params: BailiffFilterReturnData) => {
    return axiosApiInstance({
        method: 'put',
        url: '/bailiff-notice-offer/filter/edit',
        data: {
            ...params,
            locations: params.city,
            officeLocations: params.officeCity
        },
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        }
    })
        .then((resp) => {
            activeNotification('Udało się!', 'Filtr został zaktualizowany', 'success');

            return resp;
        })
        .catch((err) => {
            activeNotification('Problem z zapisem', 'Niestety nie udało się zapisać danych. Spróbuj ponownie później', 'danger');

            return Promise.reject(err);
        });
};

const addFilter = (params: BailiffFilterReturnData): Promise<AxiosResponse<number>> => {
    const savedLocations = Object.values(params.savedLocations).map((x) => {
        // @ts-expect-error dont need districts and cities
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { districts, cities, ...rest } = x;

        return { ...rest };
    });

    const savedOfficeLocations = Object.values(params.savedOfficeLocations).map((x) => {
        // @ts-expect-error dont need districts and cities
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { districts, cities, ...rest } = x;

        return { ...rest };
    });

    return axiosApiInstance({
        method: 'post',
        url: '/bailiff-notice-offer/filter/create',
        data: {
            ...params,
            locations: savedLocations,
            officeLocations: savedOfficeLocations
        },
        paramsSerializer: function (params) {
            return qs.stringify(params);
        }
    })
        .then((resp) => {
            activeNotification('Udało się!', 'Filtr został dodany', 'success');

            return resp;
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                activeNotification(
                    'Błąd',
                    err.response.data,
                    'danger'
                );

                return Promise.reject(err);
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );

                return Promise.reject(err);
            }
        });
};

const getBailiffOffers = (params: BailiffFilterReturnData, page: number, size: number): Promise<BailiffOffersResponse> => {
    return axiosApiInstance({
        method: 'post',
        url: '/bailiff-notice-offer/advertisement',
        params: {
            page,
            size
        },
        data: {
            ...params,
            locations: params.city,
            officeLocations: params.officeCity
        }
    })
        .then((resp) => {
            if (resp.data) {
                return {
                    ...resp.data,
                    content: resp.data.content.map((el: BailiffOfferResponseContent) => ({
                        ...el,
                        city: el.location,
                        officeCity: el.officeLocation,
                        dateAdded: el.dateAdded,
                        estimateDate: el.estimateDate ? el.estimateDate : undefined,
                        reaction: {
                            likeStatus: el.reaction?.likeStatus,
                            unlikeStatus: el.reaction?.unlikeStatus,
                            viewedStatus: el.reaction?.viewedStatus,
                            outdatedStatus: el.reaction?.outdatedStatus,
                            arrangedStatus: el.reaction?.arrangedStatus,
                            phoneStatus: el.reaction?.phoneStatus,
                            hideStatus: el.reaction?.hideStatus,
                            openedStatus: el.reaction?.openedStatus
                        }
                    }))
                };
            }

            return { empty: true, content: [], afterSearch: true };
        })
        .catch(() => {
            activeNotification(
                'Nie znaleziono wyników',
                'Wyszukiwanie nie powiodło się, spróbuj jeszcze raz.',
                'warning'
            );

            return { empty: true, content: [], afterSearch: true };
        });
};

const getBailiffOfferDetails = async (id: number): Promise<BailiffOfferDetailsResponse> => {
    return axiosApiInstance
        .get(`/bailiff-notice-offer/advertisement/details?id=${id}`)
        .then((resp) => {
            if (resp.data) {
                return {
                    ...resp.data
                };
            }
        })
        .catch(() => {
            activeNotification(
                'Nie udało się pobrać ogłoszenia',
                'Nie udało się pobrać danych szczegółowych ogłoszenia',
                'warning'
            );

            return [{ empty: true }];
        });
};

const getBailiffOfferByHashId = async (hash: string): Promise<BailiffNoticeDetailsHashResponse> => {
    return axiosApiInstance
        .get(`/bailiff-notice-offer/single-advertisement/detail?hash=${hash}`)
        .then((resp) => {
            if (resp.data) {
                return {
                    ...resp.data,
                    price: resp.data.price ? convertPrice(resp.data.price) : ''
                };
            }
        })
        .catch(() => {
            activeNotification(
                'Nie udało się pobrać ogłoszenia',
                'Nie udało się pobrać danych szczegółowych ogłoszenia',
                'warning'
            );

            return null;
        });
};

export const bailiffOffersApiRequests = {
    getSavedFilters,
    getFilterDetailsBailiff,
    removeSavedFilter,
    updateFilter,
    addFilter,
    getBailiffOffers,
    getBailiffOfferDetails,
    getBailiffOfferByHashId
};
