import { AxiosResponse } from 'axios';
import qs from 'qs';
import { axiosApiInstance } from 'Store';
import { convertPrice } from 'utils/convertPrice';
import {
    CooperativeOfferDetailsResponse,
    CooperativeOfferResponseContent,
    CooperativeOffersResponse
} from 'utils/state-managment/cooperative/cooperativeOffer';
import { CooperativeFilterReturnData } from 'utils/types/Cooperative';
import { CooperativeDetailsResponse, FilterLocation } from 'utils/types/CooperativeModels';
import { CooperativeSavedFilter } from 'utils/types/Filter';
import { SavedLocationsType } from 'utils/types/Locations';

import { activeNotification } from 'components/functions/activeNotification';

const getSavedFilters = async (): Promise<CooperativeSavedFilter[]> => {
    return axiosApiInstance
        .get<CooperativeSavedFilter[]>('/cooperative-offer/filter/list')
        .then((resp) => {
            if (resp.data) {
                return resp.data.map((el) => ({
                    ...el,
                    name: el.name
                }));
            }

            return [];
        })
        .catch((err) => {
            if (err?.response?.status === 401) {
                window.parent.location.reload();
            }

            return [];
        });
};

const getFilterDetailsCooperative = async (filterId: number): Promise<CooperativeFilterReturnData> => {
    return axiosApiInstance
        .get(`/cooperative-offer/filter/details?id=${filterId}`)
        .then((resp) => {
            const filters = resp.data;
            const savedLocations: SavedLocationsType[] = [];

            filters.locations.forEach((city: FilterLocation) => {
                if (city.name) {
                    // @ts-expect-error TODO: INVEST-254
                    savedLocations[city.name] = { ...city, label: city.name };
                }
            });

            return {

                ...filters,
                city: filters.locations ? filters.locations : [],
                officeCity: filters.officeLocations ? filters.officeLocations : [],
                wantedKeywords: filters.wantedKeywords ? filters.wantedKeywords : [],
                unwantedKeywords: filters.unwantedKeywords ? filters.unwantedKeywords : [],
                filterId: filterId,
                name: filters.name ? filters.name : '',
                pushAlert: filters.alertPush ? filters.alertPush : false,
                alertEmail: filters.alertEmail ? filters.alertEmail : false,
                alertSms: filters.alertSms ? filters.alertSms : false,
                module: filters.module ? filters.module : '',
                notificationsDelay: filters.notificationsDelay !== null && filters.notificationsDelay !== undefined
                    ? filters.notificationsDelay.toString()
                    : '0',
                includeZeroArea: filters.includeZeroArea ? filters.includeZeroArea : false,
                offerAddedFrom: filters.offerAddedFrom ? filters.offerAddedFrom : '',
                offerAddedTo: filters.offerAddedTo ? filters.offerAddedTo : '',
                savedFilter: filters.savedFilter ? filters.savedFilter : false,
                locations: [],
                savedLocations: savedLocations,
                searchLocationResult: []
            };
        })
        .catch(() => {
            activeNotification('Problem z załadowaniem danych', 'Niestety nie udało się załadować danych. Spróbuj ponownie później', 'danger');
        });
};

const removeSavedFilter = (filterId: number) => {
    return axiosApiInstance
        .delete(`/cooperative-offer/filter/delete?id=${filterId}`)
        .then(() => {
            activeNotification('Udało się!', 'Filtr został usunięty', 'success');
        })
        .catch(() => {
            activeNotification('Problem z usunięciem', 'Niestety nie udało się usunąć danych. Spróbuj ponownie później', 'danger');
        });
};

const updateFilter = async (params: CooperativeFilterReturnData) => {
    return axiosApiInstance({
        method: 'put',
        url: '/cooperative-offer/filter/edit',
        data: {
            ...params,
            locations: params.city
        },
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        }
    })
        .then((resp) => {
            activeNotification('Udało się!', 'Filtr został zaktualizowany', 'success');

            return resp;
        })
        .catch((err) => {
            activeNotification('Problem z zapisem', 'Niestety nie udało się zapisać danych. Spróbuj ponownie później', 'danger');

            return Promise.reject(err);
        });
};

const addFilter = (params: CooperativeFilterReturnData): Promise<AxiosResponse<number>> => {
    const savedLocations = Object.values(params.savedLocations).map((x) => {
        // @ts-expect-error dont need districts and cities
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { districts, cities, ...rest } = x;

        return { ...rest };
    });

    return axiosApiInstance({
        method: 'post',
        url: '/cooperative-offer/filter/create',
        data: {
            ...params,
            locations: savedLocations

        },
        paramsSerializer: function (params) {
            return qs.stringify(params);
        }
    })
        .then((resp) => {
            activeNotification('Udało się!', 'Filtr został dodany', 'success');

            return resp;
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                activeNotification(
                    'Błąd',
                    err.response.data,
                    'danger'
                );

                return Promise.reject(err);
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );

                return Promise.reject(err);
            }
        });
};

const getCooperativeOffers = (params: CooperativeFilterReturnData, page: number, size: number): Promise<CooperativeOffersResponse> => {
    return axiosApiInstance({
        method: 'post',
        url: '/cooperative-offer/advertisement',
        params: {
            page,
            size
        },
        data: {
            ...params,
            locations: params.city
        }
    })
        .then((resp) => {
            if (resp.data) {
                return {
                    ...resp.data,
                    content: resp.data.content.map((el: CooperativeOfferResponseContent) => ({
                        ...el,
                        city: el.location,
                        dateAdded: el.dateAdded,
                        reaction: {
                            likeStatus: el.reaction?.likeStatus,
                            unlikeStatus: el.reaction?.unlikeStatus,
                            viewedStatus: el.reaction?.viewedStatus,
                            outdatedStatus: el.reaction?.outdatedStatus,
                            arrangedStatus: el.reaction?.arrangedStatus,
                            phoneStatus: el.reaction?.phoneStatus,
                            hideStatus: el.reaction?.hideStatus,
                            openedStatus: el.reaction?.openedStatus
                        }
                    }))
                };
            }

            return { empty: true, content: [], afterSearch: true };
        })
        .catch(() => {
            activeNotification(
                'Nie znaleziono wyników',
                'Wyszukiwanie nie powiodło się, spróbuj jeszcze raz.',
                'warning'
            );

            return { empty: true, content: [], afterSearch: true };
        });
};

const getCooperativeOfferDetails = async (id: number): Promise<CooperativeOfferDetailsResponse> => {
    return axiosApiInstance
        .get(`/cooperative-offer/advertisement/details?id=${id}`)
        .then((resp) => {
            if (resp.data) {
                return {
                    ...resp.data
                };
            }
        })
        .catch(() => {
            activeNotification(
                'Nie udało się pobrać ogłoszenia',
                'Nie udało się pobrać danych szczegółowych ogłoszenia',
                'warning'
            );

            return [{ empty: true }];
        });
};

const getCooperativeOfferByHashId = async (hash: string): Promise<CooperativeDetailsResponse> => {
    return axiosApiInstance
        .get(`/cooperative-offer/single-advertisement/detail?hash=${hash}`)
        .then((resp) => {
            if (resp.data) {
                return {
                    ...resp.data,
                    price: resp.data.price ? convertPrice(resp.data.price) : ''
                };
            }
        })
        .catch(() => {
            activeNotification(
                'Nie udało się pobrać ogłoszenia',
                'Nie udało się pobrać danych szczegółowych ogłoszenia',
                'warning'
            );

            return null;
        });
};

const addPortalUrl = async (link: string) => {
    return axiosApiInstance({
        method: 'post',
        url: '/cooperative-offer/portal/add',
        data: {
            link
        }
    })
        .then((resp) => {
            activeNotification('Udało się!', 'Portal został dodany', 'success');

            return resp;
        })
        .catch((err) => {
            activeNotification('Problem z zapisem', 'Niestety nie udało się zapisać danych. Spróbuj ponownie później', 'danger');

            return Promise.reject(err);
        });
};

const getPortalList = (savedLocations: SavedLocationsType) => {
    // @ts-expect-error todo: add subRegionNumber to Location.tsx
    const locations: SavedLocationsType[] = Object.values(savedLocations).map((location) => ({
        communityNumber: location.communityNumber,
        countyNumber: location.countyNumber,
        kindNumber: location.kindNumber,
        label: location.fullName,
        name: location.fullName,
        regionNumber: location.regionNumber,
        streetNumber: location.streetNumber,
        // @ts-expect-error todo: add subRegionNumber to Location.tsx
        subRegionNumber: location.subRegionNumber || null,
        voivodeshipNumber: location.voivodeshipNumber
    }));

    return axiosApiInstance({
        method: 'post',
        url: '/cooperative-offer/portal/list',
        data: {
            locations
        },
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then((resp) => {
            if (resp.data) {
                return resp.data;
            }

            return null;
        })
        .catch((err) => {
            activeNotification('Problem z zapisem', 'Niestety nie udało się porbać portali. Spróbuj ponownie później', 'danger');
            // eslint-disable-next-line no-console
            console.error('Błąd:', err);

            return Promise.reject(err);
        });
};

export const cooperativeOffersApiRequests = {
    getSavedFilters,
    getFilterDetailsCooperative,
    removeSavedFilter,
    updateFilter,
    addFilter,
    getCooperativeOffers,
    getCooperativeOfferDetails,
    getCooperativeOfferByHashId,
    addPortalUrl,
    getPortalList
};
