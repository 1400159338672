import React from 'react';
import dayjs from 'dayjs';
import { getNameFromBrokerModule } from 'utils/getNameFromModule';
import { BrokerOfferResponseContent } from 'utils/state-managment/broker/brokerOffer';
import { BrokerOfferModule } from 'utils/types/BrokerOfferModule';

import { InfoElementType } from 'components/common/Card/common/V2CardBodyDefault/InfoElements';
import { IconEnum } from 'components/common/Icon';
import { V2DetailsTabsProps } from 'components/common/Tabs/tabs/V2DetailsTab';

type Offer = Omit<BrokerOfferResponseContent, 'detailedContent' | 'encryptedId'> & {
    detailedContent?: {
        description?: string;
    }
};

const generateInfoElements = (offer: Offer): InfoElementType[] => {
    return [
        {
            icon: IconEnum.BUILDING,
            isVisible: !!offer.module,
            children: <b>{getNameFromBrokerModule(offer.module as unknown as BrokerOfferModule)}</b>
        },
        {
            icon: IconEnum.WORK,
            isVisible: !!offer.typeOfMarket,
            children: <>Rynek <b>{offer.typeOfMarket}</b></>
        },
        {
            icon: IconEnum.SQUARE,
            isVisible: !!offer.area,
            children: <><b>{offer.area}</b> m<sup>2</sup></>
        },
        {
            icon: IconEnum.BED,
            isVisible: !!offer.numberOfRooms,
            children: <><b>{offer.numberOfRooms}</b> pokoje</>
        },
        {
            icon: IconEnum.TWO_TYPE,
            isVisible: !!offer.buildingType,
            children: <><b>{offer.buildingType}</b></>
        },
        {
            icon: IconEnum.CALENDAR,
            isVisible: !!offer.builtYear,
            children: <><b>{offer.builtYear}</b></>
        }
    ];
};

const generateOfferDetailsTabContent = (offer: BrokerOfferResponseContent) => {
    const detailsElements = [
        {
            icon: IconEnum.CALENDAR,
            text: 'Data dodania:',
            value: dayjs(offer.dateAdded).format('DD.MM.YYYY, HH:mm')
        },
        ...offer.price
            ? [
                {
                    icon: IconEnum.PRICE,
                    text: 'Cena:',
                    value: `${offer.price} złotych`
                }
            ]
            : [],
        ...offer.pricePerSquareMeter
            ? [
                {
                    icon: IconEnum.PRICE_PART,
                    text: 'Cena za m<sup>2</sup>:',
                    value: `${offer.pricePerSquareMeter} złotych`
                }
            ]
            : [],
        ...'rentPrice' in offer && offer.rentPrice
            ? [
                {
                    icon: IconEnum.PRICE,
                    text: 'Czynsz:',
                    value: `${offer.rentPrice} złotych`
                }
            ]
            : [],
        ...'depositPrice' in offer && offer.depositPrice
            ? [
                {
                    icon: IconEnum.PRICE_PART,
                    text: 'Kaucja:',
                    value: `${offer.depositPrice} zł`
                }
            ]
            : [],
        ...offer.area
            ? [
                {
                    icon: IconEnum.SQUARE,
                    text: 'Metraż:',
                    value: `${offer.area} m<sup>2</sup>`
                }
            ]
            : [],
        ...offer.numberOfRooms
            ? [
                {
                    icon: IconEnum.BED,
                    text: 'Liczba pokoi:',
                    value: `${offer.numberOfRooms} pokoje`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.floor
            ? [
                {
                    icon: IconEnum.FLOOR_LEVEL,
                    text: 'Piętro:',
                    value: `${offer.detailedContent.floor} piętro`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.floors
            ? [
                {
                    icon: IconEnum.FLOOR_LEVEL,
                    text: 'Liczba pięter:',
                    value: `${offer.detailedContent.floors} piętro`
                }
            ]
            : [],
        ...offer.module
            ? [
                {
                    icon: IconEnum.LEAVE,
                    text: 'Typ nieruchomości:',
                    value: getNameFromBrokerModule(offer.module as unknown as BrokerOfferModule)
                }
            ]
            : [],
        ...offer.buildingType
            ? [
                {
                    icon: IconEnum.TWO_TYPE,
                    text: 'Rodzaj budynku:',
                    value: offer.buildingType
                }
            ]
            : [],
        ...offer.builtYear
            ? [
                {
                    icon: IconEnum.CALENDAR,
                    text: 'Rok budowy:',
                    value: offer.builtYear.toString()
                }
            ]
            : [],
        ...offer.typeOfMarket
            ? [
                {
                    icon: IconEnum.WORK,
                    text: 'Rodzaj rynku:',
                    value: offer.typeOfMarket
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.propertyForm
            ? [
                {
                    icon: IconEnum.BUILDING,
                    text: 'Forma własności mieszkania:',
                    value: offer.detailedContent.propertyForm
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.availableFrom
            ? [
                {
                    icon: IconEnum.SQUARE_TIMER,
                    text: 'Dostępne od:',
                    value: dayjs(offer.detailedContent.availableFrom).format('DD.MM.YYYY')
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.window
            ? [
                {
                    icon: IconEnum.TWO_TYPE,
                    text: 'Okna:',
                    value: offer.detailedContent.window
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.attic
            ? [
                {
                    icon: IconEnum.SQUARE,
                    text: 'Poddasze:',
                    value: offer.detailedContent.attic
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.roof
            ? [
                {
                    icon: IconEnum.BOOKMARK,
                    text: 'Dach:',
                    value: offer.detailedContent.roof
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.roofMaterial
            ? [
                {
                    icon: IconEnum.BUILDING,
                    text: 'Pokrycie dachu:',
                    value: offer.detailedContent.roofMaterial
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.additionalInformation && (offer.detailedContent.additionalInformation as unknown as []).length > 0
            ? [
                {
                    icon: IconEnum.INFO,
                    text: 'Informacje dodatkowe:',
                    value: `${(offer.detailedContent.additionalInformation as unknown as []).join(', ')}`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.propertyCondition
            ? [
                {
                    icon: IconEnum.WORK,
                    text: 'Stan nieruchomości:',
                    value: offer.detailedContent.propertyCondition
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.dimensions
            ? [
                {
                    icon: IconEnum.SQUARE,
                    text: 'Wymiary działki:',
                    value: offer.detailedContent.dimensions
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.roofMaterial
            ? [
                {
                    icon: IconEnum.BUILDING,
                    text: 'Materiał dachu:',
                    value: offer.detailedContent.roofMaterial
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.heating
            ? [
                {
                    icon: IconEnum.BUILDING,
                    text: 'Ogrzewanie:',
                    value: offer.detailedContent.heating
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.media && (offer.detailedContent.media as []).length > 0
            ? [
                {
                    icon: IconEnum.BUILDING,
                    text: 'Media:',
                    value: `${(offer.detailedContent.media as unknown as []).join(', ')}`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.location
            ? [
                {
                    icon: IconEnum.WORK,
                    text: 'Położenie:',
                    value: offer.detailedContent.location
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.recreationalHouse
            ? [
                {
                    icon: IconEnum.WORK,
                    text: 'Dom rekreacyjny:',
                    value: offer.detailedContent.recreationalHouse ? 'Tak' : 'Nie'
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.security && (offer.detailedContent.security as []).length > 0
            ? [
                {
                    icon: IconEnum.BOOKMARK,
                    text: 'Zabezpieczenia budynku:',
                    value: `${(offer.detailedContent.security as unknown as []).join(', ')}`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.fence && (offer.detailedContent.fence as []).length > 0
            ? [
                {
                    icon: IconEnum.TWO_TYPE,
                    text: 'Ogrodzenie budynku:',
                    value: `${(offer.detailedContent.fence as unknown as []).join(', ')}`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.access && (offer.detailedContent.access as []).length > 0
            ? [
                {
                    icon: IconEnum.LINK,
                    text: 'Dojazd do budynku:',
                    value: `${(offer.detailedContent.access as unknown as []).join(', ')}`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.neighborhood && (offer.detailedContent.neighborhood as []).length > 0
            ? [
                {
                    icon: IconEnum.LINK,
                    text: 'Otoczenie budynku:',
                    value: `${(offer.detailedContent.neighborhood as unknown as []).join(', ')}`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.flooring
            ? [
                {
                    icon: IconEnum.BUILDING,
                    text: 'Posadzka:',
                    value: offer.detailedContent.flooring
                }
            ]
            : [],

        ...offer.detailedContent && offer.detailedContent.buildingRooms && (offer.detailedContent.buildingRooms as []).length > 0
            ? [
                {
                    icon: IconEnum.BUILDING,
                    text: 'Pomieszczenia użytkowe:',
                    value: `{${(offer.detailedContent.buildingRooms as unknown as []).join(', ')}}`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.buildingPurpose && (offer.detailedContent.buildingPurpose as []).length > 0
            ? [
                {
                    icon: IconEnum.BUILDING,
                    text: 'Przeznaczenie budynku:',
                    value: `{${(offer.detailedContent.buildingPurpose as unknown as []).join(', ')}}`
                }
            ]
            : [],
        ...offer.detailedContent && offer.detailedContent.equipment && (offer.detailedContent.equipment as []).length > 0
            ? [
                {
                    icon: IconEnum.BUILDING,
                    text: 'Wyposażenie mieszkania:',
                    value: `${(offer.detailedContent.equipment as unknown as []).join(', ')}`
                }
            ]
            : []

    ];

    const offerDetailsTabContent: V2DetailsTabsProps = {
        title: offer.title,
        address: offer.city.fullName,
        detailsElements,
        description: offer.detailedContent?.description
    };

    return offerDetailsTabContent;
};

export const brokerOfferListFunctions = {
    generateInfoElements,
    generateOfferDetailsTabContent
};
