const removeNullProps = (obj: object) => {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    const newObj = Array.isArray(obj) ? [] : {};

    Object.keys(obj).forEach((key) => {
        const value = obj[key as keyof typeof obj];

        if (value !== null) {
            // @ts-expect-error its not empty obj
            newObj[key] = removeNullProps(value);
        }
    });

    if (Object.keys(newObj).length === 0) {
        return undefined;
    }

    return newObj;
};

export default removeNullProps;
