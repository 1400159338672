import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useWindowWidth } from 'utils/useWindowWidth';

import { BoxContainer } from 'components/common/BoxContainer';
import { User } from 'components/common/Card/common/V2CardBodyDefault/Top/Top';
import { NotInteractiveElement } from 'components/common/Card/common/V2InteractiveIconsWrapper/NotInteractiveIcons';
import V2InteractiveIconsWrapper, {
    InteractiveElements
} from 'components/common/Card/common/V2InteractiveIconsWrapper/V2InteractiveIconsWrapper';
import { IconEnum } from 'components/common/Icon';

import Tab from './common/Tab';
import TabPanel from './common/TabPanel';
import OpinionTab, { OpinionTabProps } from './tabs/OpinionTab';
import V2AdditionalTab, { V2AdditionalTabProps } from './tabs/V2AdditionalTab';
import V2DetailsTab, { V2DetailsTabsProps } from './tabs/V2DetailsTab';
import V2DuplicatesTab, { V2DuplicatesTabProps } from './tabs/V2DuplicatesTab';
import V2MessagesTab from './tabs/V2MessagesTab';
import V2ModificationsTab, { V2ModificationsTabProps } from './tabs/V2ModificationsTab';
import V2NoteTab, { V2NoteTabProps } from './tabs/V2NoteTab';

const TabWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    gap: 10px;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--color-alt-second-10) var(--color-background);
    scroll-behavior: smooth;
    padding: 10px 0;
    margin: 0 20px;

    ::-webkit-scrollbar-thumb {
        background-color: var(--color-primary);
        border: none;
    }

    ::-webkit-scrollbar {
        height: 4px;
    }

    @media (min-width: 1100px) {
        justify-content: flex-start;
        overflow: hidden;
    }
       
    & > button:first-child {
        width: fit-content;
        @media (max-width: 1100px) {
            margin-right: unset;
            width: unset;
        }
    }

    & > button:last-child {
        margin-right: 10px;
    }
`;

const Badger = styled.span`
    color: var(--color-white);
    background-color: var(--color-primary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 0;
    right: -15px;
`;

export enum TabType {
    DETAILS = 1,
    DUPLICATES = 2,
    MODIFICATIONS = 3,
    NOTES = 4,
    ADDITIONAL = 5,
    MESSAGES = 6,
    OPINIONS = 7,
}

export interface V2TabsProperties {
    toggleContainerDetails?: (e: React.MouseEvent) => void;
    tabIndex?: number;
    isNotInteractive?: boolean;
    notInteractiveElements?: NotInteractiveElement[];
    interactiveElements?: InteractiveElements[];
    userDetails?: User;
    numberOfDuplicates?: number;
    numberOfModifications?: number;
    numberOfNotes?: number;
    detailsTabContent: V2DetailsTabsProps;
    duplicatesTabContent?: V2DuplicatesTabProps;
    modificationTabContent?: V2ModificationsTabProps;
    notesTabContent?: V2NoteTabProps;
    opinionTabContent?: OpinionTabProps;
    additionalTabContent?: V2AdditionalTabProps;
    handleLoadDuplicates?: () => Promise<void>;
    handleLoadNotes?: () => void;
    messagesTabContent?: boolean;
    handleLoadComments?: () => void;
    closeDetailsTabText?: string;
    ratingScore?: number;
    scorePrecision?: number;
}

const V2Tabs: FC<V2TabsProperties> = ({
    toggleContainerDetails,
    tabIndex,
    isNotInteractive,
    notInteractiveElements,
    interactiveElements,
    userDetails,
    numberOfDuplicates,
    numberOfModifications,
    detailsTabContent,
    duplicatesTabContent,
    modificationTabContent,
    notesTabContent,
    additionalTabContent,
    handleLoadDuplicates,
    handleLoadNotes,
    messagesTabContent,
    numberOfNotes,
    opinionTabContent,
    handleLoadComments,
    closeDetailsTabText
}) => {
    const [activeTabIndex, setActiveTabIndex] = useState(1);
    const tabWrapperRef = useRef<HTMLDivElement>(null);
    const width = useWindowWidth();

    useEffect(() => {
        if (tabIndex) setActiveTabIndex(tabIndex);
    }, [tabIndex]);

    const noteCount = notesTabContent && notesTabContent.notes ? notesTabContent.notes.length : 0;

    const commentsCount = opinionTabContent && opinionTabContent.commentsCount ? opinionTabContent.commentsCount : null;

    const getNoteCount = useCallback(() => {
        const count = numberOfNotes ? numberOfNotes : 0;

        return count > 0 ? <Badger>{count}</Badger> : null;
    }, [noteCount])();

    useEffect(() => {
        handleLoadNotes && handleLoadNotes();
    }, [noteCount]);

    useEffect(() => {
        handleLoadComments && handleLoadComments();
    }, [commentsCount]);

    const getMessagesCount = useCallback(() => {
        const count = userDetails ? userDetails.userMessages.length : 0;

        return count > 0 ? <Badger>{count}</Badger> : null;
    }, [messagesTabContent])();

    const handleDuplicateClick = async () => {
        handleLoadDuplicates && await handleLoadDuplicates().then(() => {
            setActiveTabIndex(TabType.DUPLICATES);
        });
    };

    const onSaveNote = async (value: string, id?: string) => {
        notesTabContent && notesTabContent.handleSaveNote(value, id).then(() => {
            handleLoadNotes && handleLoadNotes();
        });
    };

    const onSaveComment = async (rating: number, value: string, id?: number) => {
        await opinionTabContent?.handleSaveOpinion?.(rating, value, id);
        await handleLoadComments?.();
    };

    return <>
        {width < 991
            ? <BoxContainer>
                <V2InteractiveIconsWrapper
                    className="icons-modal"
                    isModalOpen
                    interactiveElements={interactiveElements}
                    notInteractiveElements={notInteractiveElements}
                    isNotInteractive={isNotInteractive}
                    userDetails={userDetails}
                    openOfferTab={setActiveTabIndex}
                    // onNoteAdd={() => setActiveTabIndex(4)}
                />
            </BoxContainer>
            : null}
        <BoxContainer className="swipeable">
            <TabWrapper ref={tabWrapperRef}>
                {width > 991 && toggleContainerDetails
                    ? <Tab onClick={toggleContainerDetails} icon={IconEnum.LEAVE} label={closeDetailsTabText ? closeDetailsTabText : 'Wróć do wyszukiwania'}/>
                    : null}
                <Tab onClick={() => setActiveTabIndex(TabType.DETAILS)} isActive={TabType.DETAILS === activeTabIndex} label={'Szczegóły'}/>
                {duplicatesTabContent && <Tab disabled={numberOfDuplicates === null || numberOfDuplicates === 0} onClick={handleDuplicateClick} isActive={TabType.DUPLICATES === activeTabIndex} label={'Duplikaty'}>
                    {numberOfDuplicates ? <Badger>{numberOfDuplicates}</Badger> : null}
                </Tab>}
                {modificationTabContent && <Tab disabled={numberOfModifications === null || numberOfModifications === 0}
                    onClick={() => setActiveTabIndex(TabType.MODIFICATIONS)} isActive={TabType.MODIFICATIONS === activeTabIndex} label={'Modyfikacje'}>
                    {numberOfModifications ? <Badger>{numberOfModifications}</Badger> : null}
                </Tab>}
                {opinionTabContent && <Tab onClick={() => setActiveTabIndex(TabType.OPINIONS)} isActive={TabType.OPINIONS === activeTabIndex}
                    label={'Opinie'}>{commentsCount !== null ? <Badger>{commentsCount}</Badger> : null}</Tab>}
                {notesTabContent && <Tab onClick={() => setActiveTabIndex(TabType.NOTES)} isActive={TabType.NOTES === activeTabIndex}
                    label={'Notatki'}>{getNoteCount}</Tab>}
                {additionalTabContent && <Tab disabled={additionalTabContent?.keys.length === 0} onClick={() => setActiveTabIndex(TabType.ADDITIONAL)}
                    isActive={TabType.ADDITIONAL === activeTabIndex}
                    label={'Pozostałe'}/>}
                {messagesTabContent && <Tab onClick={() => setActiveTabIndex(TabType.MESSAGES)} label={'Wiadomości'}>{getMessagesCount}</Tab> }
            </TabWrapper>
        </BoxContainer>

        <TabPanel isActive={TabType.DETAILS === activeTabIndex}>
            <V2DetailsTab {...detailsTabContent} />
        </TabPanel>
        {duplicatesTabContent
            ? <TabPanel isActive={TabType.DUPLICATES === activeTabIndex}>
                <V2DuplicatesTab {...duplicatesTabContent} />
            </TabPanel>
            : null}
        {modificationTabContent
            ? <TabPanel isActive={TabType.MODIFICATIONS === activeTabIndex}>
                <V2ModificationsTab {...modificationTabContent} />
            </TabPanel>
            : null}
        {opinionTabContent
            ? <TabPanel isActive={TabType.OPINIONS === activeTabIndex}>
                <OpinionTab {...opinionTabContent} handleSaveOpinion={onSaveComment} />
            </TabPanel>
            : null}
        {notesTabContent
            ? <TabPanel isActive={TabType.NOTES === activeTabIndex}>
                <V2NoteTab {...notesTabContent} handleSaveNote={onSaveNote}/>
            </TabPanel>
            : null}
        {additionalTabContent
            ? <TabPanel isActive={TabType.ADDITIONAL === activeTabIndex}>
                <V2AdditionalTab {...additionalTabContent} />
            </TabPanel>
            : null}
        {messagesTabContent && userDetails
            ? <TabPanel isActive={TabType.MESSAGES === activeTabIndex}>
                <V2MessagesTab userDetails={userDetails}/>
            </TabPanel>
            : null}
    </>;
};

export default V2Tabs;
