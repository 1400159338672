import { ManageAdvertStructureType } from 'pages/AdvertManagementPage/AdvertManagementPage';
import { generateOptionsFromEnum } from 'utils/generateOptionsFromEnum';
import { BrokerCreateAdvertStateType } from 'utils/types/BrokerAdvert';
import { PlotBuildingType } from 'utils/types/BrokerOfferModels';
import { InputType, ObjectAndSingleValueChangeType } from 'utils/types/InputTypes';

import { IconEnum } from 'components/common/Icon';

type HandleChangeType = (name: string, value: ObjectAndSingleValueChangeType) => void;

export const getSalePlotStructure = (brokerAdvertFieldsState: BrokerCreateAdvertStateType, handleChange: HandleChangeType): ManageAdvertStructureType => {
    return {
        Parametry: [
            {
                title: 'Metraż',
                type: InputType.NUMERIC,
                icon: IconEnum.SQUARE,
                placeholder: 'Wpisz metraż...',
                options: {
                    [InputType.NUMERIC]: {
                        modulesProps: {
                            value: brokerAdvertFieldsState.area || '',
                            fieldName: 'area',
                            suffix: 'm²'
                        },
                        contextFunctions: {
                            handleChange: (name, value) => {
                                handleChange(name, value);
                                handleChange('pricePerSquareMeter', brokerAdvertFieldsState.price ? (brokerAdvertFieldsState.price / Number(value)).toFixed(2) : 0);
                            }
                        }
                    }
                }
            },
            {
                title: 'Cena',
                type: InputType.NUMERIC,
                icon: IconEnum.CALENDAR,
                placeholder: 'Wpisz kwotę...',
                options: {
                    [InputType.NUMERIC]: {
                        contextFunctions: {
                            handleChange: (name, value) => {
                                handleChange(name, value);
                                handleChange('pricePerSquareMeter', brokerAdvertFieldsState.area ? (Number(value) / brokerAdvertFieldsState.area).toFixed(2) : 0);
                            }
                        },
                        modulesProps: {
                            fieldName: 'price',
                            value: brokerAdvertFieldsState.price || '',
                            suffix: 'PLN'
                        }
                    }
                }
            },
            {
                title: 'Cena za metr',
                type: InputType.NUMERIC,
                icon: IconEnum.PRICE_PART,
                placeholder: '',
                options: {
                    [InputType.NUMERIC]: {
                        modulesProps: {
                            value: brokerAdvertFieldsState.pricePerSquareMeter || '',
                            fieldName: 'pricePerSquareMeter',
                            suffix: 'zł/m²',
                            disabled: true
                        },
                        contextFunctions: {
                            handleChange
                        }
                    }
                }
            },
            {
                title: 'Wymiary działki',
                type: InputType.TEXT,
                icon: IconEnum.SQUARE,
                placeholder: 'Wpisz wymiary...',
                options: {
                    [InputType.TEXT]: {
                        modulesProps: {
                            value: brokerAdvertFieldsState.dimensions || '',
                            fieldName: 'dimensions'
                        },
                        contextFunctions: {
                            handleChange
                        }
                    }
                }
            }
        ],
        'Parametry 2': [
            {
                title: 'Rodzaj nieruchomości',
                type: InputType.AUTOCOMPLETE,
                icon: IconEnum.LEAVE,
                placeholder: 'Wybierz typ...',
                options: {
                    [InputType.AUTOCOMPLETE]: {
                        contextFunctions: {
                            handleChange
                        },
                        modulesProps: {
                            value: brokerAdvertFieldsState.buildingType || '',
                            options: generateOptionsFromEnum(PlotBuildingType),
                            fieldName: 'buildingType'
                        }
                    }
                }
            }
        ]
    };
};
