import styled from 'styled-components';

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #030D45;
    opacity: 0.2;
    border-radius: 10px;
    margin: auto 0 24px;
`;

export default Divider;
