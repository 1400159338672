import {
    getRentFlatStructure, getRentHouseStructure, getRentOtherStructure, getRentPlotStructure,
    getSaleFlatStructure,
    getSaleHouseStructure,
    getSaleOtherStructure,
    getSalePlotStructure
} from 'pages/AdvertManagementPage/structures';
import { BrokerCreateAdvertStateType, BrokerOfferType } from 'utils/types/BrokerAdvert';
import { ObjectAndSingleValueChangeType } from 'utils/types/InputTypes';
import { AdvertValidatorType } from 'utils/validators/advertAddingValidator';

type HandleChangeType = (name: string, value: ObjectAndSingleValueChangeType) => void;
type ValidateFieldType = (name: AdvertValidatorType, value: ObjectAndSingleValueChangeType) => boolean

export const getParametersSection = (brokerAdvertFieldsState: BrokerCreateAdvertStateType, handleChange: HandleChangeType, type: BrokerOfferType, triggerValidation: boolean, validateField: ValidateFieldType) => {
    const saleHouseStructure = getSaleHouseStructure(brokerAdvertFieldsState, handleChange, triggerValidation, validateField);
    const saleFlatStructure = getSaleFlatStructure(brokerAdvertFieldsState, handleChange, triggerValidation, validateField);
    const salePlotStructure = getSalePlotStructure(brokerAdvertFieldsState, handleChange);
    const saleOtherStructure = getSaleOtherStructure(brokerAdvertFieldsState, handleChange, triggerValidation, validateField);
    const rentFlatStructure = getRentFlatStructure(brokerAdvertFieldsState, handleChange, triggerValidation, validateField);
    const rentHouseStructure = getRentHouseStructure(brokerAdvertFieldsState, handleChange, triggerValidation, validateField);
    const rentOtherStructure = getRentOtherStructure(brokerAdvertFieldsState, handleChange, triggerValidation, validateField);
    const rentPlotStructure = getRentPlotStructure(brokerAdvertFieldsState, handleChange);

    return {
        First: [
            ...type === BrokerOfferType.SALE_HOUSE ? saleHouseStructure.Parametry : [],
            ...type === BrokerOfferType.SALE_PLOT ? salePlotStructure.Parametry : [],
            ...type === BrokerOfferType.SALE_FLAT ? saleFlatStructure.Parametry : [],
            ...type === BrokerOfferType.SALE_OTHER ? saleOtherStructure.Parametry : [],
            ...type === BrokerOfferType.RENT_FLAT ? rentFlatStructure.Parametry : [],
            ...type === BrokerOfferType.RENT_HOUSE ? rentHouseStructure.Parametry : [],
            ...type === BrokerOfferType.RENT_OTHER ? rentOtherStructure.Parametry : [],
            ...type === BrokerOfferType.RENT_PLOT ? rentPlotStructure.Parametry : []
        ],
        Second: [
            ...type === BrokerOfferType.SALE_HOUSE ? saleHouseStructure['Parametry 2'] : [],
            ...type === BrokerOfferType.SALE_PLOT ? salePlotStructure['Parametry 2'] : [],
            ...type === BrokerOfferType.SALE_FLAT ? saleFlatStructure['Parametry 2'] : [],
            ...type === BrokerOfferType.SALE_OTHER ? saleOtherStructure['Parametry 2'] : [],
            ...type === BrokerOfferType.RENT_FLAT ? rentFlatStructure['Parametry 2'] : [],
            ...type === BrokerOfferType.RENT_HOUSE ? rentHouseStructure['Parametry 2'] : [],
            ...type === BrokerOfferType.RENT_OTHER ? rentOtherStructure['Parametry 2'] : [],
            ...type === BrokerOfferType.RENT_PLOT ? rentPlotStructure['Parametry 2'] : []
        ]
    };
};
